import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Col, InputNumber, Row } from 'antd';

const OtherValueAddedService = ({ data, setData }) => {
  const { valueAddedIsEdit } = useSelector((state) => state.valueAddedReducer);
  const inputEls = useRef([]);

  const onChange = (value, name, i) => {
    const newData = [...data];
    newData[i][name] = value;
    let updatedData = { otherValueServices: newData };
    setData((prev) => ({ ...prev, ...updatedData }));
    setTimeout(() => {
      inputEls.current[`${name}_${i}`].focus();
    }, 100);
  };

  const LabelSpan = ({ value }) => {
    if (!valueAddedIsEdit && !value) {
      return <span className='label'>-</span>;
    }
    return <span className='label'>R</span>;
  };

  const HeadingCol = ({ label }) => {
    return (
      <Col className='service-column'>
        <div className='heading-div'>
          <span className='label'>{label}</span>
        </div>
        <hr />
      </Col>
    );
  };

  const DetailCol = ({ name, value, i }) => {
    return (
      <Col className='service-column details-col'>
        <div>
          <LabelSpan value={value} />{' '}
          <InputNumber
            value={value}
            onChange={(e) => onChange(e, name, i)}
            disabled={!valueAddedIsEdit}
            ref={(el) => (inputEls.current[`${name}_${i}`] = el)}
          />
        </div>
      </Col>
    );
  };

  return (
    <Row
      justify='center'
      gutter={[0, 20]}
      className='value-added-service-section'
      key='value-added-service-section'
    >
      <Col md={24} lg={23} xl={23} xxl={23}>
        <Row
          justify='space-between'
          gutter={[40, 0]}
          className='parent-row heading-row'
        >
          <HeadingCol label='DESCRIPTION' />
          <HeadingCol label='ONCE OFF PRICE' />
          <HeadingCol label='24 MONTHS PRICE' />
          <HeadingCol label='12 MONTHS PRICE' />
          <HeadingCol label='MONTH TO MONTH PRICE' />
        </Row>
        {data.map((el, i) => {
          const {
            description,
            onceOffPrice,
            twentyFourMonthsPrice,
            twelveMonthsPrice,
            monthToMonthPrice,
          } = el;

          return (
            <Row
              justify='space-between'
              gutter={[40, 0]}
              className='parent-row'
            >
              <Col className='service-column details-col'>
                <div>
                  <span className='label'>{description}</span>
                </div>
              </Col>
              <DetailCol value={onceOffPrice} name='onceOffPrice' i={i} />
              <DetailCol
                value={twentyFourMonthsPrice}
                name='twentyFourMonthsPrice'
                i={i}
              />
              <DetailCol
                value={twelveMonthsPrice}
                name='twelveMonthsPrice'
                i={i}
              />
              <DetailCol
                value={monthToMonthPrice}
                name='monthToMonthPrice'
                i={i}
              />
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};

export default OtherValueAddedService;
