import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Settings } from '../../../assets/settings.svg';
import { ReactComponent as ReportSummary } from '../../../assets/curriculum.svg';
import { ReactComponent as ImportIcon } from '../../../assets/import.svg';
import { ReactComponent as Users } from '../../../assets/users.svg';
import { ReactComponent as Slots } from '../../../assets/slotIcon.svg';
import { ReactComponent as ClassIcon } from '../../../assets/class.svg';
import { ReactComponent as ClassSession } from '../../../assets/classes-sessions.svg';
import UseGetUserRole from '../../../components/useGetUserRole';
import { capitalizeFirstLetterofEachWord } from '../../../globalUtils/capitalizeFirstLetterofEachWord';
import { baseURL } from '../../../globalUtils/axiosConfig';

const typesArray = ['Teacher', 'Admin'];
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const useSidebar = () => {
  const { user } = useSelector((state) => state.authReducer);
  const [data, setData] = useState();
  const [isSuperAdmin] = UseGetUserRole();
  const [showSettings, setShowSettings] = useState(true);

  useEffect(() => {
    setData(user);

    if (user?.role === 'Admin') {
      setShowSettings(false);
    }
  }, [user]);

  const handleRoleLength = (role) =>
    role.length > 20 ? `${role.substring(0, 18) + ' ...'}` : role;

  const handleNameLength = (role) =>
    role.length > 15 ? role.substring(0, 15) + '...' : role;

  const getRole = () => {
    const { role } = data;

    if (typesArray.includes(role)) {
      return capitalizeFirstLetterofEachWord(role);
    } else {
      return handleRoleLength(data?.job_title || '');
    }
  };

  let name = handleNameLength(data?.first_name + ' ' + data?.last_name || '');

  const menuItemsData = [
    {
      key: '1',
      src:
        data?.profile_picture?.length > 1
          ? `${baseURL}/uploads/${data?.profile_picture}`
          : null,
      SvgImg: false,
      alt: '',
      name: data ? `${name} ${user?.tenant ? `(${user.tenant})` : ''}` : '',
      role: `Timezone: ${timeZone}`,
      link: '/profile',
      hasSubItems: false,
      permission: true,
      hasPermission: true,
    },
    {
      key: '2',
      SvgImg: Users,
      alt: 'User Management',
      name: 'User Management',
      link: '/settings',
      hasSubItems: true,
      // permission: ["super_admin", "admin"],
      hasPermission: true,
      subItems: [
        {
          key: 'users-manager',
          SvgImg: Settings,
          alt: 'Users',
          name: 'Users',
          link: '/settings/users-manager',
          hasSubItems: false,
          hasPermission: true, // user?.permissions?.users_manager?.view
        },
      ],
    },
    {
      key: '3',
      SvgImg: ReportSummary,
      alt: 'Curriculum Management',
      name: 'Curriculum Management',
      link: '/curriculum-management',
      hasSubItems: true,
      hasPermission: true,
      subItems: [
        {
          SvgImg: ReportSummary,
          alt: 'Stickers',
          name: 'Stickers',
          link: '/stickers',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          SvgImg: ReportSummary,
          alt: 'Streams',
          name: 'Streams',
          link: '/streams',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          SvgImg: ReportSummary,
          alt: 'Learning Activities',
          name: 'Learning Activities',
          link: '/learning-activities',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          key: 'books',
          SvgImg: ReportSummary,
          alt: 'Books',
          name: 'Books',
          link: '/books',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          key: 'curriculum-management',
          SvgImg: ReportSummary,
          alt: 'Curriculum Management',
          name: 'Curriculum Management',
          link: '/curriculum-management',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          SvgImg: ReportSummary,
          alt: 'Comments',
          name: 'Comments',
          link: '/comments',
          hasSubItems: false,
          hasPermission: true,
        },
      ],
    },
    {
      key: '7',
      SvgImg: ClassSession,
      alt: 'Class Sessions',
      name: 'Class Sessions',
      link: '/classes-sessions',
      hasSubItems: false,
      hasPermission: true,
    },
    {
      key: '6',
      SvgImg: ClassIcon,
      alt: 'Class Management',
      name: 'Class Management',
      link: '/classes',
      hasSubItems: false,
      hasPermission: true,
    },
    {
      key: '5',
      SvgImg: Slots,
      alt: 'Slots Management',
      name: 'Slots Management',
      link: '/slots-management',
      hasSubItems: true,
      hasPermission: true,
      subItems: [
        {
          key: 'rooms',
          SvgImg: Settings,
          alt: 'Rooms',
          name: 'Rooms',
          link: '/rooms',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          key: 'slots',
          SvgImg: Settings,
          alt: 'Slots',
          name: 'Slots',
          link: '/slots',
          hasSubItems: false,
          hasPermission: true,
        },
      ],
    },
    {
      key: '4',
      SvgImg: ImportIcon,
      alt: 'Data Management',
      name: 'Data Management',
      link: '/import',
      hasSubItems: true,
      hasPermission: true,
      subItems: [
        {
          key: 'enroll-students',
          SvgImg: ImportIcon,
          alt: 'Students Enrolment',
          name: 'Students Enrolment',
          link: '/enroll-students',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          key: 'import-stickers',
          SvgImg: ReportSummary,
          alt: 'Import Stickers',
          name: 'Import Stickers',
          link: '/import-stickers',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          key: 'import-zip',
          SvgImg: ReportSummary,
          alt: 'Import Users Classes',
          name: 'Import Users Classes',
          link: '/import-zip',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          key: 'import-profile-images',
          SvgImg: ReportSummary,
          alt: 'Upload Profile Images',
          name: 'Upload Profile Images',
          link: '/import-profile-images',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          key: 'import-observations',
          SvgImg: ReportSummary,
          alt: 'Import Observations',
          name: 'Import Observations',
          link: '/import-observations',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          key: 'import-rooms',
          SvgImg: Settings,
          alt: 'Import Rooms',
          name: 'Import Rooms',
          link: '/import-rooms',
          hasSubItems: false,
          hasPermission: true,
        },
        {
          key: 'import-slots',
          SvgImg: Settings,
          alt: 'Import Slots',
          name: 'Import Slots',
          link: '/import-slots',
          hasSubItems: false,
          hasPermission: true,
        },
      ],
    },
  ];

  return [menuItemsData];
};

export default useSidebar;
