import * as constants from './constants';

export const fetchStreams = (data) => {
  return {
    type: constants.FETCH_STREAMS,
    data,
  };
};

export const fetchStreamsSuccess = (data) => {
  return {
    type: constants.FETCH_STREAMS_SUCCESS,
    data: data,
  };
};

export const fetchStreamsError = (data) => {
  return {
    type: constants.FETCH_STREAMS_ERROR,
    data: data,
  };
};

export const updateStreams = (data) => {
  return {
    type: constants.UPDATE_STREAMS,
    data: data,
  };
};

export const updateStreamsSuccess = (data) => {
  return {
    type: constants.UPDATE_STREAMS_SUCCESS,
    data: data,
  };
};

export const updateStreamsError = () => {
  return {
    type: constants.UPDATE_STREAMS_ERROR,
  };
};

export const updateStreamsOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_STREAMS,
    data: data,
  };
};

export const updateStreamsOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_STREAMS_SUCCESS,
    data: data,
  };
};

export const updateStreamsOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_STREAMS_ERROR,
  };
};

export const deleteStreams = (data) => {
  return {
    type: constants.DELETE_STREAMS,
    data: data,
  };
};

export const deleteStreamsSuccess = (data) => {
  return {
    type: constants.DELETE_STREAMS_SUCCESS,
    data: data,
  };
};

export const deleteStreamsError = () => {
  return {
    type: constants.DELETE_STREAMS_ERROR,
  };
};

export const createStreams = (data, id) => {
  return {
    type: constants.CREATE_STREAMS,
    data: data,
  };
};

export const createStreamsSuccess = (data) => {
  return {
    type: constants.CREATE_STREAMS_SUCCESS,
    data: data,
  };
};

export const createStreamsError = (id) => {
  return {
    type: constants.CREATE_STREAMS_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};

export const setStorage = (data) => {
  return {
    type: constants.SET_STREAMS_MANAGER_STATE,
    data,
  };
};
