import { useEffect, useState } from 'react';
import { message as Alert, Form } from 'antd';
import axios from 'axios';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllNpos } from '../../../LearningActivities/redux/actions';

let url = `${baseURL}/api/v2/admin/curriculums`;

const useFirstStep = () => {
  const [isEdit, setIsEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fetchOutlinesLoading, setFetchOutlines] = useState(false);
  const [value, setValue] = useState([]);
  const params = useParams();
  const [form] = Form.useForm();
  const [currentLaId, setCurrentLaId] = useState(null);

  const [data, setData] = useState({
    books: [],
    difficultyLevels: [],
    curriculumStatus: ['Active', 'Inactive'],
    academicYears: [],
    currentObj: {
      name: null,
      book: null,
      learning_activity_id: null,
      duration: null,
      nominal_term: null,
      nominal_year_level: null,
      outlines: [],
    },
  });

  const allLearningActivities = useSelector(
    (state) => state.learningActivitiesReducer.allNpos
  );

  const dispatch = useDispatch();

  function convertToTreeData(arr, parentKey = '0', parentTitle = '') {
    return arr.map((item, index) => {
      const key = `${parentKey}-${index}`;
      const fullTitle = parentTitle
        ? `${parentTitle} > ${item.title}`
        : item.title;
      const children =
        item?.children?.length > 0
          ? convertToTreeData(item.children, key, fullTitle)
          : null;

      return {
        title: item.title,
        fullTitle,
        value: key,
        key: key,
        children: children,
      };
    });
  }

  const getOutlines = async (book_id) => {
    setFetchOutlines(true);
    try {
      const res = await axios.post(
        `${baseURL}/api/v2/admin/books/read-outlines`,
        {
          book_id,
        }
      );
      const { outlines } = res.data.data;
      setData((prev) => ({
        ...prev,
        currentObj: {
          ...prev.currentObj,
          outlines: convertToTreeData(outlines),
        },
      }));
      setFetchOutlines(false);
    } catch (error) {
      console.log('error', error);
      setFetchOutlines(false);
    }
  };

  const getLearningActivities = async (book_id) => {
    try {
      const res = await axios.post(
        `${baseURL}/api/v2/admin/books/learning-activities`,
        {
          book_id,
        }
      );
      const { learning_activities } = res.data.data;
      setData((prev) => ({
        ...prev,
        activities: learning_activities,
        currentObj: {
          ...prev.currentObj,
          outlines: [],
        },
      }));
      await getOutlines(book_id);
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchById = async (id) => {
    try {
      const res = await axios.get(`${url}/fetch-by-id/${id}`);
      const { curriculum } = res.data.data;
      const { treeOutlines } = curriculum;

      let outlineIds = treeOutlines
        .filter((outline) => outline?.key)
        .map((outline) => outline.key);

      setData((prev) => ({
        ...prev,
        currentObj: {
          ...curriculum,
          book_id: curriculum.book,
          learning_activity_id:
            curriculum?.learning_activity_book?.learning_activity,
          outlines: [],
        },
      }));
      if (curriculum?.learning_activity_book?.learning_activity) {
        setCurrentLaId(curriculum.learning_activity_book.learning_activity);
      }
      setTimeout(() => {
        form.setFieldsValue({
          ...curriculum,
          book_id: curriculum.book,
          learning_activity_id:
            curriculum?.learning_activity_book?.learning_activity,
        });
      }, 200);
      await getOutlines(curriculum.book);
      // await getLearningActivities(curriculum.book);

      setValue(outlineIds);
    } catch (error) {
      console.log('error', error);
    }
  };

  // const getAcademicYears = async () => {
  //   try {
  //     const res = await axios.get(`${url}/academicYears`);
  //     const { academic_years } = res.data.data;
  //     setData((prev) => ({ ...prev, academicYears: academic_years }));
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  const handleCreate = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(`${url}`, data);
      const { status } = res;
      if (status === 200) {
        setLoading(false);
        const { curriculum } = res.data.data;
        setData((prev) => ({
          ...prev,
          currentObj: { ...curriculum, outlines: prev.currentObj.outlines },
        }));
        Alert.success(res.data.message);
        setIsEdit(false);
      }
    } catch (error) {
      setLoading(false);
      const { message } = error.response.data;
      Alert.error(message);
      console.log('error', message);
    }
  };

  const handleUpdate = async (data) => {
    try {
      setLoading(true);
      const res = await axios.put(`${url}`, data);
      const { status } = res;
      if (status === 200) {
        setLoading(false);
        const { curriculum } = res.data.data;
        setData((prev) => ({
          ...prev,
          currentObj: { ...curriculum, outlines: prev.currentObj.outlines },
        }));
        Alert.success(res.data.message);
        setIsEdit(false);
      }
    } catch (error) {
      setLoading(false);
      const { message } = error.response.data;
      Alert.error(message);
      console.log('error', message);
    }
  };

  useEffect(() => {
    dispatch(
      fetchAllNpos({
        search: {},
      })
    );
  }, []);

  useEffect(() => {
    if (currentLaId) {
      const selectedLearningActivity = allLearningActivities.find(
        (item) => item._id === currentLaId
      );
      const learning_activity_books =
        selectedLearningActivity?.learning_activity_books || [];

      let allBooks = [];
      for (let i = 0; i < learning_activity_books.length; i++) {
        const { book } = learning_activity_books[i];
        if (book) {
          allBooks.push(book);
        }
      }

      setData((prev) => ({
        ...prev,
        books: allBooks,
      }));
    }
  }, [currentLaId, allLearningActivities]);

  useEffect(() => {
    // getDifficultyLevels();
    // getAcademicYears();
    if (params.id) {
      fetchById(params.id);
      setIsEdit(true);
    }
  }, [params]);

  const onChangeLearningActivity = (value) => {
    setCurrentLaId(value);
  };

  return {
    data,
    isEdit,
    setIsEdit,
    loading,
    handleCreate,
    handleUpdate,
    setData,
    getOutlines,
    value,
    setValue,
    form,
    Form,
    fetchOutlinesLoading,
    activities: allLearningActivities,
    onChangeLearningActivity,
  };
};

export default useFirstStep;
