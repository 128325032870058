import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Spin } from 'antd';
import { ReactComponent as BackIcon } from '../../../assets/back-icon.svg';
import DetailsSection from './detailsSection';
import { fetchNpo, npoDetailCleanupFunc } from '../redux/actions';
// import { fetchNpoStatuses } from '../../NpoStatuses/redux/actions';
import './index.less';
import NpoNotifications from './npoNotifications';
import axios from 'axios';
import { baseURL } from '../../../globalUtils/axiosConfig';
// import { fetchNpoRegions } from '../../NpoRegions/redux/actions';

const BookDetail = () => {
  const [isNpoUpdate, setIsNpoUpdate] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [canUpdate, setCanUpdate] = useState(true);
  const [learningActivities, setLearningActivities] = useState([]);

  const { currentNpoObj, fetchNpoDetailLoading } = useSelector(
    (state) => state.npoSetupReducer
  );
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (currentNpoObj?._id) {
      setIsNpoUpdate(true);
    }
  }, [currentNpoObj]);

  useEffect(() => {
    if (params?.id) {
      setIsNpoUpdate(true);
      dispatch(fetchNpo(params?.id));
    } else if (pathname === '/profile') {
      setIsNpoUpdate(true);
      dispatch(fetchNpo(user.id));
    }

    return () => {
      dispatch(npoDetailCleanupFunc());
      // dispatch(fetchNpoRegions());
    };
  }, [params.id]);

  // useEffect(() => {
  //   if (user?.permissions?.npo_setup?.add_edit) {
  //     setCanUpdate(true);
  //   }
  // }, [user]);

  const fetchLearningActivities = async () => {
    const response = await axios.get(`${baseURL}/api/v2/admin/learning-activities`);
    const activities = response.data?.data?.learning_activities || [];

    const updatedActivities = activities.map((item) => ({
      label: item.name,
      value: item._id,
    }));

    setLearningActivities(updatedActivities);
  };

  useEffect(() => {
    // dispatch(fetchNpoStatuses({ search: {} }));
    fetchLearningActivities();
  }, []);

  return (
    <div className='page-top-div heading-parent-div'>
      <Row justify='space-between'>
        <Col>
          <span className='text-span'>
            {isNpoUpdate ? 'Update Book' : 'Add Book'}
          </span>
        </Col>
        <Col>
          <Button
            onClick={() => navigate('/books')}
            icon={<BackIcon width={24} height={14} />}
            className='back-btn'
            size='middle'
          >
            BACK
          </Button>
        </Col>
      </Row>
      <Spin spinning={fetchNpoDetailLoading}>
        <div className='user-manager-parent-div'>
          <DetailsSection
            isNpoUpdate={isNpoUpdate}
            canUpdate={canUpdate}
            learningActivities={learningActivities}
          />
          {false && (
            <NpoNotifications isNpoUpdate={isNpoUpdate} canUpdate={canUpdate} />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default BookDetail;
