import axios from "axios";
import { put } from "redux-saga/effects";
import { errorHandler } from "../../../../components/unauthorizeHandler";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import * as actions from "../actions";

export function* handleFetchSalesData({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(`${baseURL}/api/v2/sales/register`, {
      params: { ...rest, ...(Object.keys(search).length > 0 && { search }) },
    });

    const { success } = response.data;

    if (success) {
      let { orders, total } = response.data.data;
      const ordersUpdated = orders.map((el, index) => ({
        ...el,
        id: index,
        key: index,
      }));
      yield put(
        actions.fetchSalesDataSuccess({
          orders: ordersUpdated,
          total,
        })
      );
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchSalesUtilsData({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v2/sales/search_lists`, {
      params: { ...(Object.keys(data).length > 0 && { ...data }) },
    });
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchSalesUtilsDataSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchSalesSecondLevelData({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/production/items?soid=${data}`
    );
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchSalesSecondLevelDataSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchSalesGraphData({ data }) {
  const { search } = data;

  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(`${baseURL}/api/v2/sales/graph_data`, {
      params: { ...(Object.keys(search).length > 0 && { search }) },
    });
    const { success } = response.data;

    if (success) {
      let { client_sales, total_sales, sales_rep, total_target_actual_sales } =
        response.data.data;

      const updatedClienSales = client_sales.map((el, index) => {
        const { company, sum } = el;
        return {
          id: `${company + index}`,
          Customers: Number(sum),
        };
      });
      sales_rep = sales_rep ? sales_rep : [];
      const updatedRepSales = sales_rep.map((el, index) => {
        const { fname, sales_target, sum } = el;
        return {
          id: fname,
          Target: sales_target,
          Actual: Number(sum),
        };
      });

      const { total_actual_sales, total_target_sales } =
        total_target_actual_sales;

      yield put(
        actions.fetchSalesGraphDataSuccess({
          client_sales: updatedClienSales,
          sales_rep: updatedRepSales,
          total_sales: [
            {
              id: "",
              TotalSales: Number(total_sales),
            },
          ],
          totalActualVsTarget: [
            {
              id: "",
              Actual: Number(total_actual_sales),
              Target: Number(total_target_sales),
            },
          ],
        })
      );
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}
