import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Spin } from 'antd';
import { ReactComponent as BackIcon } from '../../../assets/back-icon.svg';
import DetailsSection from './detailsSection';
import { fetchClass, classDetailCleanupFunc } from '../redux/actions';
import './index.less';
import axios from 'axios';
import { baseURL } from '../../../globalUtils/axiosConfig';
import StudentsTable from '../enrollmentsTable';

const ClassDetail = () => {
  const [isClassUpdate, setIsClassUpdate] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [canUpdate, setCanUpdate] = useState(true);

  const [{ allStudents, allTeachers, allUsers }, setAllUsers] = useState({
    allStudents: [],
    allTeachers: [],
    allUsers: [],
  });
  const { currentClassObj, fetchClassDetailLoading } = useSelector(
    (state) => state.classesReducer
  );

  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (currentClassObj?._id) {
      setIsClassUpdate(true);
    }
  }, [currentClassObj]);

  useEffect(() => {
    if (params?.id) {
      setIsClassUpdate(true);
      dispatch(fetchClass(params?.id));
    } else if (pathname === '/profile') {
      setIsClassUpdate(true);
      dispatch(fetchClass(user.id));
    }

    return () => {
      dispatch(classDetailCleanupFunc());
    };
  }, [params.id]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const response = await axios.get(`${baseURL}/api/v2/admin/users`);

    const users = response.data?.data?.users || [];

    const { allStudents, allTeachers, allUsers } = users.reduce(
      (acc, el) => {
        const { first_name, last_name, _id, roles } = el;
        let isStudent = roles.includes('student');
        let isTeacher = roles.includes('teacher');

        let label = `${first_name} ${last_name} ${
          isStudent ? ' (Student)' : ''
        }${isTeacher ? ' (Teacher)' : ''}`;

        if (isStudent) {
          acc.allStudents.push({ label, value: _id, roles });
        }
        if (isTeacher) {
          acc.allTeachers.push({ label, value: _id, roles });
        }

        if (isStudent || isTeacher) {
          acc.allUsers.push({ label, value: _id, roles });
        }

        return acc;
      },
      { allStudents: [], allTeachers: [], allUsers: [] }
    );

    setAllUsers({ allStudents, allTeachers, allUsers });
  };

  const options =
    currentClassObj?.class_enrolments?.map((item) => item._id) || [];

  return (
    <div className='page-top-div heading-parent-div'>
      <Row justify='space-between'>
        <Col>
          <span className='text-span'>
            {isClassUpdate ? 'Update Class' : 'Add Class'}
          </span>
        </Col>
        <Col>
          <Button
            onClick={() => navigate('/classes')}
            icon={<BackIcon width={24} height={14} />}
            className='back-btn'
            size='middle'
          >
            BACK
          </Button>
        </Col>
      </Row>
      <Spin spinning={fetchClassDetailLoading}>
        <div className='user-manager-parent-div'>
          <DetailsSection
            isClassUpdate={isClassUpdate}
            canUpdate={canUpdate}
            allTeachers={allTeachers}
            allStudents={allStudents}
            allUsers={allUsers}
            prevSelected={options}
          />
          {isClassUpdate && (
            <StudentsTable
              enrollments={currentClassObj.class_enrolments || []}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default ClassDetail;
