import * as constants from './constants';

export const fetchSiteStatuses = (data) => {
  return {
    type: constants.FETCH_SITE_STATUSES,
    data,
  };
};

export const fetchSiteStatusesSuccess = (data) => {
  return {
    type: constants.FETCH_SITE_STATUSES_SUCCESS,
    data: data,
  };
};

export const fetchSiteStatusesError = (data) => {
  return {
    type: constants.FETCH_SITE_STATUSES_ERROR,
    data: data,
  };
};

export const updateSiteStatuses = (data) => {
  return {
    type: constants.UPDATE_SITE_STATUSES,
    data: data,
  };
};

export const updateSiteStatusesSuccess = (data) => {
  return {
    type: constants.UPDATE_SITE_STATUSES_SUCCESS,
    data: data,
  };
};

export const updateSiteStatusesError = () => {
  return {
    type: constants.UPDATE_SITE_STATUSES_ERROR,
  };
};

export const updateSiteOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_SITE_STATUS,
    data: data,
  };
};

export const updateSiteOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_SITE_STATUS_SUCCESS,
    data: data,
  };
};

export const updateSiteOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_SITE_STATUS_ERROR,
  };
};

export const deleteSiteStatuses = (data) => {
  return {
    type: constants.DELETE_SITE_STATUSES,
    data: data,
  };
};

export const deleteSiteStatusesSuccess = (data) => {
  return {
    type: constants.DELETE_SITE_STATUSES_SUCCESS,
    data: data,
  };
};

export const deleteSiteStatusesError = () => {
  return {
    type: constants.DELETE_SITE_STATUSES_ERROR,
  };
};

export const createSiteStatuses = (data, id) => {
  return {
    type: constants.CREATE_SITE_STATUSES,
    data: data,
  };
};

export const createSiteStatusesSuccess = (data) => {
  return {
    type: constants.CREATE_SITE_STATUSES_SUCCESS,
    data: data,
  };
};

export const createSiteStatusesError = (id) => {
  return {
    type: constants.CREATE_SITE_STATUSES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};

export const setStorage = (data) => {
  return {
    type: constants.SET_STICKERS_MANAGER_STATE,
    data,
  };
};
