import React, { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Space,
  DatePicker,
  Select,
  Col,
  Row,
  Button,
} from 'antd';
import {
  CloseOutlined,
  DownOutlined,
  PlusOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteClass,
  fetchAllClasses,
  setClassDetailsIsEdit,
  setStorage,
} from './redux/actions';
import SpinnerComponent from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import CustomPagination from '../../components/customPagination/CustomPagination';
import EnrollmentsTable from './enrollmentsTable';

const BooksTable = ({ canUpdate, canDelete, assessorOptions }) => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [currentDelRecord, setCurrentDelRecord] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [date, setDate] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const { Option } = Select;
  const {
    allClasses,
    fetchClassesLoading,
    deleteClassLoading,
    totalRecords,
    ...rest
  } = useSelector((state) => state.classesReducer);

  useEffect(() => {
    if (localStorage.getItem('classesManagerState')) {
      const localStorageData = JSON.parse(
        localStorage.getItem('classesManagerState')
      );

      if (localStorageData) {
        setTimeout(() => {
          setSearch(localStorageData.search);
          setPage(localStorageData.page);

          setResultPerPage(localStorageData.resultPerPage);
          dispatch(setStorage(localStorageData));

          if (localStorageData?.sortedInfo) {
            setSortedInfo(localStorageData.sortedInfo);
          }

          debouncedCallApi();
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllClasses({
          page: page,
          limit: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === 'ascend'
              ? 1
              : -1
            : null,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllClasses({
          page: paramsPage,
          limit: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === 'ascend'
              ? 1
              : -1
            : null,
        })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setDataSource(allClasses);
  }, [allClasses]);

  useEffect(() => {
    const isSearchChanged =
      JSON.stringify(search) !== JSON.stringify(rest.search);

    if (isSearchChanged) {
      setSearch(rest.search);
    }

    const isPageChanged = rest.page && rest.page !== page;
    const isResultPerPageChanged =
      rest.resultPerPage && rest.resultPerPage !== resultPerPage;

    if (isPageChanged) {
      setPage(rest.page);
    }

    if (isResultPerPageChanged) {
      setResultPerPage(rest.resultPerPage);
    }

    if (isPageChanged || isResultPerPageChanged || isSearchChanged) {
      debouncedCallApi();
    }
  }, []);

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };
  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
  };

  const handleSetStorage = (key, value) => {
    dispatch(setStorage({ [key]: value }));
  };

  const handleChange = (pagination, filters, sorter) => {
    if (['room', 'assessor'].includes(sorter.columnKey) || datePickerOpened) {
      return;
    }
    setSortedInfo(sorter);
    handleSetStorage('sortedInfo', sorter);
  };

  const handleDelete = (record) => {
    setCurrentDelRecord(record);
    dispatch(deleteClass(record));
  };
  const handleSearch = (key, value, func = null) => {
    let updatedState = {
      ...search,
      [key]: value,
    };

    setSearch(updatedState);

    if (typeof func === 'function') {
      func();
    }

    dispatch(setStorage({ search: updatedState, page: 1 }));
    handleSetStorage('search', updatedState);
    // debouncedDefaultPageOneCallApi();
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      value={search[key]}
      onChange={(e) => {
        handleSearch(key, e.target.value, debouncedDefaultPageOneCallApi);
      }}
    />
  );

  const dateInput = (key) => (
    <DatePicker
      placeholder=''
      // className='activities-time-picker'
      style={{ width: '100%' }}
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      suffixIcon={null}
      format='DD MMM YYYY'
      // use12Hours
      // showTime
      width='100%'
      // nextIcon={null}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        debouncedDefaultPageOneCallApi();
      }}
      onOpenChange={(e) => setDatePickerOpened(e)}
    />
  );

  const handleNavigate = (id) => navigate(`/classes/update/${id}`);

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      let searchObj = { ...search };
      delete searchObj.slot_date_start;
      delete searchObj.slot_date_end;

      // setSearch({ ...searchObj, slot_date_start });
    } else {
      setSearch({
        ...search,
        slot_date_start: value[0],
        slot_date_end: value[1],
      });
    }
    debouncedDefaultPageOneCallApi();
  };

  const columns = [
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Name</div>
            <div>{searchInput('name')}</div>
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      // sorter: (a, b) =>
      //   a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase()),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      render: (name, { _id }) => (
        <div onClick={() => handleNavigate(_id)}>{name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>id</div>
            <div>{searchInput('id')}</div>
          </div>
        );
      },
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'id' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      render: (id) => <div>{id}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>code</div>
            <div>{searchInput('code')}</div>
          </div>
        );
      },
      dataIndex: 'code',
      key: 'code',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'code' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      render: (code) => <div>{code}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>class risk</div>
            <div>{searchInput('class_risk')}</div>
          </div>
        );
      },
      dataIndex: 'class_risk',
      key: 'class_risk',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'class_risk' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (class_risk) => <div>{class_risk}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>competency rate</div>
            <div>{searchInput('class_competency_rate')}</div>
          </div>
        );
      },
      dataIndex: 'class_competency_rate',
      key: 'class_competency_rate',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'class_competency_rate' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (class_competency_rate) => <div>{class_competency_rate}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>homework rate</div>
            <div>{searchInput('class_homework_rate')}</div>
          </div>
        );
      },
      dataIndex: 'class_homework_rate',
      key: 'class_homework_rate',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'class_homework_rate' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (class_homework_rate) => <div>{class_homework_rate}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>
              parents engagement rate
            </div>
            <div>{searchInput('class_parents_engagement_rate')}</div>
          </div>
        );
      },
      dataIndex: 'class_parents_engagement_rate',
      key: 'class_parents_engagement_rate',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'class_parents_engagement_rate' &&
        sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      align: 'center',
      render: (title) => <div>{title}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>student count</div>
            <div>{searchInput('student_count')}</div>
          </div>
        );
      },
      dataIndex: 'student_count',
      key: 'student_count',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'student_count' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (title) => <div>{title}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Status</div>
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                size='small'
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select status'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                value={search?.deleted_status}
                onChange={(e) => {
                  handleSearch(
                    'deleted_status',
                    e,
                    debouncedDefaultPageOneCallApi
                  );
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {[
                  { key: 'Active', value: 'active' },
                  { key: 'Inactive', value: 'inactive' },
                ].map(({ key, value }) => {
                  return (
                    <Option value={value} label={key}>
                      {key}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'deleted_status',
      key: 'deleted_status',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'deleted_status' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (deleted_status) => <div>{deleted_status?.toUpperCase()}</div>,
    },
    {
      title: null,
      dataIndex: '',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        // if (!canUpdate && !canDelete) {
        //   return;
        // }
        return deleteClassLoading && record._id === currentDelRecord._id ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align='baseline'>
            {canUpdate && (
              <EditIcon
                width={18}
                height={18}
                className='custom-icon'
                onClick={() => {
                  handleNavigate(record._id);
                  dispatch(setClassDetailsIsEdit(true));
                }}
              />
            )}
            {/* {canDelete && (
              <Popconfirm
                title='Are you sure you want to delete this NPO?'
                onConfirm={() => handleDelete(record)}
                okText='Yes'
                cancelText='No'
              >
                <DeleteIcon width={18} height={18} className='custom-icon' />
              </Popconfirm>
            )} */}
          </Space>
        );
      },
    },
  ];

  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record._id);
    }
    setExpandedRowKeys(keys);
  };

  return (
    <div className='page-top-div'>
      <span className='text-span'>Classes</span>
      <Row className='production-manager-parent-row'>
        <Col span={24} className='heading-col'>
          <Row align='middle' gutter={[30, 0]} justify='space-between'>
            <Col>
              <Row align='middle' gutter={[40, 0]}>
                <Col>
                  <div>
                    <span className='heading-span'>Classes Register</span>
                  </div>
                </Col>
                <Col>
                  {canUpdate && (
                    <Button
                      onClick={() => {
                        navigate('/classes/create');
                        dispatch(setClassDetailsIsEdit(true));
                      }}
                      icon={<PlusOutlined />}
                      className='add-user-btn'
                      size='small'
                      style={{
                        width: '105%',
                      }}
                    >
                      Class
                    </Button>
                  )}
                </Col>
                <Button
                  onClick={() => {
                    handleSetStorage('search', {});
                    handleSetStorage('sortedInfo', null);
                    handleSetStorage('page', 1);
                    handleSetStorage('resultPerPage', 10);
                    setPage(1);
                    setResultPerPage(10);
                    setSearch({});
                    setSortedInfo(null);
                  }}
                  icon={<CloseOutlined />}
                  className='add-user-btn'
                  size='small'
                >
                  Clear Filters
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Table
            className='special-table'
            columns={columns}
            scroll={{ x: !fetchClassesLoading }}
            onChange={handleChange}
            dataSource={dataSource}
            pagination={false}
            sortUpIcon={<UpOutlined />}
            sortDownIcon={<DownOutlined />}
            loading={fetchClassesLoading}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <EnrollmentsTable
                    enrollments={record?.class_enrolments || []}
                    counts={record.student_count}
                  />
                );
              },
              expandRowByClick: true,
              // expandIcon: null,
              showExpandColumn: true,
              expandedRowKeys: expandedRowKeys,
              onExpand: onTableRowExpand,
              // rowExpandable: ({ _id }) => expandedRowKeys.includes(_id),
            }}
            onRow={({ _id }) => {
              return (
                expandedRowKeys.includes(_id) && {
                  className:
                    'expand-parent expand-doc-parent fourth-level-expanded-row',
                }
              );
            }}
            rowKey={(record) => record._id}
            rowClassName='editable-row'
          />
          <CustomPagination
            page={page}
            setPage={setPage}
            resultPerPage={resultPerPage}
            setResultPerPage={setResultPerPage}
            debouncedCallApi={debouncedCallApi}
            totalRecord={totalRecords}
            handleSetStorage={handleSetStorage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BooksTable;
