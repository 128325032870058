import React, { useRef, useState } from 'react';
import {
  Button,
  Col,
  Row,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
} from 'antd';
import SectionA from './sectionA';
import SectionB from './sectionB';
import SectionC from './sectionC';
import SectionD from './sectionD';
import SectionE from './sectionE';
import { CustomerType, EditApplicationBtn } from './customerType';
import { formItemLayout, initialState } from './utils';
import './index.less';
import axios from 'axios';
import { baseURL } from '../../../../../globalUtils/axiosConfig';
import moment from 'moment';

const emailRegex =
  /^[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const EditApplication = ({ formData, setFormData, record }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const inputEls = useRef([]);

  const handleEdit = () => {
    const obj = {};
    if (record?.formDetail) {
      for (const [key, value] of Object.entries(record?.formDetail)) {
        if (['otherValueAdded', 'interimLteAccess'].includes(key)) {
          obj[key] = value ? value : null;
        } else if (key.includes('Date')) {
          obj[key] = value ? moment(value) : null;
        } else {
          obj[key] = value;
        }
      }
      form.setFieldsValue(obj);
      setData(obj);
    }
    setIsEdit(true);
  };

  if (!isEdit) {
    return <EditApplicationBtn formData={formData} handleEdit={handleEdit} />;
  }

  const updateForm = async (allData) => {
    setLoading(true);
    const fd = new FormData();
    for (const [key, value] of Object.entries(allData)) {
      if (['otherValueAdded', 'interimLteAccess'].includes(key)) {
        for (const key1 in value) {
          if (value.hasOwnProperty(key1)) {
            const subObject = value[key1];
            for (const subKey in subObject) {
              if (subObject.hasOwnProperty(subKey)) {
                fd.append(
                  `formDetail[${key}][${key1}][${subKey}]`,
                  subObject[subKey]
                );
              }
            }
          }
        }
      } else if (key.includes('Date')) {
        fd.append(`formDetail[${key}]`, moment(value).format('DD MMM YYYY'));
      } else if (key !== undefined && value !== null) {
        fd.append(`formDetail[${key}]`, value);
      }
    }
    const response = await axios.put(`${baseURL}/leads/${record?._id}`, fd, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    const { success } = response.data;
    setLoading(false);

    if (success) {
      console.log('response.data', response.data);
      // setIsEdit(false);
      message.success('Form successfully updated.');
      // updateActivitySuccess(response.data.lead);
    } else if (!success) {
      message.error('Something went wrong.');
      // updateActivityError(response.data.lead);
    }
  };

  const onFinish = (values) => {
    updateForm({ ...data, ...values });
  };

  const Heading = ({ text }) => (
    <Col span={23}>
      <div className='heading-label-div'>{text}</div>
    </Col>
  );

  const InputComponent = ({ name, label, placeholder }) => {
    let rules = [];
    if (name?.toLowerCase()?.includes('email')) {
      let rule = {
        pattern: emailRegex,
        message: 'Invalid Email!',
      };
      rules.push(rule);
    }
    return (
      <Form.Item name={name} label={label} rules={rules}>
        <Input size='middle' placeholder={placeholder} />
      </Form.Item>
    );
  };

  const handleAccountType = (e) => {
    const { value, checked, keyName } = e.target;
    let updatedValue = checked ? value : null;
    setData({ ...data, [keyName]: updatedValue });
  };

  const AccountTypeBox = ({ value, label, keyName }) => {
    const isSelected = Boolean(data[keyName]);
    return (
      <Checkbox
        onChange={handleAccountType}
        value={value}
        keyName={keyName}
        checked={data[keyName] === value}
        style={{
          lineHeight: '32px',
        }}
        disabled={isSelected && data[keyName] !== value}
      >
        <div className='desc-parent'>
          <div className={`price`}>{label}</div>
        </div>
      </Checkbox>
    );
  };

  const handleAccountAge = (name, value) => {
    setData({ ...data, [name]: value });
    setTimeout(() => {
      inputEls.current[name].focus();
    }, 100);
  };

  const AccountAgeBox = ({ name, value, label, placeholder }) => {
    return (
      <div className='number-input-div'>
        <label>{label}</label>
        <InputNumber
          ref={(el) => (inputEls.current[name] = el)}
          size='middle'
          min={0}
          value={value}
          name={name}
          onChange={(e) => handleAccountAge(name, e)}
          placeholder={placeholder || label}
        />
      </div>
    );
  };

  const handleSaveDraft = async (key) => {
    try {
      const row = await form.validateFields();
      form.submit();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      // const { errorFields } = errInfo;
      // errorFields.forEach((el) => {
      //   el.errors[0] !== 'Required!' && message.error(el.errors[0]);
      // });
    }
  };

  return (
    <Row justify='center'>
      <Col md={24} lg={24} xl={24} xxl={21} className='edit-application-parent'>
        <Form
          {...formItemLayout}
          form={form}
          name='clientForm'
          onFinish={onFinish}
          initialValues={formData}
          scrollToFirstError
          labelWrap={true}
          layout='horizontal'
          className='user-detail-form'
          requiredMark={false}
          style={{ width: '100%' }}
        >
          <Row justify='center' gutter={[0, 25]}>
            <Col span={23} className='name-col'>
              {formData.contract_name}
            </Col>
            <CustomerType data={data} setData={setData} />
            <SectionA
              Heading={Heading}
              data={data}
              setData={setData}
              formData={formData}
            />
            <SectionB
              InputComponent={InputComponent}
              AccountTypeBox={AccountTypeBox}
              AccountAgeBox={AccountAgeBox}
              Heading={Heading}
              setData={setData}
              data={data}
            />

            <Heading text='SECTION C: Existing Vodacom Customer Details' />
            <SectionC InputComponent={InputComponent} />
            <Heading text='SECTION D: New Vodacom Customer Details' />
            <SectionD
              InputComponent={InputComponent}
              AccountTypeBox={AccountTypeBox}
              AccountAgeBox={AccountAgeBox}
              data={data}
            />
            <Heading text='SECTION E: Declaration' />
            <SectionE data={data} setData={setData} />
            <Col span={23} className='btns-col'>
              <Row justify='center' gutter={[80, 0]}>
                <Col>
                  <Button onClick={handleSaveDraft} disabled={loading}>
                    Save Draft
                  </Button>
                </Col>
                <Col>
                  <Button disabled={!data.signature || loading}>
                    Save Application
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default EditApplication;
