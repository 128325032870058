import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Col, Row, Statistic } from 'antd';
import React from 'react';

const StatsComponent = ({
  successRecords = 0,
  failedRecords = 0,
  totalRecords = 0,
}) => (
  <Row gutter={30} justify='center' align='middle'>
    <Col>
      <Statistic
        title='Successfully Imported'
        value={successRecords}
        prefix={<CheckCircleOutlined />}
        suffix={`/ ${totalRecords}`}
        valueStyle={{
          color: '#3f8600',
        }}
        decimalSeparator={null}
      />
    </Col>
    <Col offset={1}>
      <Statistic
        title='Failed Records'
        value={failedRecords}
        suffix={`/ ${totalRecords}`}
        prefix={
          <WarningOutlined
            color='red'
            style={{
              fill: 'red',
            }}
          />
        }
        valueStyle={{
          color: '#cf1322',
        }}
      />
    </Col>
  </Row>
);

export default StatsComponent;
