import React from 'react';
import { Row, Col, Button } from 'antd';
import Logo from '../../assets/logo1.png';
import VodacomLogo from '../../assets/vodacom.jpg';
import './index.less';
import AttachComponent from './attachComponent';
import UseAttachments from './useAttachments';

const UploadAttachments = () => {
  const {
    data,
    setData,
    handleSave,
    handleDeleteImage,
    handleImage,
    handleShowImage,
  } = UseAttachments();

  console.log('data', data);
  return (
    <Row
      className='upload-attachments-public'
      gutter={[0, 10]}
      justify='center'
    >
      <Col span={24} className='logo-col'>
        <Row justify='center' align='middle'>
          <Col className='img-col'>
            <img src={Logo} alt='Logo' />
          </Col>
        </Row>
      </Col>
      <Col xxl={12}>
        <Row justify='center' gutter={[0, 15]}>
          <Col span={24} className='vodafon-logo-col'>
            <Row justify='center' align='middle'>
              <Col className='img-col'>
                <img src={VodacomLogo} alt='Logo' />
              </Col>
            </Row>
          </Col>
          <Col span={24} className='title-col'>
            <span>
              Application for a Vodacom Fibre Service – Vodacom Network
            </span>
          </Col>
          <Col span={24} className='attach-heading'>
            <span>UPLOAD ATTACHMENTS</span>
          </Col>
          <Col span={24} offset={2} className='subheading-col'>
            <span>
              Upload the below attachments for Application for a Vodacom Fibre
              Service – Vodacom Network:
            </span>
          </Col>
          <Col span={24} className='attach-main-col' offset={2}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <AttachComponent
                  images={data.applications}
                  setData={setData}
                  name='applications'
                  label='Applications'
                />
              </Col>
              <Col span={24}>
                <AttachComponent
                  images={data.idPassports}
                  setData={setData}
                  name='idPassports'
                  label='ID / PASSPORT'
                />
              </Col>
              <Col span={24}>
                <AttachComponent
                  images={data.bankStatements}
                  setData={setData}
                  name='bankStatements'
                  label='BANK STATEMENTS'
                />
              </Col>
              <Col span={24}>
                <AttachComponent
                  images={data.others}
                  setData={setData}
                  name='others'
                  label='OTHER ATTACHMENTS'
                />
              </Col>
            </Row>
          </Col>
          <Col className='btn-col'>
            <Button onClick={handleSave}>SEND ATTACHMENTS</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UploadAttachments;
