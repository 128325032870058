import React, { useEffect, useState } from 'react';
import { Table, Space, Select, Tag, Row, Col, Button } from 'antd';
import { DownOutlined, UpOutlined, CloseOutlined } from '@ant-design/icons';
// import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
// import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteClassSession,
  fetchAllClassSessions,
  setStorage,
  // setClassSessionDetailsIsEdit,
} from './redux/actions';
import SpinnerComponent from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
// import { baseURL } from "../../../../globalUtils/axiosConfig";
// import { baseURL } from '../../globalUtils/axiosConfig';
import _ from 'lodash';
import CustomPagination from '../../components/customPagination/CustomPagination';
import moment from 'moment';
import CustomCalendar from '../../components/calendar';
// import CustomCalendar from '../../components/calendar';
// import AMultiSelect from '../../components/AMultiSelect';

const { Option } = Select;

let start_time = {
  start_time: moment(new Date()).format('YYYY-MM-DD'),
};

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const BooksTable = ({ canUpdate, canDelete, assessorOptions }) => {
  const [dataSource, setDataSource] = useState([]);
  const [currentDelRecord, setCurrentDelRecord] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({}); // { start_time }
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  // const [statusCheckedList, setStatusCheckedList] = useState([]);
  // const [regionsCheckedList, setRegionsCheckedList] = useState([]);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [date, setDate] = useState(null);
  // const [npoStatuses, SetClassSessionStatuses] = useState([]);
  // const [npoRegions, SetClassSessionRegions] = useState([]);

  const {
    allClassSessions,
    fetchClassSessionsLoading,
    deleteClassSessionLoading,
    totalRecords,
    ...rest
  } = useSelector((state) => state.classSessionsReducer);

  const [sortedInfo, setSortedInfo] = useState(rest.sortedInfo || null);

  // useEffect(() => {
  //   let updatedStatuses = allClassSessionStatuses.map((el) => {
  //     const { id, name } = el;
  //     return { value: id, label: name };
  //   });
  //   SetClassSessionStatuses(updatedStatuses);
  // }, [allClassSessionStatuses]);

  // useEffect(() => {
  //   let updatedRegions = allClassSessionRegions.map((el) => {
  //     const { id, name } = el;
  //     return { value: id, label: name };
  //   });
  //   SetClassSessionRegions(updatedRegions);
  // }, [allClassSessionRegions]);

  useEffect(() => {
    if (localStorage.getItem('classSessionsManagerState')) {
      const localStorageData = JSON.parse(
        localStorage.getItem('classSessionsManagerState')
      );

      console.log('sessions localStorageData', localStorageData);

      if (localStorageData) {
        setTimeout(() => {
          setSearch(localStorageData.search);
          setPage(localStorageData.page);
          if (localStorageData?.sortedInfo) {
            setSortedInfo(localStorageData.sortedInfo);
          }
          setResultPerPage(localStorageData.resultPerPage);
          dispatch(setStorage(localStorageData));

          if (localStorageData?.search?.start_time) {
            const { start_time, end_time } = localStorageData.search;
            setDate([moment(start_time), moment(end_time)]);
          }

          debouncedCallApi();
        }, 2000);
      }
    }
  }, []);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllClassSessions({
          page: page,
          per_page: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === 'ascend'
              ? 1
              : -1
            : null,
          timezone: timeZone,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllClassSessions({
          page: paramsPage,
          per_page: resultPerPage,
          search,
          timezone: timeZone,
        })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setDataSource(allClassSessions);
  }, [allClassSessions]);

  useEffect(() => {
    const isSearchChanged =
      JSON.stringify(search) !== JSON.stringify(rest.search);

    if (isSearchChanged) {
      setSearch(rest.search);
    }

    const isPageChanged = rest.page && rest.page !== page;
    const isResultPerPageChanged =
      rest.resultPerPage && rest.resultPerPage !== resultPerPage;

    if (isPageChanged) {
      setPage(rest.page);
    }

    if (isResultPerPageChanged) {
      setResultPerPage(rest.resultPerPage);
    }

    if (isPageChanged || isResultPerPageChanged || isSearchChanged) {
      debouncedCallApi();
    }
  }, []);

  const handleSetStorage = (key, value) => {
    dispatch(setStorage({ [key]: value }));
  };

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };
  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    if (['room', 'created_by'].includes(sorter.columnKey) || datePickerOpened) {
      return;
    }
    setSortedInfo(sorter);
    handleSetStorage('sortedInfo', sorter);
  };

  const handleDelete = (record) => {
    setCurrentDelRecord(record);
    dispatch(deleteClassSession(record));
  };

  const handleNavigate = (id) => navigate(`/class-session/details/${id}`);

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      let searchObj = { ...search };
      delete searchObj.start_time;
      delete searchObj.end_time;

      setSearch(searchObj);
      dispatch(setStorage({ search: searchObj }));
    } else {
      let updatedSearch = {
        ...search,
        start_time: value[0],
        end_time: value[1],
      };

      setSearch(updatedSearch);

      dispatch(setStorage({ search: updatedSearch }));
    }
    dispatch(setStorage({ page: 1 }));

    debouncedDefaultPageOneCallApi();
  };

  const handleSearch = (key, value, func = null) => {
    let updatedState = {
      ...search,
      [key]: value,
    };

    setSearch(updatedState);

    if (typeof func === 'function') {
      func();
    }

    dispatch(setStorage({ search: updatedState, page: 1 }));
    handleSetStorage('search', updatedState);
    // debouncedDefaultPageOneCallApi();
  };

  const columns = [
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Created By</div>
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                size='small'
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                value={search['created_by']}
                className='Select Assessor'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                onChange={(e) => {
                  handleSearch('created_by', e, debouncedDefaultPageOneCallApi);
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {assessorOptions.map(({ label, value }) => {
                  return (
                    <Option value={value} key={value} label={label}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'created_by',
      key: 'created_by',
      sorter: true,
      // sortOrder: sortedInfo?.columnKey === 'created_by' && sortedInfo?.order,
      ellipsis: true,
      width: '40%',
      align: 'left',
      render: (created_by) => {
        let name = created_by
          ? `${created_by?.first_name} ${created_by?.last_name} - ${created_by?.email}`
          : '';

        return <div>{name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Session Date</div>
            <div>
              <CustomCalendar
                date={date}
                setDate={setDate}
                format={'D MMM'}
                handleDateInput={handleDateInput}
                setDatePickerOpened={setDatePickerOpened}
                width='100%'
              />
            </div>
          </div>
        );
      },
      dataIndex: 'start_time',
      key: 'start_time',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'start_time' && sortedInfo?.order,
      ellipsis: true,
      width: '30%',
      align: 'left',
      render: (start_time, record) => {
        if (!start_time) {
          return;
        }

        let end_time = null;
        if (start_time) {
          start_time = moment
            .utc(record?.start_time, 'YYYY-MM-DD HH:mm:ss.SSS')
            .local()
            .format('DD MMM YYYY hh:mm A');
        }

        if (record?.end_time) {
          end_time = moment
            .utc(record?.end_time, 'YYYY-MM-DD HH:mm:ss.SSS')
            .local()
            .format('DD MMM YYYY hh:mm A');

          start_time += ' - ' + end_time;
        }

        return <div>{start_time}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Status</div>
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                size='small'
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select Project'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                onChange={(e) => {
                  handleSearch(
                    'session_status',
                    e,
                    debouncedDefaultPageOneCallApi
                  );
                }}
                value={search['session_status']}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {[
                  { key: 'Completed', value: 'Completed' },
                  { key: 'In Progress', value: 'Inprogress' },
                ].map(({ key, value }) => {
                  return (
                    <Option value={value} label={key}>
                      {key}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'session_status',
      key: 'session_status',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'session_status' && sortedInfo?.order,
      ellipsis: true,
      width: '20%',
      align: 'left',
      render: (session_status) => {
        let status = session_status;
        if (session_status === 'Inprogress') {
          status = 'In Progress';
        }
        return <div>{status?.toUpperCase()}</div>;
      },
    },
    {
      title: null,
      dataIndex: '',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        // if (!canUpdate && !canDelete) {
        //   return;
        // }
        return deleteClassSessionLoading &&
          record._id === currentDelRecord._id ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align='baseline'>
            {/* {canUpdate && ( */}
            <Tag
              onClick={() => handleNavigate(record._id)}
              color={'#50B6F2'}
              style={{
                color: 'white',
                width: '80px',
                height: '24px',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Details
            </Tag>
            {/* <EditIcon
              width={18}
              height={18}
              className='custom-icon'
              onClick={() => {
                handleNavigate(record._id);
                dispatch(setClassSessionDetailsIsEdit(true));
              }}
            /> */}
            {/* // )} */}
            {/* {canDelete && (
              <Popconfirm
                title='Are you sure you want to delete this NPO?'
                onConfirm={() => handleDelete(record)}
                okText='Yes'
                cancelText='No'
              >
                <DeleteIcon width={18} height={18} className='custom-icon' />
              </Popconfirm>
            )} */}
          </Space>
        );
      },
    },
  ];

  return (
    <div className='page-top-div'>
      <span className='text-span'>Class Sessions</span>
      <Row className='production-manager-parent-row'>
        <Col span={24} className='heading-col'>
          <Row align='middle' gutter={[30, 0]} justify='space-between'>
            <Col>
              <Row align='middle' gutter={[30, 0]}>
                <Col>
                  <div>
                    <span className='heading-span'>
                      Class Sessions Register
                    </span>
                  </div>
                </Col>
                {/* <Col>
                {canUpdate && (
                <Button
                  onClick={() => {
                    navigate('/classes-attempts-history/create');
                    dispatch(setNpoDetailsIsEdit(true));
                  }}
                  icon={<PlusOutlined />}
                  className='add-user-btn'
                  size='middle'
                  style={{
                    width: '105%',
                  }}
                >
                  SLOT
                </Button>
                )} 
              </Col> */}
                <Col>
                  <Button
                    onClick={() => {
                      handleSetStorage('search', {});
                      handleSetStorage('sortedInfo', null);
                      handleSetStorage('page', 1);
                      handleSetStorage('resultPerPage', 10);
                      setPage(1);
                      setResultPerPage(10);
                      setSearch({});
                      setDate(null);
                      setSortedInfo(null);
                    }}
                    icon={<CloseOutlined />}
                    className='add-user-btn'
                    size='small'
                  >
                    Clear Filters
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Table
            className='special-table'
            columns={columns}
            scroll={{ x: !fetchClassSessionsLoading }}
            onChange={handleChange}
            dataSource={dataSource}
            pagination={false}
            sortUpIcon={<UpOutlined />}
            sortDownIcon={<DownOutlined />}
            loading={fetchClassSessionsLoading}
            onRow={(record) => {
              return {
                onClick: () => handleNavigate(record._id),
              };
            }}
          />
          <CustomPagination
            page={page}
            setPage={setPage}
            resultPerPage={resultPerPage}
            setResultPerPage={setResultPerPage}
            debouncedCallApi={debouncedCallApi}
            totalRecord={totalRecords}
            handleSetStorage={handleSetStorage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BooksTable;
