const CREATE_CLASS = 'CREATE_CLASS';
const CREATE_CLASS_SUCCESS = 'CREATE_CLASS_SUCCESS';
const CREATE_CLASS_ERROR = 'CREATE_CLASS_ERROR';
const UPDATE_CLASS = 'UPDATE_CLASS';
const UPDATE_CLASS_SUCCESS = 'UPDATE_CLASS_SUCCESS';
const UPDATE_CLASS_ERROR = 'UPDATE_CLASS_ERROR';
const FETCH_CLASS = 'FETCH_CLASS';
const FETCH_CLASS_SUCCESS = 'FETCH_CLASS_SUCCESS';
const FETCH_CLASS_ERROR = 'FETCH_CLASS_ERROR';
const CLASS_DETAILS_IS_EDIT = 'CLASSES_DETAILS_IS_EDIT';
const FETCH_ALL_CLASSES = 'FETCH_ALL_CLASSES';
const FETCH_ALL_CLASSES_SUCCESS = 'FETCH_ALL_CLASSES_SUCCESS';
const FETCH_ALL_CLASSES_ERROR = ' FETCH_ALL_CLASSES_ERROR';
const DELETE_CLASS = 'DELETE_CLASS';
const DELETE_CLASS_SUCCESS = 'DELETE_CLASSES_SUCCESS';
const DELETE_CLASS_ERROR = 'DELETE_CLASSES_ERROR';
const CLASS_DETAIL_CLEANUP_FUNC = 'CLASSES_DETAIL_CLEANUP_FUNC';
const SET_CLASSES_MANAGER_STATE = 'SET_CLASSES_MANAGER_STATE';

export {
  CREATE_CLASS,
  CREATE_CLASS_SUCCESS,
  CREATE_CLASS_ERROR,
  UPDATE_CLASS,
  UPDATE_CLASS_SUCCESS,
  UPDATE_CLASS_ERROR,
  FETCH_CLASS,
  FETCH_CLASS_SUCCESS,
  FETCH_CLASS_ERROR,
  CLASS_DETAILS_IS_EDIT,
  FETCH_ALL_CLASSES,
  FETCH_ALL_CLASSES_SUCCESS,
  FETCH_ALL_CLASSES_ERROR,
  DELETE_CLASS,
  DELETE_CLASS_SUCCESS,
  DELETE_CLASS_ERROR,
  CLASS_DETAIL_CLEANUP_FUNC,
  SET_CLASSES_MANAGER_STATE,
};
