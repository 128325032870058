let fibreFixedVoice = [
  {
    label: 'Option 1',
    description: '',
    price: null,
  },
  {
    label: 'Option 2',
    description: '',
    price: null,
  },
  {
    label: 'Option 3',
    description: '',
    price: null,
  },
  {
    label: 'Option 4',
    description: '',
    price: null,
  },
];

let otherValueServices = [
  {
    description: 'Yealink Cordless VoIP Phone',
    onceOffPrice: null,
    twentyFourMonthsPrice: null,
    twelveMonthsPrice: null,
    monthToMonthPrice: null,
  },
  {
    description: 'Vodacom Fibre Mobile back-up 40GB',
    onceOffPrice: null,
    twentyFourMonthsPrice: null,
    twelveMonthsPrice: null,
    monthToMonthPrice: null,
  },
  {
    description: 'Wi-Fi Extender\n Vodacom Super Wi-Fi',
    onceOffPrice: null,
    twentyFourMonthsPrice: null,
    twelveMonthsPrice: null,
    monthToMonthPrice: null,
  },
  {
    description: 'Video Subscription\n Showmax',
    onceOffPrice: null,
    twentyFourMonthsPrice: null,
    twelveMonthsPrice: null,
    monthToMonthPrice: null,
  },
];

let interimLteServices = [
  {
    label: 'Option 1',
    description: '',
    price: null,
  },
  {
    label: 'Option 2',
    description: '',
    price: null,
  },
  {
    label: 'Option 3',
    description: '',
    price: null,
  },
];

export const initialState = {
  fibreFixedVoice,
  otherValueServices,
  interimLteServices,
};
