import React, { useRef } from 'react';
import {
  Table,
  Button,
  Popconfirm,
  Form,
  Space,
  Col,
  Row,
  message,
} from 'antd';
// import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../../../assets/save-icon.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/cross-icon.svg';
import SpinnerComponent from '../../../../../components/spinner';
import './index.less';
import EditableCell from './editableCell';
import moment from 'moment';
import UseNotes from './useNotes';

const NotesTable = ({ record }) => {
  // canUpdate, canDelete
  // const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ref = useRef(null);
  const isEditing = (record) => record._id === editingKey;
  const {
    data,
    setData,
    editingKey,
    setEditingKey,
    handleCreate,
    handleUpdate,
    deleteNote,
    notesData,
  } = UseNotes(record._id);

  const { fetchingLoading, updateLoading, deletingLoading } = notesData;

  const edit = (record) => {
    // dispatch(isAddingAction(true));
    const { date, contactType, comment } = record;

    form.setFieldsValue({
      comment,
      date: date ? moment(date) : null,
      contactType: contactType?._id,
    });
    setEditingKey(record._id);
    setTimeout(() => {
      ref.current?.focus();
    }, 300);
  };

  const cancel = (record) => {
    // dispatch(isAddingAction(false));
    form.resetFields();
    setEditingKey('');
    if (record?.isAdd) {
      return setData((data) => data.filter((el) => el._id !== record._id));
    } else {
      setData(data);
    }
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item._id);
      if (index > -1) {
        const item = newData[index];
        if (item?.isAdd) {
          form.submit();
        } else {
          if (isAlreadyExist({ ...item, ...row })) {
            return message.error('Type name already exist!');
          }

          handleUpdate({ ...item, ...row, noteId: editingKey });
        }
      }
    } catch (errInfo) {
      console.log('errInfo', errInfo);
      if (errInfo?.values?.name === '') {
        message.error('Type Name should not be blank!');
      }
    }
  };

  const handleDelete = (record) => {
    deleteNote(record._id);
  };

  const handleAdd = () => {
    // dispatch(isAddingAction(true));
    if (editingKey) {
      return;
    }
    let _id = Math.floor(Math.random() * 1122123456);
    const newData = {
      _id,
      date: null,
      contactType: null,
      comment: '',
      isAdd: true,
    };
    setData((data) => [newData, ...data]);
    setEditingKey(_id);
    edit(newData);
  };

  const isAlreadyExist = (record) => {
    const { contactType } = record;
    const alreadyExist = data.filter(
      (el) => el.contactType?._id === contactType
    );

    return alreadyExist.length > 0 && record._id !== alreadyExist[0]?._id
      ? true
      : false;
  };

  const handleFinish = (values) => {
    const { date, comment, contactType } = values;

    if (isAlreadyExist(values)) {
      return message.error('Contact Type already exist!');
    }

    // let updatedData = data.map((el) =>
    //   el._id === editingKey ? { ...el, ...values, date: moment(date) } : el
    // );

    handleCreate({
      comment,
      contactType,
      date: moment(date),
      leadId: record._id,
    });

    // setData(updatedData);
    // setEditingKey(null);

    // dispatch(
    //   createContactTypes({
    //     name: name.trim(),
    //     _id: editingKey,
    //     type_for: 'site',
    //   })
    // );
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      editable: true,
      align: 'left',
      render: (date) => {
        return <div>{moment(date).format('DD MMM YY')}</div>;
      },
    },
    {
      title: 'Contact Type',
      dataIndex: 'contactType',
      key: 'contactType',
      width: '30%',
      editable: true,
      align: 'left',
      render: (date, record) => <div>{record?.contactType?.name}</div>,
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      width: '40%',
      editable: true,
      align: 'left',
    },
    {
      title: '',
      key: 'action',
      dataIndex: '',
      width: '15%',
      align: 'center',
      render: (_, record) => {
        // if (!canUpdate && !canDelete) {
        //   return;
        // }

        const editable = isEditing(record);
        let isLoading = updateLoading || deletingLoading;

        return editable ? (
          isLoading && editingKey === record._id ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={10} align='center'>
              <SaveIcon
                className='custom-icon'
                onClick={() => save(record._id)}
              />
              <Popconfirm
                title='Sure to cancel?'
                onConfirm={() => cancel(record)}
              >
                <CrossIcon
                  style={{ height: '20px', width: '20px' }}
                  className='custom-icon'
                />
              </Popconfirm>
            </Space>
          )
        ) : (
          <Space size={10} align='center'>
            {/* {canUpdate && ( */}
            <EditIcon
              className={`custom-icon ${
                Boolean(editingKey) ? 'disable-icon' : ''
              }`}
              disabled={editingKey !== ''}
              onClick={() => !Boolean(editingKey) && edit(record)}
            />
            {/* )} */}
            {
              // canDelete && canDeleteStatus &&
              data.length >= 1 &&
                (Boolean(editingKey) ? (
                  <DeleteIcon className={`custom-icon disable-icon`} />
                ) : (
                  <Popconfirm
                    title='Are you sure you want to delete this note?'
                    onConfirm={() => handleDelete(record)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <DeleteIcon className='custom-icon' />
                  </Popconfirm>
                ))
            }
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Row justify='center' className='notes-parent-row'>
      <Col xxl={24} xl={24} lg={24} md={24} className='heading-col'>
        <Row align='middle' gutter={[30, 0]}>
          <Col>
            <div>
              <span className='heading-span'>Note</span>
            </div>
          </Col>
          <Col>
            {/* {canUpdate && ( */}
            <Button
              icon={<PlusOutlined />}
              className='add-note-btn'
              size='small'
              disabled={Boolean(editingKey)}
              onClick={handleAdd}
            >
              Note
            </Button>
            {/* )} */}
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Form
          form={form}
          component={false}
          onFinish={handleFinish}
          className='table-style'
        >
          <Table
            // className='special-table activity-type-table project-statuses-table'
            components={{
              body: {
                cell: (props) => <EditableCell {...props} />,
              },
            }}
            scroll={{ y: data.length > 2 && 250 }}
            style={{ minHeight: '100%' }}
            rowClassName='editable-row'
            dataSource={data}
            loading={fetchingLoading}
            columns={mergedColumns}
            pagination={false}
            rowKey='position'
          />
        </Form>
      </Col>
    </Row>
  );
};

export default NotesTable;
