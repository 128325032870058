import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Space,
  Col,
  Row,
  message,
  Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
// import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import { ReactComponent as CrossIcon } from '../../../assets/cross-icon.svg';
import sampleImg from '../../../assets/user-icon.svg';

import SpinnerComponent from '../../../components/spinner';
import { arrayMoveImmutable } from 'array-move';
import DragDropIcon from '../../../assets/dragdropicon.png';
import * as Actions from '../../QuoteStatuses/redux/actions';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { baseURL } from '../../../globalUtils/axiosConfig';

const { Option } = Select;
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <img alt='' src={DragDropIcon} />);

const SubObservationTable = ({
  canUpdate,
  canDelete,
  learning_activity_id,
  sub_observations,
  record,
}) => {
  const [editingLoading, setEditingLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ref = useRef();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [icon, setIcon] = useState(null);
  const [isIconSelected, setIsIconSelected] = useState(false);

  const isEditing = (record) => record._id === editingKey;

  const { subObservations, subUpdateLoading, subIsAdding } = useSelector(
    (state) => state.subObservationsReducer
  );

  useEffect(() => {
    dispatch(
      Actions.fetchSubObservations({
        learning_activity_id: learning_activity_id,
        observation_id: record.observation_id,
      })
    );
  }, []);

  useEffect(() => {
    setEditingLoading(subUpdateLoading);
    if (!subUpdateLoading) {
      setEditingKey('');
    }
  }, [subUpdateLoading]);

  // useEffect(() => {
  //   dispatch(Actions.fetchSubObservationsSuccess(sub_observations));
  // }, [sub_observations]);

  useEffect(() => {
    setData(subObservations);
  }, [subObservations]);

  const changeHandler = (event) => {
    const image = event.target.files[0];
    if (!image) {
      return false;
    }
    if (!image.name.match(/\.(svg)$/)) {
      message.error('Photo should be svg format.');
      return false;
    }
    if (image.size > 5120000) {
      message.error('Photo size should be less than 5MB.');
      return false;
    }
    setIcon(event.target.files[0]);
    setIsIconSelected(true);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const textInput = (
      <Input
        placeholder='Insert Name'
        ref={ref}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            save(record._id);
          }
        }}
        // readOnly={record?._id === 2 ? true : false}
      />
    );

    let inputNode = null;

    if (dataIndex === 'name') {
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `This field is required!`,
                },
                {
                  min: 1,
                  message: `invalid!`,
                },
              ]}
            >
              {textInput}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    }

    if (dataIndex === 'detail') {
      inputNode = (
        <Input
          placeholder='Insert Description'
          //   ref={ref}
          //   onKeyPress={(e) => {
          //     if (e.key === 'Enter') {
          //       save(record._id);
          //     }
          //   }}
          // readOnly={record?._id === 2 ? true : false}
        />
      );
    }

    if (dataIndex === 'icon') {
      inputNode = (
        <div style={{ width: '40px' }}>
          <div
            style={{
              width: '30px',
              height: '30px',
              textAlign: 'center',
            }}
          >
            {isIconSelected ? (
              <img
                width='100%'
                height='100%'
                style={{
                  objectFit: 'contain',
                }}
                src={
                  // URL.createObjectURL(icon)
                  isIconSelected
                    ? URL.createObjectURL(icon)
                    : // : icon
                      // ? `${baseURL}/${icon}`
                      sampleImg
                }
                alt='icon'
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: icon,
                }}
              />
            )}
          </div>
          <div
            className='edit-icon-div'
            style={
              {
                // textAlign: 'center',
              }
            }
          >
            <input
              // readOnly={loading}
              // disabled={!isEdit}
              type='file'
              name='profileImg'
              id='profileImg'
              accept='image/*'
              style={{ display: 'none' }}
              onChange={changeHandler}
            />
            <label
              htmlFor='profileImg'
              style={{
                paddingLeft: '10px',
              }}
            >
              <EditIcon height={14} width={14} fill='#50B6F2' />
            </label>
          </div>
        </div>
      );
    }

    if (dataIndex === 'deleted_status') {
      inputNode = (
        <Select
          showSearch
          className={`deleted-status-selector`}
          style={{ width: '100px', height: '30px' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // onChange={handleUpdateSite}
          placeholder='Select Status'
          optionLabelProp='label'
        >
          {[
            { label: 'ACTIVE', value: 'active' },
            { label: 'INACTIVE', value: 'inactive' },
          ]?.map(({ label, value }) => {
            return (
              <Option value={value} key={value} label={label}>
                {label || ''}
              </Option>
            );
          })}
        </Select>
      );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            valuePropName={'value'}
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Required!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(Actions.isSubObservationAdding(true));
    form.setFieldsValue({
      ...record,
    });
    if (record?.isAdd) {
    } else {
      setIcon(record.icon || null);
    }
    setEditingKey(record._id);
    setTimeout(() => {
      ref.current?.focus();
    }, 300);
    setIsIconSelected(false);
  };

  const cancel = (record) => {
    dispatch(Actions.isSubObservationAdding(false));
    form.resetFields();
    setEditingKey('');

    if (record?.isAdd) {
      return setData((data) => data.filter((el) => el._id !== record._id));
    } else {
      setData(data);
    }
  };

  const isAlreadyExist = (record) => {
    const { name } = record;
    const alreadyExist = data.filter(
      (el) => el?.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase()
    );

    return alreadyExist.length > 0 && record._id !== alreadyExist[0]?._id
      ? true
      : false;
  };

  const save = async (id) => {
    if (!icon) {
      return message.error('Sub observation icon is required!');
    }

    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item._id);
      if (index > -1) {
        const item = newData[index];
        if (item?.isAdd) {
          form.submit();
        } else {
          let itemData = {
            ...item,
            ...row,
            learning_activity_id: learning_activity_id,
            observation_id: record.observation_id,
          };
          if (isIconSelected) {
            itemData.icon = icon;
          }

          if (isAlreadyExist(itemData)) {
            return message.error('Observation name already exist!');
          }

          dispatch(Actions.updateSubObservation(itemData));
        }
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      if (errInfo?.values?.name === '') {
        message.error('Name should not be blank!');
      }
    }
  };

  const handleDelete = (record) => {
    setEditingKey(record._id);
    // dispatch(Actions.delete(record));
  };

  const handleAdd = () => {
    dispatch(Actions.isSubObservationAdding(true));
    let _id = Math.floor(Math.random() * 1122123456);
    const newData = {
      _id,
      name: '',
      deleted_status: 'active',
      isAdd: true,
      learning_activity_id,
      observation_id: record.observation_id,
    };
    setData((data) => [...data, newData]);
    setEditingKey(_id);
    setIcon(null);
    edit(newData);
  };

  const hanldeFinish = (values) => {
    const { name, deleted_status, detail } = values;

    if (isAlreadyExist(values)) {
      return message.error('Observation name already exist!');
    }

    dispatch(
      Actions.createSubObservation({
        name: name.trim(),
        deleted_status,
        id: editingKey,
        learning_activity_id: learning_activity_id,
        icon,
        observation_id: record.observation_id,
        detail
      })
    );
  };

  const handleSuccess = () => {
    // dispatch(Actions.fetchQuoteStatuses());
  };

  const onChangeCompletedStatus = (checked, record) => {
    const { _id } = record;
    if (checked) {
      const updated = data.map((el) => ({
        ...el,
        completed_status: el._id === record._id,
      }));

      setData(updated);
    }

    dispatch(
      Actions.updateQuoteCompletedStatuses({
        _id,
        completed_status: true,
        handleSuccess,
      })
    );
  };

  let disableIcons = () => subIsAdding;

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: '15',
      editable: true,
      render: (_, record) => {
        return (
          <div
          // onClick={() => {
          //   if (expandedRowKeys.includes(record._id)) {
          //     setExpandedRowKeys([]);
          //   } else {
          //     const keys = [];
          //     keys.push(record._id);
          //     setExpandedRowKeys(keys);
          //   }
          // }}
          >
            {record?.name}
          </div>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'detail',
      key: 'detail',
      align: 'left',
      width: '20%',
      editable: true,
      render: (_, record) => {
        return (
          <div
          // onClick={() => {
          //   if (expandedRowKeys.includes(record._id)) {
          //     setExpandedRowKeys([]);
          //   } else {
          //     const keys = [];
          //     keys.push(record._id);
          //     setExpandedRowKeys(keys);
          //   }
          // }}
          >
            {record?.detail}
          </div>
        );
      },
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      align: 'center',
      width: '10%',
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      width: '20%',
      editable: true,
      align: 'left',
      render: (icon, record) => {
        if (!icon) {
          return;
        }

        return (
          <div
            dangerouslySetInnerHTML={{
              __html: icon,
            }}
          />
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'deleted_status',
      key: 'deleted_status',
      width: '15%',
      editable: true,
      align: 'center',
      // sorter: (a, b) => a.deleted_status?.localeCompare(b.deleted_status),
      render: (deleted_status, record) => {
        return (
          <div style={{ paddingLeft: '12px' }}>
            {record?.deleted_status?.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: '',
      width: '10%',
      align: 'left',
      render: (_, record) => {
        // const { id } = record;
        // if (id === 2) {
        //   return;
        // }
        if (!canUpdate && !canDelete) {
          return;
        }

        const editable = isEditing(record);
        return editable ? (
          editingLoading && editingKey === record._id ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={20} align='center'>
              <SaveIcon
                className='custom-icon'
                onClick={() => save(record._id)}
              />
              <Popconfirm
                title='Sure to cancel?'
                onConfirm={() => cancel(record)}
              >
                <CrossIcon
                  style={{ height: '20px', width: '20px' }}
                  className='custom-icon'
                />
              </Popconfirm>
            </Space>
          )
        ) : (
          <Space size={20} align='center'>
            <EditIcon
              className={`custom-icon ${disableIcons() ? 'disable-icon' : ''}`}
              disabled={editingKey !== ''}
              onClick={() => !disableIcons() && edit(record)}
            />
            {/* {canDelete &&
              data.length >= 1 &&
              (disableIcons() ? (
                <DeleteIcon className={`custom-icon disable-icon`} />
              ) : (
                <Popconfirm
                  title='Are you sure you want to delete this status?'
                  onConfirm={() => handleDelete(record)}
                  okText='Yes'
                  cancelText='No'
                >
                  <DeleteIcon className={`custom-icon`} />
                </Popconfirm>
              ))} */}
          </Space>
        );
      },
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      width: '10%',
      className: 'drag-visible',
      render: (order, record) => {
        if (subIsAdding) {
          return <img alt='' className='disable-icon' src={DragDropIcon} />;
        }

        return !Boolean(editingKey) && canUpdate && <DragHandle />;
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(data.slice(), oldIndex, newIndex)
        .filter((el) => !!el)
        .map((el) => ({ sub_observation_id: el.sub_observation_id }));

      dispatch(
        Actions.updateSubObservationOrder({
          data: newData,
          learning_activity_id,
          observation_id: record.observation_id,
        })
      );
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex(
      (x) => x.position == restProps['data-row-key']
    );

    return <SortableItem index={index} {...restProps} />;
  };
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        // title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Row justify='center' className=''>
      <Col xxl={24} xl={24} lg={24} md={24} className='heading-col'>
        <Row align='middle' gutter={[30, 0]}>
          <Col>
            <div>
              <span className='heading-span'>Sub Observations</span>
            </div>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Form
          form={form}
          component={false}
          onFinish={hanldeFinish}
          className='table-style'
        >
          <Table
            className='special-table activity-type-table project-statuses-table doc-status-table'
            components={{
              body: {
                cell: EditableCell,
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            scroll={{ x: 750 }}
            rowClassName='editable-row'
            dataSource={data}
            loading={subUpdateLoading}
            columns={mergedColumns}
            pagination={false}
            rowKey='position'
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
          />
          <br />
          <Col offset={1} lg={24} xl={18} xxl={17} md={24} className='table'>
            {canUpdate && (
              <Button
                icon={<PlusOutlined />}
                // className='add-status-btn'
                size='middle'
                disabled={subIsAdding}
                onClick={handleAdd}
              >
                Sub Observation
              </Button>
            )}
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default SubObservationTable;
