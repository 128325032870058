import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { baseURL } from "../../../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../../../components/unauthorizeHandler";

export function* handleFetchImsRevisions({ data }) {
  const { search, id, ...rest } = data;

  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/document_control/${id}/revisions`,
      {
        params: { ...rest, ...(Object.keys(search).length > 0 && { search }) },
      }
    );
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchImsRevisionsSuccess(response.data.data));
    } else if (!success) {
      yield put(actions.fetchImsRevisionsError());

      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchImsRevisionsError());
    console.log(error);
  }
}

// export function* handleFetchActivitiesUtilsData({ data }) {
//   try {
//     const response = yield axios.get(
//       `${baseURL}/api/v2/sales_activities/search_lists`
//     );
//     const { success } = response.data;

//     if (success) {
//       yield put(actions.fetchActivitiesSearchUtilsSuccess(response.data.data));
//     } else if (!success) {
//       errorHandler(response.data);
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }

export function* handleCreateImsRevision({ data }) {
  const fd = new FormData();
  const { parentRecordID, ...rest } = data;
  for (const [key, value] of Object.entries(rest)) {
    if (key === "images" && value !== undefined && value !== null) {
      Array.from(value).forEach((image) => {
        fd.append(`attachments[]`, image);
      });
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const response = yield axios.post(
      `${baseURL}/api/v2/document_control/${parentRecordID}/revisions`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createImsRevisionSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createImsRevisionError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.createImsRevisionError());
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleUpdateImsRevision({ data }) {
  const { id, parentRecordID, ...res } = data;

  const fd = new FormData();
  for (const [key, value] of Object.entries(res)) {
    if (key === "images" && value !== undefined && value !== null) {
      Array.from(value).forEach((image) => {
        const { isLocal } = image;
        if (isLocal) {
          fd.append(`attachments[]`, image);
        }
        // else fd.append("image_ids[]", key);
      });
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v2/document_control/${parentRecordID}/revisions/${id}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateImsRevisionSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateImsRevisionError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateImsRevisionError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleDeleteImsRevision({ data }) {
  const { parentRecordID, id, onDeleteSuccess } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/document_control/${parentRecordID}/revisions/${id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteImsRevisionSuccess(response.data.data));
      onDeleteSuccess();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteImsRevisionError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteImsRevisionError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleDeleteImsRevisionAttach({ data }) {
  const { key, recordId, parentRecordID } = data;

  let params = {
    id: key,
  };

  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/document_control/${parentRecordID}/revisions/delete_revision_attachment`,
      {
        params,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.deleteImsRevisionAttachSuccess({
          recordId,
          imgItem: response.data.data,
        })
      );
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteImsRevisionAttachError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteImsRevisionAttachError(response.data.data));
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleAddImsRevisionAttach({ data }) {
  const { setCurrentUpdateObj } = data;

  const { id, images, parentRecordID } = data.data;

  const fd = new FormData();
  Array.from(images).forEach((image) => {
    const { isLocal } = image;
    if (isLocal) {
      fd.append(`attachments[]`, image);
    }
  });

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v2/document_control/${parentRecordID}/revisions/${id}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateImsRevisionSuccess(response.data.data));
      setCurrentUpdateObj(response.data.data);
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateImsRevisionError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateImsRevisionError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}
