import { CREATE_IMS_DISTRIBUTION_SUCCESS } from '../tableSection/distributionTable/redux/constants';
import { CREATE_IMS_REVISIONS_SUCCESS } from '../tableSection/classes/redux/constants';
import * as constants from './constants';

const initialState = {
  docTypeGrpahData: [],
  allDocs: [],
  fetchingLoading: false, // true
  fetchDocsLoading: false, // true
  updateLoading: false,
  totalRecords: 0,
  deleteAttachLoading: false,
  isAddImsDoc: false,
  isUpdateImsDoc: false,
  deletingLoading: false,
  totalAndCompleted: [],
  defaultPillars: [],
};

const sitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_IMS_DOCS:
      return {
        ...state,
        fetchDocsLoading: true,
      };
    case constants.FETCH_IMS_DOCS_SUCCESS:
      // const { documents, total_documents } = action.data;
      return {
        ...state,
        fetchDocsLoading: false,
        allDocs: action.data.sites,
        totalRecords: action.data.total_sites,
      };
    case constants.FETCH_IMS_DOCS_ERROR:
      return {
        ...state,
        fetchDocsLoading: false,
      };

    case constants.CREATE_IMS_DOC:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_IMS_DOC_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allDocs: [action.data, ...state.allDocs],
        isAddImsDoc: false,
        isUpdateImsDoc: false,
      };
    case constants.CREATE_IMS_DOC_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAddImsDoc: false,
        isUpdateImsDoc: false,
      };

    case constants.FETCH_ACTIVITIES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_ACTIVITIES_SUCCESS:
      const { completed, total, ...rest } = action.data;

      let totalAndCompleted = [
        {
          label: '',
          id: 1,
          Completed: completed,
          Total: total,
        },
      ];

      let result = [];

      Object.entries(rest).map((el, i) =>
        result.push({
          Completed: el[1].completed_by_template,
          id: i + 1,
          label: el[0],
          Total: el[1].total_by_template,
        })
      );

      return {
        ...state,
        fetchingLoading: false,
        docTypeGrpahData: result,
        totalAndCompleted,
      };
    case constants.FETCH_ACTIVITIES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };
    case constants.UPDATE_ACTIVITY:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_ACTIVITY_SUCCESS:
      const updated = state.allDocs.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allDocs: updated,
        updateLoading: false,
        // isAddImsDoc: false,
        // isUpdateImsDoc: false,
      };
    case constants.UPDATE_ACTIVITY_ERROR:
      return {
        ...state,
        updateLoading: false,
      };
    case constants.DELETE_IMS_DOC:
      return {
        ...state,
        deletingLoading: true,
      };
    case constants.DELETE_IMS_DOC_SUCCESS:
      const updatedAct = state.allDocs.filter((el) => el.id !== action.data.id);
      return {
        ...state,
        allDocs: updatedAct,
        deletingLoading: false,
      };
    case constants.DELETE_IMS_DOC_ERROR:
      return {
        ...state,
        deletingLoading: false,
      };
    case constants.DELETE_IMS_DOC_ATTACH:
      return {
        ...state,
        deleteAttachLoading: true,
      };
    case constants.DELETE_IMS_DOC_ATTACH_SUCCESS:
      const {
        recordId,
        imgItem: { id },
      } = action.data;

      let updatedActivities = [];

      state.allDocs.map((el) => {
        if (el.id === recordId) {
          let document_attachments = el.document_attachments.filter(
            (img) => img.id != id
          );
          updatedActivities.push({ ...el, document_attachments });
        } else {
          updatedActivities.push(el);
        }
      });

      return {
        ...state,
        allDocs: updatedActivities,
        deleteAttachLoading: false,
      };
    case constants.UPDATE_ISADD_ISUPDATE:
      const { isAddImsDoc, isUpdateImsDoc } = action.data;
      return {
        ...state,
        isAddImsDoc,
        isUpdateImsDoc,
      };
    case constants.DELETE_IMS_DOC_ATTACH_ERROR:
      return {
        ...state,
        deleteAttachLoading: false,
      };

    case constants.FETCH_BATCH_UPDATE:
      return {
        ...state,
        fetchDocsLoading: true,
      };
    case constants.FETCH_BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        fetchDocsLoading: false,
        allDocs: action.data.documents,
        totalRecords: action.data.total_documents,
      };
    case CREATE_IMS_REVISIONS_SUCCESS:
      const { document_control_id, rev } = action.data;

      let updatedDocs = state.allDocs.map((el) => {
        let updated = {
          latest_revision: rev,
          doc_revisions: [...el.doc_revisions, action.data],
        };

        return el.id === document_control_id ? { ...el, ...updated } : el;
      });
      return {
        ...state,
        allDocs: updatedDocs,
      };
    case constants.FETCH_BATCH_UPDATE_ERROR:
      return {
        ...state,
        fetchDocsLoading: false,
      };
    case CREATE_IMS_DISTRIBUTION_SUCCESS:
      const { data } = action;
      let updatedDocumentss = state.allDocs.map((el) => {
        let updatedDis = [...el.doc_distributions, action.data];

        return el.id === data.document_control_id
          ? { ...el, doc_distributions: updatedDis }
          : el;
      });
      return {
        ...state,
        allDocs: updatedDocumentss,
      };
    case constants.SET_PILLARS_DATA: {
      return {
        ...state,
        defaultPillars: action.data,
      };
    }
    default:
      return state;
  }
};

export default sitesReducer;
