import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleLoginUser({ data }) {
  const { navigate, tenant, ...rest } = data;
  try {
    const response = yield axios.post(`${baseURL}/api/v2/admin/auth/login`, rest, {
      headers: {
        tenant,
      },
    });
    const { message } = response.data;
    if (response.data?.data) {
      const { user } = response.data.data;
      let allowedUserTypes = ['admin', 'administrative'];

      // Validate user roles
      const isValidUser = user.roles.some((role) =>
        allowedUserTypes.includes(role)
      );

      if (isValidUser) {
        AntdAlert.success(message);

        yield put(
          actions.loginUserSuccess({
            user: { ...user, auth_token: user.token, tenant },
            navigate,
            shouldNavigate: true,
          })
        );
      } else {
        yield put(actions.loginUserError());
        AntdAlert.error(
          "Access Forbidden: You don't have the necessary permissions."
        );
      }
    } else if (!response.data?.data) {
      AntdAlert.error(message);
      yield put(actions.loginUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.loginUserError());
    AntdAlert.error(error?.response?.data?.message || 'Something went wrong!');
    console.log(error);
  }
}

export function* handleLogoutUser({ data }) {
  const { navigate, id } = data;
  AntdAlert.success('Logout Successfully!');
  yield put(actions.logoutUserSuccess({ navigate }));
  // try {
  //   const response = yield axios.post(`${baseURL}/api/v2/admin/users/logout`, { id });
  //   const { success, message } = response.data;
  //   if (success) {
  //     AntdAlert.success(message);
  //     yield put(actions.logoutUserSuccess({ ...response.data.data, navigate }));
  //     navigate('/');
  //   } else if (!success) {
  //     AntdAlert.error(message);
  //     yield put(actions.logoutUserError());
  //     errorHandler(response.data);
  //   }
  // } catch (error) {
  //   console.log(error);
  // }
}

export function* handleFetchSidebarProjects({ data }) {
  const { user_id } = data;
  try {
    const response = yield axios.get(`${baseURL}/api/v2/admin/users/${user_id}/npos`);
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchSidebarProjectSuccess(response.data.data));
    } else {
      yield put(actions.fetchSidebarProjectError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchSidebarProjectError());
    console.log(error);
  }
}
