import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';

export function* handleFetchProductTypes({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/product_types`, {});
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchProductTypesSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchProductTypesError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateProductTypes({ data }) {
  const { position, _id, name } = data;

  try {
    const response = yield axios.patch(`${baseURL}/product_types/${_id}`, {
      name,
      position,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateProductTypesSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateProductTypesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateProductTypesError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleDeleteProductTypes({ data }) {
  const { _id } = data;
  try {
    const response = yield axios.delete(`${baseURL}/product_types/${_id}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteProductTypesSuccess(_id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteProductTypesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteProductTypesError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleCreateProductTypes({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData._id;
    const response = yield axios.post(`${baseURL}/product_types`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createProductTypesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createProductTypesError(data._id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateProductOrder({ data }) {
  try {
    const response = yield axios.patch(`${baseURL}/sort_product_types`, data);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateProductTypesOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateProductTypesOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateProductTypesOrderError());
    AntdAlert.error('Something went wrong!');
  }
}

export function* watcherProductTypesSaga() {
  yield takeLatest(constants.FETCH_PRODUCT_TYPES, handleFetchProductTypes);
  yield takeLatest(constants.CREATE_PRODUCT_TYPES, handleCreateProductTypes);
  yield takeLatest(constants.UPDATE_PRODUCT_TYPES, handleUpdateProductTypes);
  yield takeLatest(
    constants.UPDATE_ORDER_PRODUCT_TYPES,
    handleUpdateProductOrder
  );
  yield takeLatest(constants.DELETE_PRODUCT_TYPES, handleDeleteProductTypes);
}
