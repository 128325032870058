import React, { useState } from 'react';
import { Col, Row } from 'antd';
import Table from './Table';

const StickersTable = ({ id }) => {
  // const { user } = useSelector((state) => state.authReducer);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);

  // useEffect(() => {
  //   if (user?.permissions?.site_statuses?.add_edit) {
  //     setCanUpdate(true);
  //   }
  //   if (user?.permissions?.site_statuses?.delete) {
  //     setCanDelete(true);
  //   }
  // }, [user]);

  return (
    <Row>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[0, 0]}>
          <Col>
            <div>
              <span className='heading-span'>Stickers</span>
            </div>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Table canUpdate={canUpdate} canDelete={canDelete} id={id} />
      </Col>
    </Row>
  );
};

export default StickersTable;
