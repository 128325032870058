import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContactTypes } from '../contactTypes/redux/actions';
import { fetchProductTypes } from '../productTypes/redux/actions';
import { fetchQuoteStatuses } from '../QuoteStatuses/redux/actions';
import { fetchLeadStatuses } from '../LeadStatuses/redux/actions';
// import { fetchAllFormSetups } from '../FormSetupManager/redux/actions';
// import GraphSection from './graphSection';
import GraphSection from '../learners/graphSection/index';
import TableSection from './tableSection';
import './index.less';
import { fetchAllFormSetups } from '../FormSetupManager/redux/actions';
import { fetchValueAdded } from '../valueAddedServices/redux/actions';

const searchParams = {
  search: {},
};

const Leads = () => {
  const [contactTypesCheckList, setContactTypesCheckList] = useState([]);
  const [productTypesCheckList, setProductTypesCheckList] = useState([]);
  const [quoteStatusesCheckList, setQuoteStatusesCheckList] = useState([]);
  const [leadStatusesCheckList, setLeadStatusesCheckList] = useState([]);
  // const [coachUsers, setCoachUsers] = useState([]);

  const {
    allContactTypes,
    allProductTypes,
    allQuoteStatuses,
    allLeadStatuses,
  } = useSelector((state) => ({
    allContactTypes: state.contactTypesReducer.allContactTypes,
    allProductTypes: state.productTypesReducer.allProductTypes,
    allQuoteStatuses: state.quoteStatusReducer.allQuoteStatuses,
    allLeadStatuses: state.leadStatusReducer.allLeadStatuses,
  }));

  const dispatch = useDispatch();

  const fetchInitialData = useCallback(() => {
    // dispatch(fetchImsDocs(sitesParams));
    dispatch(fetchContactTypes({}));
    dispatch(fetchProductTypes(searchParams));
    dispatch(fetchLeadStatuses());
    dispatch(fetchQuoteStatuses());
    dispatch(fetchAllFormSetups());
    dispatch(fetchValueAdded());
  }, [dispatch]);

  useEffect(() => {
    // fetchInitialData();
  }, []);

  const contactTypesOptions = useMemo(
    () =>
      allContactTypes.map((el) => {
        const { name, _id } = el;
        return { value: _id, label: name };
      }),
    [allContactTypes]
  );

  const quoteStatusOptions = useMemo(
    () =>
      allQuoteStatuses.map((el) => {
        const { name, _id } = el;
        return { value: _id, label: name };
      }),
    [allQuoteStatuses]
  );

  const productTypesOptions = useMemo(
    () =>
      allProductTypes.map((el) => {
        const { name, _id } = el;
        return { value: _id, label: name };
      }),
    [allProductTypes]
  );

  const leadStatusOptions = useMemo(
    () =>
      allLeadStatuses.map((el) => {
        const { name, _id } = el;
        return { value: _id, label: name };
      }),
    [allLeadStatuses]
  );

  return (
    <div className='page-top-div'>
      <span
        className='text-span'
        style={{
          lineHeight: '17px',
        }}
      >
        Curriculum Management
      </span>
      {/* <GraphSection /> */}
      <TableSection
        contactTypesCheckList={contactTypesCheckList}
        setContactTypesCheckList={setContactTypesCheckList}
        contactTypesOptions={contactTypesOptions}
        setProductTypesCheckList={setProductTypesCheckList}
        productTypesCheckList={productTypesCheckList}
        productTypesOptions={productTypesOptions}
        quoteStatusOptions={quoteStatusOptions}
        setQuoteStatusesCheckList={setQuoteStatusesCheckList}
        quoteStatusesCheckList={quoteStatusesCheckList}
        leadStatusesCheckList={leadStatusesCheckList}
        setLeadStatusesCheckList={setLeadStatusesCheckList}
        leadStatusOptions={leadStatusOptions}
        // coachUsers={coachUsers}
      />
    </div>
  );
};

export default React.memo(Leads);
