import * as constants from './constants';

const initialState = {
  allStreams: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
  search: {},
};

const streamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_STREAMS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_STREAMS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allStreams: [action.data.stream, ...state.allStreams],
        isAdding: false,
      };
    case constants.CREATE_STREAMS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_ORDER_STREAMS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_ORDER_STREAMS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allStreams: action.data,
      };
    case constants.UPDATE_ORDER_STREAMS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_STREAMS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_STREAMS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allStreams: action.data.streams,
        isAdding: false,
      };
    case constants.FETCH_STREAMS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_STREAMS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_STREAMS_SUCCESS:
      const updated = state.allStreams.map((el) =>
        el._id === action.data.stream._id
          ? { ...el, ...action.data.stream }
          : el
      );
      return {
        ...state,
        allStreams: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_STREAMS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_STREAMS:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_STREAMS_SUCCESS:
      const updatedData = state.allStreams.filter(
        (el) => el._id !== action.data
      );
      return {
        ...state,
        allStreams: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_STREAMS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    case constants.SET_STREAMS_MANAGER_STATE:
      const { search } = state;

      localStorage.setItem(
        'streamsManagerState',
        JSON.stringify({ search, ...action.data })
      );
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default streamsReducer;
