import * as constants from './constants';

export const fetchProductTypes = (data) => {
  return {
    type: constants.FETCH_PRODUCT_TYPES,
    data,
  };
};

export const fetchProductTypesSuccess = (data) => {
  return {
    type: constants.FETCH_PRODUCT_TYPES_SUCCESS,
    data: data,
  };
};

export const fetchProductTypesError = (data) => {
  return {
    type: constants.FETCH_PRODUCT_TYPES_ERROR,
    data: data,
  };
};

export const updateProductTypes = (data) => {
  return {
    type: constants.UPDATE_PRODUCT_TYPES,
    data: data,
  };
};

export const updateProductTypesSuccess = (data) => {
  return {
    type: constants.UPDATE_PRODUCT_TYPES_SUCCESS,
    data: data,
  };
};

export const updateProductTypesError = () => {
  return {
    type: constants.UPDATE_PRODUCT_TYPES_ERROR,
  };
};

export const updateProductTypesOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_PRODUCT_TYPES,
    data: data,
  };
};

export const updateProductTypesOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_PRODUCT_TYPES_SUCCESS,
    data: data,
  };
};

export const updateProductTypesOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_PRODUCT_TYPES_ERROR,
  };
};

export const deleteProductTypes = (data) => {
  return {
    type: constants.DELETE_PRODUCT_TYPES,
    data: data,
  };
};

export const deleteProductTypesSuccess = (data) => {
  return {
    type: constants.DELETE_PRODUCT_TYPES_SUCCESS,
    data: data,
  };
};

export const deleteProductTypesError = () => {
  return {
    type: constants.DELETE_PRODUCT_TYPES_ERROR,
  };
};

export const createProductTypes = (data, id) => {
  return {
    type: constants.CREATE_PRODUCT_TYPES,
    data: data,
  };
};

export const createProductTypesSuccess = (data) => {
  return {
    type: constants.CREATE_PRODUCT_TYPES_SUCCESS,
    data: data,
  };
};

export const createProductTypesError = (id) => {
  return {
    type: constants.CREATE_PRODUCT_TYPES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
