import * as constants from './constants';

export const fetchLeadDocs = (data) => {
  return {
    type: constants.FETCH_LEADS_DOCS,
    data: data,
  };
};

export const fetchLeadDocsSuccess = (data) => {
  return {
    type: constants.FETCH_LEADS_DOCS_SUCCESS,
    data: data,
  };
};

export const fetchLeadDocsError = () => {
  return {
    type: constants.FETCH_LEADS_DOCS_ERROR,
  };
};

export const fetchActivities = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES,
    data,
  };
};

export const fetchActivitiesSuccess = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SUCCESS,
    data: data,
  };
};

export const fetchActivitiesSearchUtils = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA,
    data,
  };
};

export const fetchActivitiesSearchUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS,
    data: data,
  };
};

export const fetchActivitiesSearchUtilsError = (data) => {
  return {
    type: constants.FETCH_ACTIVITIES_SEARCH_DATA_ERROR,
    data,
  };
};
export const updateActivity = (data) => {
  return {
    type: constants.UPDATE_ACTIVITY,
    data: data,
  };
};

export const updateActivitySuccess = (data) => {
  return {
    type: constants.UPDATE_ACTIVITY_SUCCESS,
    data: data,
  };
};

export const updateActivityError = () => {
  return {
    type: constants.UPDATE_ACTIVITY_ERROR,
  };
};

export const deleteLeadDoc = (data) => {
  return {
    type: constants.DELETE_LEADS_DOC,
    data: data,
  };
};

export const deleteLeadDocSuccess = (data) => {
  return {
    type: constants.DELETE_LEADS_DOC_SUCCESS,
    data: data,
  };
};

export const deleteLeadDocError = () => {
  return {
    type: constants.DELETE_LEADS_DOC_ERROR,
  };
};

export const createLeadDoc = (data) => {
  return {
    type: constants.CREATE_LEADS_DOC,
    data: data,
  };
};

export const createLeadDocSuccess = (data) => {
  return {
    type: constants.CREATE_LEADS_DOC_SUCCESS,
    data: data,
  };
};

export const createLeadDocError = () => {
  return {
    type: constants.CREATE_LEADS_DOC_ERROR,
  };
};

export const deleteLeadDocAttach = (data) => {
  return {
    type: constants.DELETE_LEADS_DOC_ATTACH,
    data: data,
  };
};

export const deleteLeadDocAttachSuccess = (data) => {
  return {
    type: constants.DELETE_LEADS_DOC_ATTACH_SUCCESS,
    data: data,
  };
};

export const deleteLeadDocAttachError = () => {
  return {
    type: constants.DELETE_LEADS_DOC_ATTACH_ERROR,
  };
};

export const updateIsAddIsUpdate = (data) => {
  return {
    type: constants.UPDATE_ISADD_ISUPDATE,
    data,
  };
};

export const addActivityAttach = (data) => {
  return {
    type: constants.ADD_LEADS_DOC_ATTACH,
    data: data,
  };
};

export const fetchBatchUpdate = (data) => {
  return {
    type: constants.FETCH_BATCH_UPDATE,
    data: data,
  };
};

export const fetchBatchUpdateSuccess = (data) => {
  return {
    type: constants.FETCH_BATCH_UPDATE_SUCCESS,
    data: data,
  };
};

export const fetchBatchUpdateError = () => {
  return {
    type: constants.FETCH_BATCH_UPDATE_ERROR,
  };
};

export const setPillarData = (data) => {
  return {
    type: constants.SET_PILLARS_DATA,
    data,
  };
};

export const setStorage = (data) => {
  return {
    type: constants.SET_CURRICULUMS_MANAGER_STATE,
    data,
  };
};
