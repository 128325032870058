const FETCH_SITE_STATUSES = 'FETCH_STICKERS';
const FETCH_SITE_STATUSES_SUCCESS = 'FETCH_STICKERS_SUCCESS';
const FETCH_SITE_STATUSES_ERROR = 'FETCH_STICKERS_ERROR';
const UPDATE_SITE_STATUSES = 'UPDATE_STICKER';
const UPDATE_SITE_STATUSES_SUCCESS = 'UPDATE_STICKER_SUCCESS';
const UPDATE_SITE_STATUSES_ERROR = 'UPDATE_STICKER_ERROR';
const DELETE_SITE_STATUSES = 'DELETE_STICKER';
const DELETE_SITE_STATUSES_SUCCESS = 'DELETE_STICKER_SUCCESS';
const DELETE_SITE_STATUSES_ERROR = 'DELETE_STICKER_ERROR';
const CREATE_SITE_STATUSES = 'CREATE_STICKER';
const CREATE_SITE_STATUSES_SUCCESS = 'CREATE_STICKER_SUCCESS';
const CREATE_SITE_STATUSES_ERROR = 'CREATE_STICKER_ERROR';
const UPDATE_ORDER_SITE_STATUS = 'UPDATE_ORDER_STICKER';
const UPDATE_ORDER_SITE_STATUS_SUCCESS = 'UPDATE_ORDER_STICKER_SUCCESS';
const UPDATE_ORDER_SITE_STATUS_ERROR = 'UPDATE_ORDER_STICKER_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE_STICKER';
const SET_STICKERS_MANAGER_STATE = 'SET_STICKERS_MANAGER_STATE';

export {
  FETCH_SITE_STATUSES,
  FETCH_SITE_STATUSES_SUCCESS,
  FETCH_SITE_STATUSES_ERROR,
  UPDATE_SITE_STATUSES,
  UPDATE_SITE_STATUSES_SUCCESS,
  UPDATE_SITE_STATUSES_ERROR,
  DELETE_SITE_STATUSES,
  DELETE_SITE_STATUSES_SUCCESS,
  DELETE_SITE_STATUSES_ERROR,
  CREATE_SITE_STATUSES,
  CREATE_SITE_STATUSES_SUCCESS,
  CREATE_SITE_STATUSES_ERROR,
  UPDATE_ORDER_SITE_STATUS,
  UPDATE_ORDER_SITE_STATUS_SUCCESS,
  UPDATE_ORDER_SITE_STATUS_ERROR,
  IS_ADDING_UPDATE,
  SET_STICKERS_MANAGER_STATE,
};
