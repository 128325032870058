import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Button } from 'antd';
import { setFormSetupIsEdit } from './redux/actions';
import Table from './Table';
import './index.less';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const FormSetupManager = () => {
  const [canUpdate, setCanUpdate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (user?.permissions?.form_manager?.add_edit) {
      setCanUpdate(true);
    }
    if (user?.permissions?.form_manager?.delete) {
      setCanDelete(true);
    }
  }, [user]);

  return (
    <div className='page-top-div'>
      <span className='text-span'>Forms Manager</span>
      <Row className='production-manager-parent-row'>
        <Col span={24} className='heading-col'>
          <Row align='middle' gutter={[50, 0]}>
            <Col>
              <div>
                <span className='heading-span'>Form Setup Register</span>
              </div>
            </Col>
            <Col>
              {canUpdate && (
                <Button
                  onClick={() => {
                    navigate('/settings/form-manager/form-setup/create');
                    dispatch(setFormSetupIsEdit(true));
                  }}
                  icon={<PlusOutlined />}
                  className='add-template-btn'
                  size='middle'
                >
                  FORM
                </Button>
              )}
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Table canUpdate={canUpdate} canDelete={canDelete} />
        </Col>
      </Row>
    </div>
  );
};

export default FormSetupManager;
