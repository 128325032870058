import React from 'react';
import { Col, Row } from 'antd';
import CardComponent from './cardComponent';

const Plans = ({ data, setData, keyName }) => {
  return (
    <>
      <Col md={24} lg={23} xl={23} xxl={22}>
        <Row justify='start' gutter={[15, 30]}>
          {data.map((el, i) => {
            return (
              <Col md={8} lg={8} xl={6} xxl={6} className='plan-parent-col'>
                <CardComponent
                  {...el}
                  i={i}
                  data={data}
                  setData={setData}
                  keyName={keyName}
                />
              </Col>
            );
          })}
        </Row>
      </Col>
    </>
  );
};

export default Plans;
