import * as constants from './constants';

export const setFormSetupIsEdit = (data) => {
  return {
    type: constants.FORM_SETUP_IS_EDIT,
    data: data,
  };
};

export const createFormSetup = (data) => {
  return {
    type: constants.CREATE_FORM_SETUP,
    data: data,
  };
};

export const createFormSetupSuccess = (data) => {
  return {
    type: constants.CREATE_FORM_SETUP_SUCCESS,
    data: data,
  };
};

export const createFormSetupError = () => {
  return {
    type: constants.CREATE_FORM_SETUP_ERROR,
  };
};

export const fetchAllFormSetups = (data) => {
  return {
    type: constants.FETCH_ALL_FORM_SETUPS,
    data,
  };
};

export const fetchAllFormSetupsSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_FORM_SETUPS_SUCCESS,
    data,
  };
};
export const fetchAllFormSetupsError = (data) => {
  return {
    type: constants.FETCH_ALL_FORM_SETUPS_ERROR,
    data,
  };
};
export const updateFormSetups = (data) => {
  return {
    type: constants.UPDATE_FORM_SETUP,
    data: data,
  };
};

export const updateFormSetupsSuccess = (data) => {
  return {
    type: constants.UPDATE_FORM_SETUP_SUCCESS,
    data: data,
  };
};

export const updateFormSetupsError = () => {
  return {
    type: constants.UPDATE_FORM_SETUP_ERROR,
  };
};
export const templateCreationCleanupFunc = () => {
  return {
    type: constants.FORM_SETUP_CREATION_CLEANUP_FUNC,
  };
};
export const deleteFormSetup = (data) => {
  return {
    type: constants.DELETE_FORM_SETUP,
    data,
  };
};

export const deleteFormSetupSuccess = (data) => {
  return {
    type: constants.DELETE_FORM_SETUP_SUCCESS,
    data,
  };
};

export const deleteFormSetupError = (data) => {
  return {
    type: constants.DELETE_FORM_SETUP_ERROR,
    data,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};

export const fetchFormSetup = (data) => {
  return {
    type: constants.FETCH_FORM_SETUP,
    data,
  };
};

export const fetchFormSetupSuccess = (data) => {
  return {
    type: constants.FETCH_FORM_SETUP_SUCCESS,
    data,
  };
};

export const fetchFormSetupError = () => {
  return {
    type: constants.FETCH_FORM_SETUP_ERROR,
  };
};
