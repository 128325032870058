import React, { useEffect } from 'react';
import { Col, InputNumber, Row, Select } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { thousandsSeparators } from '../../globalUtils/convertStrToMinutes';
import './index.less';

export default function CustomPagination({
  page,
  setPage,
  resultPerPage,
  setResultPerPage,
  debouncedCallApi,
  totalRecord,
  handleSetStorage = null,
}) {
  const { Option } = Select;

  const handleChange = (value) => {
    if (resultPerPage != value) {
      setResultPerPage(value);
      handleSetStorage && handleSetStorage('resultPerPage', value);
      debouncedCallApi();
    }
  };

  useEffect(() => {
    if (page * resultPerPage - resultPerPage + 1 > totalRecord) {
      setPage(1);
      // handleSetStorage && handleSetStorage('page', 1);
    }
  }, [page, resultPerPage, totalRecord]);

  return (
    <div>
      <Row justify='space-between' className='table-pagination-parent-row'>
        <Col
          lg={{ span: 8, order: 1 }}
          md={{ span: 7, order: 1 }}
          xs={{ span: 13, order: 2 }}
          style={{
            textAlign: 'start',
          }}
          className='result-per-page-parent-div'
        >
          Results per Page:{' '}
          <Select
            className='result-per-page-selector'
            dropdownClassName='result-per-page-dropdown'
            defaultValue='10'
            value={resultPerPage}
            onChange={handleChange}
            getPopupContainer={() =>
              document.getElementsByClassName(
                'ant-layout-content layout-content site-layout-background custom-textarea'
              )[0]
            }
          >
            <Option value={10}>10</Option>
            <Option value={25}>25</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
        </Col>
        <Col
          lg={{ span: 9, order: 2 }}
          md={{ span: 10, order: 2 }}
          xs={{ span: 24, order: 1 }}
          style={{
            textAlign: 'center',
          }}
          className='table-pagination-arrow-col'
        >
          {page !== 1 && page <= Math.ceil(totalRecord / resultPerPage) && (
            <ArrowLeftOutlined
              className='table-pagination-arrow'
              onClick={() => {
                setPage((e) => e - 1);
                handleSetStorage && handleSetStorage('page', page - 1);

                debouncedCallApi();
              }}
            />
          )}
          <span className='text'>
            Displaying{' '}
            {page * resultPerPage - resultPerPage + 1 > totalRecord
              ? 'Exceed limit '
              : page * resultPerPage - resultPerPage + 1}
            &nbsp;to{' '}
            {page * resultPerPage > totalRecord
              ? totalRecord
              : page * resultPerPage}{' '}
            of {totalRecord > 0 && thousandsSeparators(totalRecord)}
          </span>
          {Math.ceil(totalRecord / resultPerPage) > page && (
            <ArrowRightOutlined
              className='table-pagination-arrow'
              onClick={() => {
                setPage((e) => e + 1);
                handleSetStorage && handleSetStorage('page', page + 1);
                debouncedCallApi();
              }}
            />
          )}
        </Col>
        <Col
          lg={{ span: 6, order: 3 }}
          md={{ span: 6, order: 3 }}
          xs={{ span: 11, order: 3 }}
          style={{
            textAlign: 'end',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
          className='result-per-page-parent-div'
        >
          Go to page{' '}
          <InputNumber
            type={'number'}
            className='result-per-page-input-number go-to-page-input'
            value={page}
            min={1}
            max={Math.ceil(totalRecord / resultPerPage)}
            controls={true}
            onChange={(e) => {
              setPage(e);
              handleSetStorage && handleSetStorage('page', e);
              debouncedCallApi();
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
