import { useSelector } from 'react-redux';

const UsePermissions = () => {
  // const { user } = useSelector((state) => state.authReducer);

  // let { leads } = user.permissions;

  // let canView = leads.view;
  // let canEdit = leads.add_edit;
  // // let canExport = leads.export || false;
  // // let canImport = leads.import || false;
  // let canDelete = leads.delete;

  let canView = true;
  let canEdit = true;
  // let canExport = leads.export || false;
  // let canImport = leads.import || false;
  let canDelete = true;


  return [canView, canEdit, canDelete, false, false];
};

export default UsePermissions;
