import * as constants from './constants';
import { initialState } from '../utils';

const initialData = {
  valueServiceData: initialState,
  createLoading: false,
  templateCreatedSuccess: false,
  templateEditSuccess: false,
  updateLoading: false,
  fetchLoading: false,
  valueAddedIsEdit: false,
  deleteLoading: false,
};

const valueAddedReducer = (state = initialData, action) => {
  switch (action.type) {
    case constants.VALUE_ADDED_IS_EDIT:
      return {
        ...state,
        valueAddedIsEdit: action.data,
      };
    case constants.CREATE_VALUE_ADDED:
      return {
        ...state,
        templateEditSuccess: false,
        createLoading: true,
        templateCreatedSuccess: false,
        valueAddedIsEdit: false,
      };
    case constants.CREATE_VALUE_ADDED_SUCCESS:
      return {
        ...state,
        createLoading: false,
        valueAddedIsEdit: false,
        templateCreatedSuccess: true,
        templateEditSuccess: false,
        valueServiceData: action.data,
      };
    case constants.CREATE_VALUE_ADDED_ERROR:
      return {
        ...state,
        createLoading: false,
        templateCreatedSuccess: false,
      };
    case constants.FETCH_ALL_VALUE_ADDED:
      return {
        ...state,
        fetchLoading: true,
        templateCreatedSuccess: false,
        templateEditSuccess: false,
      };
    case constants.FETCH_ALL_VALUE_ADDED_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        isAdding: false,
        allForms: action.data,
        // totalRecords: total_document_templates,
        templateCreatedSuccess: false,
        valueAddedIsEdit: false,
        templateEditSuccess: false,
      };
    case constants.FETCH_ALL_VALUE_ADDED_ERROR:
      return {
        ...state,
        fetchLoading: false,
        isAdding: false,
        templateCreatedSuccess: false,
        templateEditSuccess: false,
      };
    case constants.UPDATE_VALUE_ADDED:
      return {
        ...state,
        updateLoading: true,
        templateCreatedSuccess: false,
      };
    case constants.UPDATE_VALUE_ADDED_SUCCESS:
      return {
        ...state,
        valueServiceData: action.data,
        updateLoading: false,
        isAdding: false,
        templateCreatedSuccess: false,
        valueAddedIsEdit: false,
        templateEditSuccess: true,
      };
    case constants.UPDATE_VALUE_ADDED_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
        templateCreatedSuccess: false,
      };
    case constants.DELETE_VALUE_ADDED:
      return {
        ...state,
        templateEditSuccess: false,
        deleteLoading: true,
        templateCreatedSuccess: false,
        valueAddedIsEdit: false,
      };
    case constants.DELETE_VALUE_ADDED_SUCCESS:
      let updatedTemplate = state.allForms.filter(
        (el) => el._id !== action.data._id
      );
      let totalRecords = state.totalRecords - 1;
      return {
        ...state,
        totalRecords: totalRecords,
        fetchTemplatesLoading: false,
        allForms: updatedTemplate,
        deleteLoading: false,
      };
    case constants.DELETE_VALUE_ADDED_ERROR:
      return {
        ...state,
        fetchTemplatesLoading: false,
      };

    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };
    case constants.FETCH_VALUE_ADDED:
      return {
        ...state,
        fetchLoading: true,
        templateCreatedSuccess: false,
        valueAddedIsEdit: false,
      };
    case constants.FETCH_VALUE_ADDED_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        templateCreatedSuccess: false,
        valueServiceData: action.data || initialData.valueServiceData,
      };
    case constants.FETCH_VALUE_ADDED_ERROR:
      return {
        ...state,
        fetchLoading: false,
        templateCreatedSuccess: false,
      };
    case constants.VALUE_ADDED_CREATION_CLEANUP_FUNC:
      return {
        ...state,
        ...initialData,
      };
    default:
      return state;
  }
};

export default valueAddedReducer;
