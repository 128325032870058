import React, { useState } from 'react';
import Table from './Table';

const Stickers = () => {
  // const { user } = useSelector((state) => state.authReducer);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);

  // useEffect(() => {
  //   if (user?.permissions?.site_statuses?.add_edit) {
  //     setCanUpdate(true);
  //   }
  //   if (user?.permissions?.site_statuses?.delete) {
  //     setCanDelete(true);
  //   }
  // }, [user]);

  return (
    <div className='page-top-div'>
      <span className='text-span'>Stickers Manager</span>
      <Table canUpdate={canUpdate} canDelete={canDelete} />
    </div>
  );
};

export default Stickers;
