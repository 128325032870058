import React, { useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Row, message, Spin, Button } from 'antd';
import { useSelector } from 'react-redux';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as AttachIcon } from '../../assets/attach.svg';
import { ReactComponent as WarningIcon } from '../../assets/warning.svg';
import { ReactComponent as TickIcon } from '../../assets/green-tick.svg';
import { baseURL } from '../../globalUtils/axiosConfig';
// import { addProductionAttach, deleteProdAttach } from './redux/actions';
// import UseGetUserRole from '../../../../../components/useGetUserRole';

const AttachComponent = ({ images, setData, name, label }) => {
  const [deleteItemKey, setDeleteItemKey] = useState([]);
  const inputFile = useRef(null);
  // const dispatch = useDispatch();
  //   const { attachmentLoading } = useSelector((state) => state.productionReducer);
  // const [isSuperAdmin, isAdmin, , isSalesUser] = UseGetUserRole();
  // const { user } = useSelector((state) => state.authReducer);

  const handleDeleteImage = (imgRecord) => {
    let filteredImages = images.filter((el) => el.key !== imgRecord.key);
    setData((prev) => ({ ...prev, [name]: filteredImages }));
    // setDeleteItemKey([...deleteItemKey, imgRecord.key]);
    // deleteAttachment({ ...imgRecord, pillarId, questionId: id });
  };

  const handleShowImage = (image) => {
    let url = image.isLocal
      ? URL.createObjectURL(image)
      : `${baseURL}${image.image}`;
    window.open(url, '_blank');
  };

  const handleImage = (imgObj, e) => {
    e.currentTarget.value = null;
    console.log('imgObj', imgObj);
    const isValid =
      /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|jpg|jpeg|gif|png|mp4|)$/i.test(
        imgObj.name
      );

    if (isValid) {
      imgObj['key'] = Math.round(Math.random() * 1000000);
      imgObj['isLocal'] = true;
      imgObj['attachType'] = name;
      imgObj['fileName'] = imgObj.name;

      if (imgObj) {
        let updatedImages = [...images, imgObj];
        setData((prev) => ({ ...prev, [name]: updatedImages }));
        // handleAddImage(imgObj);
        // const { soid } = currentUpdateObj;
        // dispatch(
        //   addProductionAttach({
        //     data: {
        //       soid,
        //       images: [imgObj],
        //     },
        //     setCurrentUpdateObj,
        //   })
        // );
      }
    } else {
      message.error('This file format is not supported.');
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );

  return (
    <Row className='attach-label-row'>
      <Col span={24} style={{ marginBottom: '10px' }}>
        <Row justify='start' align='start' gutter={[15, 0]}>
          <Col className='label' span={14}>
            <div className={name}>{label}</div>
            <Row gutter={[0, 10]} style={{ marginTop: '10px' }}>
              {images.map((el) => {
                // console.log('el', el);
                // const { image, key } = el;
                // let name = image
                //   .split('/')
                //   [image.split('/').length - 1].split('?')[0];
                const { name, key } = el;

                return (
                  <Col key={key} span={24}>
                    <Row
                      justify='space-between'
                      align='middle'
                      gutter={[15, 0]}
                    >
                      <Col className='label'>
                        <span
                          className='name-span'
                          onClick={() => handleShowImage(el)}
                        >
                          {name}
                        </span>
                      </Col>
                      <Col>
                        <Popconfirm
                          overlayClassName='activities-popconfirm'
                          title='Are you sure to delete this attachment?'
                          onConfirm={() => handleDeleteImage(el)}
                          okText='Yes'
                          cancelText='No'
                        >
                          {deleteItemKey.includes(key) ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            <DeleteIcon
                              fill='#e21c21'
                              width={13}
                              height={13}
                              className='del-icon'
                            />
                          )}
                        </Popconfirm>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
            <Button size='small' onClick={() => inputFile.current.click()}>
              Upload
            </Button>
            <input
              type='file'
              id='file'
              multiple
              accept=''
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={(e) => handleImage(e.target.files[0], e)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AttachComponent;
