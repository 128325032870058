const FETCH_COMMENTS = 'FETCH_COMMENTS';
const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
const FETCH_COMMENTS_ERROR = 'FETCH_COMMENTS_ERROR';
const UPDATE_COMMENTS = 'UPDATE_COMMENTS';
const UPDATE_COMMENTS_SUCCESS = 'UPDATE_COMMENTS_SUCCESS';
const UPDATE_COMMENTS_ERROR = 'UPDATE_COMMENTS_ERROR';
const DELETE_COMMENTS = 'DELETE_COMMENTS';
const DELETE_COMMENTS_SUCCESS = 'DELETE_COMMENTS_SUCCESS';
const DELETE_COMMENTS_ERROR = 'DELETE_COMMENTS_ERROR';
const CREATE_COMMENTS = 'CREATE_COMMENTS';
const CREATE_COMMENTS_SUCCESS = 'CREATE_COMMENTS_SUCCESS';
const CREATE_COMMENTS_ERROR = 'CREATE_COMMENTS_ERROR';
const UPDATE_ORDER_COMMENTS = 'UPDATE_ORDER_COMMENTS';
const UPDATE_ORDER_COMMENTS_SUCCESS = 'UPDATE_ORDER_COMMENTS_SUCCESS';
const UPDATE_ORDER_COMMENTS_ERROR = 'UPDATE_ORDER_COMMENTS_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';
const SET_COMMENTS_MANAGER_STATE = 'SET_COMMENTS_MANAGER_STATE';

export {
  FETCH_COMMENTS,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_ERROR,
  UPDATE_COMMENTS,
  UPDATE_COMMENTS_SUCCESS,
  UPDATE_COMMENTS_ERROR,
  DELETE_COMMENTS,
  DELETE_COMMENTS_SUCCESS,
  DELETE_COMMENTS_ERROR,
  CREATE_COMMENTS,
  CREATE_COMMENTS_SUCCESS,
  CREATE_COMMENTS_ERROR,
  UPDATE_ORDER_COMMENTS,
  UPDATE_ORDER_COMMENTS_SUCCESS,
  UPDATE_ORDER_COMMENTS_ERROR,
  IS_ADDING_UPDATE,
  SET_COMMENTS_MANAGER_STATE,
};
