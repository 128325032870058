import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import './index.less';
import { useDispatch, useSelector } from 'react-redux';
import { setClassDetailsIsEdit } from './redux/actions';
import Table from './Table';
import { PlusOutlined } from '@ant-design/icons';
import { fetchRooms } from '../rooms/redux/actions';
import { fetchAllUsers } from '../UserManager/redux/actions';

const Classes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [canUpdate, setCanUpdate] = useState(true);
  const [assessorOptions, setAssessorOptions] = useState([]);

  const { allClasses } = useSelector((state) => state.classesReducer);

  useEffect(() => {
    if (user?.permissions?.npo_setup?.add_edit) {
      setCanUpdate(true);
    }
  }, [user]);

  useEffect(() => {
    dispatch(fetchRooms());
    dispatch(
      fetchAllUsers({
        page_size: 999999999,
        page: 1,
        sort: 'first_name',
        order: 1,
        search: {
          roles: 'assessor',
        },
      })
    );
  }, []);

  useEffect(() => {
    if (allClasses?.length > 0) {
      const updatedAssessorOptions = allClasses.map((item) => ({
        label: `${item.first_name} ${item.last_name}`,
        value: item._id,
      }));
      setAssessorOptions(updatedAssessorOptions);
    }
  }, [allClasses]);

  return (
    <Table
      canUpdate={canUpdate}
      canDelete={false}
      assessorOptions={assessorOptions}
    />
  );
};

export default Classes;
