import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import { ReactComponent as SaveIcon } from '../../assets/save-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import SpinnerComponent from '../../components/spinner';
import DetailComponent from './details';
import {
  fetchValueAdded,
  setValueAddedIsEdit,
  updateValueAdded,
} from './redux/actions';
import './index.less';

const ValueAddedServices = () => {
  const [canUpdate, setCanUpdate] = useState(false);
  const [state, setState] = useState({
    fibreFixedVoice: [],
    otherValueServices: [],
    interimLteServices: [],
  });

  const dispatch = useDispatch();

  const { fetchLoading, updateLoading, valueServiceData, valueAddedIsEdit } =
    useSelector((state) => state.valueAddedReducer);
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(fetchValueAdded());
  }, []);

  useEffect(() => {
    if (user?.permissions?.form_manager?.add_edit) {
      setCanUpdate(true);
    }
  }, [user]);

  useEffect(() => {
    setState(valueServiceData);
  }, [valueServiceData]);

  const handleSave = () => {
    return dispatch(updateValueAdded(state));
  };

  return (
    <div className='page-top-div heading-parent-div'>
      <Row justify='start'>
        <Col>
          <span className='text-span'>FORM Manager</span>
        </Col>
      </Row>
      <Spin spinning={fetchLoading}>
        <div className='user-manager-parent-div'>
          <Row>
            <Col span={24} className='heading-col'>
              <Row align='middle' justify='space-between'>
                <Col>
                  <div>
                    <span className='heading-span'>VALUE ADDED SERVICES</span>
                  </div>
                </Col>
                <Col>
                  {valueAddedIsEdit ? (
                    updateLoading ? (
                      <SpinnerComponent fontSize={14} />
                    ) : (
                      <SaveIcon width={16} height={16} onClick={handleSave} />
                    )
                  ) : (
                    canUpdate && (
                      <EditIcon
                        width={16}
                        fill='#e21c21'
                        height={16}
                        onClick={() => dispatch(setValueAddedIsEdit(true))}
                      />
                    )
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              span={24}
              className={`user-details-section document-type-section value-added-services-section ${
                !valueAddedIsEdit && 'is-edit'
              }`}
            >
              <Row justify='center'>
                <DetailComponent state={state} setState={setState} />
              </Row>
            </Col>
          </Row>
          <div className='empty-div'></div>
        </div>
      </Spin>
    </div>
  );
};

export default ValueAddedServices;
