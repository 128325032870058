import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';

export function* handleFetchLeadStatuses({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/lead_statuses`, {});
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchLeadStatusesSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchLeadStatusesError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateLeadStatuses({ data }) {
  const { color, _id, show_by_default, name } = data;

  try {
    const response = yield axios.patch(`${baseURL}/lead_statuses/${_id}`, {
      show_by_default,
      name,
      color,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateLeadStatusesSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateLeadStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateLeadStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleDeleteLeadStatuses({ data }) {
  const { _id } = data;
  try {
    const response = yield axios.delete(`${baseURL}/lead_statuses/${_id}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteLeadStatusesSuccess(_id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteLeadStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteLeadStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleCreateLeadStatuses({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData._id;
    const response = yield axios.post(`${baseURL}/lead_statuses`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createLeadStatusesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createLeadStatusesError(data._id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateLeadOrder({ data }) {
  try {
    const response = yield axios.patch(`${baseURL}/sort_lead_statuses`, data);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateLeadOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateLeadOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateLeadOrderError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* watcherLeadStatusesSaga() {
  yield takeLatest(constants.FETCH_LEAD_STATUSES, handleFetchLeadStatuses);
  yield takeLatest(constants.CREATE_LEAD_STATUSES, handleCreateLeadStatuses);
  yield takeLatest(constants.UPDATE_LEAD_STATUSES, handleUpdateLeadStatuses);
  yield takeLatest(constants.UPDATE_ORDER_LEAD_STATUS, handleUpdateLeadOrder);
  yield takeLatest(constants.DELETE_LEAD_STATUSES, handleDeleteLeadStatuses);
}
