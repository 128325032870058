import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Spin } from 'antd';
import { ReactComponent as BackIcon } from '../../../assets/back-icon.svg';
import DetailsSection from './detailsSection';
import {
  fetchClassSession,
  classSessionDetailCleanupFunc,
} from '../redux/actions';
import './index.less';
// import axios from 'axios';
// import { baseURL } from '../../../globalUtils/axiosConfig';
import { fetchAllUsers } from '../../UserManager/redux/actions';
import AttemptsTable from './attemptsTable';
import AttendanceTable from './attendanceTable';
// import StudentBookingSection from './enrollmentsSection';

const ClassesSessionsDetail = () => {
  const [isClassSessionUpdate, setIsClassSessionUpdate] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [canUpdate, setCanUpdate] = useState(true);
  const [rooms, setRooms] = useState([]);
  const [assessorOptions, setAssessorOptions] = useState([]);
  // const [usersData, setUsersData] = useState([]);
  const { currentClassSessionObj, fetchDetailLoading, attempts, attendances } =
    useSelector((state) => state.classSessionsReducer);
  const { allUsers } = useSelector((state) => state.userManagerReducer);

  useEffect(() => {
    if (currentClassSessionObj?._id) {
      setIsClassSessionUpdate(true);
    }
  }, [currentClassSessionObj]);

  useEffect(() => {
    if (params?.id) {
      // setIsClassSessionUpdate(true);
      dispatch(fetchClassSession(params?.id));
    }

    return () => {
      dispatch(classSessionDetailCleanupFunc());
      // dispatch(fetchClassSessionRegions());
    };
  }, [params.id]);

  // useEffect(() => {
  //   if (user?.permissions?.classSession_setup?.add_edit) {
  //     setCanUpdate(true);
  //   }
  // }, [user]);

  // const fetchUsers = async () => {
  //   const response = await axios.get(
  //     `${baseURL}/api/v2/users/slot-booking-students`
  //   );
  //   const users = response.data?.data?.users || [];

  //   // const updatedRooms = users.map((item) => ({
  //   //   label: item.code,
  //   //   value: item._id,
  //   // }));

  //   setUsersData(users);
  // };

  useEffect(() => {
    // fetchUsers();
    dispatch(
      fetchAllUsers({
        page: 1,
        page_size: 999999999999,
        search: {
          // roles: 'assessor',
        },
        sort: 'first_name',
        order: 1,
      })
    );
  }, []);

  useEffect(() => {
    if (allUsers?.length > 0) {
      const updatedAssessorOptions = allUsers
        // .filter((el) =>
        //   el?.roles?.some((role) => ['assessor', 'teacher'].includes(role))
        // )
        .map((item) => ({
          label: `${item.first_name} ${item.last_name}`,
          value: item._id,
        }));
      setAssessorOptions(updatedAssessorOptions);
    }
  }, [allUsers]);

  let updatedAttempts = attempts.map((item) => {
    return {
      ...item,
      status: item?.curriculum_student?.status,
    };
  });

  const presentCount = attendances.filter(
    ({ attendance }) => attendance === 'present'
  ).length;

  const lateCount = attendances.filter(
    ({ attendance }) => attendance === 'late'
  ).length;

  const absentCount = attendances.filter(
    ({ attendance }) => attendance === 'absent'
  ).length;

  return (
    <div className='page-top-div heading-parent-div'>
      <Row justify='space-between'>
        <Col>
          <span className='text-span'>
            {/* {isClassSessionUpdate ? 'Update Class Session' : 'Add Class Session'} */}
            Class Session
          </span>
        </Col>
        <Col>
          <Button
            onClick={() => navigate('/classes-sessions')}
            icon={<BackIcon width={24} height={14} />}
            className='back-btn'
            size='middle'
          >
            BACK
          </Button>
        </Col>
      </Row>
      <Spin spinning={fetchDetailLoading}>
        <div className='user-manager-parent-div'>
          <DetailsSection
            isClassSessionUpdate={isClassSessionUpdate}
            canUpdate={canUpdate}
            rooms={rooms}
            assessorOptions={assessorOptions}
          />
          <AttemptsTable attempts={attempts} />
          <AttendanceTable
            attendances={attendances}
            presentCount={presentCount}
            lateCount={lateCount}
            absentCount={absentCount}
          />
        </div>
      </Spin>
    </div>
  );
};

export default ClassesSessionsDetail;
