import React, { useEffect, useState } from 'react';
import { message as AntdAlert } from 'antd';
import axios from 'axios';
import { baseURL } from '../../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../../components/unauthorizeHandler';

const initialData = {
  allNotes: [],
  fetchingLoading: false,
  updateLoading: false,
  isAddImsRevision: false,
  isUpdateImsRevision: false,
  deletingLoading: false,
};

const UseNotes = (id) => {
  const [notesData, setNotesData] = useState(initialData);
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchNotes(id);
  }, [id]);

  const fetchNotes = async (id) => {
    setNotesData({ ...notesData, fetchingLoading: true });

    try {
      const response = await axios.get(`${baseURL}/leads/${id}/notes`);
      const { success } = response.data;

      setNotesData({ ...notesData, fetchingLoading: false });

      if (success) {
        const { notes } = response.data;

        setData(notes);

        setNotesData({
          ...notesData,
          allNotes: notes,
        });
        // setTimeout(() => {
        //   setUpdateData((prev) => !prev);
        // }, 500);
      }
    } catch (error) {
      console.log(error);
      setNotesData({ ...notesData, fetchingLoading: false });
    }
  };

  const handleCreate = async (item) => {
    setNotesData({ ...notesData, updateLoading: true });
    // const fd = new FormData();
    // const { debouncedCallApi, ...rest } = item;
    const { leadId, ...rest } = item;
    try {
      const response = await axios.post(
        `${baseURL}/leads/${leadId}/notes`,
        rest
      );

      const { success, message } = response.data;
      if (success) {
        let updatedData = [response.data.note, ...notesData.allNotes];
        AntdAlert.success(message);
        setEditingKey('');
        setData(updatedData);
        let updated = {
          ...notesData,
          updateLoading: false,
          allNotes: updatedData,
          isAddImsRevision: false,
          isUpdateImsRevision: false,
        };
        setNotesData(updated);
        // setTimeout(() => {
        //   setUpdateData((prev) => !prev);
        // }, 500);
        // debouncedCallApi();
      } else if (!success) {
        AntdAlert.error(response.data?.errors[0]);
        setNotesData({
          ...notesData,
          updateLoading: false,
          isAddImsRevision: false,
          isUpdateImsRevision: false,
        });
        errorHandler(response.data);
      }
    } catch (error) {
      setNotesData({
        ...notesData,
        updateLoading: false,
        isAddImsRevision: false,
        isUpdateImsRevision: false,
      });
      AntdAlert.error('Something went wrong!');
    }
  };

  const handleUpdate = async (noteData) => {
    setNotesData({ ...notesData, updateLoading: true });
    const { noteId, ...rest } = noteData; // handleUpdateSuccess,

    try {
      const response = await axios.put(
        `${baseURL}/leads/notes/${noteId}`,
        rest
      );
      const { success, message } = response.data;

      if (success) {
        AntdAlert.success(message);

        const updatedNotes = data.map((el) =>
          el._id === noteId ? response.data.note : el
        );

        setData(updatedNotes);
        let updated = {
          ...notesData,
          updateLoading: false,
          allNotes: updatedNotes,
          isAddImsRevision: false,
          isUpdateImsRevision: false,
        };
        setNotesData(updated);
        setEditingKey('');
        // handleUpdateSuccess();
      } else if (!success) {
        AntdAlert.error(response.data?.errors[0]);
        setNotesData({
          ...notesData,
          updateLoading: false,
          isAddImsRevision: false,
          isUpdateImsRevision: false,
        });
        errorHandler(response.data);
      }
    } catch (error) {
      console.log('error', error);
      setNotesData({
        ...notesData,
        updateLoading: false,
        isAddImsRevision: false,
        isUpdateImsRevision: false,
      });
      AntdAlert.error('Something went wrong!');
    }
  };

  const deleteNote = async (id) => {
    setNotesData({ ...notesData, deletingLoading: true });

    try {
      const response = await axios.delete(`${baseURL}/leads/notes/${id}`);
      const { success, message } = response.data;

      if (success) {
        // console.log('response.data', response.data);
        const filtered = data.filter((el) => el._id !== id);
        setData(filtered);

        AntdAlert.success(message);
        let state = {
          ...notesData,
          allNotes: filtered,
          deletingLoading: false,
        };
        setNotesData(state);
        // setTimeout(() => {
        //   setUpdateData((prev) => !prev);
        // }, 500);
        // onDeleteSuccess();
      } else {
        AntdAlert.error('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
      AntdAlert.error('Something went wrong!');
    }
  };

  const handleIsIsUpdate = (isAdd, isUpdate) => {
    setNotesData({
      ...notesData,
      isAddImsRevision: isAdd,
      isUpdateImsRevision: isUpdate,
    });
  };

  return {
    notesData,
    data,
    setData,
    editingKey,
    setEditingKey,
    setNotesData,
    handleCreate,
    fetchNotes,
    deleteNote,
    handleIsIsUpdate,
    handleUpdate,
  };
};

export default UseNotes;
