import React, { useState, useEffect } from 'react';
import { Input, Col, Row, Spin, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createFormSetup, updateFormSetups } from '../redux/actions';
import Plans from './plans';

const { TextArea } = Input;

const AddFieldsComponent = ({ isUpdate, canUpdate }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createLoading, currentTemplateObj, updateLoading } = useSelector(
    (state) => state.formSetupReducer
  );
  const [state, setState] = useState(currentTemplateObj);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (currentTemplateObj?._id) {
      let { plans, contract_cover, contract_name, description } =
        currentTemplateObj;
      setState({ contract_cover, contract_name, description });
      setPlans(plans);
    }
  }, [currentTemplateObj]);

  const onChange = (e, name) => {
    const { value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSave = () => {
    const { contract_name, contract_cover, description } = state;
    if (contract_name.length < 1) {
      return message.error('Contract name is required!');
    }
    if (contract_cover.length < 1) {
      return message.error('Contract cover is required!');
    }
    if (description.length < 1) {
      return message.error('Description is required!');
    }

    if (plans.length < 1) {
      return message.error('At least one plan is required!');
    }

    let isPlansValid = plans.filter((plan) => {
      const { planName, speed, dataLimit, priceNow, priceWas, hasDiscount } =
        plan;
      if (
        planName.length < 1 ||
        speed.length < 1 ||
        dataLimit.length < 1 ||
        priceNow === null ||
        (hasDiscount && priceWas === null) ||
        (hasDiscount && priceWas >= priceNow)
      ) {
        return true;
      } else return false;
    });

    if (isPlansValid.length > 0) {
      return message.error('Plans fields are missing or incorrect!');
    }

    if (isUpdate) {
      return dispatch(
        updateFormSetups({
          ...{ ...state, plans },
          _id: currentTemplateObj._id,
          navigate,
        })
      );
    } else {
      dispatch(
        createFormSetup({
          ...{ ...state, plans },
          navigate,
        })
      );
    }
  };

  return (
    <>
      <Col md={24} lg={23} xl={23} xxl={22}>
        <Col>
          <Spin spinning={loading}>
            <Row gutter={[20, 30]} className='doc-template-parent-div'>
              <Col span={24} className='parent-col' key={900}>
                <div className='label-div'>
                  <label>Form Name</label>
                  <hr />
                </div>
                <Row justify='center' gutter={[0, 15]}>
                  <Col md={24} lg={23} xl={23} xxl={22}>
                    <label>CONTRACT NAME</label>
                    <Input
                      value={state.contract_name}
                      placeholder='Insert Contract Name'
                      onChange={(e) => onChange(e, 'contract_name')}
                      readOnly={!canUpdate}
                    />
                  </Col>
                  <Col md={24} lg={23} xl={23} xxl={22}>
                    <label>CONTRACT COVER</label>
                    <Input
                      value={state.contract_cover}
                      placeholder='Insert Contract Cover'
                      onChange={(e) => onChange(e, 'contract_cover')}
                      readOnly={!canUpdate}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24} className='parent-col' key={900}>
                <div className='label-div'>
                  <label>CONNECTIVITY PLANS</label>
                  <hr />
                </div>
                <Row justify='center' gutter={[0, 20]}>
                  <Col md={24} lg={23} xl={23} xxl={22}>
                    <label>DESCRIPTION</label>
                    <TextArea
                      value={state.description}
                      onChange={(e) => onChange(e, 'description')}
                      placeholder='Insert Connectivity Plan Description'
                      autoSize={true}
                      readOnly={!canUpdate}
                    />
                  </Col>
                  <Plans
                    plans={plans}
                    setPlans={setPlans}
                    canUpdate={canUpdate}
                  />
                </Row>
              </Col>
            </Row>
          </Spin>
        </Col>
      </Col>
      {canUpdate && <Col span={24} className='heading-col'></Col>}
      <Col md={24} lg={24} xl={24} xxl={24}>
        {canUpdate && (
          <Row gutter={[20, 10]} justify='center'>
            <Row align='middle' justify='center'>
              <Col>
                <Button
                  type='primary'
                  className='save-btn'
                  size='middle'
                  onClick={handleSave}
                  loading={updateLoading || createLoading}
                >
                  {isUpdate ? 'Update' : 'Save'} FORM
                </Button>
              </Col>
            </Row>
          </Row>
        )}
      </Col>
    </>
  );
};

export default AddFieldsComponent;
