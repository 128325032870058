import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';

export function* handleFetchStreams({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v2/admin/streams`, {});
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchStreamsSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchStreamsError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateStreams({ data }) {
  try {
    const response = yield axios.put(`${baseURL}/api/v2/admin/streams`, data);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateStreamsSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateStreamsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateStreamsError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleDeleteStreams({ data }) {
  const { _id } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/admin/streams/${_id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteStreamsSuccess(_id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteStreamsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteStreamsError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleCreateStreams({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData._id;
    const response = yield axios.post(`${baseURL}/api/v2/admin/streams`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createStreamsSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createStreamsError(data._id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateContactOrder({ data }) {
  try {
    const response = yield axios.patch(
      `${baseURL}/sort_api/v2/admin/streams`,
      data
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateStreamsOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateStreamsOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateStreamsOrderError());
    AntdAlert.error('Something went wrong!');
  }
}

export function* watcherStreamsSaga() {
  yield takeLatest(constants.FETCH_STREAMS, handleFetchStreams);
  yield takeLatest(constants.CREATE_STREAMS, handleCreateStreams);
  yield takeLatest(constants.UPDATE_STREAMS, handleUpdateStreams);
  yield takeLatest(constants.UPDATE_ORDER_STREAMS, handleUpdateContactOrder);
  yield takeLatest(constants.DELETE_STREAMS, handleDeleteStreams);
}
