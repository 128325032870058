import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';

export function* handleFetchQuoteStatuses({ data }) {
  // const { search, ...rest } = data;
  // Object.keys(search).forEach((key) => {
  //   if (search[key]?.length < 1 || search[key] === null) delete search[key];
  // });

  try {
    const response = yield axios.get(`${baseURL}/quote_statuses`, {
      // params: { ...rest, ...(Object.keys(search).length > 0 && { ...search }) },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchQuoteStatusesSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchQuoteStatusesError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateQuoteStatuses({ data }) {
  const { sub_observations, ...rest } = data;

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v2/admin/learning-activities/observations`,
      {
        ...rest,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateQuoteStatusesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateQuoteStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateQuoteStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleUpdateQuoteCompletedStatuses({ data }) {
  const { _id, completed_status } = data;

  try {
    const response = yield axios.patch(`${baseURL}/quote_statuses/${_id}`, {
      completed_status,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateQuoteStatusesSuccess(data));
      data?.handleSuccess();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateQuoteStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateQuoteStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleDeleteQuoteStatuses({ data }) {
  const { _id } = data;
  try {
    const response = yield axios.delete(`${baseURL}/quote_statuses/${_id}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteQuoteStatusesSuccess(_id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteQuoteStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteQuoteStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleCreateQuoteStatuses({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData._id;
    const response = yield axios.post(
      `${baseURL}/api/v2/admin/learning-activities/observations`,
      {
        ...newData,
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createQuoteStatusesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createQuoteStatusesError(data._id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
    AntdAlert.error('Something went wrong!');
    yield put(actions.createQuoteStatusesError());
  }
}

export function* handleUpdateQuoteOrder({ data }) {
  try {
    const response = yield axios.patch(
      `${baseURL}/api/v2/admin/learning-activities/observations/sort`,
      data
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateQuoteStatusesOrderSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateQuoteStatusesOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateQuoteStatusesOrderError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleFetchSubObservations({ data }) {
  try {
    const response = yield axios.post(
      `${baseURL}/api/v2/admin/learning-activities/get-sub-observations`,
      data
    );
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchSubObservationsSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchSubObservationsError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleCreateSubObservation({ data }) {
  try {
    const fd = new FormData();
    delete data.id;
    for (const [key, value] of Object.entries(data)) {
      if (key === 'icon' && value !== null && value !== undefined) {
        fd.append(`icon`, value);
      } else {
        fd.append(key, value);
      }
      // else if (value !== null && value !== undefined) {
      //   fd.append(key, value);
      // }
    }

    const response = yield axios.post(
      `${baseURL}/api/v2/admin/learning-activities/sub-observations`,
      fd,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createSubObservationSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createSubObservationError(data._id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
    AntdAlert.error('Something went wrong!');
    yield put(actions.createSubObservationError());
  }
}

export function* handleUpdateSubObservation({ data }) {
  const fd = new FormData();
  delete data.id;
  for (const [key, value] of Object.entries(data)) {
    if (key === 'icon' && value !== null && value !== undefined) {
      fd.append(`icon`, value);
    } else {
      fd.append(key, value);
    }
    // else if (value !== null && value !== undefined) {
    //   fd.append(key, value);
    // }
  }

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v2/admin/learning-activities/sub-observations`,
      fd,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateSubObservationSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateSubObservationError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateSubObservationError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleUpdateSubObservationsOrder({ data }) {
  try {
    const response = yield axios.patch(
      `${baseURL}/api/v2/admin/learning-activities/sub-observations/sort`,
      data
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateSubObservationOrderSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateSubObservationOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateSubObservationOrderError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* watcherQuoteStatusesSaga() {
  yield takeLatest(constants.FETCH_QUOTE_STATUSES, handleFetchQuoteStatuses);
  yield takeLatest(constants.UPDATE_QUOTE_STATUSES, handleUpdateQuoteStatuses);

  yield takeLatest(
    constants.UPDATE_QUOTE_COMPLETED_STATUS,
    handleUpdateQuoteCompletedStatuses
  );

  yield takeLatest(constants.DELETE_QUOTE_STATUSES, handleDeleteQuoteStatuses);
  yield takeLatest(constants.CREATE_QUOTE_STATUSES, handleCreateQuoteStatuses);

  yield takeLatest(constants.UPDATE_QUOTE_STATUS_ORDER, handleUpdateQuoteOrder);
  yield takeLatest(constants.FETCH_SUB_OBSERVATION, handleFetchSubObservations);
  yield takeLatest(
    constants.CREATE_SUB_OBSERVATION,
    handleCreateSubObservation
  );
  yield takeLatest(
    constants.UPDATE_SUB_OBSERVATION,
    handleUpdateSubObservation
  );
  yield takeLatest(
    constants.UPDATE_SUB_OBSERVATION_ORDER,
    handleUpdateSubObservationsOrder
  );
}
