import * as constants from './constants';

const initialState = {
  user: null,
  loading: false,
  logoutLoading: false,
  projects: [],
  projectsLoading: false,
  isCollapsed: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN:
      return {
        ...state,
        loading: true,
      };
    case constants.LOGIN_SUCCESS:
      const { user } = action.data;
      return {
        ...state,
        loading: false,
        user: {
          ...user,
          user_type: user?.user_type ? user?.user_type : 'client',
        },
      };
    case constants.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
      };
    case constants.LOGOUT:
      return {
        ...state,
        logoutLoading: true,
      };
    case constants.LOGOUT_SUCCESS:
      return {
        ...state,
        logoutLoading: false,
        user: null,
      };
    case constants.LOGOUT_ERROR:
      return {
        ...state,
        logoutLoading: false,
      };
    case constants.SET_USER:
      let { tenant = '' } = JSON.parse(localStorage.getItem('user'));
      return {
        ...state,
        user: { ...action.data, tenant },
      };
    case constants.FETCH_SIDEBAR_PROJECTS:
      return {
        ...state,
        projectsLoading: true,
      };
    case constants.FETCH_SIDEBAR_PROJECTS_SUCCESS:
      let projectsWithDocControlViewPermission = action.data.filter(
        (el) => el?.permissions?.document_control?.view
      );
      // console.log(
      //   "projectsWithDocControlViewPermission",
      //   projectsWithDocControlViewPermission
      // );
      return {
        ...state,
        projectsLoading: false,
        projects: action.data,
        // projects: projectsWithDocControlViewPermission,
      };
    case constants.FETCH_SIDEBAR_PROJECTS_ERROR:
      return {
        ...state,
        projectsLoading: false,
      };
    case constants.NAVBAR_SET_COLLAPSED:
      return {
        ...state,
        isCollapsed: action.data,
      };

    default:
      return state;
  }
};

export default authReducer;
