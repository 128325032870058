import React from 'react';
import { Col, Row } from 'antd';
import OptionsComponent from './optionsComponent';
import OtherValueAddedService from './otherValueAddedService';

const AddFieldsComponent = ({ isUpdate, state, setState }) => {
  const { fibreFixedVoice, otherValueServices, interimLteServices } = state;

  return (
    <>
      <Col md={23} lg={24} xl={23} xxl={22}>
        <Row gutter={[20, 30]} className='doc-template-parent-div'>
          <Col span={24} className='parent-col' key={900}>
            <div className='label-div'>
              <label>VODACOM FIBRE FIXED VOICE</label>
              <hr />
            </div>
            <Row justify='center' gutter={[0, 15]}>
              <OptionsComponent
                data={fibreFixedVoice}
                setData={setState}
                keyName='fibreFixedVoice'
              />
            </Row>
          </Col>
          <Col span={24} className='parent-col' key={901}>
            <div className='label-div'>
              <label>OTHER VALUE ADDED SERVICES</label>
              <hr />
            </div>
            <OtherValueAddedService
              data={otherValueServices}
              setData={setState}
              isUpdate={isUpdate}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24} className='heading-col'>
        <Row align='middle' justify='space-between'>
          <Col>
            <div>
              <span className='heading-span'>
                VODACOM FIBRE INTERIM LTE ACCESS PRODUCT SELECTION (all pricing
                incl. VAT)
              </span>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 30]} justify='center'>
          <Col md={24} lg={23} xl={23} xxl={22}>
            <Row gutter={[20, 30]} className='doc-template-parent-div'>
              <Col span={24} className='parent-col' key={903}>
                <div className='label-div'>
                  <label>INTERIM LTE ACCESS</label>
                  <hr />
                </div>
                <Row justify='center' gutter={[0, 15]}>
                  <OptionsComponent
                    data={interimLteServices}
                    setData={setState}
                    keyName='interimLteServices'
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default AddFieldsComponent;
