const FETCH_LEAD_STATUSES = 'FETCH_LEAD_STATUSES';
const FETCH_LEAD_STATUSES_SUCCESS = 'FETCH_LEAD_STATUSES_SUCCESS';
const FETCH_LEAD_STATUSES_ERROR = 'FETCH_LEAD_STATUSES_ERROR';
const UPDATE_LEAD_STATUSES = 'UPDATE_LEAD_STATUSES';
const UPDATE_LEAD_STATUSES_SUCCESS = 'UPDATE_LEAD_STATUSES_SUCCESS';
const UPDATE_LEAD_STATUSES_ERROR = 'UPDATE_LEAD_STATUSES_ERROR';
const DELETE_LEAD_STATUSES = 'DELETE_LEAD_STATUSES';
const DELETE_LEAD_STATUSES_SUCCESS = 'DELETE_LEAD_STATUSES_SUCCESS';
const DELETE_LEAD_STATUSES_ERROR = 'DELETE_LEAD_STATUSES_ERROR';
const CREATE_LEAD_STATUSES = 'CREATE_LEAD_STATUSES';
const CREATE_LEAD_STATUSES_SUCCESS = 'CREATE_LEAD_STATUSES_SUCCESS';
const CREATE_LEAD_STATUSES_ERROR = 'CREATE_LEAD_STATUSES_ERROR';
const UPDATE_ORDER_LEAD_STATUS = 'UPDATE_ORDER_LEAD_STATUS';
const UPDATE_ORDER_LEAD_STATUS_SUCCESS = 'UPDATE_ORDER_LEAD_STATUS_SUCCESS';
const UPDATE_ORDER_LEAD_STATUS_ERROR = 'UPDATE_ORDER_LEAD_STATUS_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';

export {
  FETCH_LEAD_STATUSES,
  FETCH_LEAD_STATUSES_SUCCESS,
  FETCH_LEAD_STATUSES_ERROR,
  UPDATE_LEAD_STATUSES,
  UPDATE_LEAD_STATUSES_SUCCESS,
  UPDATE_LEAD_STATUSES_ERROR,
  DELETE_LEAD_STATUSES,
  DELETE_LEAD_STATUSES_SUCCESS,
  DELETE_LEAD_STATUSES_ERROR,
  CREATE_LEAD_STATUSES,
  CREATE_LEAD_STATUSES_SUCCESS,
  CREATE_LEAD_STATUSES_ERROR,
  UPDATE_ORDER_LEAD_STATUS,
  UPDATE_ORDER_LEAD_STATUS_SUCCESS,
  UPDATE_ORDER_LEAD_STATUS_ERROR,
  IS_ADDING_UPDATE,
};
