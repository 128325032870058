const FETCH_CONTACT_TYPES = 'FETCH_CONTACT_TYPES';
const FETCH_CONTACT_TYPES_SUCCESS = 'FETCH_CONTACT_TYPES_SUCCESS';
const FETCH_CONTACT_TYPES_ERROR = 'FETCH_CONTACT_TYPES_ERROR';
const UPDATE_CONTACT_TYPES = 'UPDATE_CONTACT_TYPES';
const UPDATE_CONTACT_TYPES_SUCCESS = 'UPDATE_CONTACT_TYPES_SUCCESS';
const UPDATE_CONTACT_TYPES_ERROR = 'UPDATE_CONTACT_TYPES_ERROR';
const DELETE_CONTACT_TYPES = 'DELETE_CONTACT_TYPES';
const DELETE_CONTACT_TYPES_SUCCESS = 'DELETE_CONTACT_TYPES_SUCCESS';
const DELETE_CONTACT_TYPES_ERROR = 'DELETE_CONTACT_TYPES_ERROR';
const CREATE_CONTACT_TYPES = 'CREATE_CONTACT_TYPES';
const CREATE_CONTACT_TYPES_SUCCESS = 'CREATE_CONTACT_TYPES_SUCCESS';
const CREATE_CONTACT_TYPES_ERROR = 'CREATE_CONTACT_TYPES_ERROR';
const UPDATE_ORDER_CONTACT_TYPES = 'UPDATE_ORDER_CONTACT_TYPES';
const UPDATE_ORDER_CONTACT_TYPES_SUCCESS = 'UPDATE_ORDER_CONTACT_TYPES_SUCCESS';
const UPDATE_ORDER_CONTACT_TYPES_ERROR = 'UPDATE_ORDER_CONTACT_TYPES_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';

export {
  FETCH_CONTACT_TYPES,
  FETCH_CONTACT_TYPES_SUCCESS,
  FETCH_CONTACT_TYPES_ERROR,
  UPDATE_CONTACT_TYPES,
  UPDATE_CONTACT_TYPES_SUCCESS,
  UPDATE_CONTACT_TYPES_ERROR,
  DELETE_CONTACT_TYPES,
  DELETE_CONTACT_TYPES_SUCCESS,
  DELETE_CONTACT_TYPES_ERROR,
  CREATE_CONTACT_TYPES,
  CREATE_CONTACT_TYPES_SUCCESS,
  CREATE_CONTACT_TYPES_ERROR,
  UPDATE_ORDER_CONTACT_TYPES,
  UPDATE_ORDER_CONTACT_TYPES_SUCCESS,
  UPDATE_ORDER_CONTACT_TYPES_ERROR,
  IS_ADDING_UPDATE,
};
