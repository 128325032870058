import React from 'react';
import { Row } from 'antd';
import UsersRegisterTable from './registerTable';
// import { setUserDetailsIsEdit } from './redux/actions';
import './index.less';
import { useLocation } from 'react-router-dom';
// import UseUserManager from './hooks/useUserManager';

const UserManager = () => {
  const { pathname } = useLocation();
  let isTeacher = pathname.includes('teachers');
  let userType = isTeacher ? 'Teachers' : 'Students';
  // const [allYears, setYearsOptions] = useState([]);

  // const getYears = async () => {
  //   const response = await axios.get(`${baseURL}/api/v2/admin/years`);

  //   const allYears = response.data?.data?.years_levels || [];
  //   setYearsOptions(allYears);
  // };

  // useEffect(() => {
  //   getYears();
  // }, []);

  // const yearsLevelOption = useMemo(
  //   () =>
  //     allYears.map((el) => {
  //       const { label, level } = el;
  //       return { value: level, label };
  //     }),
  //   [allYears]
  // );

  return (
    <div className='page-top-div'>
      <span className='text-span'>Users Manager</span>
      <Row className='production-manager-parent-row users-manager-parent-row'>
        <UsersRegisterTable
          yearsLevelOption={[]}
          userType={userType}
          isTeacher={isTeacher}
        />
      </Row>
    </div>
  );
};

export default UserManager;
