import * as constants from './constants';

export const fetchLeadStatuses = (data) => {
  return {
    type: constants.FETCH_LEAD_STATUSES,
    data,
  };
};

export const fetchLeadStatusesSuccess = (data) => {
  return {
    type: constants.FETCH_LEAD_STATUSES_SUCCESS,
    data: data,
  };
};

export const fetchLeadStatusesError = (data) => {
  return {
    type: constants.FETCH_LEAD_STATUSES_ERROR,
    data: data,
  };
};

export const updateLeadStatuses = (data) => {
  return {
    type: constants.UPDATE_LEAD_STATUSES,
    data: data,
  };
};

export const updateLeadStatusesSuccess = (data) => {
  return {
    type: constants.UPDATE_LEAD_STATUSES_SUCCESS,
    data: data,
  };
};

export const updateLeadStatusesError = () => {
  return {
    type: constants.UPDATE_LEAD_STATUSES_ERROR,
  };
};

export const updateLeadOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_LEAD_STATUS,
    data: data,
  };
};

export const updateLeadOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_LEAD_STATUS_SUCCESS,
    data: data,
  };
};

export const updateLeadOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_LEAD_STATUS_ERROR,
  };
};

export const deleteLeadStatuses = (data) => {
  return {
    type: constants.DELETE_LEAD_STATUSES,
    data: data,
  };
};

export const deleteLeadStatusesSuccess = (data) => {
  return {
    type: constants.DELETE_LEAD_STATUSES_SUCCESS,
    data: data,
  };
};

export const deleteLeadStatusesError = () => {
  return {
    type: constants.DELETE_LEAD_STATUSES_ERROR,
  };
};

export const createLeadStatuses = (data, id) => {
  return {
    type: constants.CREATE_LEAD_STATUSES,
    data: data,
  };
};

export const createLeadStatusesSuccess = (data) => {
  return {
    type: constants.CREATE_LEAD_STATUSES_SUCCESS,
    data: data,
  };
};

export const createLeadStatusesError = (id) => {
  return {
    type: constants.CREATE_LEAD_STATUSES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
