import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { baseURL } from '../../globalUtils/axiosConfig';

const UseAttachments = () => {
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState({
    applications: [],
    idPassports: [],
    bankStatements: [],
    others: [],
  });

  const navigate = useNavigate();
  const { leadId } = useParams();

  console.log('leadId', leadId);

  const fetchLead = async () => {
    const res = await axios.get(`${baseURL}/leads_attachments/${leadId}`);
    console.log('res', res);
    const { success } = res.data;
    if (success) {
      const { attachments } = res.data;
      let dataArray = []
      for (let index = 0; index < attachments.length; index++) {
        const {_id,attachType, name, leadId} = attachments[index];
        let obj = {
            _id,
            key: _id,
            isLocal: false,
            
        }
        dataArray.push({
        //   ...element,
          isLocal: false,
        //   url: `${baseURL}${element.url}`
        //   attachType: 
        })
      }
    }
  };

  //   useEffect( fetchLead, [leadId]);
  useEffect(() => {
    if (leadId) {
      fetchLead();
    }
  }, [leadId]);

  if (!leadId) {
    return navigate('/');
  }

  const handleSave = async () => {
    const { applications, idPassports, bankStatements, others } = data;
    let allImages = [
      ...applications,
      ...idPassports,
      ...bankStatements,
      ...others,
    ];
    let filteredImages = allImages.filter((image) => image.isLocal);
    console.log('filteredImages', filteredImages);
    let fd = new FormData();

    filteredImages.forEach((image) => {
      fd.append(`files`, image);
    });

    fd.append('attachmentsInfo', JSON.stringify(filteredImages));

    const res = await axios.post(`${baseURL}/leads/attachments/${leadId}`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const { success, message } = res.data;
    console.log('res.data', res.data);
    if (success) {
      AntdAlert.success(message);
      // let filteredImages = images.filter((image) => image.key !== key);
      // setImages(filteredImages);
      // dispatch(
      //   deleteLeadDocAttachSuccess({
      //     recordId: currentUpdateObj.id,
      //     key,
      //   })
      // );
    } else {
      AntdAlert.error('Something went wrong!');
    }
  };

  const handleDeleteImage = async ({ key, isLocal }) => {
    if (isLocal) {
      //   let filteredImages = images.filter((image) => image.key !== key);
      //   setImages(filteredImages);
    } else {
      const res = await axios.delete(
        `${baseURL}/api/v2/sites/delete_attachment?id=${key}`
      );

      const { success, message } = res.data;
      if (success) {
        AntdAlert.success(message);
        // let filteredImages = images.filter((image) => image.key !== key);
        // setImages(filteredImages);
        // dispatch(
        //   deleteLeadDocAttachSuccess({
        //     recordId: currentUpdateObj.id,
        //     key,
        //   })
        // );
      } else {
        AntdAlert.error('Something went wrong!');
      }
    }
  };
  const handleImage = (imgObj, e) => {
    e.currentTarget.value = null;

    const isValid =
      /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|jpg|jpeg|gif|png|mp4|)$/i.test(
        imgObj.name
      );

    if (isValid) {
      imgObj['key'] = Math.round(Math.random() * 1000000);
      imgObj['isLocal'] = true;
      //   return imgObj ? setImages([...images, imgObj]) : null;
    } else {
      AntdAlert.error('This file format is not supported.');
    }
  };

  const handleShowImage = (image) => {
    let url = image.isLocal ? URL.createObjectURL(image) : image.url;
    window.open(url, '_blank');
  };

  return {
    data,
    setData,
    handleSave,
    handleDeleteImage,
    handleImage,
    handleShowImage,
  };
};

export default UseAttachments;
