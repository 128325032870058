import React, { useEffect, useState } from 'react';
import { message as Alert } from 'antd';
import axios from 'axios';
import { baseURL } from '../../../../globalUtils/axiosConfig';

let url = `${baseURL}/api/v2/admin/curriculums`;

const useFirstStep = () => {
  const [isEdit, setIsEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [getContentLoading, setGetContentLoading] = useState(false);
  const [data, setData] = useState({
    activities: [],
    difficultyLevels: [],
    curriculumStatus: ['Active', 'Inactive'],
    academicYears: [],
    contents: [],
  });

  const getLearningActivities = async () => {
    try {
      const res = await axios.get(`${url}/learningActivities`);
      const { learning_activities } = res.data.data;
      setData((prev) => ({ ...prev, activities: learning_activities }));
    } catch (error) {
      console.log('error', error);
    }
  };
  const getDifficultyLevels = async () => {
    try {
      const res = await axios.get(`${url}/difficultyLevels`);
      const { difficulty_levels } = res.data.data;
      setData((prev) => ({ ...prev, difficultyLevels: difficulty_levels }));
    } catch (error) {
      console.log('error', error);
    }
  };
  const getAcademicYears = async () => {
    try {
      const res = await axios.get(`${url}/academicYears`);
      const { academic_years } = res.data.data;
      setData((prev) => ({ ...prev, academicYears: academic_years }));
    } catch (error) {
      console.log('error', error);
    }
  };

  const getContent = async () => {
    setGetContentLoading(true);
    try {
      const res = await axios.post(`${url}/contentsToAdd`, {
        curriculum_duration_id: '651e687e5dd19e04d9d9c59a',
      });
      const { contents } = res.data.data;
      const updatedContent = contents.map((el) => {
        const juzNumber = el._id.juz_number;
        let sortedChapter = el.chapters.sort((a, b) => a.chapter - b.chapter);
        return {
          title: `Juz ${juzNumber}`,
          key: `Juz_${juzNumber}`,
          children: sortedChapter.map((chap) => {
            const { chapter, chapter_name, pages } = chap;
            let contents = pages.map((el) => el.contents[0]);
            let sortedPages = contents.sort(
              (a, b) => a.page_number - b.page_number
            );
            let key = `Juz_${juzNumber}_chapter_${chapter}`;
            return {
              title: `chapter ${chapter} - ${chapter_name}`,
              key,
              children: sortedPages.map((pg) => {
                const { _id, page_number } = pg;
                return {
                  title: `Page - ${page_number}`,
                  key: `${key}_Page_${page_number}_${_id}`,
                };
              }),
            };
          }),
        };
      });
      setData((prev) => ({ ...prev, contents: updatedContent }));
      setGetContentLoading(false);
    } catch (error) {
      setGetContentLoading(false);

      console.log('error', error);
    }
  };

  const handleCreate = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(`${url}/create`, data);
      const { status } = res;
      if (status === 200) {
        setLoading(false);
        // const { academic_years } = res.data.data;
        // setData((prev) => ({ ...prev, academicYears: academic_years }));
        Alert.success(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      const { message } = error.response.data;
      Alert.error(message);
      console.log('error', message);
    }
  };

  useEffect(() => {
    // getLearningActivities();
    // getDifficultyLevels();
    // getAcademicYears();
    getContent();
  }, []);

  return {
    contents: data.contents,
    getContentLoading,
    isEdit,
    setIsEdit,
    loading,
    handleCreate,
  };
};

export default useFirstStep;
