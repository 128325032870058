import * as constants from './constants';

const initialState = {
  allForms: [],
  createLoading: false,
  currentTemplateObj: {
    contract_name: '',
    contract_cover: '',
    description: '',
    plans: [],
  },
  templateCreatedSuccess: false,
  templateEditSuccess: false,
  // fetchingLoading: false,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
  fetchLoading: false,
  documentTemplateIsEdit: false,
  deleteLoading: false,
};

const formSetupReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FORM_SETUP_IS_EDIT:
      return {
        ...state,
        documentTemplateIsEdit: action.data,
      };
    case constants.CREATE_FORM_SETUP:
      return {
        ...state,
        templateEditSuccess: false,
        createLoading: true,
        templateCreatedSuccess: false,
        DocumentTemplateIsEdit: false,
      };
    case constants.CREATE_FORM_SETUP_SUCCESS:
      return {
        ...state,
        createLoading: false,
        DocumentTemplateIsEdit: false,
        templateCreatedSuccess: true,
        templateEditSuccess: false,
        currentTemplateObj: action.data,
      };
    case constants.CREATE_FORM_SETUP_ERROR:
      return {
        ...state,
        createLoading: false,
        templateCreatedSuccess: false,
      };
    case constants.FETCH_ALL_FORM_SETUPS:
      return {
        ...state,
        fetchLoading: true,
        templateCreatedSuccess: false,
        templateEditSuccess: false,
      };
    case constants.FETCH_ALL_FORM_SETUPS_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        isAdding: false,
        allForms: action.data,
        // totalRecords: total_document_templates,
        templateCreatedSuccess: false,
        DocumentTemplateIsEdit: false,
        templateEditSuccess: false,
      };
    case constants.FETCH_ALL_FORM_SETUPS_ERROR:
      return {
        ...state,
        fetchLoading: false,
        isAdding: false,
        templateCreatedSuccess: false,
        templateEditSuccess: false,
      };
    case constants.UPDATE_FORM_SETUP:
      return {
        ...state,
        updateLoading: true,
        templateCreatedSuccess: false,
      };
    case constants.UPDATE_FORM_SETUP_SUCCESS:
      const updated = state.allForms.map((el) =>
        el._id === action.data._id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allTemplate: updated,
        updateLoading: false,
        isAdding: false,
        templateCreatedSuccess: false,
        DocumentTemplateIsEdit: false,
        templateEditSuccess: true,
      };
    case constants.UPDATE_FORM_SETUP_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
        templateCreatedSuccess: false,
      };
    case constants.DELETE_FORM_SETUP:
      return {
        ...state,
        templateEditSuccess: false,
        deleteLoading: true,
        templateCreatedSuccess: false,
        DocumentTemplateIsEdit: false,
      };
    case constants.DELETE_FORM_SETUP_SUCCESS:
      let updatedTemplate = state.allForms.filter(
        (el) => el._id !== action.data._id
      );
      let totalRecords = state.totalRecords - 1;
      return {
        ...state,
        totalRecords: totalRecords,
        fetchTemplatesLoading: false,
        allForms: updatedTemplate,
        deleteLoading: false,
      };
    case constants.DELETE_FORM_SETUP_ERROR:
      return {
        ...state,
        fetchTemplatesLoading: false,
      };

    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };
    case constants.FETCH_FORM_SETUP:
      return {
        ...state,
        fetchLoading: true,
        templateCreatedSuccess: false,
      };
    case constants.FETCH_FORM_SETUP_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        templateCreatedSuccess: false,
        currentTemplateObj: action.data,
      };
    case constants.FETCH_FORM_SETUP_ERROR:
      return {
        ...state,
        fetchLoading: false,
        templateCreatedSuccess: false,
      };
    // default:
    //   return state;
    case constants.FORM_SETUP_CREATION_CLEANUP_FUNC:
      return {
        ...state,
        allForms: [],
        createLoading: false,
        currentProjectObj: {},
        templateDetailsIsEdit: false,
        fetchLoading: false,
        deletePLoading: false,
        templateEditSuccess: false,
        currentTemplateObj: {
          contract_name: '',
          contract_cover: '',
          description: '',
          plans: [],
        },
        // projectNotificationIsEdit: false,
      };
    default:
      return state;
  }
};

export default formSetupReducer;
