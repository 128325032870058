import React from 'react';
import { Col, DatePicker, Form, Row } from 'antd';

const SectionD = ({ InputComponent, AccountTypeBox, AccountAgeBox, data }) => {
  return (
    <>
      <Col span={23} className='sub-section-heading-col'>
        <div>Payment Details</div>
      </Col>
      <Col span={23} className='individual-app-inputs'>
        <Row justify='start'>
          <Col span={24}>
            <InputComponent
              name='bankAccountHolder'
              label='Bank Acc Holder'
              placeholder='Bank Account Holder'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='bankName'
              label='Bank Name'
              placeholder='Bank Name'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='branchName'
              label='Branch Name'
              placeholder='Branch Name'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='Account Number' style={{ marginBottom: 0 }}>
              <Row justify='space-between'>
                <Col span={11}>
                  <InputComponent
                    name='accountNumber'
                    label=''
                    placeholder='Account Number'
                  />
                </Col>
                <Col span={13}>
                  <InputComponent
                    name='branchNumber'
                    label='Branch Number'
                    placeholder='Branch Number'
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label='Account Type' style={{ marginBottom: 24 }}>
              <Row justify='start'>
                <Col>
                  <AccountTypeBox
                    value='current'
                    label='Current'
                    keyName='accountType'
                  />
                </Col>
                <Col>
                  <AccountTypeBox
                    value='savings'
                    label='Savings'
                    keyName='accountType'
                  />
                </Col>
                <Col>
                  <AccountTypeBox
                    value='transmission'
                    label='Transmission'
                    keyName='accountType'
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label='Account Age' style={{ marginBottom: 0 }}>
              <Row justify='space-between'>
                <Col>
                  <Row justify='start'>
                    <Col>
                      <AccountAgeBox
                        value={data?.accountAgeYear}
                        label='Years'
                        name='accountAgeYear'
                      />
                    </Col>
                    <Col>
                      <AccountAgeBox
                        value={data?.accountAgeMonths}
                        label='Months'
                        name='accountAgeMonths'
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={15}>
                  <Form.Item
                    name='debitOrderDate'
                    label='Debit Order Date'
                    style={{ marginBottom: 0 }}
                    wrapperCol={24}
                  >
                    <DatePicker
                      format='YYYY-MM-DD'
                      placeholder='Y Y Y Y - M M - D D'
                      suffixIcon={null}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col span={23} className='sub-section-heading-col'>
        <div>Residential Information</div>
      </Col>
      <Col span={23} className='individual-app-inputs'>
        <Row justify='start'>
          <Col span={24}>
            <Form.Item label='Owner Type' style={{ marginBottom: 24 }}>
              <Row justify='start'>
                <Col>
                  <AccountTypeBox
                    value='owner'
                    label='Owner'
                    keyName='ownerType'
                  />
                </Col>
                <Col>
                  <AccountTypeBox
                    value='tenant'
                    label='Tenant'
                    keyName='ownerType'
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label='Time at Current Address'
              style={{ marginBottom: 0 }}
            >
              <Row justify='start'>
                <Col>
                  <AccountAgeBox
                    value={data?.yearsAtCurrentAddress}
                    label='Years'
                    name='yearsAtCurrentAddress'
                  />
                </Col>
                <Col>
                  <AccountAgeBox
                    value={data?.monthsAtCurrentAddress}
                    label='Months'
                    name='monthsAtCurrentAddress'
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={23} className='sub-section-heading-col'>
        <div>Employment History</div>
      </Col>
      <Col span={23} className='individual-app-inputs'>
        <Row justify='start'>
          <Col span={24}>
            <Form.Item label='Employment Status' style={{ marginBottom: 24 }}>
              <Row justify='start'>
                <Col>
                  <AccountTypeBox
                    value='employee'
                    label='Employee'
                    keyName='employmentStatus'
                  />
                </Col>
                <Col>
                  <AccountTypeBox
                    value='memberOrDirector'
                    label='Member / Director'
                    keyName='employmentStatus'
                  />
                </Col>
                <Col>
                  <AccountTypeBox
                    value='soleOwner'
                    label='Sole Owner'
                    keyName='employmentStatus'
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <InputComponent
              name='employerName'
              label='Employer Name'
              placeholder='Employer Name'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='employeeOccupation'
              label='Occupation'
              placeholder='Occupation'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='employeePhoneNo'
              label='Employer Phone Number'
              placeholder='Employer Phone Number'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='employeeIndustry'
              label='Employer Industry'
              placeholder='Employer Industry'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='Gross Monthly Income'>
              <Row justify='start'>
                <Col span={12}>
                  <AccountAgeBox
                    value={data?.grossMonthlyIncome}
                    label='R'
                    name='grossMonthlyIncome'
                    placeholder='Gross Monthly Income'
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label='Time at Current Employer'
              style={{ marginBottom: 0 }}
            >
              <Row justify='start'>
                <Col>
                  <AccountAgeBox
                    value={data?.yearsAtCurrentEmployer}
                    label='Years'
                    name='yearsAtCurrentEmployer'
                  />
                </Col>
                <Col>
                  <AccountAgeBox
                    value={data?.monthsAtCurrentEmployer}
                    label='Months'
                    name='monthsAtCurrentEmployer'
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default SectionD;
