const UPDATE_VALUE_ADDED = 'UPDATE_VALUE_ADDED';
const UPDATE_VALUE_ADDED_SUCCESS = 'UPDATE_VALUE_ADDED_SUCCESS';
const UPDATE_VALUE_ADDED_ERROR = 'UPDATE_VALUE_ADDED_ERROR';
const DELETE_VALUE_ADDED = 'DELETE_VALUE_ADDED';
const DELETE_VALUE_ADDED_SUCCESS = 'DELETE_VALUE_ADDED_SUCCESS';
const DELETE_VALUE_ADDED_ERROR = 'DELETE_VALUE_ADDED_ERROR';
const CREATE_VALUE_ADDED = 'CREATE_VALUE_ADDED';
const CREATE_VALUE_ADDED_SUCCESS = 'CREATE_VALUE_ADDED_SUCCESS';
const CREATE_VALUE_ADDED_ERROR = 'CREATE_VALUE_ADDED_ERROR';
const FETCH_ALL_VALUE_ADDED = 'FETCH_ALL_VALUE_ADDED';
const FETCH_ALL_VALUE_ADDED_SUCCESS = 'FETCH_ALL_VALUE_ADDED_SUCCESS';
const FETCH_ALL_VALUE_ADDED_ERROR = 'FETCH_ALL_VALUE_ADDED_ERROR';
const VALUE_ADDED_IS_EDIT = 'VALUE_ADDED_IS_EDIT';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';
const FETCH_VALUE_ADDED = 'FETCH_VALUE_ADDED';
const VALUE_ADDED_CREATION_CLEANUP_FUNC = 'VALUE_ADDED_CREATION_CLEANUP_FUNC';
const FETCH_VALUE_ADDED_SUCCESS = 'FETCH_VALUE_ADDED_SUCCESS';
const FETCH_VALUE_ADDED_ERROR = 'FETCH_VALUE_ADDED_ERROR';
export {
  VALUE_ADDED_IS_EDIT,
  FETCH_ALL_VALUE_ADDED,
  FETCH_ALL_VALUE_ADDED_SUCCESS,
  FETCH_ALL_VALUE_ADDED_ERROR,
  CREATE_VALUE_ADDED,
  CREATE_VALUE_ADDED_SUCCESS,
  CREATE_VALUE_ADDED_ERROR,
  UPDATE_VALUE_ADDED,
  UPDATE_VALUE_ADDED_SUCCESS,
  UPDATE_VALUE_ADDED_ERROR,
  DELETE_VALUE_ADDED,
  DELETE_VALUE_ADDED_SUCCESS,
  DELETE_VALUE_ADDED_ERROR,
  IS_ADDING_UPDATE,
  FETCH_VALUE_ADDED,
  VALUE_ADDED_CREATION_CLEANUP_FUNC,
  FETCH_VALUE_ADDED_SUCCESS,
  FETCH_VALUE_ADDED_ERROR,
};
