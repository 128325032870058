import React from 'react';
import { Checkbox, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from '../../../../../assets/plus-icon.svg';
import { contractTermOptions } from './utils';

const SectionA = ({ Heading, data, setData, formData }) => {
  const { valueServiceData } = useSelector((state) => state.valueAddedReducer);

  const contractTermChange = (e) => {
    const { value, checked } = e.target;
    let contractTerm = checked ? value : null;
    setData({ ...data, contractTerm });
  };

  const handleConnectivityPlan = (e) => {
    const { value, checked } = e.target;
    let connectivityPlan = checked ? value : null;
    setData({ ...data, connectivityPlan });
  };

  const handleFibreFixedVoice = (e) => {
    const { value, checked } = e.target;
    let fibreFixedVoice = checked ? value : null;
    setData({ ...data, fibreFixedVoice });
  };

  const handleOtherValueAdded = (e) => {
    const { checked, id, name, price, description } = e.target;
    if (checked) {
      let obj = { name, price, description };
      setData({
        ...data,
        otherValueAdded: {
          ...data.otherValueAdded,
          [id]: obj,
        },
      });
    } else {
      let dataCopy = { ...data };
      delete dataCopy.otherValueAdded[id];
      setData(dataCopy);
    }
  };
  const OtherValueAddedService = ({
    id,
    price,
    isFirstRow,
    heading,
    name,
    description,
  }) => {
    let isRowSelected = data?.otherValueAdded?.hasOwnProperty(id);
    return (
      <Col span={6} className='full-height'>
        <div className={`plan-div`}>
          {isFirstRow && <div className='heading'>{heading}</div>}
          {price && (
            <Checkbox
              onChange={handleOtherValueAdded}
              key='otherValueAdded'
              value={id}
              id={id}
              name={name}
              price={price}
              description={description}
              checked={
                isRowSelected && data?.otherValueAdded[id]?.name === name
              }
              style={{
                lineHeight: '32px',
              }}
              disabled={
                isRowSelected && data?.otherValueAdded[id]?.name !== name
              }
            >
              <div className='desc-parent'>
                <div className={`price`}>R{price}</div>
              </div>
            </Checkbox>
          )}
        </div>
      </Col>
    );
  };

  const handleInterimLteAccess = (e) => {
    const { checked, id, price, description } = e.target;
    if (checked) {
      let obj = { price, description, id };
      setData({
        ...data,
        interimLteAccess: {
          ...data.interimLteAccess,
          [id]: obj,
        },
      });
    } else {
      let dataCopy = { ...data };
      dataCopy.interimLteAccess = null;
      setData(dataCopy);
    }
  };

  const InterimLteAccessCol = (el) => {
    const { _id, price, description } = el;
    let isRowSelected = data?.interimLteAccess?.hasOwnProperty(_id);
    return (
      <Col span={8} className='full-height'>
        <div className={`plan-div`}>
          {price && (
            <Checkbox
              {...el}
              onChange={handleInterimLteAccess}
              key='interimLteAccess'
              id={_id}
              checked={isRowSelected}
              style={{
                lineHeight: '32px',
              }}
              disabled={data?.interimLteAccess && !isRowSelected}
            >
              <div className='desc-parent'>
                <div className='description'>{description}</div>
                <div className='price'>R{price}</div>
              </div>
            </Checkbox>
          )}
        </div>
      </Col>
    );
  };

  return (
    <>
      <Heading text='SECTION A: Vodacom Fibre Broadband Price Plan Selection (all pricing incl VAT)' />
      <Col span={23}>
        <Row justify='center' align='middle' gutter={[20, 0]}>
          <Col>
            <div className='contract-term-div'>
              <div className='label-div'>
                <label>contract term</label>
              </div>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={[data.contractTerm]}
              >
                <Row justify='center' style={{ width: '100%' }}>
                  {contractTermOptions.map((item) => {
                    const { key, label, value } = item;

                    return (
                      <Col key={key} span={24}>
                        <Checkbox
                          onChange={contractTermChange}
                          name={value}
                          key='contractTerm'
                          checked={value === data.contractTerm}
                          value={value}
                          style={{
                            lineHeight: '32px',
                          }}
                          disabled={
                            data.contractTerm &&
                            item.value !== data.contractTerm
                          }
                        >
                          <div>{label}</div>
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </div>
          </Col>
          <Col>
            <PlusIcon className='plus-icon' />
          </Col>
          <Col>
            <div className='contract-term-div'>
              <div className='label-div'>
                <label>Vodasure Contract Cover</label>
              </div>
              <div className='description'>
                Settles outstanding contract <br /> balance + R5000 pay-out
              </div>

              <Row justify='center' style={{ width: '100%' }}>
                <Col key={'key'} span={24}>
                  <Checkbox
                    className='contractCover-checkbox'
                    onChange={(e) =>
                      setData({
                        ...data,
                        contractCover: e.target.checked,
                      })
                    }
                    key='contractCover'
                    name='contractCover'
                    checked={data.contractCover}
                    style={{
                      lineHeight: '32px',
                    }}
                  >
                    <div>R9.99pm</div>
                  </Checkbox>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={23} xl={23} xxl={22} className='section-a-connectivity-plans'>
        <Row className='' style={{ minHeight: 240 }}>
          <Col span={6} className='description-col'>
            <div className='description-div border'>
              <label>Connectivity Plans</label>
            </div>
            <div className='description more-padding'>
              {formData?.description?.split('\n')?.map((el) => (
                <div>{el}</div>
              ))}
            </div>
          </Col>
          <Col span={18}>
            <Row align='middle' style={{ height: '100%' }}>
              {formData?.plans.map((el, i) => {
                return (
                  <Col span={6} className='half-height'>
                    <div className={`plan-div ${i < 4 && 'border-bottom'}`}>
                      <Checkbox
                        onChange={handleConnectivityPlan}
                        key='connectivityPlan'
                        value={el._id}
                        checked={data.connectivityPlan === el._id}
                        style={{
                          lineHeight: '32px',
                        }}
                        disabled={
                          data.connectivityPlan &&
                          el._id !== data.connectivityPlan
                        }
                      >
                        <div>{el.planName}</div>
                        <div>{el.speed}</div>
                        <div className='limit'>{el.dataLimit}</div>
                        {el.hasDiscount && (
                          <div className={`price`}>WAS R{el.priceWas}</div>
                        )}
                        <div className={`price ${el.hasDiscount && 'red'}`}>
                          NOW R{el.priceNow}
                        </div>
                      </Checkbox>
                    </div>
                  </Col>
                );
              })}
              {new Array(8 - formData?.plans.length).fill(1).map((el, i) => {
                return (
                  <Col
                    span={6}
                    className={`half-height ${
                      formData?.plans.length + i < 4 && 'border-bottom'
                    }`}
                  >
                    <div className='plan-div'></div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Col>
      <Heading text='Value Added Services' />
      <Col span={23} xl={23} xxl={22} className='fibre-fixed-voice'>
        <Row className=''>
          <Col span={6} className='description-col'>
            <div className='description-div'>
              <label>Vodacom Fibre Fixed Voice</label>
            </div>
            <div className='description'>
              <div>(Telephone not included)</div>
              <div>
                *If you want to keep your existing fixed line number, please
                also complete the GNP request form
              </div>
            </div>
          </Col>
          <Col span={18}>
            <Row align='middle' style={{ height: '100%' }}>
              {valueServiceData.fibreFixedVoice.map((el, i) => {
                return (
                  <Col span={6} className='full-height'>
                    <div className={`plan-div`}>
                      <Checkbox
                        onChange={handleFibreFixedVoice}
                        key='fibreFixedVoice'
                        value={el._id}
                        checked={data.fibreFixedVoice === el._id}
                        style={{
                          lineHeight: '32px',
                        }}
                        disabled={
                          data.fibreFixedVoice &&
                          el._id !== data.fibreFixedVoice
                        }
                      >
                        <div className='desc-parent'>
                          <div>{el.description}</div>
                          <div className={`price`}>R{el.price}</div>
                        </div>
                      </Checkbox>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        span={23}
        xl={23}
        xxl={22}
        className='fibre-fixed-voice other-value-added'
      >
        {valueServiceData.otherValueServices.map((el, i) => {
          const length = valueServiceData.otherValueServices.length;
          let borderCls = i !== length - 1 && 'border-row';
          let isFirstRow = i === 0;
          let firstRow = isFirstRow && 'first-row';

          return (
            <Row className={`parent-row ${borderCls} ${firstRow}`}>
              <Col span={6} className='description-col'>
                <div className='description-div'>
                  <label>{el.description}</label>
                </div>
              </Col>
              <Col span={18}>
                <Row align='middle' style={{ height: '100%' }}>
                  <OtherValueAddedService
                    id={el._id}
                    price={el.onceOffPrice}
                    isFirstRow={isFirstRow}
                    heading='Once Off'
                    name='onceOffPrice'
                    description={el.description}
                  />
                  <OtherValueAddedService
                    id={el._id}
                    price={el.twentyFourMonthsPrice}
                    isFirstRow={isFirstRow}
                    heading='24 Months'
                    name='twentyFourMonthsPrice'
                    description={el.description}
                  />
                  <OtherValueAddedService
                    id={el._id}
                    price={el.twelveMonthsPrice}
                    isFirstRow={isFirstRow}
                    heading='12 Months'
                    name='twelveMonthsPrice'
                    description={el.description}
                  />
                  <OtherValueAddedService
                    id={el._id}
                    price={el.monthToMonthPrice}
                    isFirstRow={isFirstRow}
                    heading='Month To Month'
                    name='monthToMonthPrice'
                    description={el.description}
                  />
                </Row>
              </Col>
            </Row>
          );
        })}
      </Col>
      <Col span={18} className='value-added-services-note'>
        <div className='note-heading'>Note:</div>
        <div className='note-sub-heading'>
          Vodacom Fibre Interim LTE Access is only available to customers with
          an approved Vodacom Fibre Broadband order.
          <br />
          The product is not available as a standalone offer.
        </div>
      </Col>
      <Heading text='Vodacom Fibre Interim LTE Access Product Selection (all pricing incl. VAT)' />
      <Col
        span={23}
        xl={23}
        xxl={22}
        className='fibre-fixed-voice lte-access-section'
      >
        <Row className='parent-row first-row'>
          <Col span={6} className='description-col'>
            <div className='description-div border'>
              <label>Contract Term</label>
            </div>
            <div className='description-div padding-top'>
              <label>Interim LTE Access</label>
            </div>
          </Col>
          <Col span={18}>
            <Row align='middle' style={{ height: '100%' }}>
              <Col span={24} className='head-col'>
                <div className='description-div border'>
                  <label>Month to Month</label>
                </div>
              </Col>
              {valueServiceData.interimLteServices.map((el, i) => {
                return <InterimLteAccessCol {...el} />;
              })}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={22} xxl={21} className='interim-lte-note'>
        <div className='note-heading'>
          *Additional Top Up data bundles can be purchased by contacting 082
          1904
        </div>
      </Col>
    </>
  );
};

export default SectionA;
