import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Popconfirm, Row, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import SpinnerComponent from '../../../components/spinner';
// import { deleteNpoAttach } from '../redux/actions';
import { baseURL } from '../../../globalUtils/axiosConfig';

const AttachmentsSection = ({ isEdit, loading, documents, setDocuments }) => {
  const [delAttachKey, setDelAttachKey] = useState([]);
  const inputFile = useRef(null);

  const dispatch = useDispatch();

  const { deleteAttachLoading, createNpoLoading } = useSelector(
    (state) => state.npoSetupReducer
  );

  const handleShowImage = (image) => {
    let url = image?.isLocal
      ? URL.createObjectURL(image)
      : `${baseURL}/${image.pdf_path}`;
    window.open(url, '_blank');
  };

  const changeHandler = (event) => {
    // event.currentTarget.value = null;

    if (event.target.files.length < 1) {
      return;
    }
    let file = event.target.files[0];
    if (file.type !== 'application/pdf') {
      return message.error('Please select PDF file');
    }
    file['key'] = Math.round(Math.random() * 1000000);
    file['isLocal'] = true;
    setDocuments([file]);

    // Object.entries(event.target.files).forEach((item) => {
    //   console.log(item);
    //   item[1]['key'] = Math.round(Math.random() * 1000000);
    //   item[1]['isLocal'] = true;
    //   console.log('item[1]', item[1]);
    //   setDocuments((prev) => [...prev, item[1]]);
    // });
  };

  const handleDeleteImage = (record) => {
    setDocuments([]);
    // if (record?.isLocal) {
    //   let filteredImages = documents.filter(
    //     (image) => image.key !== record.key
    //   );

    //   setDocuments(filteredImages);
    // } else {
    //   setDelAttachKey([...delAttachKey, record.key]);
    //   dispatch(deleteNpoAttach(record.key));
    // }
  };

  return (
    <Col md={22} lg={22} xl={19} xxl={18} style={{ paddingLeft: '0px' }}>
      <div className='project-attach-parent-div'>
        <span className='text-span'>Book Content</span>
        <div className={`img-parent-div ${isEdit && 'edit-image'}`}>
          {isEdit && documents.length === 0 && (
            <div className='edit-icon-div'>
              <input
                readOnly={loading}
                disabled={!isEdit}
                type='file'
                name='profileImg'
                id='profileImg'
                accept='application/pdf'
                style={{ display: 'none' }}
                onChange={changeHandler}
                multiple={false}
                // accept="*"
                ref={inputFile}
              />
              <label htmlFor='profileImg'>
                <Button
                  icon={<PlusOutlined />}
                  className='add-activity-btn'
                  style={{
                    minWidth: '140px',
                  }}
                  size='middle'
                  onClick={() => inputFile.current.click()}
                  loading={createNpoLoading}
                >
                  Upload Content
                </Button>
              </label>
            </div>
          )}
        </div>
      </div>
      <Row gutter={[20, 10]} className='attach-listing-div add-attach-btn'>
        {documents.map((el) => {
          return (
            <Col span={24}>
              <Row justify='space-between'>
                <Col
                  span={20}
                  onClick={() => handleShowImage(el)}
                  className='name-col'
                >
                  <span className='name'>
                    {el.name.replaceAll(/[^a-zA-Z0-9.-]/g, ' ')}
                  </span>
                </Col>
                <Col span={4}>
                  {deleteAttachLoading && delAttachKey.includes(el?.key) ? (
                    <SpinnerComponent fontSize={14} />
                  ) : (
                    <Popconfirm
                      title='Are you sure you want to delete this book?'
                      onConfirm={() => handleDeleteImage(el)}
                      okText='Yes'
                      cancelText='No'
                    >
                      {isEdit && <DeleteIcon className='del-icon' />}
                    </Popconfirm>
                  )}
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Col>
  );
};

export default AttachmentsSection;
