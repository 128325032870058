import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';

export function* handleFetchRooms({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v2/admin/rooms`, {});
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchRoomsSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchRoomsError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateRooms({ data }) {
  try {
    const response = yield axios.put(`${baseURL}/api/v2/admin/rooms`, data);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateRoomsSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateRoomsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateRoomsError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleDeleteRooms({ data }) {
  const { _id } = data;
  try {
    const response = yield axios.delete(`${baseURL}/api/v2/admin/rooms/${_id}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteRoomsSuccess(_id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteRoomsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteRoomsError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleCreateRooms({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData._id;
    const response = yield axios.post(`${baseURL}/api/v2/admin/rooms`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createRoomsSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createRoomsError(data._id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateContactOrder({ data }) {
  try {
    const response = yield axios.patch(`${baseURL}/sort_api/v2/admin/rooms`, data);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateRoomsOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateRoomsOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateRoomsOrderError());
    AntdAlert.error('Something went wrong!');
  }
}

export function* watcherRoomsSaga() {
  yield takeLatest(constants.FETCH_ROOMS, handleFetchRooms);
  yield takeLatest(constants.CREATE_ROOMS, handleCreateRooms);
  yield takeLatest(constants.UPDATE_ROOMS, handleUpdateRooms);
  yield takeLatest(constants.UPDATE_ORDER_ROOMS, handleUpdateContactOrder);
  yield takeLatest(constants.DELETE_ROOMS, handleDeleteRooms);
}
