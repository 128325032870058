const FETCH_SITE_STATUSES = 'FETCH_LEARNING_ACTIVITY_STICKERS';
const FETCH_SITE_STATUSES_SUCCESS = 'FETCH_LEARNING_ACTIVITY_STICKERS_SUCCESS';
const FETCH_SITE_STATUSES_ERROR = 'FETCH_LEARNING_ACTIVITY_STICKERS_ERROR';
const UPDATE_SITE_STATUSES = 'UPDATE_LEARNING_ACTIVITY_STICKER';
const UPDATE_SITE_STATUSES_SUCCESS = 'UPDATE_LEARNING_ACTIVITY_STICKER_SUCCESS';
const UPDATE_SITE_STATUSES_ERROR = 'UPDATE_LEARNING_ACTIVITY_STICKER_ERROR';
const DELETE_SITE_STATUSES = 'DELETE_LEARNING_ACTIVITY_STICKER';
const DELETE_SITE_STATUSES_SUCCESS = 'DELETE_LEARNING_ACTIVITY_STICKER_SUCCESS';
const DELETE_SITE_STATUSES_ERROR = 'DELETE_LEARNING_ACTIVITY_STICKER_ERROR';
const CREATE_SITE_STATUSES = 'CREATE_LEARNING_ACTIVITY_STICKER';
const CREATE_SITE_STATUSES_SUCCESS = 'CREATE_LEARNING_ACTIVITY_STICKER_SUCCESS';
const CREATE_SITE_STATUSES_ERROR = 'CREATE_LEARNING_ACTIVITY_STICKER_ERROR';
const UPDATE_ORDER_SITE_STATUS = 'UPDATE_ORDER_LEARNING_ACTIVITY_STICKER';
const UPDATE_ORDER_SITE_STATUS_SUCCESS =
  'UPDATE_LEARNING_ACTIVITY_STICKER_SUCCESS';
const UPDATE_ORDER_SITE_STATUS_ERROR =
  'UPDATE_ORDER_LEARNING_ACTIVITY_STICKER_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE_LEARNING_ACTIVITY_STICKER';

export {
  FETCH_SITE_STATUSES,
  FETCH_SITE_STATUSES_SUCCESS,
  FETCH_SITE_STATUSES_ERROR,
  UPDATE_SITE_STATUSES,
  UPDATE_SITE_STATUSES_SUCCESS,
  UPDATE_SITE_STATUSES_ERROR,
  DELETE_SITE_STATUSES,
  DELETE_SITE_STATUSES_SUCCESS,
  DELETE_SITE_STATUSES_ERROR,
  CREATE_SITE_STATUSES,
  CREATE_SITE_STATUSES_SUCCESS,
  CREATE_SITE_STATUSES_ERROR,
  UPDATE_ORDER_SITE_STATUS,
  UPDATE_ORDER_SITE_STATUS_SUCCESS,
  UPDATE_ORDER_SITE_STATUS_ERROR,
  IS_ADDING_UPDATE,
};
