import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import * as constants from '../constants';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleFetchStickers({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v2/admin/stickers`);
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchSiteStatusesSuccess(response.data.data.stickers));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchSiteStatusesError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateSticker({ data }) {
  const { id, name, deleted_status, ...rest } = data;

  try {
    const fd = new FormData();
    fd.append('name', name);
    fd.append('sticker_id', id);
    fd.append('deleted_status', deleted_status);
    if (rest?.icon) {
      fd.append('icon', rest.icon);
    }

    const response = yield axios.put(`${baseURL}/api/v2/admin/stickers`, fd);

    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateSiteStatusesSuccess(response.data.data.sticker));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateSiteStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateSiteStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleDeleteSiteStatuses({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/admin/learning-activities/stickers/${id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteSiteStatusesSuccess(id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteSiteStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteSiteStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleCreateSticker({ data }) {
  const { name, icon, deleted_status } = data;
  try {
    const fd = new FormData();
    fd.append('name', name);
    fd.append('icon', icon);
    fd.append('deleted_status', deleted_status);
    const response = yield axios.post(`${baseURL}/api/v2/admin/stickers`, fd);
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createSiteStatusesSuccess(response.data.data.sticker));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createSiteStatusesError(data.id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
    yield put(actions.createSiteStatusesError());
  }
}

export function* handleUpdateSiteOrder({ data }) {
  const fd = new FormData();
  data.map((el, i) => fd.append(`statuses[${el.id}]`, i + 1));

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v2/admin/learning-activities/stickers/sort`,
      fd
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateSiteOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateSiteOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateSiteOrderError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* watcherStickersSaga() {
  yield takeEvery(constants.FETCH_SITE_STATUSES, handleFetchStickers);
  yield takeEvery(constants.CREATE_SITE_STATUSES, handleCreateSticker);
  yield takeEvery(constants.UPDATE_SITE_STATUSES, handleUpdateSticker);
}
