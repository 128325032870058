import * as constants from './constants';

export const setNpoDetailsIsEdit = (data) => {
  return {
    type: constants.NPO_DETAILS_IS_EDIT,
    data: data,
  };
};

export const createNpo = (data) => {
  return {
    type: constants.CREATE_NPO,
    data: data,
  };
};

export const createNpoSuccess = (data) => {
  return {
    type: constants.CREATE_NPO_SUCCESS,
    data: data,
  };
};

export const createNpoError = () => {
  return {
    type: constants.CREATE_NPO_ERROR,
  };
};

export const fetchNpo = (data) => {
  return {
    type: constants.FETCH_NPO,
    data: data,
  };
};

export const fetchNpoSuccess = (data) => {
  return {
    type: constants.FETCH_NPO_SUCCESS,
    data,
  };
};

export const fetchNpoError = () => {
  return {
    type: constants.FETCH_NPO_ERROR,
  };
};

export const updateNpo = (data) => {
  return {
    type: constants.UPDATE_NPO,
    data: data,
  };
};

export const updateNpoSuccess = (data) => {
  return {
    type: constants.UPDATE_NPO_SUCCESS,
    data: data,
  };
};

export const updateNpoError = () => {
  return {
    type: constants.UPDATE_NPO_ERROR,
  };
};

export const fetchNpoDetailsUtils = () => {
  return {
    type: constants.FETCH_NPOS_UTIL_DATA,
  };
};

export const fetchNpoDetailsUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_NPOS_UTIL_DATA_SUCCESS,
    data,
  };
};

export const fetchAllNpos = (data) => {
  return {
    type: constants.FETCH_ALL_NPOS,
    data,
  };
};

export const fetchAllNposSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_NPOS_SUCCESS,
    data,
  };
};
export const fetchAllNposError = (data) => {
  return {
    type: constants.FETCH_ALL_NPOS_ERROR,
    data,
  };
};
export const deleteNpo = (data) => {
  return {
    type: constants.DELETE_NPO,
    data,
  };
};

export const deleteNpoSuccess = (data) => {
  return {
    type: constants.DELETE_NPO_SUCCESS,
    data,
  };
};

export const deleteNpoError = (data) => {
  return {
    type: constants.DELETE_NPO_ERROR,
    data,
  };
};

export const deleteNpoAttach = (data) => {
  return {
    type: constants.DELETE_NPO_ATTACH,
    data,
  };
};

export const deleteNpoAttachSuccess = (data) => {
  return {
    type: constants.DELETE_NPO_ATTACH_SUCCESS,
    data,
  };
};

export const deleteNpoAttachError = (data) => {
  return {
    type: constants.DELETE_NPO_ATTACH_ERROR,
    data,
  };
};

export const npoDetailCleanupFunc = () => {
  return {
    type: constants.NPO_DETAIL_CLEANUP_FUNC,
  };
};

export const setNpoNotificationIsEdit = (data) => {
  return {
    type: constants.NPO_NOTIF_IS_EDIT,
    data: data,
  };
};

export const updateNpoNotifications = (data) => {
  return {
    type: constants.UPDATE_NPO_NOTIFICATION,
    data: data,
  };
};

export const updateNpoNotificationsSuccess = (data) => {
  return {
    type: constants.UPDATE_NPO_NOTIFICATION_SUCCESS,
    data: data,
  };
};

export const updateNpoNotificationsError = () => {
  return {
    type: constants.UPDATE_NPO_NOTIFICATION_ERROR,
  };
};

export const updateSlotEnrollments = (data) => {
  return {
    type: constants.UPDATE_ENROLLMENTS,
    data: data,
  };
};

export const updateSlotEnrollmentsSuccess = (data) => {
  return {
    type: constants.UPDATE_ENROLLMENTS_SUCCESS,
    data: data,
  };
};

export const updateSlotEnrollmentsError = () => {
  return {
    type: constants.UPDATE_ENROLLMENTS_ERROR,
  };
};

export const setEnrollmentsIsEdit = (data) => {
  return {
    type: constants.ENROLLMENTS_IS_EDIT,
    data: data,
  };
};

export const setStorage = (data) => {
  return {
    type: constants.SET_SLOTS_MANAGER_STATE,
    data,
  };
};
