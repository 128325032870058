import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Button } from 'antd';
import { ReactComponent as BackIcon } from '../../../../assets/back-icon.svg';
import MilestonesTabs from './milestonesTabs';

const CurriculumMilestones = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className='page-top-div heading-parent-div'>
        <Row justify='space-between'>
          <Col>
            <span className='text-span'>Curriculum Management</span>
          </Col>
          <Col>
            <Button
              onClick={() => navigate(`/curriculum-management`)}
              icon={<BackIcon width={24} height={14} />}
              className='back-btn'
              size='middle'
            >
              BACK
            </Button>
          </Col>
        </Row>
        <div className='user-manager-parent-div'>
          <MilestonesTabs />
        </div>
      </div>
    </>
  );
};

export default CurriculumMilestones;
