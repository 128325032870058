import { Col, List, Row, Table } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ResponseDetails = ({ responseData }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpand = (record) => {
    if (expandedRowKeys.includes(record._id)) {
      setExpandedRowKeys([]);
    } else {
      const keys = [];
      keys.push(record._id);
      setExpandedRowKeys(keys);
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      width: '10%',
      render: (title, record) => {
        return <div onClick={() => handleExpand(record)}>{title}</div>;
      },
    },
    {
      title: 'description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: '15%',
      render: (title, record) => {
        return <div onClick={() => handleExpand(record)}>{title}</div>;
      },
    },
    {
      title: 'room_code',
      dataIndex: 'room_code',
      key: 'room_code',
      align: 'center',
      width: '10%',
      render: (title, record) => {
        return <div onClick={() => handleExpand(record)}>{title}</div>;
      },
    },
    {
      title: 'slot_date',
      dataIndex: 'slot_date',
      key: 'slot_date',
      align: 'center',
      width: '10%',
      render: (title, record) => {
        return <div onClick={() => handleExpand(record)}>{title}</div>;
      },
    },
    {
      title: 'start_time',
      dataIndex: 'start_time',
      key: 'start_time',
      align: 'center',
      width: '10%',
      render: (title, record) => {
        return <div onClick={() => handleExpand(record)}>{title}</div>;
      },
    },
    {
      title: 'end_time',
      dataIndex: 'end_time',
      key: 'end_time',
      align: 'center',
      width: '10%',
      render: (title, record) => {
        return <div onClick={() => handleExpand(record)}>{title}</div>;
      },
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '10%',
      render: (title, record) => {
        return <div onClick={() => handleExpand(record)}>{title}</div>;
      },
    },
    {
      title: 'capacity',
      dataIndex: 'capacity',
      key: 'capacity',
      align: 'center',
      width: '10%',
      render: (title, record) => {
        return <div onClick={() => handleExpand(record)}>{title}</div>;
      },
    },
    {
      title: 'assessor_id',
      dataIndex: 'assessor_id',
      key: 'assessor_id',
      align: 'center',
      width: '15%',
      render: (title, record) => {
        return (
          <div onClick={() => handleExpand(record)}>
            {title || record?.assessor_staff_code}
          </div>
        );
      },
    },
  ];

  const expandColumns = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      width: '10%',
      render: (title, record) => {
        return (
          <Link
            to={`/slots/update/${record._id}`}
            target='_blank'
            style={{ color: 'inherit' }}
          >
            <div onClick={() => handleExpand(record)}>{title}</div>
          </Link>
        );
      },
    },
    {
      title: '',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: '15%',
      render: (title, record) => {
        return (
          <Link
            to={`/slots/update/${record._id}`}
            target='_blank'
            style={{ color: 'inherit' }}
          >
            <div onClick={() => handleExpand(record)}>{title}</div>
          </Link>
        );
      },
    },
    {
      title: '',
      dataIndex: 'room_code',
      key: 'room_code',
      align: 'center',
      width: '10%',
      render: (title, record) => {
        return (
          <Link
            to={`/slots/update/${record._id}`}
            target='_blank'
            style={{ color: 'inherit' }}
          >
            <div>{title}</div>
          </Link>
        );
      },
    },
    {
      title: '',
      dataIndex: 'slot_date',
      key: 'slot_date',
      align: 'center',
      width: '10%',
      render: (slot_date, record) => {
        return (
          <Link
            to={`/slots/update/${record._id}`}
            target='_blank'
            style={{ color: 'inherit' }}
          >
            <div>
              {moment
                .utc(slot_date, 'YYYY-MM-DD HH:mm:ss.SSS')
                .local()
                .format('YYYY-MM-DD ')}
            </div>
          </Link>
        );
      },
    },
    {
      title: '',
      dataIndex: 'start_time',
      key: 'start_time',
      align: 'center',
      width: '10%',
      render: (start_time, record) => {
        return (
          <Link
            to={`/slots/update/${record._id}`}
            target='_blank'
            style={{ color: 'inherit' }}
          >
            <div>
              {moment
                .utc(start_time, 'YYYY-MM-DD HH:mm:ss.SSS')
                .local()
                .format('hh:mm')}
            </div>
          </Link>
        );
      },
    },
    {
      title: '',
      dataIndex: 'end_time',
      key: 'end_time',
      align: 'center',
      width: '10%',
      render: (end_time, record) => {
        return (
          <Link
            to={`/slots/update/${record._id}`}
            target='_blank'
            style={{ color: 'inherit' }}
          >
            <div>
              {moment
                .utc(end_time, 'YYYY-MM-DD HH:mm:ss.SSS')
                .local()
                .format('hh:mm')}
            </div>
          </Link>
        );
      },
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '10%',
      render: (title, record) => {
        return (
          <Link
            to={`/slots/update/${record._id}`}
            target='_blank'
            style={{ color: 'inherit' }}
          >
            <div onClick={() => handleExpand(record)}>{title}</div>
          </Link>
        );
      },
    },
    {
      title: '',
      dataIndex: 'capacity',
      key: 'capacity',
      align: 'center',
      width: '10%',
      render: (title, record) => {
        return (
          <Link
            to={`/slots/update/${record._id}`}
            target='_blank'
            style={{ color: 'inherit' }}
          >
            <div>{title}</div>
          </Link>
        );
      },
    },
    {
      title: '',
      dataIndex: 'assessor',
      key: 'assessor',
      align: 'center',
      width: '12%',
      render: (title, record) => {
        let name = `${title?.user_id}`;
        return (
          <Link
            to={`/slots/update/${record._id}`}
            target='_blank'
            style={{ color: 'inherit' }}
          >
            <div>{name}</div>
          </Link>
        );
      },
    },
  ];

  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record._id);
    }
    setExpandedRowKeys(keys);
  };

  let records = responseData?.failedRecords || [];
  let updatedRecords = records.map((record, i) => {
    return {
      ...record,
      _id: i,
    };
  });

  return (
    <Row>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[0, 0]}>
          <Col>
            <div>
              <span className='heading-span'>Failed records details</span>
            </div>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row justify='center' className=''>
          <Col span={24} style={{ marginBottom: '30px' }}>
            <Table
              className='special-table activity-type-table project-statuses-table'
              style={{
                marginTop: '0px',
              }}
              columns={columns}
              pagination={false}
              scroll={{ x: 'max-content' }}
              expandable={{
                expandedRowRender: (record, i) => {
                  return (
                    <Col span={24}>
                      <List
                        size='small'
                        header={null}
                        footer={null}
                        bordered
                        dataSource={record.errors}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                      />
                      {record?.errorType === 'overlap' && (
                        <Table
                          //   scroll={{ x: 1200 }}
                          className='ims-doc-table users-manager-table'
                          rowKey={(record) =>
                            `${record?.title}_${i}` || record?.overlapSlot?._id
                          }
                          // rowClassName='editable-row'
                          dataSource={[record?.overlapSlot || []]}
                          columns={expandColumns}
                          // onChange={handleChange}
                          pagination={false}
                          expandable={{
                            showExpandColumn: false,
                          }}
                        />
                      )}
                    </Col>
                  );
                },
                expandRowByClick: true,
                // expandIcon: null,
                showExpandColumn: true,
                expandedRowKeys: expandedRowKeys,
                onExpand: onTableRowExpand,
                // rowExpandable: ({ _id }) => expandedRowKeys.includes(_id),
              }}
              dataSource={updatedRecords}
              onRow={({ _id }) => {
                return (
                  expandedRowKeys.includes(_id) && {
                    className:
                      'expand-parent expand-doc-parent fourth-level-expanded-row',
                  }
                );
              }}
              rowKey={(record) => record._id}
              rowClassName='editable-row'
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default ResponseDetails;
