import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Col,
  Input,
  Row,
  Select,
  Spin,
  Button,
  InputNumber,
  TreeSelect,
} from 'antd';
import { ReactComponent as BackIcon } from '../../../../assets/back-icon.svg';
import { ReactComponent as SaveIcon } from '../../../../assets/save-icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/edit-icon.svg';
import SpinnerComponent from '../../../../components/spinner';
import useFirstStep from './useFirstStep';

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

const FirstStepDetails = () => {
  //   const [currentUser, setCurrentUser] = useState({});
  const navigate = useNavigate();
  const {
    data,
    isEdit,
    setIsEdit,
    loading,
    handleCreate,
    handleUpdate,
    getOutlines,
    setData,
    value,
    setValue,
    form,
    Form,
    fetchOutlinesLoading,
    activities,
    onChangeLearningActivity,
  } = useFirstStep();
  const { currentObj, books } = data;

  function findObjectByKey(key, arr) {
    let result = null;

    function recursiveSearch(items, parentKey = '0-') {
      for (let i = 0; i < items.length; i++) {
        const currentKey = `${parentKey}${i}`;

        if (currentKey === key) {
          result = items[i];
          break;
        }

        if (items[i].children && items[i].children.length > 0) {
          recursiveSearch(items[i].children, `${currentKey}-`);
        }
      }
    }

    recursiveSearch(arr);

    return result;
  }

  const onFinish = (values) => {
    let selectedOutline = value.map((element) => {
      const foundObject = findObjectByKey(element, currentObj.outlines);
      return foundObject;
    });

    if (currentObj?._id) {
      handleUpdate({
        ...values,
        id: currentObj._id,
        treeOutlines: selectedOutline,
      });
    } else {
      handleCreate({ ...values, treeOutlines: selectedOutline });
    }
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleSave = async (key) => {
    try {
      const row = await form.validateFields();

      form.submit();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      // const { errorFields } = errInfo;
      // errorFields.forEach((el) => {
      //   el.errors[0] !== 'Required!' && message.error(el.errors[0]);
      // });
    }
  };

  const NumInputComponent = ({ name, label, placeholder, min, max }) => {
    let requiredRule = [{ required: true, message: 'Required!' }];

    return (
      <Col md={12} lg={12} xl={8} xxl={8}>
        <Form.Item rules={requiredRule} name={name} label={label}>
          <InputNumber
            // readOnly={loading}
            placeholder={placeholder}
            size='small'
            controls={true}
            width='100%'
            disabled={!isEdit}
            min={min}
            max={max}
          />
        </Form.Item>
      </Col>
    );
  };

  const InputComponent = ({ name, label, placeholder, required }) => {
    let requiredRule = [{ required: required, message: 'Required!' }];
    return (
      <Col md={12} lg={12} xl={8} xxl={8}>
        <Form.Item rules={requiredRule} name={name} label={label}>
          <Input
            // readOnly={loading}
            // disabled={!userDetailsIsEdit || !canUpdate || cannotUpdate}
            disabled={!isEdit}
            placeholder={placeholder}
          />
        </Form.Item>
      </Col>
    );
  };

  const DynamicOptionsSelect = ({
    name,
    label,
    placeholder,
    options,
    loading = false,
  }) => (
    <Col md={12} lg={12} xl={8} xxl={8}>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Required!',
          },
        ]}
        name={name}
        label={label}
        shouldUpdate={(prevValues, curValues) =>
          prevValues.learning_activity_id !== curValues.learning_activity_id
        }
      >
        <Select
          style={{
            width: '100%',
          }}
          disabled={!isEdit || loading}
          loading={loading}
          className='department-select'
          dropdownClassName='select-dropdown-custom'
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          showSearch
          placeholder={placeholder}
          optionFilterProp='children'
          // onSearch={onSearch}
          filterOption={(input, option) =>
            option.children
              .toLocaleLowerCase()
              .includes(input.toLocaleLowerCase())
          }
          onChange={(e) => {
            if (name === 'learning_activity_id') {
              onChangeLearningActivity(e);
              form.setFieldsValue({
                book_id: null,
              });
              setData((prev) => ({
                ...prev,
                currentObj: {
                  ...prev.currentObj,
                  outlines: [],
                },
              }));
              setValue([]);
            }
            if (name === 'book_id') {
              getOutlines(e);
              setData((prev) => ({
                ...prev,
                currentObj: {
                  ...prev.currentObj,
                  outlines: [],
                },
              }));
              setValue([]);
            }
          }}
        >
          {options.map(({ _id, name }) => {
            return (
              <Option key={_id} value={_id}>
                {name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
  );

  // const StaticOptionsSelect = ({ name, label, placeholder, options }) => {
  //   return (
  //     <>
  //       <Col md={12} lg={12} xl={8} xxl={8}>
  //         <Form.Item
  //           rules={[
  //             {
  //               required: true,
  //               message: 'Required!',
  //             },
  //           ]}
  //           name={name}
  //           label={label}
  //         >
  //           <Select
  //             style={{
  //               width: '100%',
  //             }}
  //             disabled={!isEdit}
  //             className='department-select'
  //             dropdownClassName='select-dropdown-custom'
  //             getPopupContainer={() =>
  //               document.getElementsByClassName(
  //                 'ant-layout-content layout-content site-layout-background custom-textarea'
  //               )[0]
  //             }
  //             showSearch
  //             placeholder={placeholder}
  //             optionFilterProp='children'
  //             // onSearch={onSearch}
  //             filterOption={(input, option) => {
  //               const { children } = option;
  //               let isArray = Array.isArray(children);
  //               let str = isArray ? children.join('') : children;
  //               return str
  //                 .toLocaleLowerCase()
  //                 .includes(input.toLocaleLowerCase());
  //             }}
  //           >
  //             {options.map((key) => {
  //               return (
  //                 <Option key={key} value={key}>
  //                   {key}
  //                   {/* {capitalizeFirstLetterofEachWord(key || '')} */}
  //                 </Option>
  //               );
  //             })}
  //           </Select>
  //         </Form.Item>
  //       </Col>
  //     </>
  //   );
  // };

  // const onFinishFields = (values) => {
  //   console.log('values', values);
  // };

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const tProps = {
    treeData: currentObj.outlines,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: isEdit ? 'Select Outlines' : '',
    style: {
      width: '100%',
    },
    disabled: !isEdit || fetchOutlinesLoading,
    treeNodeLabelProp: 'fullTitle',
    treeNodeFilterProp: 'fullTitle',
    loading: fetchOutlinesLoading,
  };

  return (
    <>
      <div className='page-top-div heading-parent-div'>
        <Row justify='space-between'>
          <Col>
            <span className='text-span'>
              Create Curriculum
              {/* {isProfileCase
                ? `User - ${user?.first_name + ' ' + user?.last_name}`
                : isUserUpdate
                ? `Update ${userType.replace('_', ' ')}`
                : `Add ${userType.replace('_', ' ')}`} */}
            </span>
          </Col>
          {/* {!isProfileCase && ( */}
          <Col>
            <Button
              onClick={() => navigate(`/curriculum-management`)}
              icon={<BackIcon width={24} height={14} />}
              className='back-btn'
              size='middle'
            >
              BACK
            </Button>
          </Col>
          {/* )} */}
        </Row>
        <Spin spinning={false}>
          <div className='user-manager-parent-div'>
            <Row>
              <Col span={24} className='heading-col'>
                <Row align='middle' justify='space-between'>
                  <Col>
                    <div>
                      <span className='heading-span'>Curriculum Details</span>
                    </div>
                  </Col>
                  <Col>
                    {isEdit ? (
                      loading ? (
                        <SpinnerComponent fontSize={14} />
                      ) : (
                        <SaveIcon width={16} height={16} onClick={handleSave} />
                      )
                    ) : (
                      <EditIcon
                        width={16}
                        fill='#e21c21'
                        height={16}
                        onClick={() => setIsEdit(true)}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col
                span={24}
                className={`user-details-section ${!isEdit && 'is-edit'}`}
                style={{ minHeight: '70vh' }}
              >
                <Row justify='end'>
                  <Col className='required-field-text'>
                    {!isEdit && '* Required Fields'}
                  </Col>
                </Row>
                <Row justify='center' style={{ marginTop: '10px' }}>
                  <Col md={20} lg={20} xl={20} xxl={20}>
                    <Form
                      {...formItemLayout}
                      form={form}
                      name='user-details'
                      onFinish={onFinish}
                      //   initialValues={currentUser}
                      scrollToFirstError
                      labelWrap={true}
                      layout='horizontal'
                      className='user-detail-form'
                      requiredMark={false}
                    >
                      <Row gutter={[30, 20]}>
                        <InputComponent
                          name='name'
                          label='Curriculum Name *'
                          placeholder='Curriculum Name'
                          required={true}
                        />
                        <DynamicOptionsSelect
                          name='learning_activity_id'
                          label='Learning Activity *'
                          placeholder='Select Learning Activity'
                          options={activities}
                          required={true}
                          // loading={fetchOutlinesLoading}
                        />
                        <DynamicOptionsSelect
                          name='book_id'
                          label='Book *'
                          placeholder='Select Book'
                          options={books}
                          required={true}
                          loading={fetchOutlinesLoading}
                        />
                        <NumInputComponent
                          name='duration'
                          label='Duration (Weeks)*'
                          placeholder='Duration'
                          required={true}
                          min={1}
                          max={1000}
                        />
                        <NumInputComponent
                          name='nominal_year_level'
                          label='Nominal Year Level *'
                          placeholder='Nominal Year Level'
                          required={true}
                          min={0}
                          max={24}
                        />
                        <InputComponent
                          name='nominal_term'
                          label='Nominal Term *'
                          placeholder='Nominal Term'
                          required={true}
                        />

                        <Col span={22}>
                          <div class='ant-col ant-col-24 ant-form-item-label ant-form-item-label-wrap'>
                            <label class='ant-form-item' title='Content'>
                              Content
                            </label>
                          </div>
                          <TreeSelect {...tProps} />
                        </Col>

                        {/* <StaticOptionsSelect
                          name='status'
                          label='Curriculum Status *'
                          placeholder='Curriculum Status'
                          options={curriculumStatus}
                          required={true}
                        />

                        <DynamicOptionsSelect
                          name='curriculum_academic_year_id'
                          label='Academic Year *'
                          placeholder='Academic Year'
                          options={academicYears}
                          required={true}
                        /> */}
                      </Row>
                    </Form>
                  </Col>
                </Row>
                {/* <Row
                  justify='end'
                  style={{ marginBottom: '30px', marginTop: '30px' }}
                >
                  <Col>
                    <Button
                      onClick={() =>
                        navigate(`/curriculum-management/second-step`)
                      }
                      //   icon={<BackIcon width={24} height={14} />}
                      className='back-btn'
                      size='middle'
                    >
                      NEXT
                    </Button>
                  </Col>
                </Row> */}
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default FirstStepDetails;
