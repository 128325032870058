import React, { useState } from 'react';
import { Tree } from 'antd';

const TreeComponent = ({ items, setItems, i }) => {
  const [expandedKeys, setExpandedKeys] = useState(items[i].expandedKeys);
  const [checkedKeys, setCheckedKeys] = useState(items[i].selectedKeys);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [contents, setContents] = useState(items[i].contents);

  // useEffect(() => {
  //   setSelectedKeys(items[i].selectedKeys);
  // }, []);

  const onExpand = (expandedKeysValue) => {
    // console.log("onExpand", expandedKeysValue);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
    items[i].expandedKeys = expandedKeysValue;
    setItems(items);
  };

  const onCheck = (checkedKeysValue) => {
    // console.log("onCheck", checkedKeysValue);
    // console.log('items', items);

    // console.log('contents', contents);
    setCheckedKeys(checkedKeysValue);
    items[i].selectedKeys = checkedKeysValue;

    setItems(items);
  };

  const onSelect = (selectedKeysValue, info) => {
    // console.log("onSelect", info);
    setSelectedKeys(selectedKeysValue);
  };

  // console.log('checkedKeys', checkedKeys);
  // console.log('items[i]', items[i]);

  return (
    <Tree
      checkable
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
      treeData={contents}
      height={550}
      style={{
        marginBottom: '30px',
      }}
    />
  );
};
export default TreeComponent;
