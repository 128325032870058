import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';

export function* handleCreateNpo({ data }) {
  try {
    // const fd = new FormData();
    // for (const [key, value] of Object.entries(data)) {
    //   if (key === 'documents' && value !== undefined) {
    //     Array.from(value).forEach((image) => {
    //       // const { isLocal } = image;
    //       // if (isLocal) {
    //       fd.append(`file`, image);
    //       // }
    //       // else fd.append("image_ids[]", key);
    //     });
    //   } else if (value !== null && value !== undefined) {
    //     fd.append(key, value);
    //   }
    // }

    const response = yield axios.post(
      `${baseURL}/api/v2/admin/learning-activities`,
      data,
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    );
    // const { message } = response.data;
    if (response.data?.data?.learning_activity) {
      const { learning_activity, stickers, comments } = response.data?.data;
      AntdAlert.success(
        response.data?.data?.message || 'Learning Activity created successfully'
      );
      yield put(
        actions.createNpoSuccess({ ...learning_activity, stickers, comments })
      );
    }
    // else if (!success) {
    //   AntdAlert.error(response.data?.errors[0]);
    //   yield put(actions.createNpoError());
    //   errorHandler(response.data);
    // }
  } catch (error) {
    yield put(actions.createNpoError());
    AntdAlert.error(error?.response?.data?.message || 'Something went wrong!');
  }
}

export function* handleUpdateNpo({ data }) {
  try {
    // const { name, id, documents, learning_activities } = data;
    // const fd = new FormData();
    // fd.append('name', name);
    // fd.append('id', id);
    // if (documents) {
    //   documents.forEach((image) => {
    //     image.isLocal && fd.append(`file`, image);
    //   });
    // }
    // if (learning_activities?.length > 0) {
    //   learning_activities.forEach(({ id, action }, i) => {
    //     fd.append(`learning_activities[${i}][id]`, id);
    //     fd.append(`learning_activities[${i}][action]`, action);
    //   });
    // }
    // for (const [key, value] of Object.entries(data)) {
    // if (key === 'documents' && value !== undefined) {
    // Array.from(value).forEach((image) => {
    //   const { isLocal } = image;
    //   if (isLocal) {
    //     fd.append(`attachments[]`, image);
    //   }
    // else fd.append("image_ids[]", key);
    // });
    // } else if (value !== null && value !== undefined) {
    //   fd.append(key, value);
    // }
    // }
    const response = yield axios.put(
      `${baseURL}/api/v2/admin/learning-activities`,
      data,
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    );
    // const { success, message } = response.data;
    if (response.data?.data?.learningActivity) {
      AntdAlert.success('Learning Activity updated successfully!');
      const { learningActivity, stickers, comments } = response.data.data;
      yield put(
        actions.updateNpoSuccess({ ...learningActivity, stickers, comments })
      );
    }
    // else if (!success) {
    //   AntdAlert.error(response.data?.errors[0]);
    //   yield put(actions.updateNpoError());
    //   errorHandler(response.data);
    // }
  } catch (error) {
    console.log(error);
    yield put(actions.createNpoError());
    AntdAlert.error(error?.response?.data?.message || 'Something went wrong!');
  }
}

export function* handleFetchNpoDetailsUtils() {
  try {
    const response = yield axios.get(`${baseURL}/api/v2/admin/users/user_details`);
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchNpoDetailsUtilsSuccess(response.data.data));
    }
    errorHandler(response.data);
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchAllNpos({ data }) {
  const { search, ...rest } = data;
  // Object.keys(search).forEach((key) => {
  //   if (search[key]?.length < 1 || search[key] === null) delete search[key];
  // });

  try {
    const response = yield axios.get(`${baseURL}/api/v2/admin/learning-activities`, {
      // params: {
      //   ...rest,
      //   ...(Object.keys(search).length > 0 && {
      //     ...search,
      //     show_by_default: false,
      //   }),
      // },
    });
    const { success } = response.data;

    if (success) {
      yield put(
        actions.fetchAllNposSuccess(response.data.data.learning_activities)
      );
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.fetchAllNposError());
    }
  } catch ({ response }) {
    yield put(actions.fetchAllNposError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleDeleteNpo({ data }) {
  try {
    const response = yield axios.delete(`${baseURL}/api/v2/npos/${data.id}`);
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteNpoSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteNpoError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteNpoError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleFetchNpo({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/admin/learning-activities/fetch-by-id/${data}`
    );

    if (response.data?.data?.learningActivity) {
      const { learningActivity, stickers, comments } = response.data.data;
      yield put(
        actions.fetchNpoSuccess({ ...learningActivity, stickers, comments })
      );
    } else {
      yield put(actions.updateNpoError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.updateNpoError());
    console.log(error?.response?.data?.message || 'Something went wrong!');
  }
}

export function* handleDeleteNpoAttach({ data }) {
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/npos/delete_npo_attachment`,
      {
        params: {
          id: data,
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteNpoAttachSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteNpoAttachError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteNpoAttachError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* watcherLearningActivitiesSaga() {
  yield takeEvery(constants.CREATE_NPO, handleCreateNpo);
  yield takeEvery(constants.UPDATE_NPO, handleUpdateNpo);
  yield takeEvery(constants.FETCH_ALL_NPOS, handleFetchAllNpos);
  yield takeEvery(constants.FETCH_NPO, handleFetchNpo);
  // yield takeEvery(constants.FETCH_LEARNERS_GRAPH, handleFetcH);
}
