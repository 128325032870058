import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import Table from './table';

const Rooms = () => {
  // const { user } = useSelector((state) => state.authReducer);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canDelete, setCanDelete] = useState(false);

  // useEffect(() => {
  //   if (user?.permissions?.contact_type?.add_edit) {
  //     setCanUpdate(true);
  //   }
  //   if (user?.permissions?.contact_type?.delete) {
  //     setCanDelete(true);
  //   }
  // }, [user]);

  return (
    <div className='page-top-div'>
      <span className='text-span'>Rooms</span>
      <Table canUpdate={canUpdate} canDelete={canDelete} />
    </div>
  );
};

export default Rooms;
