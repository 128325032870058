import React from 'react';
import { Col, Row } from 'antd';

const SectionC = ({ InputComponent }) => {
  return (
    <Col span={23} className='individual-app-inputs'>
      <Row justify='start'>
        <Col span={24}>
          <InputComponent
            name='accountHolderOrCompanyName'
            label="Account Holder's / Company Name"
            placeholder="Account Holder's Name / Company Name"
          />
        </Col>
        <Col span={24}>
          <InputComponent
            name='vodacomContractPhoneNo'
            label='Vodacom Contract Cell Phone Number'
            placeholder='Vodacom Contract Cell Phone Number'
          />
        </Col>
        <Col span={24}>
          <InputComponent
            name='vodacomBillingAccountNo'
            label='Vodacom Billing Account No****'
            placeholder='Vodacom Billing Account Number'
          />
        </Col>
        <Col span={24}>
          <div className='section-c-notes'>
            NOTE: If the order is placed under a name different to section A
            (e.g. company, family member), please provide a signed letter from
            the account holder to authorize billing from the existing account.
          </div>
          <div className='section-c-notes'>
            **** The billing account number can be found on the top right hand
            corner of your statement labelled “Account Number”
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default SectionC;
