import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Space,
  Col,
  Row,
  message,
  Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../assets/save-icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/cross-icon.svg';
import SpinnerComponent from '../../components/spinner';
import _ from 'lodash';
// import { arrayMoveImmutable } from 'array-move';
// import DragDropIcon from '../../assets/dragdropicon.png';
import {
  createStreams,
  deleteStreams,
  fetchStreams,
  isAddingAction,
  updateStreams,
  setStorage,
} from './redux/actions';
// import {
//   SortableContainer,
//   SortableElement,
//   SortableHandle,
// } from 'react-sortable-hoc';

// const SortableItem = SortableElement((props) => <tr {...props} />);
// const SortableBody = SortableContainer((props) => <tbody {...props} />);
// const DragHandle = SortableHandle(() => <img alt='' src={DragDropIcon} />);
const { Option } = Select;

const StreamsTable = ({ canUpdate, canDelete }) => {
  const [editingLoading, setEditingLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [search, setSearch] = useState({});
  const [req, setReq] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ref = useRef(null);
  const isEditing = (record) => record._id === editingKey;

  const { allStreams, fetchingLoading, updateLoading, isAdding, ...rest } =
    useSelector((state) => state.streamsReducer);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 500)
  );

  useEffect(() => {
    const localStorageExist = localStorage.getItem('streamsManagerState');
    if (localStorageExist) {
      const localStorageData = JSON.parse(localStorageExist);

      if (localStorageData) {
        setTimeout(() => {
          setSearch(localStorageData.search);
          dispatch(setStorage(localStorageData));

          debouncedCallApi();
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(fetchStreams());

    return () => {
      dispatch(isAddingAction(false));
    };
  }, []);

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey('');
    }
  }, [updateLoading]);

  useEffect(() => {
    setData(allStreams);
  }, [allStreams]);

  useEffect(() => {
    let filteredData = allStreams;

    if (search?.name?.length > 0) {
      filteredData = filteredData.filter((el) =>
        el.name?.toLowerCase()?.includes(search.name?.toLowerCase())
      );
    }

    // if (search?.description?.length > 0) {
    //   filteredData = filteredData.filter((el) =>
    //     el.description
    //       ?.toLowerCase()
    //       ?.includes(search.description?.toLowerCase())
    //   );
    // }

    if (search?.deleted_status?.length > 0) {
      filteredData = filteredData.filter(
        (el) => el.deleted_status === search.deleted_status
      );
    }

    setData(filteredData);
  }, [req]);

  useEffect(() => {
    const isSearchChanged =
      JSON.stringify(search) !== JSON.stringify(rest.search);

    if (isSearchChanged) {
      setSearch(rest.search);
    }

    if (isSearchChanged) {
      debouncedCallApi();
    }
  }, []);

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode = (
      <Input
        placeholder={`Insert stream ${dataIndex}`}
        ref={dataIndex === 'name' ? ref : null}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            save(record._id);
          }
        }}
      />
    );

    // if (dataIndex === 'deleted_status') {
    //   inputNode = (
    //     <Select
    //       showSearch
    //       className={`deleted-status-selector`}
    //       style={{ width: '100px', height: '30px' }}
    //       getPopupContainer={() =>
    //         document.getElementsByClassName(
    //           'ant-layout-content layout-content site-layout-background custom-textarea'
    //         )[0]
    //       }
    //       dropdownClassName='select-dropdown-custom'
    //       size='small'
    //       optionFilterProp='children'
    //       filterOption={(input, option) =>
    //         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //       }
    //       // onChange={handleUpdateSite}
    //       placeholder='Select Status'
    //       optionLabelProp='label'
    //     >
    //       {[
    //         { label: 'ACTIVE', value: 'active' },
    //         { label: 'INACTIVE', value: 'inactive' },
    //       ]?.map(({ label, value }) => {
    //         return (
    //           <Option value={value} key={value} label={label}>
    //             {label || ''}
    //           </Option>
    //         );
    //       })}
    //     </Select>
    //   );
    // }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Required!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(isAddingAction(true));

    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record._id);
    setTimeout(() => {
      ref.current?.focus();
    }, 300);
  };

  const cancel = (record) => {
    dispatch(isAddingAction(false));
    form.resetFields();
    setEditingKey('');
    if (record?.isAdd) {
      return setData((data) => data.filter((el) => el._id !== record._id));
    } else {
      setData(data);
    }
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item._id);
      if (index > -1) {
        const item = newData[index];
        if (item?.isAdd) {
          form.submit();
        } else {
          if (isAlreadyExist({ ...item, ...row })) {
            return message.error('Stream name already exist!');
          }
          let data = { ...item, ...row };
          const { _id, name } = data;
          dispatch(updateStreams({ stream_id: _id, name }));
        }
      }
    } catch (errInfo) {
      if (errInfo?.values?.name === '') {
        message.error('Stream name should not be blank!');
      }
    }
  };

  const handleDelete = (record) => {
    setEditingKey(record._id);
    dispatch(deleteStreams(record));
  };

  const handleAdd = () => {
    dispatch(isAddingAction(true));
    let _id = Math.floor(Math.random() * 1122123456);
    const newData = {
      _id,
      name: '',
      isAdd: true,
    };
    setData((data) => [newData, ...data]);
    setEditingKey(_id);
    edit(newData);
  };

  const isAlreadyExist = (record) => {
    const { name } = record;
    const alreadyExist = data.filter(
      (el) => el.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase()
    );

    return alreadyExist.length > 0 && record._id !== alreadyExist[0]?._id
      ? true
      : false;
  };

  const handleFinish = (values) => {
    const { name } = values;

    if (isAlreadyExist(values)) {
      return message.error('Stream name already exist!');
    }

    dispatch(
      createStreams({
        name: name.trim(),
        _id: editingKey,
      })
    );
  };

  const handleSetStorage = (key, value) => {
    dispatch(setStorage({ [key]: value }));
  };

  const handleSearch = (key, value, func = null) => {
    let updatedState = {
      ...search,
      [key]: value,
    };

    setSearch(updatedState);

    if (typeof func === 'function') {
      func();
    }

    dispatch(setStorage({ search: updatedState }));
    handleSetStorage('search', updatedState);
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      value={search[key]}
      onChange={(e) => {
        handleSearch(key, e.target.value, debouncedCallApi);
      }}
    />
  );

  const columns = [
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Stream Name</div>
            <div>{searchInput('name')}</div>
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      editable: true,
      sorter: (a, b) =>
        a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase()),
      render: (name) => {
        return <div style={{ paddingLeft: '12px' }}>{name}</div>;
      },
    },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' style={{ width: '100%' }}>
    //         <div className='special-table-header-text'>Description</div>
    //         <div>{searchInput('description')}</div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'description',
    //   key: 'description',
    //   width: '50%',
    //   editable: true,
    //   sorter: (a, b) =>
    //     a.description
    //       ?.toLowerCase()
    //       ?.localeCompare(b.description?.toLowerCase()),
    //   render: (description) => {
    //     return <div style={{ paddingLeft: '12px' }}>{description}</div>;
    //   },
    // },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' style={{ width: '100%' }}>
    //         <div className='special-table-header-text'>Status</div>
    //         <div key={'1'}>
    //           <Select
    //             style={{
    //               width: '100%',
    //               textTransform: 'capitalize',
    //             }}
    //             size='small'
    //             onClick={(e) => e.stopPropagation()}
    //             allowClear={true}
    //             readOnly={true}
    //             className='Select Gender'
    //             dropdownClassName='select-dropdown-custom'
    //             getPopupContainer={() =>
    //               document.getElementsByClassName(
    //                 'ant-layout-content layout-content site-layout-background custom-textarea'
    //               )[0]
    //             }
    //             showSearch
    //             placeholder=''
    //             optionFilterProp='children'
    //             onChange={(e) => {
    //               setSearch({
    //                 ...search,
    //                 deleted_status: e,
    //               });
    //               debouncedCallApi();
    //             }}
    //             optionLabelProp='label'
    //             filterOption={(input, option) =>
    //               option?.children
    //                 ?.toLowerCase()
    //                 .indexOf(input.toLowerCase()) >= 0
    //             }
    //           >
    //             {[
    //               { label: 'ACTIVE', value: 'active' },
    //               { label: 'INACTIVE', value: 'inactive' },
    //             ]?.map(({ label, value }) => {
    //               return (
    //                 <Option value={value} label={label}>
    //                   {label || ''}
    //                 </Option>
    //               );
    //             })}
    //           </Select>
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'deleted_status',
    //   key: 'deleted_status',
    //   width: '15%',
    //   editable: true,
    //   align: 'center',
    //   sorter: (a, b) => a.deleted_status?.localeCompare(b.deleted_status),
    //   render: (deleted_status) => {
    //     return (
    //       <div style={{ paddingLeft: '12px' }}>
    //         {deleted_status?.toUpperCase()}
    //       </div>
    //     );
    //   },
    // },
    {
      title: '',
      key: 'action',
      dataIndex: '',
      width: '15%',
      align: 'center',
      render: (_, record) => {
        if (!canUpdate && !canDelete) {
          return;
        }

        const { status_type } = record;
        let canDeleteStatus = status_type === 'not-selected' ? false : true;

        const editable = isEditing(record);
        return editable ? (
          editingLoading && editingKey === record._id ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={20} align='center'>
              <SaveIcon
                className='custom-icon'
                onClick={() => save(record._id)}
              />
              <Popconfirm
                title='Sure to cancel?'
                onConfirm={() => cancel(record)}
              >
                <CrossIcon
                  style={{ height: '20px', width: '20px' }}
                  className='custom-icon'
                />
              </Popconfirm>
            </Space>
          )
        ) : (
          <Space size={20} align='center'>
            {canUpdate && (
              <EditIcon
                className={`custom-icon ${isAdding ? 'disable-icon' : ''}`}
                disabled={editingKey !== ''}
                onClick={() => !isAdding && edit(record)}
              />
            )}
            {canDelete &&
              canDeleteStatus &&
              data.length >= 1 &&
              (isAdding ? (
                <DeleteIcon className={`custom-icon disable-icon`} />
              ) : (
                <Popconfirm
                  title='Are you sure you want to delete this type?'
                  onConfirm={() => handleDelete(record)}
                  okText='Yes'
                  cancelText='No'
                >
                  <DeleteIcon className='custom-icon' />
                </Popconfirm>
              ))}
          </Space>
        );
      },
    },
    // {
    //   title: 'Order',
    //   dataIndex: 'order',
    //   key: 'order',
    //   width: '15%',
    //   className: 'drag-visible',
    //   render: (order, record) => {
    //     const { status_type } = record;
    //     let canDelete = status_type === 'not-selected' ? false : true;
    //     if (!canDelete) {
    //       return;
    //     }

    //     if (isAdding) {
    //       return <img alt='' className='disable-icon' src={DragDropIcon} />;
    //     }

    //     return !Boolean(editingKey) && canUpdate && <DragHandle />;
    //   },
    // },
  ];

  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   const { status_type } = data[newIndex];

  //   let shouldNotReorder = status_type !== 'not-selected' ? false : true;

  //   if (shouldNotReorder) {
  //     return setData((prevData) => prevData);
  //   }

  //   if (oldIndex !== newIndex) {
  //     const newData = arrayMoveImmutable(
  //       data.slice(),
  //       oldIndex,
  //       newIndex
  //     ).filter((el) => !!el);

  //     dispatch(updateStreamsOrder(newData));
  //   }
  // };

  // const DraggableContainer = (props) => (
  //   <SortableBody
  //     useDragHandle
  //     disableAutoscroll
  //     helperClass='row-dragging'
  //     onSortEnd={onSortEnd}
  //     {...props}
  //   />
  // );

  // const DraggableBodyRow = ({ className, style, ...restProps }) => {
  //   const index = data.findIndex(
  //     (x) => x.position == restProps['data-row-key']
  //   );

  //   return <SortableItem index={index} {...restProps} />;
  // };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Row justify='center' className='production-manager-parent-row'>
      <Col xxl={24} xl={24} lg={24} md={24} className='heading-col'>
        <Row align='middle' gutter={[30, 0]}>
          <Col>
            <div>
              <span className='heading-span'>Streams Register</span>
            </div>
          </Col>
          <Col>
            {canUpdate && (
              <Button
                icon={<PlusOutlined />}
                // className='add-status-btn'
                size='small'
                disabled={isAdding}
                onClick={handleAdd}
              >
                STREAM
              </Button>
            )}
          </Col>
          <Col>
            <Button
              onClick={() => {
                handleSetStorage('search', {});
                setSearch({});
                debouncedCallApi();
              }}
              icon={<CloseOutlined />}
              className='add-user-btn'
              size='middle'
            >
              Clear Filters
            </Button>
          </Col>
        </Row>
      </Col>

      <Col lg={23} xl={18} xxl={17} md={24} style={{ minHeight: '70vh' }}>
        <Form
          form={form}
          component={false}
          onFinish={handleFinish}
          className='table-style'
        >
          <Table
            className='special-table activity-type-table project-statuses-table'
            components={{
              body: {
                cell: EditableCell,
                // wrapper: DraggableContainer,
                // row: DraggableBodyRow,
              },
            }}
            scroll={{ x: !fetchingLoading }}
            rowClassName='editable-row'
            dataSource={data}
            loading={fetchingLoading}
            columns={mergedColumns}
            pagination={false}
            rowKey='position'
          />
        </Form>
      </Col>
    </Row>
  );
};

export default StreamsTable;
