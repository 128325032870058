import * as constants from './constants';

export const fetchQuoteStatuses = (data) => {
  return {
    type: constants.FETCH_QUOTE_STATUSES,
    data,
  };
};

export const fetchQuoteStatusesSuccess = (data) => {
  return {
    type: constants.FETCH_QUOTE_STATUSES_SUCCESS,
    data: data,
  };
};

export const fetchQuoteStatusesError = () => {
  return {
    type: constants.FETCH_QUOTE_STATUSES_ERROR,
  };
};

export const updateQuoteStatuses = (data) => {
  return {
    type: constants.UPDATE_QUOTE_STATUSES,
    data: data,
  };
};

export const updateQuoteStatusesSuccess = (data) => {
  return {
    type: constants.UPDATE_QUOTE_STATUSES_SUCCESS,
    data: data,
  };
};

export const updateQuoteStatusesError = () => {
  return {
    type: constants.UPDATE_QUOTE_STATUSES_ERROR,
  };
};

export const updateQuoteStatusesOrder = (data) => {
  return {
    type: constants.UPDATE_QUOTE_STATUS_ORDER,
    data: data,
  };
};

export const updateQuoteStatusesOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_QUOTE_STATUS_ORDER_SUCCESS,
    data: data,
  };
};

export const updateQuoteStatusesOrderError = () => {
  return {
    type: constants.UPDATE_QUOTE_STATUS_ORDER_ERROR,
  };
};

export const deleteQuoteStatuses = (data) => {
  return {
    type: constants.DELETE_QUOTE_STATUSES,
    data: data,
  };
};

export const deleteQuoteStatusesSuccess = (data) => {
  return {
    type: constants.DELETE_QUOTE_STATUSES_SUCCESS,
    data: data,
  };
};

export const deleteQuoteStatusesError = () => {
  return {
    type: constants.DELETE_QUOTE_STATUSES_ERROR,
  };
};

export const createQuoteStatuses = (data) => {
  return {
    type: constants.CREATE_QUOTE_STATUSES,
    data: data,
  };
};

export const createQuoteStatusesSuccess = (data) => {
  return {
    type: constants.CREATE_QUOTE_STATUSES_SUCCESS,
    data: data,
  };
};

export const createQuoteStatusesError = (id) => {
  return {
    type: constants.CREATE_QUOTE_STATUSES_ERROR,
    id: id,
  };
};

export const isQuoteStatusAdding = (data) => {
  return {
    type: constants.IS_QUOTE_STATUSES_ADD_UPDATE,
    data,
  };
};

export const setQuoteCompletedStatus = (data) => {
  return {
    type: constants.SET_QUOTE_COMPLETED_STATUS,
    data,
  };
};

export const fetchSubObservations = (data) => {
  return {
    type: constants.FETCH_SUB_OBSERVATION,
    data,
  };
};

export const fetchSubObservationsSuccess = (data) => {
  return {
    type: constants.FETCH_SUB_OBSERVATION_SUCCESS,
    data: data,
  };
};

export const fetchSubObservationsError = () => {
  return {
    type: constants.FETCH_SUB_OBSERVATION_ERROR,
  };
};

export const updateQuoteCompletedStatuses = (data) => {
  return {
    type: constants.UPDATE_QUOTE_COMPLETED_STATUS,
    data: data,
  };
};

export const updateSubObservation = (data) => {
  return {
    type: constants.UPDATE_SUB_OBSERVATION,
    data: data,
  };
};

export const updateSubObservationSuccess = (data) => {
  return {
    type: constants.UPDATE_SUB_OBSERVATION_SUCCESS,
    data: data,
  };
};

export const updateSubObservationError = () => {
  return {
    type: constants.UPDATE_SUB_OBSERVATION_ERROR,
  };
};

export const updateSubObservationOrder = (data) => {
  return {
    type: constants.UPDATE_SUB_OBSERVATION_ORDER,
    data: data,
  };
};

export const updateSubObservationOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_SUB_OBSERVATION_ORDER_SUCCESS,
    data: data,
  };
};

export const updateSubObservationOrderError = () => {
  return {
    type: constants.UPDATE_SUB_OBSERVATION_ORDER_ERROR,
  };
};

export const createSubObservation = (data) => {
  return {
    type: constants.CREATE_SUB_OBSERVATION,
    data: data,
  };
};

export const createSubObservationSuccess = (data) => {
  return {
    type: constants.CREATE_SUB_OBSERVATION_SUCCESS,
    data: data,
  };
};

export const createSubObservationError = (id) => {
  return {
    type: constants.CREATE_SUB_OBSERVATION_ERROR,
    id: id,
  };
};

export const isSubObservationAdding = (data) => {
  return {
    type: constants.IS_SUB_OBSERVATION_ADD_UPDATE,
    data,
  };
};
