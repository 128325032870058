import React, { useEffect, useState } from 'react';
import { Table, Input, Col, Row, Select } from 'antd';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import _ from 'lodash';
import { capitalizeFirstLetterofEachWord } from '../../globalUtils/capitalizeFirstLetterofEachWord';
import { genderOptions } from '../UserManager/userDetails/utils';
import { userTypeOptions } from '../UserManager/utils';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

function StudentsTable({ enrollments }) {
  const [data, setData] = useState(enrollments);
  const [sortedInfo, setSortedInfo] = useState(null);
  // images contains all attachments of item
  const [search, setSearch] = useState({});
  const [showTable, setShowTable] = useState(true);
  const [req, setReq] = useState(false);
  const navigate = useNavigate();

  //   const filterData = ({ values, classes, setData }) => {
  const filterData = () => {
    let siteFilteredData = enrollments.filter((entry) =>
      search?.user_id?.length > 0
        ? entry.user_id
            .toLocaleLowerCase()
            .includes(search?.user_id?.toLocaleLowerCase())
        : data
    );

    let coachFilteredData = siteFilteredData.filter((entry) => {
      let coachInputLength = search?.first_name?.length;
      if (coachInputLength > 0) {
        const { first_name } = entry;
        return first_name
          .toLocaleLowerCase()
          .includes(search?.first_name?.toLocaleLowerCase());
      } else return siteFilteredData;
    });

    let classFilteredData = coachFilteredData.filter((entry) => {
      let inputLength = search?.last_name?.length;
      if (inputLength > 0) {
        const { last_name } = entry;

        return last_name
          .toLocaleLowerCase()
          .includes(search?.last_name?.toLocaleLowerCase());
      } else return coachFilteredData;
    });

    let topicFilteredData = classFilteredData.filter((entry) => {
      let length = search?.email?.length;
      if (length > 0) {
        const { email } = entry;
        return email
          .toLocaleLowerCase()
          .includes(search?.email?.toLocaleLowerCase());
      } else return classFilteredData;
    });

    let npoFilteredData = topicFilteredData.filter((entry) => {
      let length = search?.roles?.length;
      if (length > 0) {
        const { roles } = entry;
        return roles.includes(search?.roles);
      } else return topicFilteredData;
    });

    let typeFilteredData = npoFilteredData.filter((entry) => {
      let length = search?.gender?.length;
      if (length > 0) {
        const { gender } = entry;

        return gender.includes(search?.gender);
      } else return npoFilteredData;
    });

    setData(typeFilteredData);
  };

  useEffect(() => {
    filterData();
  }, [req]);

  useEffect(() => {
    setData(enrollments);
  }, [enrollments]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const searchInput = (key) => (
    <Input
      placeholder={''}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedCallApi();
      }}
    />
  );

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>User ID</div>
            <div>{searchInput('user_id')}</div>
          </div>
        );
      },
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: (a, b) =>
        a.user_id?.toLowerCase()?.localeCompare(b.user_id?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'user_id' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      render: (user_id) => <div>{user_id}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>first name</div>
            <div>{searchInput('first_name')}</div>
          </div>
        );
      },
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) =>
        a.first_name?.toLowerCase()?.localeCompare(b.first_name?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'first_name' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      render: (first_name) => <div>{first_name}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>last name</div>
            <div>{searchInput('last_name')}</div>
          </div>
        );
      },
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) =>
        a.last_name?.toLowerCase()?.localeCompare(b.last_name?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'last_name' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      render: (last_name) => <div>{last_name}</div>,
    },

    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>email</div>
            <div>{searchInput('email')}</div>
          </div>
        );
      },
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) =>
        a.email?.toLowerCase()?.localeCompare(b.email?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      ellipsis: true,
      width: '25%',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>user Roles</div>
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select Project'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                onChange={(e) => {
                  setSearch({
                    ...search,
                    roles: e,
                  });
                  debouncedCallApi();
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {userTypeOptions?.map(({ label, value }) => {
                  return (
                    <Option value={value} label={label}>
                      {label || ''}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'roles',
      key: 'roles',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'roles' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      align: 'center',
      render: (roles) => {
        let rolesString = '';

        if (roles) {
          rolesString = roles
            ?.map((el) => capitalizeFirstLetterofEachWord(el))
            ?.join(', ');
        }

        return <div>{rolesString}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>gender</div>
            <Select
              style={{
                width: '100%',
                minHeight: '25px',
                fontSize: '12px',
                textTransform: 'capitalize',
              }}
              onClick={(e) => e.stopPropagation()}
              allowClear={true}
              readOnly={true}
              className='Select Gender'
              dropdownClassName='select-dropdown-custom'
              getPopupContainer={() =>
                document.getElementsByClassName(
                  'ant-layout-content layout-content site-layout-background custom-textarea'
                )[0]
              }
              showSearch
              placeholder=''
              optionFilterProp='children'
              onChange={(e) => {
                setSearch({
                  ...search,
                  gender: e,
                });
                debouncedCallApi();
              }}
              optionLabelProp='label'
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {genderOptions?.map(({ label, value }) => {
                return (
                  <Option value={value} label={label}>
                    {label || ''}
                  </Option>
                );
              })}
            </Select>
          </div>
        );
      },
      dataIndex: 'gender',
      key: 'gender',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'gender' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (gen) => {
        let gender = '';
        if (gen === 'M') {
          gender = 'Male';
        } else if (gen === 'F') {
          gender = 'Female';
        }
        return <div>{gender}</div>;
      },
    },
    {
      title: null,
      dataIndex: '',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        const { _id } = record;
        return (
          <EditIcon
            width={18}
            height={18}
            className='custom-icon'
            onClick={() => navigate(`/settings/users-manager/update/${_id}`)}
          />
        );
      },
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    if (sorter.field === 'roles') {
      return;
    }
    setSortedInfo(sorter);
  };

  return (
    <Row className='revisions-parent-row' style={{ minHeight: '20vh' }}>
      <Col span={24} className='heading-col'>
        <Row align='middle'>
          <Col>
            <div className='revisions-heading-div'>
              {/* <div onClick={() => setShowTable(!showTable)}>
                {showTable ? (
                  <UpCircleOutlined className='revision-up-down-icon' />
                ) : (
                  <DownCircleOutlined className='revision-up-down-icon' />
                )}
              </div> */}
              <div>
                <span className='heading-span'>
                  Enrollments {data.length > 0 && `(${data.length})`}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>

      {showTable && (
        <Col span={24}>
          <Table
            //   scroll={{ x: 1200 }}
            className='special-table ims-doc-table users-manager-table'
            rowKey={(record) => record._id}
            // rowClassName='editable-row'
            dataSource={data}
            columns={columns}
            onChange={handleChange}
            pagination={false}
            expandable={{
              showExpandColumn: false,
            }}
          />
        </Col>
      )}
    </Row>
  );
}

export default StudentsTable;
