import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';
import moment from 'moment';

export function* handleCreateClassSession({ data }) {
  try {
    const response = yield axios.post(`${baseURL}/api/v2/admin/slots`, data, {
      headers: {
        'content-type': 'application/json',
      },
    });
    const { message } = response.data;
    if (response.data?.data?.slot) {
      AntdAlert.success(message);
      yield put(actions.createClassSessionSuccess(response.data?.data?.slot));
    }
  } catch (error) {
    yield put(actions.createClassSessionError());
    AntdAlert.error(error?.response?.data?.message || 'Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateClassSession({ data }) {
  try {
    const response = yield axios.put(`${baseURL}/api/v2/admin/slots`, data, {
      headers: {
        'content-type': 'application/json',
      },
    });
    // const { success, message } = response.data;
    if (response.data?.data?.slot) {
      AntdAlert.success('Slot updated successfully!');
      const { slot } = response.data.data;
      yield put(actions.updateClassSessionSuccess(slot));
    }
    // else if (!success) {
    //   AntdAlert.error(response.data?.errors[0]);
    //   yield put(actions.updateClassSessionError());
    //   errorHandler(response.data);
    // }
  } catch (error) {
    console.log(error);
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleFetchAllClassSessions({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
    if (key?.includes('time'))
      search[key] = moment(search[key]).format('YYYY-MM-DD');
  });

  try {
    const response = yield axios.get(`${baseURL}/api/v2/admin/class-sessions`, {
      params: {
        ...rest,
        ...(Object.keys(search).length > 0 && {
          ...search,
        }),
      },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchAllClassSessionsSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.fetchAllClassSessionsError());
    }
  } catch ({ response }) {
    yield put(actions.fetchAllClassSessionsError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleDeleteClassSession({ data }) {
  try {
    const response = yield axios.delete(`${baseURL}/api/v2/npos/${data.id}`);
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteClassSessionSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteClassSessionError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteClassSessionError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleFetchClassSession({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/admin/class-sessions/fetch-by-id/${data}`
    );

    if (response.data?.data?.classSession) {
      // const { permissions, ...res } = response.data.data;
      yield put(actions.fetchClassSessionSuccess(response.data.data));
    } else {
      yield put(actions.fetchClassSessionError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchClassSessionError());
    console.log(error?.response?.data?.message || 'Something went wrong!');
  }
}

export function* handleDeleteClassSessionAttach({ data }) {
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/npos/delete_npo_attachment`,
      {
        params: {
          id: data,
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteClassSessionAttachSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteClassSessionAttachError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteClassSessionAttachError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleUpdateClassSessionNotifications({ data }) {
  try {
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }
    const response = yield axios.put(`${baseURL}/api/v2/npos/${data.id}`, fd, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.updateClassSessionNotificationsSuccess(response.data.data)
      );
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.updateClassSessionNotificationsError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateEnrollments({ data }) {
  try {
    const response = yield axios.post(`${baseURL}/api/v2/admin/slot-bookings`, data, {
      headers: {
        'content-type': 'application/json',
      },
    });
    // const { success, message } = response.data;
    if (response.data?.data?.slot) {
      AntdAlert.success('Slot updated successfully!');
      const { slot } = response.data.data;
      yield put(actions.updateSlotEnrollmentsSuccess(slot));
    } else {
      yield put(actions.updateSlotEnrollmentsError());
    }
  } catch (error) {
    console.log(error);
    yield put(actions.updateSlotEnrollmentsError());
    AntdAlert.error('Something went wrong!');
  }
}

export function* watcherClassSessionsSaga() {
  yield takeEvery(constants.CREATE_CLASS_SESSIONS, handleCreateClassSession);
  yield takeEvery(constants.UPDATE_CLASS_SESSIONS, handleUpdateClassSession);
  yield takeEvery(constants.FETCH_THE_CLASS_SESSIONS, handleFetchClassSession);
  yield takeEvery(
    constants.FETCH_ALL_CLASS_SESSIONS,
    handleFetchAllClassSessions
  );
  yield takeEvery(constants.UPDATE_ENROLLMENTS, handleUpdateEnrollments);
}
