import React, { useEffect, useState } from 'react';
import { Table as ATable, Input, Popconfirm, Space } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';

import { useDispatch, useSelector } from 'react-redux';
import * as actions from './redux/actions';
import SpinnerComponent from '../../components/spinner';
import { useNavigate } from 'react-router-dom';

const Table = ({ canUpdate, canDelete }) => {
  const [data, setData] = useState([]);
  const [currentDelRecord, setCurrentDelRecord] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allForms, fetchLoading, deleteLoading } = useSelector(
    (state) => state.formSetupReducer
  );

  useEffect(() => {
    dispatch(actions.fetchAllFormSetups({}));
  }, []);

  useEffect(() => {
    setData(allForms);
  }, [allForms]);

  const searchInput = () => (
    <Input
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        const { value } = e.target;
        if (value) {
          let result = allForms.filter((obj) =>
            obj.contract_name
              .toLowerCase()
              .includes(value?.trim()?.toLowerCase())
          );
          setData(result);
        } else {
          setData(allForms);
        }
      }}
    />
  );

  const handleNavigate = (id) =>
    navigate(`/settings/form-manager/form-setup/${id}`);

  const handleDelete = (record) => {
    setCurrentDelRecord(record);
    dispatch(actions.deleteFormSetup(record));
  };

  const columns = [
    {
      title: () => {
        return (
          <div align='center' className='select-parent-div '>
            <div className='special-table-header-text columns-text'>
              FORM NAME
            </div>
            <div>{searchInput()}</div>
          </div>
        );
      },
      dataIndex: 'contract_name',
      key: 'contract_name',
      sorter: (a, b) => a.contract_name.localeCompare(b.contract_name),
      ellipsis: true,
      width: 400,
      render: (_, { _id, contract_name }) => (
        <div
          onClick={() => handleNavigate(_id)}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 450,
          }}
        >
          {contract_name}
        </div>
      ),
    },
    {
      title: null,
      dataIndex: '',
      key: 'action',
      // width: '10%',
      align: 'center',
      render: (_, record) => {
        if (!canUpdate && !canDelete) {
          return;
        }

        return deleteLoading ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align='baseline'>
            {canUpdate && (
              <EditIcon
                width={18}
                height={18}
                className='custom-icon'
                onClick={() => {
                  setTimeout(() => {
                    dispatch(actions.setFormSetupIsEdit(true));
                  }, 1000);
                  handleNavigate(record._id);
                }}
              />
            )}
            {canDelete && (
              <Popconfirm
                title='Are you sure you want to delete this form?'
                placement='topRight'
                onConfirm={() => handleDelete(record)}
                okText='Yes'
                cancelText='No'
              >
                <DeleteIcon width={18} height={18} className='custom-icon' />
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className='table-main-div-holder'>
        <ATable
          style={{ maxWidth: '700px', minHeight: '580px', marginLeft: '5%' }}
          className='special-table doc-type-table form-setup-table'
          columns={columns}
          scroll={{ x: !fetchLoading }}
          dataSource={data}
          pagination={false}
          sortUpIcon={<UpOutlined />}
          sortDownIcon={<DownOutlined />}
          loading={fetchLoading}
        />
      </div>
    </>
  );
};
export default Table;
