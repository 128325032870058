import React, { useState } from 'react';
import { Col, Row, Form, Input, InputNumber } from 'antd';
import Notes from './Notes';
import Attachments from './attachments';
import EditApplication from './editApplication';
import FormSelect from './FormSelect';

const emailRegex =
  /^[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const ExpandedRow = ({
  fields,
  formRef,
  onFinish,
  isRevisionUpdateCase,
  setExpandedRowKeys,
  images,
  setImages,
  attachPopupVisible,
  setAttachPopupVisible,
  currentUpdateObj,
  shouldDisabled,
  record,
}) => {
  let classname = `${isRevisionUpdateCase ? 'ims-doc-readonly-input' : ''}`;

  let getPlaceholder = (placeholder) =>
    isRevisionUpdateCase ? '' : placeholder;

  const [formData, setFormData] = useState(record?.form || null);

  return (
    <Row gutter={[0, 20]}>
      <Col span={12}>
        <Row gutter={[10, 20]} className='static-fields-row'>
          <Col span={6}>
            <Form.Item
              className={classname}
              label='CONTACT NO'
              name='contactNumber'
              key='contactNumber'
              labelCol={{ span: 24 }}
            >
              <InputNumber
                placeholder={getPlaceholder('Contact No')}
                readOnly={isRevisionUpdateCase}
                disabled={isRevisionUpdateCase}
                size='small'
                controls={false}
                width='100%'
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              className={classname}
              label='EMAIL ADDRESS'
              name='email'
              key='email'
              labelCol={{ span: 24 }}
              rules={[
                {
                  pattern: emailRegex,
                  message: 'The input is not valid Email!',
                },
              ]}
            >
              <Input
                placeholder={getPlaceholder('Email Address')}
                readOnly={isRevisionUpdateCase}
                disabled={isRevisionUpdateCase}
                size='small'
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              className={classname}
              label='ADDRESS'
              name='address'
              key='address'
              labelCol={{ span: 24 }}
            >
              <Input
                placeholder={getPlaceholder('Address')}
                readOnly={isRevisionUpdateCase}
                disabled={isRevisionUpdateCase}
                size='small'
              />
            </Form.Item>
          </Col>
          {isRevisionUpdateCase && (
            <Col span={24}>
              <Notes
                record={record}
                // canUpdate={canUpdate}
                // canDelete={canDelete}
              />
            </Col>
          )}
        </Row>
      </Col>
      {isRevisionUpdateCase && (
        <Col span={12}>
          <Row gutter={[0, 20]} className='static-fields-row'>
            <Col span={18}>
              <FormSelect
                formData={formData}
                setFormData={setFormData}
                record={record}
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingLeft: '10px',
              }}
            >
              <Attachments />
            </Col>
          </Row>
        </Col>
      )}
      {formData !== null && isRevisionUpdateCase && (
        <Col span={24}>
          <EditApplication
            formData={formData}
            setFormData={setFormData}
            record={record}
          />
        </Col>
      )}
    </Row>
  );
};

export default ExpandedRow;
