import React, { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Space,
  Popconfirm,
  message,
  Row,
  Col,
  Button,
} from 'antd';
import {
  CloseOutlined,
  DownOutlined,
  PlusOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNpo,
  fetchAllNpos,
  setNpoDetailsIsEdit,
  setStorage,
} from './redux/actions';
import SpinnerComponent from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
// import { baseURL } from "../../../../globalUtils/axiosConfig";
import { baseURL } from '../../globalUtils/axiosConfig';
import _ from 'lodash';
// import CustomPagination from '../../components/customPagination/CustomPagination';
// import moment from 'moment';
// import CustomCalendar from '../../components/calendar';
// import AMultiSelect from '../../components/AMultiSelect';

const BooksTable = ({ canUpdate, canDelete }) => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [currentDelRecord, setCurrentDelRecord] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [page, setPage] = useState(() => 1);
  // const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  // const [statusCheckedList, setStatusCheckedList] = useState([]);
  // const [regionsCheckedList, setRegionsCheckedList] = useState([]);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  // const [date, setDate] = useState(null);
  // const [npoStatuses, SetNpoStatuses] = useState([]);
  // const [npoRegions, SetNpoRegions] = useState([]);

  const { allNpos, fetchNposLoading, deleteNpoLoading, totalRecords, ...rest } =
    useSelector((state) => state.npoSetupReducer);

  // const { allNpoStatuses } = useSelector((state) => state.npoStatusReducer);

  // const { allNpoRegions } = useSelector((state) => state.npoRegionsReducer);

  // useEffect(() => {
  //   let updatedStatuses = allNpoStatuses.map((el) => {
  //     const { id, name } = el;
  //     return { value: id, label: name };
  //   });
  //   SetNpoStatuses(updatedStatuses);
  // }, [allNpoStatuses]);

  // useEffect(() => {
  //   let updatedRegions = allNpoRegions.map((el) => {
  //     const { id, name } = el;
  //     return { value: id, label: name };
  //   });
  //   SetNpoRegions(updatedRegions);
  // }, [allNpoRegions]);

  useEffect(() => {
    if (localStorage.getItem('booksManagerState')) {
      const localStorageData = JSON.parse(
        localStorage.getItem('booksManagerState')
      );

      if (localStorageData?.search?.name) {
        setTimeout(() => {
          setSearch(localStorageData.search);
          dispatch(setStorage(localStorageData));

          debouncedCallApi();
        }, 2000);
      }
    }
  }, []);

  useEffect(() => {
    console.log('zee search', search);
    let updatedData = allNpos.filter((el) =>
      el.name?.toLowerCase().includes(search?.name?.toLowerCase().trim())
    );

    console.log('zee updatedData', updatedData);

    setDataSource(updatedData);
    // if (page && resultPerPage && page > 0 && resultPerPage > 0) {
    //   dispatch(
    //     fetchAllNpos({
    //       page: page,
    //       per_page: resultPerPage,
    //       search,
    //       sort: sortedInfo?.order && sortedInfo?.columnKey,
    //       order: sortedInfo?.order
    //         ? sortedInfo?.order === 'ascend'
    //           ? 'asc'
    //           : 'desc'
    //         : null,
    //       show_by_default: true,
    //     })
    //   );
    // }
  }, [req]);

  // useEffect(() => {
  //   let paramsPage = 1;
  //   if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
  //     dispatch(
  //       fetchAllNpos({
  //         page: paramsPage,
  //         per_page: resultPerPage,
  //         search,
  //         show_by_default: true,
  //       })
  //     );
  //     setPage(1);
  //   }
  // }, [defaultPageOneReq]);

  // useEffect(() => {
  //   debouncedCallApi();
  // }, [sortedInfo]);

  useEffect(() => {
    dispatch(fetchAllNpos({ search: {} }));

    // return () => {
    //   dispatch(isAddingAction(false));
    // };
  }, []);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setDataSource(allNpos);
  }, [allNpos]);

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };
  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    if (datePickerOpened) {
      return;
    }
    setSortedInfo(sorter);
  };

  const handleDelete = (record) => {
    const books = record?.learning_activity_books?.length || 0;
    const curriculums = record?.curriculums?.length || 0;

    if (books > 0 || curriculums > 0) {
      message.error(
        'This book is associated with learning activities or curriculums.'
      );
      return;
    } else {
      setCurrentDelRecord(record);
      dispatch(deleteNpo({ _id: record._id }));
    }
  };

  const handleSetStorage = (key, value) => {
    dispatch(setStorage({ [key]: value }));
  };

  const handleSearch = (key, value, func = null) => {
    let updatedState = {
      ...search,
      [key]: value,
    };

    setSearch(updatedState);

    if (typeof func === 'function') {
      func();
    }

    let updatedData = allNpos.filter((el) =>
      el.name?.toLowerCase().includes(value?.toLowerCase().trim())
    );

    setDataSource(updatedData);

    dispatch(setStorage({ search: updatedState }));
    handleSetStorage('search', updatedState);
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      value={search[key]}
      onChange={(e) => {
        handleSearch(key, e.target.value, debouncedCallApi);
      }}
    />
  );

  const handleNavigate = (id) => navigate(`/books/update/${id}`);

  const handleDownload = (url) => {
    window.open(url, '_blank');
  };

  const columns = [
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Name</div>
            <div>{searchInput('name')}</div>
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) =>
        a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase()),
      // sorter: true,
      // sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      ellipsis: true,
      width: '30%',
      render: (name, { _id }) => (
        <div onClick={() => handleNavigate(_id)}>{name}</div>
      ),
    },
    {
      // title: () => {
      //   return (
      //     <div align='top' style={{ width: '100%' }}>
      //       <div className='special-table-header-text'>Region</div>
      //       <div style={{ width: '100%' }}>
      //         <AMultiSelect
      //           dropdownClassName='status-selector'
      //           debouncedCallApi={debouncedCallApi}
      //           disabled={false}
      //           placeholder=''
      //           selectTags={regionsCheckedList}
      //           onSelectChange={(value) =>
      //             handleSelectOnchange(
      //               value,
      //               npoRegions,
      //               'npo_region_id',
      //               setRegionsCheckedList
      //             )
      //           }
      //           data={npoRegions}
      //           filterOption={(input, option) => {
      //             return (
      //               String(option?.label)
      //                 .toLowerCase()
      //                 .indexOf(input.toLowerCase()) >= 0
      //             );
      //           }}
      //         />
      //       </div>
      //     </div>
      //   );
      // },
      title: 'PDF',
      dataIndex: 'pdf_path',
      key: 'pdf_path',
      // sorter: (a, b) => a.pdf_path.localeCompare(b.pdf_path),
      sorter: false,
      sortOrder: sortedInfo?.columnKey === 'pdf_path' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (pdf_path) => {
        let url = null;
        if (pdf_path?.source === 'remote') {
          url = pdf_path?.url;
        } else if (pdf_path?.source === 'file') {
          url = `${baseURL}/${pdf_path?.url}`;
        }

        return (
          <div onClick={() => url && handleDownload(url)}>
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {url && 'View'}
            </span>
          </div>
        );
      },
    },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' style={{ width: '100%' }}>
    //         <div className='special-table-header-text'>
    //           DIRECTOR CONTACT NAME
    //         </div>
    //         <div>{searchInput('contact_name')}</div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'contact_name',
    //   key: 'contact_name',
    //   sorter: true,
    //   sortOrder: sortedInfo?.columnKey === 'contact_name' && sortedInfo?.order,
    //   ellipsis: true,
    //   width: '20%',
    //   render: (contact_name, { id }) => (
    //     <div onClick={() => handleNavigate(id)}>{contact_name}</div>
    //   ),
    // },

    // {
    //   title: () => {
    //     return (
    //       <div
    //         align='top'
    //         style={{ width: '100%' }}
    //         className='select-parent-div'
    //       >
    //         <div className='special-table-header-text'>
    //           DIRECTOR CONTACT NUMBER
    //         </div>
    //         <div>{searchInput('contact_no')}</div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'contact_no',
    //   key: 'contact_no',
    //   sorter: true,
    //   sortOrder: sortedInfo?.columnKey === 'contact_no' && sortedInfo?.order,
    //   ellipsis: true,
    //   width: '20%',
    //   render: (contact_no, { id }) => (
    //     <div
    //       style={{
    //         whiteSpace: 'break-spaces',
    //       }}
    //       onClick={() => handleNavigate(id)}
    //     >
    //       {contact_no.length > 180
    //         ? `${contact_no.substring(0, 180) + ' ...'}`
    //         : contact_no}
    //     </div>
    //   ),
    // },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' style={{ width: '100%' }}>
    //         <div className='special-table-header-text'>Status</div>
    //         <div style={{ width: '100%' }}>
    //           <AMultiSelect
    //             dropdownClassName='status-selector'
    //             debouncedCallApi={debouncedCallApi}
    //             disabled={false}
    //             placeholder=''
    //             selectTags={statusCheckedList}
    //             onSelectChange={(value) =>
    //               handleSelectOnchange(
    //                 value,
    //                 npoStatuses,
    //                 'status_id',
    //                 setStatusCheckedList
    //               )
    //             }
    //             data={npoStatuses}
    //             filterOption={(input, option) => {
    //               return (
    //                 String(option?.label)
    //                   .toLowerCase()
    //                   .indexOf(input.toLowerCase()) >= 0
    //               );
    //             }}
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'status_id',
    //   key: 'status_id',
    //   sorter: true,
    //   sortOrder: sortedInfo?.columnKey === 'status_id' && sortedInfo?.order,
    //   ellipsis: true,
    //   width: '15%',
    //   align: 'center',
    //   render: (statusname, { status, id }) => {
    //     const { name, color } = status;
    //     return (
    //       <Tag
    //         onClick={() => handleNavigate(id)}
    //         color={color}
    //         style={{
    //           color: '#444',
    //           width: '84px',
    //           height: '20px',
    //           textAlign: 'center',
    //           fontSize: '12px',
    //           whiteSpace: 'nowrap',
    //           overflow: 'hidden',
    //           textOverflow: 'ellipsis',
    //         }}
    //       >
    //         {name}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: null,
      dataIndex: '',
      key: 'action',
      width: '30%',
      align: 'center',
      render: (_, record) => {
        // if (!canUpdate && !canDelete) {
        //   return;
        // }
        return deleteNpoLoading && record._id === currentDelRecord._id ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align='baseline'>
            {/* {canUpdate && ( */}
            <EditIcon
              width={18}
              height={18}
              className='custom-icon'
              onClick={() => {
                handleNavigate(record._id);
                dispatch(setNpoDetailsIsEdit(true));
              }}
            />
            {/* // )} */}
            {/* {canDelete && ( */}
            <Popconfirm
              title='Are you sure you want to delete this book?'
              onConfirm={() => handleDelete(record)}
              okText='Yes'
              cancelText='No'
            >
              <DeleteIcon width={18} height={18} className='custom-icon' />
            </Popconfirm>
            {/* )} */}
          </Space>
        );
      },
    },
  ];

  return (
    <div className='page-top-div'>
      <span className='text-span'>Books</span>
      <Row className='production-manager-parent-row'>
        <Col span={24} className='heading-col'>
          <Row align='middle' gutter={[50, 0]} justify='space-between'>
            <Col>
              <Row align='middle' gutter={[30, 0]}>
                <Col>
                  <div>
                    <span className='heading-span'>Books Register</span>
                  </div>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      navigate('/books/create');
                      dispatch(setNpoDetailsIsEdit(true));
                    }}
                    icon={<PlusOutlined />}
                    className='add-user-btn'
                    size='middle'
                    style={{
                      width: '105%',
                    }}
                  >
                    Book
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      handleSetStorage('search', {});
                      setSearch({ name: '' });
                      debouncedCallApi();
                    }}
                    icon={<CloseOutlined />}
                    className='add-user-btn'
                    size='middle'
                  >
                    Clear Filters
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            className='special-table'
            columns={columns}
            scroll={{ x: !fetchNposLoading }}
            onChange={handleChange}
            dataSource={dataSource}
            pagination={false}
            sortUpIcon={<UpOutlined />}
            sortDownIcon={<DownOutlined />}
            loading={fetchNposLoading}
          />
          {/* <CustomPagination
                page={page}
                setPage={setPage}
                resultPerPage={resultPerPage}
                setResultPerPage={setResultPerPage}
                debouncedCallApi={debouncedCallApi}
                totalRecord={totalRecords}
              /> */}
        </Col>
      </Row>
    </div>
  );
};

export default BooksTable;
