import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import Table from './table';

const Comments = () => {
  // const { user } = useSelector((state) => state.authReducer);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);

  return (
    <div className='page-top-div'>
      <span className='text-span'>Comments</span>
      <Table canUpdate={canUpdate} canDelete={canDelete} />
    </div>
  );
};

export default Comments;
