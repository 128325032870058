import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Spin } from 'antd';
import { ReactComponent as BackIcon } from '../../../assets/back-icon.svg';
import DetailsSection from './detailsSection';
import { fetchNpo, npoDetailCleanupFunc } from '../redux/actions';
// import { fetchNpoStatuses } from '../../NpoStatuses/redux/actions';
import './index.less';
import axios from 'axios';
import { baseURL } from '../../../globalUtils/axiosConfig';
import { fetchAllUsers } from '../../UserManager/redux/actions';
import StudentBookingSection from './enrollmentsSection';
// import { fetchNpoRegions } from '../../NpoRegions/redux/actions';

const SlotDetail = () => {
  const [isNpoUpdate, setIsNpoUpdate] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [canUpdate, setCanUpdate] = useState(true);
  const [rooms, setRooms] = useState([]);
  const [assessorOptions, setAssessorOptions] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const { currentNpoObj, fetchNpoDetailLoading } = useSelector(
    (state) => state.slotsReducer
  );
  const { allUsers } = useSelector((state) => state.userManagerReducer);

  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (currentNpoObj?._id) {
      setIsNpoUpdate(true);
    }
  }, [currentNpoObj]);

  useEffect(() => {
    if (params?.id) {
      setIsNpoUpdate(true);
      dispatch(fetchNpo(params?.id));
    } else if (pathname === '/profile') {
      setIsNpoUpdate(true);
      dispatch(fetchNpo(user.id));
    }

    return () => {
      dispatch(npoDetailCleanupFunc());
      // dispatch(fetchNpoRegions());
    };
  }, [params.id]);

  // useEffect(() => {
  //   if (user?.permissions?.npo_setup?.add_edit) {
  //     setCanUpdate(true);
  //   }
  // }, [user]);

  const fetchRooms = async () => {
    const response = await axios.get(`${baseURL}/api/v2/admin/rooms`);
    const rooms = response.data?.data?.rooms || [];

    const updatedRooms = rooms.map((item) => ({
      label: item.code,
      value: item._id,
    }));

    setRooms(updatedRooms);
  };

  const fetchUsers = async () => {
    const response = await axios.get(
      `${baseURL}/api/v2/admin/users/slot-booking-students`
    );
    const users = response.data?.data?.users || [];

    // const updatedRooms = users.map((item) => ({
    //   label: item.code,
    //   value: item._id,
    // }));

    setUsersData(users);
  };

  useEffect(() => {
    // dispatch(fetchNpoStatuses({ search: {} }));
    fetchRooms();
    fetchUsers();
    dispatch(
      fetchAllUsers({
        page: 1,
        page_size: 999999999999,
        search: {
          roles: 'assessor',
        },
        sort: 'first_name',
        order: 1,
      })
    );
  }, []);

  useEffect(() => {
    if (allUsers?.length > 0) {
      const updatedAssessorOptions = allUsers
        // .filter((el) =>
        //   el?.roles?.some((role) => ['assessor', 'teacher'].includes(role))
        // )
        .map((item) => ({
          label: `${item.first_name} ${item.last_name}`,
          value: item._id,
        }));
      setAssessorOptions(updatedAssessorOptions);
    }
  }, [allUsers]);

  return (
    <div className='page-top-div heading-parent-div'>
      <Row justify='space-between'>
        <Col>
          <span className='text-span'>
            {isNpoUpdate ? 'Update Slot' : 'Add Slot'}
          </span>
        </Col>
        <Col>
          <Button
            onClick={() => navigate('/slots')}
            icon={<BackIcon width={24} height={14} />}
            className='back-btn'
            size='middle'
          >
            BACK
          </Button>
        </Col>
      </Row>
      <Spin spinning={fetchNpoDetailLoading}>
        <div className='user-manager-parent-div'>
          <DetailsSection
            isNpoUpdate={isNpoUpdate}
            canUpdate={canUpdate}
            rooms={rooms}
            assessorOptions={assessorOptions}
          />
          {isNpoUpdate && (
            <StudentBookingSection
              canEditEveryThing={true}
              usersData={usersData}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default SlotDetail;
