import * as constants from './constants';

const initialState = {
  allClasses: [],
  currentClassObj: {},
  updateNotLoading: false,
  departmentsList: [],
  createClassLoading: false,
  classIsEdit: false,
  fetchClassesLoading: false,
  deleteClassLoading: false,
  fetchClassDetailLoading: false,
  totalRecords: 0,
  sortedInfo: null,
  page: 1,
  resultPerPage: 10,
  search: {},
};

const classesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CLASS_DETAILS_IS_EDIT:
      return {
        ...state,
        classIsEdit: action.data,
      };

    case constants.CREATE_CLASS:
      return {
        ...state,
        createClassLoading: true,
      };
    case constants.CREATE_CLASS_SUCCESS:
      return {
        ...state,
        createClassLoading: false,
        currentClassObj: action.data,
        classIsEdit: false,
      };
    case constants.CREATE_CLASS_ERROR:
      return {
        ...state,
        createClassLoading: false,
      };
    case constants.UPDATE_CLASS:
      return {
        ...state,
        createClassLoading: true,
      };
    case constants.UPDATE_CLASS_SUCCESS:
      return {
        ...state,
        createClassLoading: false,
        currentClassObj: action.data,
        classIsEdit: false,
      };
    case constants.UPDATE_CLASS_ERROR:
      return {
        ...state,
        createClassLoading: false,
      };

    case constants.FETCH_ALL_CLASSES:
      return {
        ...state,
        fetchClassesLoading: true,
      };
    case constants.FETCH_ALL_CLASSES_SUCCESS:
      const { classes, total } = action.data;
      return {
        ...state,
        allClasses: classes,
        fetchClassesLoading: false,
        totalRecords: total,
      };
    case constants.FETCH_ALL_CLASSES_ERROR:
      return {
        ...state,
        fetchClassesLoading: false,
      };
    case constants.DELETE_CLASS:
      return {
        ...state,
        deleteClassLoading: true,
      };
    case constants.DELETE_CLASS_SUCCESS:
      let updatedClasses = state.allClasses.filter(
        (el) => el._id !== action.data._id
      );
      let totalRecords = state.totalRecords - 1;

      return {
        ...state,
        totalRecords: totalRecords,
        deleteClassLoading: false,
        allClasses: updatedClasses,
      };
    case constants.DELETE_CLASS_ERROR:
      return {
        ...state,
        deleteClassLoading: false,
      };

    case constants.FETCH_CLASS:
      return {
        ...state,
        fetchClassDetailLoading: true,
      };
    case constants.FETCH_CLASS_SUCCESS:
      return {
        ...state,
        currentClassObj: action.data,
        fetchClassDetailLoading: false,
      };
    case constants.FETCH_CLASS_ERROR:
      return {
        ...state,
        fetchClassDetailLoading: false,
      };

    case constants.SET_CLASSES_MANAGER_STATE:
      const { sortedInfo, page, resultPerPage, search } = state;

      const storageData = { sortedInfo, page, resultPerPage, search };

      localStorage.setItem(
        'classesManagerState',
        JSON.stringify({ ...storageData, ...action.data })
      );

      return {
        ...state,
        ...action.data,
      };

    case constants.CLASS_DETAIL_CLEANUP_FUNC:
      return {
        ...state,
        allClasses: [],
        createClassLoading: false,
        currentClassObj: {},
        classIsEdit: false,
        fetchClassesLoading: false,
        deleteClassLoading: false,
        fetchClassDetailLoading: false,
      };
    default:
      return state;
  }
};

export default classesReducer;
