import React, { useEffect, useState } from 'react';
import {
  Col,
  Input,
  Row,
  Form,
  Select,
  DatePicker,
  InputNumber,
  TimePicker,
} from 'antd';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { createNpo, setNpoDetailsIsEdit, updateNpo } from '../redux/actions';
import SpinnerComponent from '../../../components/spinner';
// import { isValidNumber } from '../../../globalUtils/axiosConfig';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
// import { fetchUserDetailsUtils } from '../../UserManager/redux/actions';

const SlotDetailsSection = ({
  isNpoUpdate,
  canUpdate,
  rooms,
  assessorOptions,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentNpo, setCurrentNpo] = useState({
    title: '',
    room: null,
    slot_type: '',
    slot_date: null,
    start_time: null,
    end_time: null,
    deleted_status: '',
    description: '',
    assessor: null,
  });

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;

  const { createNpoLoading, currentNpoObj, npoDetailsIsEdit } = useSelector(
    (state) => state.slotsReducer
  );

  useEffect(() => {
    if (currentNpoObj?._id) {
      setCurrentNpo(currentNpoObj);
      const { start_time, end_time, slot_date, ...res } = currentNpoObj;
      form.setFieldsValue({
        ...res,
        start_time: start_time ? moment(start_time) : null,
        end_time: end_time ? moment(end_time) : null,
        slot_date: slot_date ? moment(slot_date) : null,
      });
    }
  }, [currentNpoObj]);

  useEffect(() => {
    setLoading(createNpoLoading);
  }, [createNpoLoading]);

  useEffect(() => {
    if (pathname.includes('create') && currentNpoObj?._id === undefined) {
      setIsEdit(true);
    } else {
      setIsEdit(npoDetailsIsEdit);
    }
  }, [npoDetailsIsEdit, currentNpoObj]);

  const onFinish = (values) => {
    const { ...res } = values;

    let { slot_date, start_time, end_time } = values;
    slot_date = slot_date.format('YYYY-MM-DD');
    let updatedSlotDate = moment(`${slot_date} 00:00`);
    start_time = moment(`${slot_date} ${start_time.format('HH:mm')}`);
    end_time = moment(`${slot_date} ${end_time.format('HH:mm')}`);

    if (!res.room) {
      delete res.room;
    }
    isNpoUpdate
      ? dispatch(
          updateNpo({
            ...res,
            slot_id: currentNpoObj._id,
            slot_date: updatedSlotDate,
            start_time,
            end_time,
          })
        )
      : dispatch(
          createNpo({
            ...res,
            slot_date: updatedSlotDate,
            start_time,
            end_time,
          })
        );
  };
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const handleSave = () => {
    form.submit();
  };

  // const capitalizeFirstLetter = (str) =>
  //   str && str.charAt(0).toUpperCase() + str.slice(1);

  const startTime = Form.useWatch('start_time', form);

  const SelectFormItem = (props) => {
    const { label, name, required, options, placeholder } = props;
    return (
      <Form.Item
        label={label}
        name={name}
        rules={[
          {
            required: required,
            message: 'Required!',
          },
        ]}
      >
        <Select
          size='middle'
          style={{
            width: '100%',
          }}
          disabled={!isEdit}
          placeholder={placeholder}
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          optionFilterProp='children'
          optionLabelProp='label'
        >
          {options.map(({ label, value }) => {
            return (
              <Option key={value} value={value} label={label}>
                {label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  };

  const disabledDate = (current) => {
    // Can not select Sundays and predefined days
    let day = moment(current).day();
    return [0, 6].includes(day) || current < moment().startOf('day');
  };

  let disabledHoursList = [0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23];
  let minSlotCapacity = currentNpoObj?._id
    ? currentNpoObj?.slot_bookings?.length
    : 0;

  return (
    <>
      <Row gutter={[0, 20]}>
        <Col span={24} className='heading-col'>
          <Row align='middle' justify='space-between'>
            <Col>
              <div>
                <span className='heading-span'>SLOT Details</span>
              </div>
            </Col>
            <Col>
              {isEdit ? (
                loading ? (
                  <SpinnerComponent fontSize={14} />
                ) : (
                  <SaveIcon width={16} height={16} onClick={handleSave} />
                )
              ) : (
                canUpdate && (
                  <EditIcon
                    width={16}
                    fill='#e21c21'
                    height={16}
                    onClick={() => dispatch(setNpoDetailsIsEdit(true))}
                  />
                )
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className={`user-details-section project-details-section ${
            !isEdit && 'is-edit'
          }`}
          style={{ minHeight: '40vh' }}
        >
          <Row justify='center'>
            <Col md={24} lg={23} xl={20} xxl={20}>
              <Form
                {...formItemLayout}
                form={form}
                name='user-details'
                onFinish={onFinish}
                initialValues={currentNpo}
                scrollToFirstError
                labelWrap={true}
                layout='horizontal'
                className='user-detail-form'
                requiredMark={false}
              >
                <Row gutter={[20, 10]}>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='title'
                      label='Title *'
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder='Slot title'
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <SelectFormItem
                      label='Room'
                      name='room'
                      required={false}
                      options={rooms}
                      placeholder='Select room'
                    />
                  </Col>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='capacity'
                      label='Capacity *'
                    >
                      <InputNumber
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder='Slot capacity'
                        min={minSlotCapacity}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='slot_date'
                      label='Slot Date *'
                    >
                      <DatePicker
                        width='100%'
                        format='DD MMM YYYY'
                        disabled={!isEdit || !canUpdate}
                        suffixIcon={null}
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='start_time'
                      label='Start Time *'
                    >
                      <TimePicker
                        use12Hours
                        width='100%'
                        format='hh:mm A'
                        disabled={!isEdit || !canUpdate}
                        suffixIcon={null}
                        placeholder='Start time'
                        disabledTime={() => ({
                          disabledHours: () => {
                            return disabledHoursList;
                          },
                        })}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                        {
                          validator: (rule, value) => {
                            if (value && value <= startTime) {
                              return Promise.reject(
                                'End time should be greater than start time!'
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                      name='end_time'
                      label='End Time *'
                    >
                      <TimePicker
                        use12Hours
                        width='100%'
                        format='hh:mm A'
                        suffixIcon={null}
                        disabled={!isEdit || !canUpdate}
                        placeholder='End time'
                        disabledTime={() => ({
                          disabledHours: () => {
                            return disabledHoursList;
                          },
                        })}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <SelectFormItem
                      label='Assessor *'
                      name='assessor'
                      required={true}
                      options={assessorOptions}
                      placeholder='Select assessor'
                    />
                  </Col>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <SelectFormItem
                      label='Status *'
                      name='deleted_status'
                      required={true}
                      options={[
                        { label: 'Active', value: 'active' },
                        { label: 'Inactive', value: 'inactive' },
                      ]}
                      placeholder='Select status'
                    />
                  </Col>
                  <Col span={24}>
                    <Form.Item name='description' label='Description'>
                      <TextArea
                        rows={4}
                        readOnly={!isEdit || !canUpdate}
                        disabled={!isEdit || !canUpdate}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='empty-div'></div>
    </>
  );
};

export default SlotDetailsSection;
