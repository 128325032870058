const FETCH_QUOTE_STATUSES = 'FETCH_QUOTE_STATUSES';
const FETCH_QUOTE_STATUSES_SUCCESS = 'FETCH_QUOTE_STATUSES_SUCCESS';
const FETCH_QUOTE_STATUSES_ERROR = 'FETCH_QUOTE_STATUSES_ERROR';
const UPDATE_QUOTE_STATUSES = 'UPDATE_QUOTE_STATUSES';
const UPDATE_QUOTE_STATUSES_SUCCESS = 'UPDATE_QUOTE_STATUSES_SUCCESS';
const UPDATE_QUOTE_STATUSES_ERROR = 'UPDATE_QUOTE_STATUSES_ERROR';
const DELETE_QUOTE_STATUSES = 'DELETE_QUOTE_STATUSES';
const DELETE_QUOTE_STATUSES_SUCCESS = 'DELETE_QUOTE_STATUSES_SUCCESS';
const DELETE_QUOTE_STATUSES_ERROR = 'DELETE_QUOTE_STATUSES_ERROR';
const CREATE_QUOTE_STATUSES = 'CREATE_QUOTE_STATUSES';
const CREATE_QUOTE_STATUSES_SUCCESS = 'CREATE_QUOTE_STATUSES_SUCCESS';
const CREATE_QUOTE_STATUSES_ERROR = 'CREATE_QUOTE_STATUSES_ERROR';
const IS_QUOTE_STATUSES_ADD_UPDATE = 'IS_QUOTE_STATUSES_ADD_UPDATE';
const UPDATE_QUOTE_STATUS_ORDER = 'UPDATE_QUOTE_STATUS_ORDER';
const UPDATE_QUOTE_STATUS_ORDER_SUCCESS = 'UPDATE_QUOTE_STATUS_ORDER_SUCCESS';
const UPDATE_QUOTE_STATUS_ORDER_ERROR = 'UPDATE_QUOTE_STATUS_ORDER_ERROR';
const UPDATE_QUOTE_COMPLETED_STATUS = 'UPDATE_QUOTE_COMPLETED_STATUS';
const UPDATE_QUOTE_COMPLETED_STATUS_SUCCESS =
  'UPDATE_QUOTE_COMPLETED_STATUS_SUCCESS';
const UPDATE_QUOTE_COMPLETED_STATUS_ERROR =
  'UPDATE_QUOTE_COMPLETED_STATUS_ERROR';
const SET_QUOTE_COMPLETED_STATUS = 'SET_QUOTE_COMPLETED_STATUS';

const FETCH_SUB_OBSERVATION = 'FETCH_SUB_OBSERVATION';
const FETCH_SUB_OBSERVATION_SUCCESS = 'FETCH_SUB_OBSERVATION_SUCCESS';
const FETCH_SUB_OBSERVATION_ERROR = 'FETCH_SUB_OBSERVATION_ERROR';
const UPDATE_SUB_OBSERVATION = 'UPDATE_SUB_OBSERVATION';
const UPDATE_SUB_OBSERVATION_SUCCESS = 'UPDATE_SUB_OBSERVATION_SUCCESS';
const UPDATE_SUB_OBSERVATION_ERROR = 'UPDATE_SUB_OBSERVATION_ERROR';
const CREATE_SUB_OBSERVATION = 'CREATE_SUB_OBSERVATION';
const CREATE_SUB_OBSERVATION_SUCCESS = 'CREATE_SUB_OBSERVATION_SUCCESS';
const CREATE_SUB_OBSERVATION_ERROR = 'CREATE_SUB_OBSERVATION_ERROR';
const IS_SUB_OBSERVATION_ADD_UPDATE = 'IS_SUB_OBSERVATION_ADD_UPDATE';
const UPDATE_SUB_OBSERVATION_ORDER = 'UPDATE_SUB_OBSERVATION_ORDER';
const UPDATE_SUB_OBSERVATION_ORDER_SUCCESS =
  'UPDATE_SUB_OBSERVATION_ORDER_SUCCESS';
const UPDATE_SUB_OBSERVATION_ORDER_ERROR = 'UPDATE_SUB_OBSERVATION_ORDER_ERROR';

export {
  FETCH_QUOTE_STATUSES,
  FETCH_QUOTE_STATUSES_SUCCESS,
  FETCH_QUOTE_STATUSES_ERROR,
  UPDATE_QUOTE_STATUSES,
  UPDATE_QUOTE_STATUSES_SUCCESS,
  UPDATE_QUOTE_STATUSES_ERROR,
  DELETE_QUOTE_STATUSES,
  DELETE_QUOTE_STATUSES_SUCCESS,
  DELETE_QUOTE_STATUSES_ERROR,
  CREATE_QUOTE_STATUSES,
  CREATE_QUOTE_STATUSES_SUCCESS,
  CREATE_QUOTE_STATUSES_ERROR,
  UPDATE_QUOTE_STATUS_ORDER,
  UPDATE_QUOTE_STATUS_ORDER_SUCCESS,
  UPDATE_QUOTE_STATUS_ORDER_ERROR,
  IS_QUOTE_STATUSES_ADD_UPDATE,
  UPDATE_QUOTE_COMPLETED_STATUS,
  UPDATE_QUOTE_COMPLETED_STATUS_SUCCESS,
  UPDATE_QUOTE_COMPLETED_STATUS_ERROR,
  SET_QUOTE_COMPLETED_STATUS,
  FETCH_SUB_OBSERVATION,
  FETCH_SUB_OBSERVATION_SUCCESS,
  FETCH_SUB_OBSERVATION_ERROR,
  UPDATE_SUB_OBSERVATION,
  UPDATE_SUB_OBSERVATION_SUCCESS,
  UPDATE_SUB_OBSERVATION_ERROR,
  CREATE_SUB_OBSERVATION,
  CREATE_SUB_OBSERVATION_SUCCESS,
  CREATE_SUB_OBSERVATION_ERROR,
  IS_SUB_OBSERVATION_ADD_UPDATE,
  UPDATE_SUB_OBSERVATION_ORDER,
  UPDATE_SUB_OBSERVATION_ORDER_SUCCESS,
  UPDATE_SUB_OBSERVATION_ORDER_ERROR,
};
