import React, { useRef, useState } from 'react';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Row, message, Spin } from 'antd';
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete-icon.svg';
import { ReactComponent as AttachIcon } from '../../../../../assets/attach.svg';
import { ReactComponent as WarningIcon } from '../../../../../assets/warning.svg';
import { ReactComponent as TickIcon } from '../../../../../assets/green-tick.svg';
import { baseURL } from '../../../../../globalUtils/axiosConfig';
import { useSelector } from 'react-redux';
// import { addProductionAttach, deleteProdAttach } from './redux/actions';
// import UseGetUserRole from '../../../../../components/useGetUserRole';

const AttachComponent = ({ images, setData, name, label }) => {
  const [deleteItemKey, setDeleteItemKey] = useState([]);
  const inputFile = useRef(null);
  // const dispatch = useDispatch();
  //   const { attachmentLoading } = useSelector((state) => state.productionReducer);
  // const [isSuperAdmin, isAdmin, , isSalesUser] = UseGetUserRole();
  // const { user } = useSelector((state) => state.authReducer);

  const handleDeleteImage = (imgRecord) => {
    let filteredImages = images.filter((el) => el.key !== imgRecord.key);
    setData((prev) => ({ ...prev, [name]: filteredImages }));
    // setDeleteItemKey([...deleteItemKey, imgRecord.key]);
    // deleteAttachment({ ...imgRecord, pillarId, questionId: id });
  };

  const handleShowImage = (image) => {
    let url = image.isLocal
      ? URL.createObjectURL(image)
      : `${baseURL}${image.image}`;
    window.open(url, '_blank');
  };

  const handleImage = (imgObj, e) => {
    e.currentTarget.value = null;

    const isValid =
      /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|jpg|jpeg|gif|png|mp4|)$/i.test(
        imgObj.name
      );

    if (isValid) {
      imgObj['key'] = Math.round(Math.random() * 1000000);
      imgObj['isLocal'] = true;
      if (imgObj) {
        let updatedImages = [...images, imgObj];
        setData((prev) => ({ ...prev, [name]: updatedImages }));
        // handleAddImage(imgObj);
        // const { soid } = currentUpdateObj;
        // dispatch(
        //   addProductionAttach({
        //     data: {
        //       soid,
        //       images: [imgObj],
        //     },
        //     setCurrentUpdateObj,
        //   })
        // );
      }
    } else {
      message.error('This file format is not supported.');
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );

  return (
    <Row className='attach-label-row'>
      <Col span={24} style={{ marginBottom: '10px' }}>
        <Row justify='start' align='start' gutter={[15, 0]}>
          {name === 'others' && <Col />}
          <Col>
            {name !== 'others' &&
              (images.length > 0 ? <TickIcon /> : <WarningIcon />)}
          </Col>
          <Col className='label'>
            <div className={name}>{label}</div>
            <div
              className='attach-parent-div'
              onClick={() => inputFile.current.click()}
            >
              <AttachIcon
                className='rotate-icon activities-attach'
                width={16}
              />
              <PlusOutlined />
            </div>
            <input
              type='file'
              id='file'
              multiple
              accept=''
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={(e) => handleImage(e.target.files[0], e)}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[20, 10]}>
          {images.map((el) => {
            // console.log('el', el);
            // const { image, key } = el;
            // let name = image
            //   .split('/')
            //   [image.split('/').length - 1].split('?')[0];
            const { name, key } = el;

            return (
              <Col span={24} key={key}>
                <Row justify='start' align='middle' gutter={[20, 0]}>
                  <Col span={1} />
                  <Col>
                    <span
                      className='name-span'
                      onClick={() => handleShowImage(el)}
                    >
                      {name}
                    </span>
                  </Col>
                  <Col>
                    <Popconfirm
                      overlayClassName='activities-popconfirm'
                      title='Are you sure to delete this attachment?'
                      onConfirm={() => handleDeleteImage(el)}
                      okText='Yes'
                      cancelText='No'
                    >
                      {deleteItemKey.includes(key) ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <DeleteIcon
                          fill='#e21c21'
                          width={18}
                          height={18}
                          className='del-icon'
                        />
                      )}
                    </Popconfirm>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default AttachComponent;
