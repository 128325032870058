import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';

export function* handleFetchContactTypes({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/contact_types`, {});
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchContactTypesSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchContactTypesError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateContactTypes({ data }) {
  const { position, _id, name } = data;

  try {
    const response = yield axios.patch(`${baseURL}/contact_types/${_id}`, {
      name,
      position,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateContactTypesSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateContactTypesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateContactTypesError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleDeleteContactTypes({ data }) {
  const { _id } = data;
  try {
    const response = yield axios.delete(`${baseURL}/contact_types/${_id}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteContactTypesSuccess(_id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteContactTypesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteContactTypesError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleCreateContactTypes({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData._id;
    const response = yield axios.post(`${baseURL}/contact_types`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createContactTypesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createContactTypesError(data._id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateContactOrder({ data }) {
  try {
    const response = yield axios.patch(`${baseURL}/sort_contact_types`, data);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateContactTypesOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateContactTypesOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateContactTypesOrderError());
    AntdAlert.error('Something went wrong!');
  }
}

export function* watcherContactTypesSaga() {
  yield takeLatest(constants.FETCH_CONTACT_TYPES, handleFetchContactTypes);
  yield takeLatest(constants.CREATE_CONTACT_TYPES, handleCreateContactTypes);
  yield takeLatest(constants.UPDATE_CONTACT_TYPES, handleUpdateContactTypes);
  yield takeLatest(
    constants.UPDATE_ORDER_CONTACT_TYPES,
    handleUpdateContactOrder
  );
  yield takeLatest(constants.DELETE_CONTACT_TYPES, handleDeleteContactTypes);
}
