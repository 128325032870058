const UPDATE_FORM_SETUP = 'UPDATE_FORM_SETUP';
const UPDATE_FORM_SETUP_SUCCESS = 'UPDATE_FORM_SETUP_SUCCESS';
const UPDATE_FORM_SETUP_ERROR = 'UPDATE_FORM_SETUP_ERROR';
const DELETE_FORM_SETUP = 'DELETE_FORM_SETUPS';
const DELETE_FORM_SETUP_SUCCESS = 'DELETE_FORM_SETUP_SUCCESS';
const DELETE_FORM_SETUP_ERROR = 'DELETE_FORM_SETUP_ERROR';
const CREATE_FORM_SETUP = 'CREATE_FORM_SETUP';
const CREATE_FORM_SETUP_SUCCESS = 'CREATE_FORM_SETUP_SUCCESS';
const CREATE_FORM_SETUP_ERROR = 'CREATE_FORM_SETUP_ERROR';
const FETCH_ALL_FORM_SETUPS = 'FETCH_ALL_FORM_SETUPS';
const FETCH_ALL_FORM_SETUPS_SUCCESS = 'FETCH_ALL_FORM_SETUPS_SUCCESS';
const FETCH_ALL_FORM_SETUPS_ERROR = 'FETCH_ALL_FORM_SETUPS_ERROR';
const FORM_SETUP_IS_EDIT = 'FORM_SETUP_IS_EDIT';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';
const FETCH_FORM_SETUP = 'FETCH_FORM_SETUP';
const FORM_SETUP_CREATION_CLEANUP_FUNC = 'FORM_SETUP_CREATION_CLEANUP_FUNC';
const FETCH_FORM_SETUP_SUCCESS = 'FETCH_FORM_SETUP_SUCCESS';
const FETCH_FORM_SETUP_ERROR = 'FETCH_FORM_SETUP_ERROR';
export {
  FORM_SETUP_IS_EDIT,
  FETCH_ALL_FORM_SETUPS,
  FETCH_ALL_FORM_SETUPS_SUCCESS,
  FETCH_ALL_FORM_SETUPS_ERROR,
  CREATE_FORM_SETUP,
  CREATE_FORM_SETUP_SUCCESS,
  CREATE_FORM_SETUP_ERROR,
  UPDATE_FORM_SETUP,
  UPDATE_FORM_SETUP_SUCCESS,
  UPDATE_FORM_SETUP_ERROR,
  DELETE_FORM_SETUP,
  DELETE_FORM_SETUP_SUCCESS,
  DELETE_FORM_SETUP_ERROR,
  IS_ADDING_UPDATE,
  FETCH_FORM_SETUP,
  FORM_SETUP_CREATION_CLEANUP_FUNC,
  FETCH_FORM_SETUP_SUCCESS,
  FETCH_FORM_SETUP_ERROR,
};
