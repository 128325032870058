import * as constants from './constants';

const initialState = {
  allLearnerTypes: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
};

const leadStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_LEARNER_TYPES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_LEARNER_TYPES_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allLearnerTypes: [...state.allLearnerTypes, action.data],
        isAdding: false,
      };
    case constants.CREATE_LEARNER_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_ORDER_LEARNER_TYPES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_ORDER_LEARNER_TYPES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allLearnerTypes: action.data,
      };
    case constants.UPDATE_ORDER_LEARNER_TYPES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_LEARNER_TYPES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_LEARNER_TYPES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allLearnerTypes: action.data,
        isAdding: false,
      };
    case constants.FETCH_LEARNER_TYPES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_LEARNER_TYPES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_LEARNER_TYPES_SUCCESS:
      const updated = state.allLearnerTypes.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allLearnerTypes: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_LEARNER_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_LEARNER_TYPES:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_LEARNER_TYPES_SUCCESS:
      const updatedData = state.allLearnerTypes.filter(
        (el) => el.id !== action.data
      );
      return {
        ...state,
        allLearnerTypes: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_LEARNER_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    default:
      return state;
  }
};

export default leadStatusReducer;
