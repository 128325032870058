import * as constants from './constants';

const initialState = {
  allComments: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
  search: {},
};

const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_COMMENTS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_COMMENTS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allComments: [action.data.comment, ...state.allComments],
        isAdding: false,
      };
    case constants.CREATE_COMMENTS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_ORDER_COMMENTS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_ORDER_COMMENTS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allComments: action.data,
      };
    case constants.UPDATE_ORDER_COMMENTS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_COMMENTS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allComments: action.data.comments,
        isAdding: false,
      };
    case constants.FETCH_COMMENTS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_COMMENTS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_COMMENTS_SUCCESS:
      const updated = state.allComments.map((el) =>
        el._id === action.data.comment._id
          ? { ...el, ...action.data.comment }
          : el
      );
      return {
        ...state,
        allComments: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_COMMENTS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_COMMENTS:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_COMMENTS_SUCCESS:
      const updatedData = state.allComments.filter(
        (el) => el._id !== action.data
      );
      return {
        ...state,
        allComments: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_COMMENTS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    case constants.SET_COMMENTS_MANAGER_STATE:
      const { search } = state;

      localStorage.setItem(
        'commentsManagerState',
        JSON.stringify({ search, ...action.data })
      );
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default commentsReducer;
