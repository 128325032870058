import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Space,
  Col,
  Row,
  message,
  Select,
  InputNumber,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
// import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../assets/save-icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/cross-icon.svg';
import SpinnerComponent from '../../components/spinner';
import { arrayMoveImmutable } from 'array-move';
import DragDropIcon from '../../assets/dragdropicon.png';
import * as Actions from './redux/actions';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import SubObservationTable from '../LearningActivities/detail/subObservationsTable';

const { Option } = Select;
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <img alt='' src={DragDropIcon} />);

const QuoteStatusesTable = ({ canUpdate, canDelete, currentNpoObj }) => {
  const [editingLoading, setEditingLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ref = useRef();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const isEditing = (record) => record._id === editingKey;

  const { allQuoteStatuses, fetchingLoading, updateLoading, isAdding } =
    useSelector((state) => state.quoteStatusReducer);

  // useEffect(() => {
  //   dispatch(Actions.fetchQuoteStatuses());
  // }, []);

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey('');
    }
  }, [updateLoading]);

  useEffect(() => {
    setData(allQuoteStatuses);
  }, [allQuoteStatuses]);

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const textInput = (
      <Input
        placeholder='Insert Name'
        ref={ref}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            save(record._id);
          }
        }}
        // readOnly={record?._id === 2 ? true : false}
      />
    );

    let inputNode = null;

    if (dataIndex === 'name') {
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `This field is required!`,
                },
                {
                  min: 1,
                  message: `invalid!`,
                },
              ]}
            >
              {textInput}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    }

    if (dataIndex === 'deleted_status') {
      inputNode = (
        <Select
          showSearch
          className={`deleted-status-selector`}
          style={{ width: '100px', height: '30px' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // onChange={handleUpdateSite}
          placeholder='Select Status'
          optionLabelProp='label'
        >
          {[
            { label: 'ACTIVE', value: 'active' },
            { label: 'INACTIVE', value: 'inactive' },
          ]?.map(({ label, value }) => {
            return (
              <Option value={value} key={value} label={label}>
                {label || ''}
              </Option>
            );
          })}
        </Select>
      );
    }

    if (dataIndex === 'position') {
      inputNode = (
        <InputNumber
          placeholder='Insert Position'
          min={1}
          // readOnly={record?._id === 2 ? true : false}
        />
      );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            valuePropName={'value'}
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Required!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(Actions.isQuoteStatusAdding(true));
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record._id);
    setTimeout(() => {
      ref.current?.focus();
    }, 300);
  };

  const cancel = (record) => {
    dispatch(Actions.isQuoteStatusAdding(false));
    form.resetFields();
    setEditingKey('');

    if (record?.isAdd) {
      return setData((data) => data.filter((el) => el._id !== record._id));
    } else {
      setData(data);
    }
  };

  const isAlreadyExist = (record) => {
    const { name } = record;
    const alreadyExist = data.filter(
      (el) => el?.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase()
    );

    return alreadyExist.length > 0 && record._id !== alreadyExist[0]?._id
      ? true
      : false;
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item._id);
      if (index > -1) {
        const item = newData[index];
        if (item?.isAdd) {
          form.submit();
        } else {
          let itemData = { ...item, ...row };

          if (isAlreadyExist(itemData)) {
            return message.error('Observation name already exist!');
          }

          dispatch(Actions.updateQuoteStatuses(itemData));
        }
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      if (errInfo?.values?.name === '') {
        message.error('Name should not be blank!');
      }
    }
  };

  const handleDelete = (record) => {
    setEditingKey(record._id);
    dispatch(Actions.deleteQuoteStatuses(record));
  };

  const handleAdd = () => {
    dispatch(Actions.isQuoteStatusAdding(true));
    let _id = Math.floor(Math.random() * 1122123456);
    const newData = {
      _id,
      name: '',
      deleted_status: 'active',
      isAdd: true,
      learningActivityId: currentNpoObj?._id,
    };
    setData((data) => [...data, newData]);
    setEditingKey(_id);
    edit(newData);
  };

  const hanldeFinish = (values) => {
    const { name, deleted_status, position } = values;

    if (isAlreadyExist(values)) {
      return message.error('Observation name already exist!');
    }

    dispatch(
      Actions.createQuoteStatuses({
        name: name.trim(),
        deleted_status,
        id: editingKey,
        position: position,
        learning_activity_id: currentNpoObj._id,
      })
    );
  };

  const handleSuccess = () => {
    // dispatch(Actions.fetchQuoteStatuses());
  };

  const onChangeCompletedStatus = (checked, record) => {
    const { _id } = record;
    if (checked) {
      const updated = data.map((el) => ({
        ...el,
        completed_status: el._id === record._id,
      }));

      setData(updated);
    }

    dispatch(
      Actions.updateQuoteCompletedStatuses({
        _id,
        completed_status: true,
        handleSuccess,
      })
    );
  };

  let disableIcons = () => isAdding;

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: '30%',
      editable: true,
      render: (_, record) => {
        return (
          <div
            onClick={() => {
              if (expandedRowKeys.includes(record._id)) {
                setExpandedRowKeys([]);
              } else {
                const keys = [];
                keys.push(record._id);
                setExpandedRowKeys(keys);
              }
            }}
          >
            {record?.name}
          </div>
        );
      },
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      align: 'center',
      width: '15%',
      editable: true,
    },
    {
      title: 'No. Of Sub Observations',
      dataIndex: 'sub_observations',
      key: 'sub_observations',
      align: 'center',
      width: '15%',
      render: (_, record) => {
        return <>{record?.sub_observations?.length}</>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'deleted_status',
      key: 'deleted_status',
      width: '15%',
      editable: true,
      align: 'center',
      // sorter: (a, b) => a.deleted_status?.localeCompare(b.deleted_status),
      render: (deleted_status, record) => {
        return (
          <div style={{ paddingLeft: '12px' }}>
            {record?.deleted_status?.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: '',
      width: '10%',
      align: 'left',
      render: (_, record) => {
        // const { id } = record;
        // if (id === 2) {
        //   return;
        // }
        if (!canUpdate && !canDelete) {
          return;
        }

        const editable = isEditing(record);
        return editable ? (
          editingLoading && editingKey === record._id ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={20} align='center'>
              <SaveIcon
                className='custom-icon'
                onClick={() => save(record._id)}
              />
              <Popconfirm
                title='Sure to cancel?'
                onConfirm={() => cancel(record)}
              >
                <CrossIcon
                  style={{ height: '20px', width: '20px' }}
                  className='custom-icon'
                />
              </Popconfirm>
            </Space>
          )
        ) : (
          <Space size={20} align='center'>
            <EditIcon
              className={`custom-icon ${disableIcons() ? 'disable-icon' : ''}`}
              disabled={editingKey !== ''}
              onClick={() => !disableIcons() && edit(record)}
            />
            {/* {canDelete &&
              data.length >= 1 &&
              (disableIcons() ? (
                <DeleteIcon className={`custom-icon disable-icon`} />
              ) : (
                <Popconfirm
                  title='Are you sure you want to delete this status?'
                  onConfirm={() => handleDelete(record)}
                  okText='Yes'
                  cancelText='No'
                >
                  <DeleteIcon className={`custom-icon`} />
                </Popconfirm>
              ))} */}
          </Space>
        );
      },
    },
    // {
    //   title: 'Order',
    //   dataIndex: 'order',
    //   key: 'order',
    //   width: '10%',
    //   className: 'drag-visible',
    //   render: (order, record) => {
    //     if (isAdding) {
    //       return <img alt='' className='disable-icon' src={DragDropIcon} />;
    //     }

    //     return !Boolean(editingKey) && canUpdate && <DragHandle />;
    //   },
    // },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(data.slice(), oldIndex, newIndex)
        .filter((el) => !!el)
        .map((el) => ({ observation_id: el.observation_id }));

      dispatch(
        Actions.updateQuoteStatusesOrder({
          data: newData,
          learning_activity_id: currentNpoObj?._id,
        })
      );
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex(
      (x) => x.position == restProps['data-row-key']
    );

    return <SortableItem index={index} {...restProps} />;
  };
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        // title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record._id);
    }
    setExpandedRowKeys(keys);
  };

  return (
    <Row justify='center' className=''>
      <Col xxl={24} xl={24} lg={24} md={24} className='heading-col'>
        <Row align='middle' gutter={[30, 0]}>
          <Col>
            <div>
              <span className='heading-span'>Observations</span>
            </div>
          </Col>
        </Row>
      </Col>

      <Col lg={23} xl={22} xxl={20} md={24}>
        <Form
          form={form}
          component={false}
          onFinish={hanldeFinish}
          className='table-style'
        >
          <Table
            className='special-table activity-type-table project-statuses-table doc-status-table'
            components={{
              body: {
                cell: EditableCell,
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            scroll={{ x: 750 }}
            rowClassName='editable-row'
            dataSource={data}
            loading={fetchingLoading}
            columns={mergedColumns}
            pagination={false}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <SubObservationTable
                    canDelete={true}
                    canUpdate={true}
                    learning_activity_id={currentNpoObj?._id}
                    sub_observations={record?.sub_observations || []}
                    record={record}
                  />
                  // <Table
                  //   className='custom-expanded-table table-second-level'
                  //   style={{
                  //     marginTop: '0px',
                  //   }}
                  //   pagination={false}
                  //   columns={columns}
                  //   dataSource={
                  //     record?.learning_activity_sub_observations || []
                  //   }
                  // />
                );
              },
              expandRowByClick: true,
              expandIcon: null,
              showExpandColumn: false,
              expandedRowKeys: expandedRowKeys,
              onExpand: onTableRowExpand,
              rowExpandable: ({ _id }) => expandedRowKeys.includes(_id),
            }}
            onRow={({ _id }) => {
              return (
                expandedRowKeys.includes(_id) && {
                  className:
                    'expand-parent expand-doc-parent fourth-level-expanded-row',
                }
              );
            }}
            // rowKey='position'
            rowKey={(record) => record._id}
          />
          <br />
          <Col offset={1} lg={24} xl={18} xxl={17} md={24} className='table'>
            {canUpdate && (
              <Button
                icon={<PlusOutlined />}
                // className='add-status-btn'
                size='middle'
                disabled={isAdding}
                onClick={handleAdd}
              >
                Observation
              </Button>
            )}
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default QuoteStatusesTable;
