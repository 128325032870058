import * as constants from './constants';

export const setClassDetailsIsEdit = (data) => {
  return {
    type: constants.CLASS_DETAILS_IS_EDIT,
    data: data,
  };
};

export const createClass = (data) => {
  return {
    type: constants.CREATE_CLASS,
    data: data,
  };
};

export const createClassSuccess = (data) => {
  return {
    type: constants.CREATE_CLASS_SUCCESS,
    data: data,
  };
};

export const createClassError = () => {
  return {
    type: constants.CREATE_CLASS_ERROR,
  };
};

export const fetchClass = (data) => {
  return {
    type: constants.FETCH_CLASS,
    data: data,
  };
};

export const fetchClassSuccess = (data) => {
  return {
    type: constants.FETCH_CLASS_SUCCESS,
    data,
  };
};

export const fetchClassError = () => {
  return {
    type: constants.FETCH_CLASS_ERROR,
  };
};

export const updateClass = (data) => {
  return {
    type: constants.UPDATE_CLASS,
    data: data,
  };
};

export const updateClassSuccess = (data) => {
  return {
    type: constants.UPDATE_CLASS_SUCCESS,
    data: data,
  };
};

export const updateClassError = () => {
  return {
    type: constants.UPDATE_CLASS_ERROR,
  };
};

export const fetchAllClasses = (data) => {
  return {
    type: constants.FETCH_ALL_CLASSES,
    data,
  };
};

export const fetchAllClassesSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_CLASSES_SUCCESS,
    data,
  };
};

export const fetchAllClassesError = (data) => {
  return {
    type: constants.FETCH_ALL_CLASSES_ERROR,
    data,
  };
};

export const deleteClass = (data) => {
  return {
    type: constants.DELETE_CLASS,
    data,
  };
};

export const deleteClassSuccess = (data) => {
  return {
    type: constants.DELETE_CLASS_SUCCESS,
    data,
  };
};

export const deleteClassError = (data) => {
  return {
    type: constants.DELETE_CLASS_ERROR,
    data,
  };
};

export const classDetailCleanupFunc = () => {
  return {
    type: constants.CLASS_DETAIL_CLEANUP_FUNC,
  };
};

export const setStorage = (data) => {
  return {
    type: constants.SET_CLASSES_MANAGER_STATE,
    data,
  };
};
