import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import './index.less';
import { useDispatch, useSelector } from 'react-redux';
import { setNpoDetailsIsEdit } from './redux/actions';
import Table from './Table';
import { PlusOutlined } from '@ant-design/icons';
// import { fetchNpoStatuses } from '../NpoStatuses/redux/actions';
// import { fetchNpoRegions } from '../NpoRegions/redux/actions';
// import ExportComponent from './export';
// import Tooltip from '../../components/tooltip-Custom';

const LearningActivities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [canUpdate, setCanUpdate] = useState(true);

  useEffect(() => {
    if (user?.permissions?.npo_setup?.add_edit) {
      setCanUpdate(true);
    }
  }, [user]);

  // useEffect(() => {
  //   dispatch(fetchNpoStatuses());
  //   dispatch(fetchNpoRegions());
  // }, []);

  return (
    <Col span={24}>
      <Table
        canUpdate={canUpdate}
        canDelete={user?.permissions?.npo_setup?.delete || false}
      />
    </Col>
  );
};

export default LearningActivities;
