import * as constants from './constants';

const initialState = {
  curriculums: [],
  updateLoading: false,
  isAdding: false,
};

const curriculumStudentReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_CURRICULUM_STUDENT:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_CURRICULUM_STUDENT_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        curriculums: action.data.map((el) => {
          const { curriculum, _id, ...rest } = el;
          return {
            ...rest,
            ...curriculum,
            _id,
            curriculum_id: curriculum?._id,
          };
        }),
        isAdding: false,
      };
    case constants.CREATE_CURRICULUM_STUDENT_ERROR:
      return {
        ...state,
        updateLoading: false,
        // isAdding: false,
        // curriculums: [],
      };

    case constants.FETCH_ALL_CURRICULUM_STUDENTS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.FETCH_ALL_CURRICULUM_STUDENTS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        curriculums: action.data.map((el) => {
          const { curriculum, _id, ...rest } = el;
          return {
            ...rest,
            ...curriculum,
            _id,
            curriculum_id: curriculum?._id,
          };
        }),
        isAdding: false,
      };
    case constants.FETCH_ALL_CURRICULUM_STUDENTS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_CURRICULUM_STUDENT:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_CURRICULUM_STUDENT_SUCCESS:
      return {
        ...state,
        curriculums: action.data.map((el) => {
          const { curriculum, _id, ...rest } = el;
          return {
            ...rest,
            ...curriculum,
            _id,
            curriculum_id: curriculum?._id,
          };
        }),
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_CURRICULUM_STUDENT_ERROR:
      return {
        ...state,
        updateLoading: false,
        // isAdding: false,
      };

    case constants.DELETE_CURRICULUM_STUDENT:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.DELETE_CURRICULUM_STUDENT_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        curriculums: action.data.map((el) => {
          const { curriculum, _id, ...rest } = el;
          return {
            ...rest,
            ...curriculum,
            _id,
            curriculum_id: curriculum?._id,
          };
        }),
        isAdding: false,
      };
    case constants.DELETE_CURRICULUM_STUDENT_ERROR:
      return {
        ...state,
        updateLoading: false,
        // isAdding: false,
        // curriculums: [],
      };
    case constants.ADD_CURRICULUM_STUDENT_IN_MANAGER:
      return {
        ...state,
        isAdding: action.data,
      };

    default:
      return state;
  }
};

export default curriculumStudentReducer;
