import * as constants from './constants';

export const fetchContactTypes = (data) => {
  return {
    type: constants.FETCH_CONTACT_TYPES,
    data,
  };
};

export const fetchContactTypesSuccess = (data) => {
  return {
    type: constants.FETCH_CONTACT_TYPES_SUCCESS,
    data: data,
  };
};

export const fetchContactTypesError = (data) => {
  return {
    type: constants.FETCH_CONTACT_TYPES_ERROR,
    data: data,
  };
};

export const updateContactTypes = (data) => {
  return {
    type: constants.UPDATE_CONTACT_TYPES,
    data: data,
  };
};

export const updateContactTypesSuccess = (data) => {
  return {
    type: constants.UPDATE_CONTACT_TYPES_SUCCESS,
    data: data,
  };
};

export const updateContactTypesError = () => {
  return {
    type: constants.UPDATE_CONTACT_TYPES_ERROR,
  };
};

export const updateContactTypesOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_CONTACT_TYPES,
    data: data,
  };
};

export const updateContactTypesOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_CONTACT_TYPES_SUCCESS,
    data: data,
  };
};

export const updateContactTypesOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_CONTACT_TYPES_ERROR,
  };
};

export const deleteContactTypes = (data) => {
  return {
    type: constants.DELETE_CONTACT_TYPES,
    data: data,
  };
};

export const deleteContactTypesSuccess = (data) => {
  return {
    type: constants.DELETE_CONTACT_TYPES_SUCCESS,
    data: data,
  };
};

export const deleteContactTypesError = () => {
  return {
    type: constants.DELETE_CONTACT_TYPES_ERROR,
  };
};

export const createContactTypes = (data, id) => {
  return {
    type: constants.CREATE_CONTACT_TYPES,
    data: data,
  };
};

export const createContactTypesSuccess = (data) => {
  return {
    type: constants.CREATE_CONTACT_TYPES_SUCCESS,
    data: data,
  };
};

export const createContactTypesError = (id) => {
  return {
    type: constants.CREATE_CONTACT_TYPES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
