const CREATE_NPO = 'CREATE_SLOTS';
const CREATE_NPO_SUCCESS = 'CREATE_SLOTS_SUCCESS';
const CREATE_NPO_ERROR = 'CREATE_SLOTS_ERROR';
const UPDATE_NPO = 'UPDATE_SLOTS';
const UPDATE_NPO_SUCCESS = 'UPDATE_SLOTS_SUCCESS';
const UPDATE_NPO_ERROR = 'UPDATE_SLOTS_ERROR';
const FETCH_NPO = 'FETCH_SLOTS';
const FETCH_NPO_SUCCESS = 'FETCH_SLOTS_SUCCESS';
const FETCH_NPO_ERROR = 'FETCH_SLOTS_ERROR';
const NPO_DETAILS_IS_EDIT = 'SLOTS_DETAILS_IS_EDIT';
const ADD_USER_IN_MANAGER = 'ADD_USER_IN_MANAGER';
const FETCH_NPOS_UTIL_DATA = 'FETCH_SLOTS_UTIL_DATA';
const FETCH_NPOS_UTIL_DATA_SUCCESS = 'FETCH_SLOTS_UTIL_DATA_SUCCESS';
const FETCH_ALL_NPOS = 'FETCH_ALL_SLOTS';
const FETCH_ALL_NPOS_SUCCESS = 'FETCH_ALL_SLOTS_SUCCESS';
const FETCH_ALL_NPOS_ERROR = ' FETCH_ALL_SLOTS_ERROR';
const DELETE_NPO = 'DELETE_NPO';
const DELETE_NPO_SUCCESS = 'DELETE_SLOTS_SUCCESS';
const DELETE_NPO_ERROR = 'DELETE_SLOTS_ERROR';
const DELETE_NPO_ATTACH = 'DELETE_SLOTS_ATTACH';
const DELETE_NPO_ATTACH_SUCCESS = 'DELETE_SLOTS_ATTACH_SUCCESS';
const DELETE_NPO_ATTACH_ERROR = 'DELETE_SLOTS_ATTACH_ERROR';
const NPO_DETAIL_CLEANUP_FUNC = 'SLOTS_DETAIL_CLEANUP_FUNC';
const NPO_NOTIF_IS_EDIT = 'SLOTS_NOTIF_IS_EDIT';
const UPDATE_NPO_NOTIFICATION = 'UPDATE_SLOTS_NOTIFICATION';
const UPDATE_NPO_NOTIFICATION_SUCCESS = 'UPDATE_SLOTS_NOTIFICATION_SUCCESS';
const UPDATE_NPO_NOTIFICATION_ERROR = 'UPDATE_SLOTS_NOTIFICATION_ERROR';
const UPDATE_ENROLLMENTS = 'UPDATE_SLOTS_ENROLLMENTS';
const UPDATE_ENROLLMENTS_SUCCESS = 'UPDATE_SLOTS_ENROLLMENTS_SUCCESS';
const UPDATE_ENROLLMENTS_ERROR = 'UPDATE_SLOTS_ENROLLMENTS_ERROR';
const ENROLLMENTS_IS_EDIT = 'SLOTS_ENROLLMENTS_IS_EDIT';
const SET_SLOTS_MANAGER_STATE = 'SET_SLOTS_MANAGER_STATE';

export {
  CREATE_NPO,
  CREATE_NPO_SUCCESS,
  CREATE_NPO_ERROR,
  UPDATE_NPO,
  UPDATE_NPO_SUCCESS,
  UPDATE_NPO_ERROR,
  FETCH_NPO,
  FETCH_NPO_SUCCESS,
  FETCH_NPO_ERROR,
  ADD_USER_IN_MANAGER,
  NPO_DETAILS_IS_EDIT,
  FETCH_NPOS_UTIL_DATA,
  FETCH_NPOS_UTIL_DATA_SUCCESS,
  FETCH_ALL_NPOS,
  FETCH_ALL_NPOS_SUCCESS,
  FETCH_ALL_NPOS_ERROR,
  DELETE_NPO,
  DELETE_NPO_SUCCESS,
  DELETE_NPO_ERROR,
  DELETE_NPO_ATTACH,
  DELETE_NPO_ATTACH_SUCCESS,
  DELETE_NPO_ATTACH_ERROR,
  NPO_DETAIL_CLEANUP_FUNC,
  NPO_NOTIF_IS_EDIT,
  UPDATE_NPO_NOTIFICATION,
  UPDATE_NPO_NOTIFICATION_SUCCESS,
  UPDATE_NPO_NOTIFICATION_ERROR,
  UPDATE_ENROLLMENTS,
  UPDATE_ENROLLMENTS_SUCCESS,
  UPDATE_ENROLLMENTS_ERROR,
  ENROLLMENTS_IS_EDIT,
  SET_SLOTS_MANAGER_STATE,
};
