import { Button, Col, Form, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import useAuth from './useAuth';
import logo from '../../assets/Color_Logo.png';

const LogInForm = () => {
  const [onFinish, onFinishFailed, loading] = useAuth();

  return (
    <div className='login-form-parent-div'>
      <Row justify='center' gutter={[0, 30]}>
        <Col>
          <div className='logo-div'>
            <img src={logo} alt='' />
          </div>
        </Col>
        <Col span={24}>
          <p className='login-text'>LOGIN</p>
        </Col>
      </Row>
      <Form
        name='signup'
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        validateTrigger={null}
        // style={{ minHeight: "428px", width: "324px" }}
      >
        <Row justify='center' align='middle'>
          <Col>
            <Form.Item
              name='tenant'
              rules={[
                {
                  required: true,
                  message: 'Tenant is required',
                },
              ]}
            >
              <Input className='email-input' placeholder='Tenant Name' />
            </Form.Item>
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Email is required',
                },
              ]}
            >
              <Input
                className='email-input'
                placeholder='Email Address'
                // value={email}
                type='email'
              />
            </Form.Item>
            <Form.Item
              name='password'
              style={{
                marginBottom: '5px',
                width: '230px',
                borderColor: 'a6a6a6',
              }}
              rules={[
                {
                  required: true,
                  message: 'Password is required',
                },
                {
                  min: 6,
                  message: (
                    <span
                      style={{
                        fontSize: '9px',
                        textAlign: 'left',
                        color: '#717171',
                        fontStyle: 'italic',
                        // width: "210px"
                      }}
                      className='password-error-text'
                    >
                      * Password should contain at least 6 characters
                    </span>
                  ),
                },
              ]}
            >
              <Input.Password placeholder='Password' type='password' />
            </Form.Item>
            <div className='forgot-password-div'>
              {/* <Link to='/forgot-password' className='link-text'>
                Forgot Password?
              </Link> */}
            </div>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button htmlType='submit' className='login-btn' loading={loading}>
                LogIn
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LogInForm;
