const FETCH_LEADS_DOCS = 'FETCH_LEADS_DOCS';
const FETCH_LEADS_DOCS_SUCCESS = 'FETCH_LEADS_DOCS_SUCCESS';
const FETCH_LEADS_DOCS_ERROR = 'FETCH_LEADS_DOCS_ERROR';
const FETCH_ACTIVITIES = 'FETCH_LEADS_ACTIVITIES';
const FETCH_ACTIVITIES_SUCCESS = 'FETCH_LEADS_ACTIVITIES_SUCCESS';
const FETCH_ACTIVITIES_ERROR = 'FETCH_LEADS_ACTIVITIES_ERROR';
const FETCH_ACTIVITIES_GRAPH_DATA = 'FETCH_LEADS_ACTIVITIES_GRAPH_DATA';
const FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS =
  'FETCH_LEADS_ACTIVITIES_GRAPH_DATA_SUCCESS';
const FETCH_ACTIVITIES_GRAPH_DATA_ERROR =
  'FETCH_LEADS_ACTIVITIES_GRAPH_DATA_ERROR';
const UPDATE_ACTIVITY = 'UPDATE_LEADS_ACTIVITY';
const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_LEADS_ACTIVITY_SUCCESS';
const UPDATE_ACTIVITY_ERROR = 'UPDATE_LEADS_ACTIVITY_ERROR';
const FETCH_ACTIVITIES_SEARCH_DATA = 'FETCH_LEADS_ACTIVITIES_SEARCH_DATA';
const FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS =
  'FETCH_LEADS_ACTIVITIES_SEARCH_DATA_SUCCESS';
const FETCH_ACTIVITIES_SEARCH_DATA_ERROR =
  'FETCH_LEADS_ACTIVITIES_SEARCH_DATA_ERROR';
const DELETE_LEADS_DOC = 'DELETE_LEADS_DOC';
const DELETE_LEADS_DOC_SUCCESS = 'DELETE_LEADS_DOC_SUCCESS';
const DELETE_LEADS_DOC_ERROR = 'DELETE_LEADS_DOC_ERROR';
const CREATE_LEADS_DOC = 'CREATE_LEADS_DOC';
const CREATE_LEADS_DOC_SUCCESS = 'CREATE_LEADS_DOC_SUCCESS';
const CREATE_LEADS_DOC_ERROR = 'CREATE_LEADS_DOC_ERROR';
const DELETE_LEADS_DOC_ATTACH = 'DELETE_LEADS_DOC_ATTACH';
const DELETE_LEADS_DOC_ATTACH_SUCCESS = 'DELETE_LEADS_DOC_ATTACH_SUCCESS';
const DELETE_LEADS_DOC_ATTACH_ERROR = 'DELETE_LEADS_DOC_ATTACH_ERROR';
const UPDATE_ISADD_ISUPDATE = 'UPDATE_LEADS_ISADD_ISUPDATE';
const ADD_LEADS_DOC_ATTACH = 'ADD_LEADS_DOC_ATTACH';
const FETCH_BATCH_UPDATE = 'FETCH_LEADS_BATCH_UPDATE';
const FETCH_BATCH_UPDATE_SUCCESS = 'FETCH_LEADS_BATCH_UPDATE_SUCCESS';
const FETCH_BATCH_UPDATE_ERROR = 'FETCH_LEADS_BATCH_UPDATE_ERROR';
const SET_PILLARS_DATA = 'SET_LEADS_PILLARS_DATA';
const SET_CURRICULUMS_MANAGER_STATE = 'SET_CURRICULUMS_MANAGER_STATE';

export {
  FETCH_LEADS_DOCS,
  FETCH_LEADS_DOCS_SUCCESS,
  FETCH_LEADS_DOCS_ERROR,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_ERROR,
  FETCH_ACTIVITIES_GRAPH_DATA,
  FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS,
  FETCH_ACTIVITIES_GRAPH_DATA_ERROR,
  FETCH_ACTIVITIES_SEARCH_DATA,
  FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS,
  FETCH_ACTIVITIES_SEARCH_DATA_ERROR,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_ERROR,
  DELETE_LEADS_DOC,
  DELETE_LEADS_DOC_SUCCESS,
  DELETE_LEADS_DOC_ERROR,
  CREATE_LEADS_DOC,
  CREATE_LEADS_DOC_SUCCESS,
  CREATE_LEADS_DOC_ERROR,
  DELETE_LEADS_DOC_ATTACH,
  DELETE_LEADS_DOC_ATTACH_SUCCESS,
  DELETE_LEADS_DOC_ATTACH_ERROR,
  UPDATE_ISADD_ISUPDATE,
  ADD_LEADS_DOC_ATTACH,
  FETCH_BATCH_UPDATE,
  FETCH_BATCH_UPDATE_SUCCESS,
  FETCH_BATCH_UPDATE_ERROR,
  SET_PILLARS_DATA,
  SET_CURRICULUMS_MANAGER_STATE,
};
