import { Col, Row } from 'antd';
import countriesList from '../countries.json';

export const userProjectPermissions = (userType) => {
  let sitesOption = {
    ...(userType === 'system_manager' && {
      add_edit: false,
    }),
    view: false,
    ...(userType === 'system_manager' && {
      delete: false,
      export: false,
      import: false,
    }),
  };

  let ClassesOption = {
    ...(userType !== 'learner' && {
      add_edit: false,
    }),
    view: false,
    ...(userType === 'system_manager' && {
      delete: false,
      export: false,
    }),
    ...(userType !== 'learner' && {
      import: false,
    }),
  };
  return {
    learners: {
      view: false,
      ...(userType === 'system_manager' && {
        export: false,
      }),
    },
    coaches: {
      view: false,
      ...(userType === 'system_manager' && {
        export: false,
      }),
    },
    sites: { ...sitesOption },
    classes: { ...ClassesOption },
  };
};

export const initialEditingRowData = {
  popup_notifications: true,
  email_notifications: true,
};

const permissionsCol = (permissions) => (
  <Col span={24} className='name-col'>
    <span>
      {Object.entries(permissions).map(([key, value]) => {
        let filtered = Object.entries(value).filter((el) => el[1]);
        if (filtered.length === 0) {
          return '';
        }
        let str = filtered
          .map((ele) => ele[0])
          .join(', ')
          .replace('_', '/');

        return (
          <div key={key}>
            {key.charAt(0).toUpperCase() + key.slice(1)} - {str}
          </div>
        );
      })}
    </span>
  </Col>
);

export const handlePermissionsCol = ({ permissions }) => {
  const { learners, coaches, sites, classes } = permissions;
  let updatedOrdered = { learners, coaches, sites, classes };

  return (
    <Row gutter={[0, 0]} className='permission-col'>
      {permissionsCol(updatedOrdered)}
    </Row>
  );
};

export const systemUsersOptions = [
  // 'administrative', 'staff',
  { label: 'Guardian', value: 'guardian' },
  { label: 'Student', value: 'student' },
  { label: 'Teacher', value: 'teacher' },
];

export const yearsLevels = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
];

export const genderOptions = [
  {
    label: 'Female',
    value: 'F',
  },
  {
    label: 'Male',
    value: 'M',
  },
];

export const raceOptions = [
  { key: 'african' },
  { key: 'asian' },
  { key: 'coloured' },
  { key: 'white' },
];

export const statusOptions = [{ key: 'Active' }, { key: 'Inactive' }];

export const martialOptions = [{ key: 'single' }, { key: 'married' }];

export const drivingOptions = [{ key: 'yes' }, { key: 'no' }];

export const nationalityOptions = [
  ...new Map(
    countriesList.filter(Boolean).map((item) => [item['key'], item])
  ).values(),
].sort((a, b) => a.key.localeCompare(b.key));

let AddViewDelete = (val) => ({
  view: val,
  add_edit: val,
  delete: val,
});

export const getLeadsOptions = (val) => {
  return {
    leads: {
      select_all: val,
      view: val,
      add_edit: val,
      delete: val,
    },
  };
};

export const systemPermissions = (val) => ({
  select_all: val,
  users_manager: AddViewDelete(val),
  contact_type: AddViewDelete(val),
  product_type: AddViewDelete(val),
  lead_statuses: AddViewDelete(val),
  quote_statuses: AddViewDelete(val),
  form_manager: AddViewDelete(val),
});

export const systemUserPermissions = (userType, val) => {
  if (['super_admin', 'system_admin'].includes(userType)) {
    return systemPermissions(val);
  }
  return false;
};

export const hideKeys = [
  'nationality',
  'id_number',
  'dob',
  'contact_no',
  'site_id',
  'kinship_attributes_name',
  'kinship_attributes_surname',
  'kinship_attributes_contact',
  'home_address',
  'postal_address',
];

export let user_roles = [
  { label: 'Administrative', value: 'administrative' },
  { label: 'Admin', value: 'admin' },
  { label: 'Assessor', value: 'assessor' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Student', value: 'student' },
  { label: 'Staff', value: 'staff' },
  { label: 'Teacher', value: 'teacher' },
];

export let progressStatuses = [
  { label: 'Advanced', value: 'Advanced' },
  { label: 'On Track', value: 'OnTrack' },
  { label: 'Slow Paced', value: 'SlowPaced' },
];

export let progressStatusesObj = {
  Advanced: 'Advanced',
  OnTrack: 'On Track',
  SlowPaced: 'Slow Paced',
};
