import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import SpinnerComponent from '../../../components/spinner';
import ByPerformance from './ByPerformance';
import ByDemographics from './ByDemographics';
import moment from 'moment';
import UseWindowSize from '../../container/sidebar/useWindowSize';

let format = 'DD MMMM YYYY';
const firstApril = moment().month(3).date(1).startOf('day').format(format);
const currentDate = moment().format(format);

const Graph = React.memo(({ graphByPerformance }) => {
  const screenWidth = UseWindowSize();
  const { fetchGraphLoading, fetchDocsLoading } = useSelector(
    (state) => state.learnersReducer
  );

  if (fetchGraphLoading || fetchDocsLoading) {
    return (
      <div className='spinner-div'>
        <SpinnerComponent fontSize={50} />
      </div>
    );
  }

  if (graphByPerformance) {
    return <ByPerformance screenWidth={screenWidth} />;
  } else if (!graphByPerformance) {
    return <ByDemographics screenWidth={screenWidth} />;
  }
});

const ReportingSection = () => {
  const [graphByPerformance, setGraphByPerformance] = useState(true);
  const { fetchGraphLoading } = useSelector((state) => state.learnersReducer);

  return (
    <Row className='doc-control-parent-row' style={{ minHeight: '50vh' }}></Row>
  );
  // return (
  //   <Row className='doc-control-parent-row'>
  //     <Col span={24} className='heading-col'>
  //       <Row justify='space-between' align='middle'>
  //         <Col span={14} md={14} lg={14} xl={14}>
  //           <span className='heading-span'>Reports</span>
  //         </Col>
  //         <Col className='heading-span'>
  //           <div className='date-div'>
  //             Date Range: {firstApril} - {currentDate}
  //           </div>
  //         </Col>
  //       </Row>
  //     </Col>

  //     <Col span={24} className='graph-section'>
  //       <Graph graphByPerformance={graphByPerformance} />
  //     </Col>
  //   </Row>
  // );
};

export default React.memo(ReportingSection);
