import * as constants from './constants';

export const setClassSessionDetailsIsEdit = (data) => {
  return {
    type: constants.CLASS_SESSIONS_DETAILS_IS_EDIT,
    data: data,
  };
};

export const createClassSession = (data) => {
  return {
    type: constants.CREATE_CLASS_SESSIONS,
    data: data,
  };
};

export const createClassSessionSuccess = (data) => {
  return {
    type: constants.CREATE_CLASS_SESSIONS_SUCCESS,
    data: data,
  };
};

export const createClassSessionError = () => {
  return {
    type: constants.CREATE_CLASS_SESSIONS_ERROR,
  };
};

export const fetchClassSession = (data) => {
  console.log('fetchClassSession', data);
  return {
    type: constants.FETCH_THE_CLASS_SESSIONS,
    data: data,
  };
};

export const fetchClassSessionSuccess = (data) => {
  return {
    type: constants.FETCH_CLASS_SESSIONS_SUCCESS,
    data,
  };
};

export const fetchClassSessionError = () => {
  return {
    type: constants.FETCH_CLASS_SESSIONS_ERROR,
  };
};

export const updateClassSession = (data) => {
  return {
    type: constants.UPDATE_CLASS_SESSIONS,
    data: data,
  };
};

export const updateClassSessionSuccess = (data) => {
  return {
    type: constants.UPDATE_CLASS_SESSIONS_SUCCESS,
    data: data,
  };
};

export const updateClassSessionError = () => {
  return {
    type: constants.UPDATE_CLASS_SESSIONS_ERROR,
  };
};

export const fetchClassSessionDetailsUtils = () => {
  return {
    type: constants.FETCH_CLASS_SESSIONS_UTIL_DATA,
  };
};

export const fetchClassSessionDetailsUtilsSuccess = (data) => {
  return {
    type: constants.FETCH_CLASS_SESSIONS_UTIL_DATA_SUCCESS,
    data,
  };
};

export const fetchAllClassSessions = (data) => {
  return {
    type: constants.FETCH_ALL_CLASS_SESSIONS,
    data,
  };
};

export const fetchAllClassSessionsSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_CLASS_SESSIONS_SUCCESS,
    data,
  };
};
export const fetchAllClassSessionsError = (data) => {
  return {
    type: constants.FETCH_ALL_CLASS_SESSIONS_ERROR,
    data,
  };
};
export const deleteClassSession = (data) => {
  return {
    type: constants.DELETE_CLASS_SESSIONS,
    data,
  };
};

export const deleteClassSessionSuccess = (data) => {
  return {
    type: constants.DELETE_CLASS_SESSIONS_SUCCESS,
    data,
  };
};

export const deleteClassSessionError = (data) => {
  return {
    type: constants.DELETE_CLASS_SESSIONS_ERROR,
    data,
  };
};

export const deleteClassSessionAttach = (data) => {
  return {
    type: constants.DELETE_CLASS_SESSIONS_ATTACH,
    data,
  };
};

export const deleteClassSessionAttachSuccess = (data) => {
  return {
    type: constants.DELETE_CLASS_SESSIONS_ATTACH_SUCCESS,
    data,
  };
};

export const deleteClassSessionAttachError = (data) => {
  return {
    type: constants.DELETE_CLASS_SESSIONS_ATTACH_ERROR,
    data,
  };
};

export const classSessionDetailCleanupFunc = () => {
  return {
    type: constants.CLASS_SESSIONS_DETAIL_CLEANUP_FUNC,
  };
};

export const setClassSessionNotificationIsEdit = (data) => {
  return {
    type: constants.CLASS_SESSIONS_NOTIF_IS_EDIT,
    data: data,
  };
};

export const updateClassSessionNotifications = (data) => {
  return {
    type: constants.UPDATE_CLASS_SESSIONS_NOTIFICATION,
    data: data,
  };
};

export const updateClassSessionNotificationsSuccess = (data) => {
  return {
    type: constants.UPDATE_CLASS_SESSIONS_NOTIFICATION_SUCCESS,
    data: data,
  };
};

export const updateClassSessionNotificationsError = () => {
  return {
    type: constants.UPDATE_CLASS_SESSIONS_NOTIFICATION_ERROR,
  };
};

export const updateSlotEnrollments = (data) => {
  return {
    type: constants.UPDATE_ENROLLMENTS,
    data: data,
  };
};

export const updateSlotEnrollmentsSuccess = (data) => {
  return {
    type: constants.UPDATE_ENROLLMENTS_SUCCESS,
    data: data,
  };
};

export const updateSlotEnrollmentsError = () => {
  return {
    type: constants.UPDATE_ENROLLMENTS_ERROR,
  };
};

export const setEnrollmentsIsEdit = (data) => {
  return {
    type: constants.ENROLLMENTS_IS_EDIT,
    data: data,
  };
};

export const setStorage = (data) => {
  return {
    type: constants.SET_CLASS_SESSIONS_MANAGER_STATE,
    data,
  };
};
