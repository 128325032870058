const CREATE_NPO = 'CREATE_LEARNING_ACTIVITIES';
const CREATE_NPO_SUCCESS = 'CREATE_LEARNING_ACTIVITIES_SUCCESS';
const CREATE_NPO_ERROR = 'CREATE_LEARNING_ACTIVITIES_ERROR';
const UPDATE_NPO = 'UPDATE_LEARNING_ACTIVITIES';
const UPDATE_NPO_SUCCESS = 'UPDATE_LEARNING_ACTIVITIES_SUCCESS';
const UPDATE_NPO_ERROR = 'UPDATE_LEARNING_ACTIVITIES_ERROR';
const FETCH_NPO = 'FETCH_LEARNING_ACTIVITIES';
const FETCH_NPO_SUCCESS = 'FETCH_LEARNING_ACTIVITIES_SUCCESS';
const FETCH_NPO_ERROR = 'FETCH_LEARNING_ACTIVITIES_ERROR';
const NPO_DETAILS_IS_EDIT = 'LEARNING_ACTIVITIES_DETAILS_IS_EDIT';
const ADD_USER_IN_MANAGER = 'ADD_USER_IN_MANAGER';
const FETCH_NPOS_UTIL_DATA = 'FETCH_LEARNING_ACTIVITIES_UTIL_DATA';
const FETCH_NPOS_UTIL_DATA_SUCCESS =
  'FETCH_LEARNING_ACTIVITIES_UTIL_DATA_SUCCESS';
const FETCH_ALL_NPOS = 'FETCH_ALL_LEARNING_ACTIVITIES';
const FETCH_ALL_NPOS_SUCCESS = 'FETCH_ALL_LEARNING_ACTIVITIES_SUCCESS';
const FETCH_ALL_NPOS_ERROR = ' FETCH_ALL_LEARNING_ACTIVITIES_ERROR';
const DELETE_NPO = 'DELETE_LEARNING_ACTIVITIES';
const DELETE_NPO_SUCCESS = 'DELETE_LEARNING_ACTIVITIES_SUCCESS';
const DELETE_NPO_ERROR = 'DELETE_LEARNING_ACTIVITIES_ERROR';
const DELETE_NPO_ATTACH = 'DELETE_LEARNING_ACTIVITIES_ATTACH';
const DELETE_NPO_ATTACH_SUCCESS = 'DELETE_LEARNING_ACTIVITIES_ATTACH_SUCCESS';
const DELETE_NPO_ATTACH_ERROR = 'DELETE_LEARNING_ACTIVITIES_ATTACH_ERROR';
const NPO_DETAIL_CLEANUP_FUNC = 'LEARNING_ACTIVITIES_DETAIL_CLEANUP_FUNC';
const NPO_NOTIF_IS_EDIT = 'LEARNING_ACTIVITIES_NOTIF_IS_EDIT';
const UPDATE_NPO_NOTIFICATION = 'UPDATE_LEARNING_ACTIVITIES_NOTIFICATION';
const UPDATE_NPO_NOTIFICATION_SUCCESS =
  'UPDATE_LEARNING_ACTIVITIES_NOTIFICATION_SUCCESS';
const UPDATE_NPO_NOTIFICATION_ERROR =
  'UPDATE_LEARNING_ACTIVITIES_NOTIFICATION_ERROR';
const SET_LEARNING_ACTIVITIES_MANAGER_STATE =
  'SET_LEARNING_ACTIVITIES_MANAGER_STATE';

export {
  CREATE_NPO,
  CREATE_NPO_SUCCESS,
  CREATE_NPO_ERROR,
  UPDATE_NPO,
  UPDATE_NPO_SUCCESS,
  UPDATE_NPO_ERROR,
  FETCH_NPO,
  FETCH_NPO_SUCCESS,
  FETCH_NPO_ERROR,
  ADD_USER_IN_MANAGER,
  NPO_DETAILS_IS_EDIT,
  FETCH_NPOS_UTIL_DATA,
  FETCH_NPOS_UTIL_DATA_SUCCESS,
  FETCH_ALL_NPOS,
  FETCH_ALL_NPOS_SUCCESS,
  FETCH_ALL_NPOS_ERROR,
  DELETE_NPO,
  DELETE_NPO_SUCCESS,
  DELETE_NPO_ERROR,
  DELETE_NPO_ATTACH,
  DELETE_NPO_ATTACH_SUCCESS,
  DELETE_NPO_ATTACH_ERROR,
  NPO_DETAIL_CLEANUP_FUNC,
  NPO_NOTIF_IS_EDIT,
  UPDATE_NPO_NOTIFICATION,
  UPDATE_NPO_NOTIFICATION_SUCCESS,
  UPDATE_NPO_NOTIFICATION_ERROR,
  SET_LEARNING_ACTIVITIES_MANAGER_STATE,
};
