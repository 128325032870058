import { Button } from 'antd';
import React, { useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';

const SignaturePadComponent = ({ signature, setData }) => {
  let sigPad = {};

  const onClear = () => {
    sigPad.clear();
    setData((prev) => ({
      ...prev,
      signature: null,
    }));
  };

  const trim = () => {
    let img = sigPad?.toDataURL('image/png');
    setData((prev) => ({
      ...prev,
      signature: img,
    }));
  };

  useEffect(() => {
    if (signature) {
      setTimeout(() => {
        initializeSignatureWithImage();
      }, 2000);
    }
  }, []);

  const initializeSignatureWithImage = () => {
    let canvas = sigPad.getCanvas();

    // You can load the previous image from your data source here and set it in the state.
    // For example, if you have a URL to the previous image, you can use it like this:
    const previousImageUrl = signature;

    const img = new Image();
    img.src = previousImageUrl;

    img.onload = () => {
      // Once the image is loaded, draw it on the canvas
      canvas.getContext('2d').drawImage(img, 0, 0);
      //   setPreviousImage(previousImageUrl);
      setData((prev) => ({
        ...prev,
        signature: previousImageUrl,
      }));
    };
  };

  return (
    <div className='container'>
      <div className='sigContainer'>
        <SignaturePad
          canvasProps={{ className: 'sigPad' }}
          ref={(ref) => {
            sigPad = ref;
          }}
          onEnd={trim}
        />
      </div>
      <div>
        <Button className='clear-btn' onClick={onClear}>
          Clear
        </Button>
        {/* <button className='buttons' onClick={trim}>
          Trim
        </button> */}
      </div>
      {/* {trimmedDataURL ? (
        <img alt='' className='sigImage' src={trimmedDataURL} />
      ) : null} */}
    </div>
  );
};

export default SignaturePadComponent;
