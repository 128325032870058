import * as constants from './constants';

const initialState = {
  allRooms: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
  search: {},
};

const roomsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_ROOMS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_ROOMS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allRooms: [action.data.room, ...state.allRooms],
        isAdding: false,
      };
    case constants.CREATE_ROOMS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_ORDER_ROOMS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_ORDER_ROOMS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allRooms: action.data,
      };
    case constants.UPDATE_ORDER_ROOMS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_ROOMS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_ROOMS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allRooms: action.data.rooms,
        isAdding: false,
      };
    case constants.FETCH_ROOMS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_ROOMS:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_ROOMS_SUCCESS:
      const updated = state.allRooms.map((el) =>
        el._id === action.data.room._id ? { ...el, ...action.data.room } : el
      );
      return {
        ...state,
        allRooms: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_ROOMS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_ROOMS:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_ROOMS_SUCCESS:
      const updatedData = state.allRooms.filter((el) => el._id !== action.data);
      return {
        ...state,
        allRooms: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_ROOMS_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    case constants.SET_ROOMS_MANAGER_STATE:
      const { search } = state;

      localStorage.setItem(
        'roomsManagerState',
        JSON.stringify({ search, ...action.data })
      );
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default roomsReducer;
