import React from 'react';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import { options } from './utils';

export const EditApplicationBtn = ({ formData, handleEdit }) => {
  return (
    <Row justify='center'>
      <Col xxl={20} className='edit-application-parent'>
        <Row justify='center' gutter={[0, 15]}>
          <Col span={23} className='name-col'>
            {formData.contract_name}
          </Col>
          <Col span={23} className='edit-btn-col'>
            <Button onClick={handleEdit}>Edit Application</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const CustomerType = ({ setData, data }) => {
  const handleChange = (e) => {
    const { value, checked } = e.target;
    let customerType = checked ? value : null;
    setData({ ...data, customerType });
  };

  return (
    <Col span={24} className='edit-btn-col'>
      <Row justify='center' style={{ width: '100%' }}>
        <Form.Item
          name='customerType'
          label=''
          style={{ width: '100%' }}
          wrapperCol={24}
        >
          <Checkbox.Group style={{ width: '100%' }}>
            <Row justify='center' style={{ width: '100%' }} gutter={[50, 0]}>
              {options.map((item) => {
                const { key, label, subTitle, value } = item;
                return (
                  <Col className='customer-type-col' key={key}>
                    <Checkbox
                      onChange={handleChange}
                      key='new'
                      checked={value === data.customerType}
                      value={value}
                      style={{
                        lineHeight: '32px',
                      }}
                      disabled={
                        data.customerType && value !== data.customerType
                      }
                    >
                      <div>
                        <div className='label'>{label}</div>
                        <div className='sub-label'>{subTitle}</div>
                      </div>
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Row>
    </Col>
  );
};

export { CustomerType };
