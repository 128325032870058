import { message } from 'antd';
import axios from 'axios';

export const baseURL = process.env.REACT_APP_BASE_URL;
// export const cableBaseURL = "ws://68.183.127.76:3000/cable";
export const isValidNumber = /^[0-9]+$/i;
export const isValidNumberWithForwardSlash = /^[0-9/]+$/i;

axios.defaults.baseURL = baseURL;

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (
      err.response.data?.message?.toLowerCase() ===
      'invalid authorization token'
    ) {
      localStorage.clear();
      localStorage.removeItem('user');
      axios.defaults.headers.common['Authorization'] = null;
      message.error('Session has been expired!');
      window.location.replace('/');
    }
    return Promise.reject(err);
  }
);
