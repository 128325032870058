import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';
import moment from 'moment';

export function* handleCreateClass({ data }) {
  try {
    const response = yield axios.post(`${baseURL}/api/v2/admin/classes`, data, {
      headers: {
        'content-type': 'application/json',
      },
    });
    const { message, success } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createClassSuccess(response.data?.data?.class));
    } else {
      AntdAlert.error(message);
      yield put(actions.createClassError());
    }
  } catch (error) {
    yield put(actions.createClassError());
    AntdAlert.error(error?.response?.data?.message || 'Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateClass({ data }) {
  try {
    const response = yield axios.put(`${baseURL}/api/v2/admin/classes`, data, {
      headers: {
        'content-type': 'application/json',
      },
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success('Class updated successfully!');
      const { data } = response.data;
      yield put(actions.updateClassSuccess(data.class));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateClassError());
    }
  } catch (error) {
    console.log(error);
    yield put(actions.updateClassError());
    AntdAlert.error(error?.response?.data?.message || 'Something went wrong!');
  }
}

export function* handleFetchAllClasses({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
    if (key?.includes('date'))
      search[key] = moment(search[key]).format('YYYY-MM-DD');
  });

  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/admin/classes/classes-list-filtered`,
      {
        params: {
          ...rest,
          ...(Object.keys(search).length > 0 && {
            ...search,
          }),
        },
      }
    );
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchAllClassesSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.fetchAllClassesError());
    }
  } catch ({ response }) {
    yield put(actions.fetchAllClassesError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleDeleteClass({ data }) {
  try {
    const response = yield axios.delete(`${baseURL}/api/v2/admin/classes/${data.id}`);
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteClassSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteClassError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteClassError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleFetchClass({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/admin/classes/fetch-by-id/${data}`
    );

    if (response.data?.data?.class) {
      yield put(actions.fetchClassSuccess(response.data.data.class));
    } else {
      yield put(actions.fetchClassError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchClassError());
    console.log(error?.response?.data?.message || 'Something went wrong!');
  }
}

export function* watcherClassesSaga() {
  yield takeEvery(constants.CREATE_CLASS, handleCreateClass);
  yield takeEvery(constants.UPDATE_CLASS, handleUpdateClass);
  yield takeEvery(constants.FETCH_CLASS, handleFetchClass);
  yield takeEvery(constants.FETCH_ALL_CLASSES, handleFetchAllClasses);
  yield takeEvery(constants.DELETE_CLASS, handleDeleteClass);
}
