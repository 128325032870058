import * as constants from './constants';

const initialState = {
  statusOptions: [],
  allClassSessions: [],
  currentClassSessionObj: {
    onboard_date: null,
  },
  utilsData: {},
  updateNotLoading: false,
  departmentsList: [],
  createClassSessionsLoading: false,
  classSessionDetailsIsEdit: false,
  fetchClassSessionsLoading: false,
  deleteClassSessionsLoading: false,
  fetchDetailLoading: false,
  totalRecords: 0,
  deleteAttachLoading: false,
  classSessionNotificationIsEdit: false,
  attempts: [],
  attendances: [],
  slotBookingsLoading: false,
  bookingIsEdit: false,
  sortedInfo: null,
  page: 1,
  resultPerPage: 10,
  search: {},
};

const classSessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_CLASS_SESSIONS_UTIL_DATA_SUCCESS:
      // const { sales_reps } = action.data;
      return {
        ...state,
        utilsData: {
          ...action.data,
        },
      };
    case constants.CLASS_SESSIONS_DETAILS_IS_EDIT:
      return {
        ...state,
        classSessionDetailsIsEdit: action.data,
      };

    case constants.CREATE_CLASS_SESSIONS:
      return {
        ...state,
        createClassSessionsLoading: true,
      };
    case constants.CREATE_CLASS_SESSIONS_SUCCESS:
      return {
        ...state,
        createClassSessionsLoading: false,
        currentClassSessionObj: action.data,
        classSessionDetailsIsEdit: false,
      };
    case constants.CREATE_CLASS_SESSIONS_ERROR:
      return {
        ...state,
        createClassSessionsLoading: false,
      };
    case constants.UPDATE_CLASS_SESSIONS:
      return {
        ...state,
        createClassSessionsLoading: true,
      };
    case constants.UPDATE_CLASS_SESSIONS_SUCCESS:
      return {
        ...state,
        createClassSessionsLoading: false,
        currentClassSessionObj: action.data,
        classSessionDetailsIsEdit: false,
      };
    case constants.UPDATE_CLASS_SESSIONS_ERROR:
      return {
        ...state,
        createClassSessionsLoading: false,
      };

    case constants.UPDATE_CLASS_SESSIONS_NOTIFICATION:
      return {
        ...state,
        updateNotLoading: true,
      };
    case constants.UPDATE_CLASS_SESSIONS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        updateNotLoading: false,
        classSessionNotificationIsEdit: false,
      };
    case constants.UPDATE_CLASS_SESSIONS_NOTIFICATION_ERROR:
      return {
        ...state,
        updateNotLoading: false,
      };

    case constants.FETCH_ALL_CLASS_SESSIONS:
      return {
        ...state,
        fetchClassSessionsLoading: true,
      };
    case constants.FETCH_ALL_CLASS_SESSIONS_SUCCESS:
      const { classSessions, total } = action.data;
      return {
        ...state,
        allClassSessions: classSessions,
        fetchClassSessionsLoading: false,
        totalRecords: total,
      };
    case constants.FETCH_ALL_CLASS_SESSIONS_ERROR:
      return {
        ...state,
        fetchClassSessionsLoading: false,
      };
    case constants.DELETE_CLASS_SESSIONS:
      return {
        ...state,
        deleteClassSessionsLoading: true,
      };
    case constants.DELETE_CLASS_SESSIONS_SUCCESS:
      let updatedClassSessions = state.allClassSessions.filter(
        (el) => el.id !== action.data.id
      );
      let totalRecords = state.totalRecords - 1;
      let lselectedClassSessions = JSON.parse(
        localStorage.getItem('lsselectedClassSessions')
      );

      if (lselectedClassSessions?.length > 0) {
        let lisCheck = JSON.parse(localStorage.getItem('lsisCheck'));
        let llist = JSON.parse(localStorage.getItem('lslist'));
        let lfilteredClassSessions = JSON.parse(
          localStorage.getItem('lsfilteredClassSessions')
        );
        let updatedlsselectedClassSessions = lselectedClassSessions.filter(
          (classSession) => classSession.id !== action.data.id
        );

        if (updatedlsselectedClassSessions.length > 0) {
          localStorage.setItem(
            'lsselectedClassSessions',
            JSON.stringify(updatedlsselectedClassSessions)
          );

          if (lselectedClassSessions !== null) {
            let userPojectsIds = updatedClassSessions.map((el) => el.id);

            let updatedLsfilteredClassSessions = lfilteredClassSessions.filter(
              (el) => userPojectsIds.includes(el.id)
            );

            let updatedlsisCheck = lisCheck.filter((id) =>
              userPojectsIds.includes(id)
            );
            let updatedlslist = llist.filter((el) =>
              userPojectsIds.includes(el.id)
            );

            localStorage.setItem(
              'lsselectedClassSessions',
              JSON.stringify(updatedlsselectedClassSessions)
            );
            localStorage.setItem(
              'lsfilteredClassSessions',
              JSON.stringify(updatedLsfilteredClassSessions)
            );

            localStorage.setItem('lsisCheck', JSON.stringify(updatedlsisCheck));
            localStorage.setItem('lslist', JSON.stringify(updatedlslist));
          }
        } else {
          localStorage.removeItem('lsselectedClassSessions');
          localStorage.removeItem('lsfilteredClassSessions');
          localStorage.removeItem('lsisCheck');
          localStorage.removeItem('lslist');
        }
      }

      return {
        ...state,
        totalRecords: totalRecords,
        deleteClassSessionsLoading: false,
        allClassSessions: updatedClassSessions,
      };
    case constants.DELETE_CLASS_SESSIONS_ERROR:
      return {
        ...state,
        deleteClassSessionsLoading: false,
      };

    case constants.DELETE_CLASS_SESSIONS_ATTACH:
      return {
        ...state,
        deleteAttachLoading: true,
      };
    case constants.DELETE_CLASS_SESSIONS_ATTACH_SUCCESS:
      const { id } = action.data;

      let updatedDocs = state.currentClassSessionObj.document_urls.filter(
        (el) => el.id != id
      );

      return {
        ...state,
        deleteAttachLoading: false,
        currentClassSessionObj: {
          ...state.currentClassSessionObj,
          document_urls: updatedDocs,
        },
      };
    case constants.DELETE_CLASS_SESSIONS_ATTACH_ERROR:
      return {
        ...state,
        deleteAttachLoading: false,
      };

    case constants.FETCH_THE_CLASS_SESSIONS:
      return {
        ...state,
        // departmentLoading: true,
        fetchDetailLoading: true,
      };
    case constants.FETCH_CLASS_SESSIONS_SUCCESS:
      const { classSession, attempts, attendances = [] } = action.data;
      return {
        ...state,
        currentClassSessionObj: classSession,
        fetchDetailLoading: false,
        attempts: attempts || [],
        attendances: attendances,
      };
    case constants.FETCH_CLASS_SESSIONS_ERROR:
      return {
        ...state,
        fetchDetailLoading: false,
      };

    case constants.CLASS_SESSIONS_NOTIF_IS_EDIT:
      return {
        ...state,
        classSessionNotificationIsEdit: action.data,
      };

    case constants.UPDATE_ENROLLMENTS:
      return {
        ...state,
        slotBookingsLoading: true,
      };
    case constants.UPDATE_ENROLLMENTS_SUCCESS:
      // const { attempts } = action.data;
      return {
        ...state,
        slotBookingsLoading: false,
        // attempts: attempts,
        bookingIsEdit: false,
      };
    case constants.UPDATE_ENROLLMENTS_ERROR:
      return {
        ...state,
        slotBookingsLoading: false,
      };
    case constants.ENROLLMENTS_IS_EDIT:
      return {
        ...state,
        bookingIsEdit: action.data,
      };
    case constants.SET_CLASS_SESSIONS_MANAGER_STATE:
      const { sortedInfo, page, resultPerPage, search } = state;

      const storageData = { sortedInfo, page, resultPerPage, search };

      localStorage.setItem(
        'classSessionsManagerState',
        JSON.stringify({ ...storageData, ...action.data })
      );

      return {
        ...state,
        ...action.data,
      };

    case constants.CLASS_SESSIONS_DETAIL_CLEANUP_FUNC:
      return {
        ...state,
        allClassSessions: [],
        createClassSessionsLoading: false,
        currentClassSessionObj: {},
        classSessionDetailsIsEdit: false,
        fetchClassSessionsLoading: false,
        deleteClassSessionsLoading: false,
        classSessionNotificationIsEdit: false,
      };
    default:
      return state;
  }
};

export default classSessionsReducer;
