const FETCH_STREAMS = 'FETCH_STREAMS';
const FETCH_STREAMS_SUCCESS = 'FETCH_STREAMS_SUCCESS';
const FETCH_STREAMS_ERROR = 'FETCH_STREAMS_ERROR';
const UPDATE_STREAMS = 'UPDATE_STREAMS';
const UPDATE_STREAMS_SUCCESS = 'UPDATE_STREAMS_SUCCESS';
const UPDATE_STREAMS_ERROR = 'UPDATE_STREAMS_ERROR';
const DELETE_STREAMS = 'DELETE_STREAMS';
const DELETE_STREAMS_SUCCESS = 'DELETE_STREAMS_SUCCESS';
const DELETE_STREAMS_ERROR = 'DELETE_STREAMS_ERROR';
const CREATE_STREAMS = 'CREATE_STREAMS';
const CREATE_STREAMS_SUCCESS = 'CREATE_STREAMS_SUCCESS';
const CREATE_STREAMS_ERROR = 'CREATE_STREAMS_ERROR';
const UPDATE_ORDER_STREAMS = 'UPDATE_ORDER_STREAMS';
const UPDATE_ORDER_STREAMS_SUCCESS = 'UPDATE_ORDER_STREAMS_SUCCESS';
const UPDATE_ORDER_STREAMS_ERROR = 'UPDATE_ORDER_STREAMS_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';
const SET_STREAMS_MANAGER_STATE = 'SET_STREAMS_MANAGER_STATE';

export {
  FETCH_STREAMS,
  FETCH_STREAMS_SUCCESS,
  FETCH_STREAMS_ERROR,
  UPDATE_STREAMS,
  UPDATE_STREAMS_SUCCESS,
  UPDATE_STREAMS_ERROR,
  DELETE_STREAMS,
  DELETE_STREAMS_SUCCESS,
  DELETE_STREAMS_ERROR,
  CREATE_STREAMS,
  CREATE_STREAMS_SUCCESS,
  CREATE_STREAMS_ERROR,
  UPDATE_ORDER_STREAMS,
  UPDATE_ORDER_STREAMS_SUCCESS,
  UPDATE_ORDER_STREAMS_ERROR,
  IS_ADDING_UPDATE,
  SET_STREAMS_MANAGER_STATE,
};
