import React from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import PlansComponent from './plansComponents';

const FormSetupDetail = ({ isUpdate, canUpdate }) => {
  const { currentTemplateObj } = useSelector((state) => state.formSetupReducer);

  return (
    <>
      <Row>
        <Col span={24} className='heading-col'>
          <Row align='middle' justify='space-between'>
            <Col>
              <div>
                <span className='heading-span'>
                  {/* {currentTemplateObj?.name} */}
                  FORM SETUP
                  {/* {isUpdate ? ' UPDATE' : ' CREATION'} */}
                </span>
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={24} className={`user-details-section document-type-section`}>
          <Row justify='center'>
            <PlansComponent isUpdate={isUpdate} canUpdate={canUpdate} />
          </Row>
        </Col>
      </Row>
      <div className='empty-div'></div>
    </>
  );
};
export default FormSetupDetail;
