import * as constants from './constants';

export const fetchRooms = (data) => {
  return {
    type: constants.FETCH_ROOMS,
    data,
  };
};

export const fetchRoomsSuccess = (data) => {
  return {
    type: constants.FETCH_ROOMS_SUCCESS,
    data: data,
  };
};

export const fetchRoomsError = (data) => {
  return {
    type: constants.FETCH_ROOMS_ERROR,
    data: data,
  };
};

export const updateRooms = (data) => {
  return {
    type: constants.UPDATE_ROOMS,
    data: data,
  };
};

export const updateRoomsSuccess = (data) => {
  return {
    type: constants.UPDATE_ROOMS_SUCCESS,
    data: data,
  };
};

export const updateRoomsError = () => {
  return {
    type: constants.UPDATE_ROOMS_ERROR,
  };
};

export const updateRoomsOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_ROOMS,
    data: data,
  };
};

export const updateRoomsOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_ROOMS_SUCCESS,
    data: data,
  };
};

export const updateRoomsOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_ROOMS_ERROR,
  };
};

export const deleteRooms = (data) => {
  return {
    type: constants.DELETE_ROOMS,
    data: data,
  };
};

export const deleteRoomsSuccess = (data) => {
  return {
    type: constants.DELETE_ROOMS_SUCCESS,
    data: data,
  };
};

export const deleteRoomsError = () => {
  return {
    type: constants.DELETE_ROOMS_ERROR,
  };
};

export const createRooms = (data, id) => {
  return {
    type: constants.CREATE_ROOMS,
    data: data,
  };
};

export const createRoomsSuccess = (data) => {
  return {
    type: constants.CREATE_ROOMS_SUCCESS,
    data: data,
  };
};

export const createRoomsError = (id) => {
  return {
    type: constants.CREATE_ROOMS_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};

export const setStorage = (data) => {
  return {
    type: constants.SET_ROOMS_MANAGER_STATE,
    data,
  };
};
