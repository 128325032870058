import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import './index.less';
import { useDispatch, useSelector } from 'react-redux';
// import { setNpoDetailsIsEdit } from './redux/actions';
import Table from './Table';
// import { PlusOutlined } from '@ant-design/icons';
// import { fetchRooms } from '../rooms/redux/actions';
import { fetchAllUsers } from '../UserManager/redux/actions';
// import { fetchNpoStatuses } from '../NpoStatuses/redux/actions';
// import { fetchNpoRegions } from '../NpoRegions/redux/actions';
// import ExportComponent from './export';
// import Tooltip from '../../components/tooltip-Custom';

const ClassesSessions = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [canUpdate, setCanUpdate] = useState(true);
  const [assessorOptions, setAssessorOptions] = useState([]);

  const { allUsers } = useSelector((state) => state.userManagerReducer);

  useEffect(() => {
    if (user?.permissions?.npo_setup?.add_edit) {
      setCanUpdate(true);
    }
  }, [user]);

  useEffect(() => {
    dispatch(
      fetchAllUsers({
        page_size: 999999999,
        page: 1,
        sort: 'first_name',
        order: 1,
        search: {
          //   roles: 'assessor',
        },
      })
    );
  }, []);

  useEffect(() => {
    if (allUsers?.length > 0) {
      const updatedAssessorOptions = allUsers
        // .filter((el) => el?.roles?.some((role) => ['assessor', 'teacher'].includes(role)))
        .map((item) => ({
          label: `${item.first_name} ${item.last_name} - ${item.email}`,
          value: item._id,
        }));
      setAssessorOptions(updatedAssessorOptions);
    }
  }, [allUsers]);

  return (
    <Table
      canUpdate={canUpdate}
      canDelete={user?.permissions?.npo_setup?.delete || false}
      assessorOptions={assessorOptions}
    />
  );
};

export default ClassesSessions;
