import { Col, List, Row, Table } from 'antd';
import React, { useState } from 'react';

const ResponseDetails = ({ responseData }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpand = (record) => {
    if (expandedRowKeys.includes(record._id)) {
      setExpandedRowKeys([]);
    } else {
      const keys = [];
      keys.push(record._id);
      setExpandedRowKeys(keys);
    }
  };

  const columns = [
    {
      title: 'Class ID',
      dataIndex: 'class_id',
      key: 'class_id',
      align: 'left',
      width: '30%',
      render: (title, record) => {
        return <div onClick={() => handleExpand(record)}>{title}</div>;
      },
    },
    {
      title: 'class code',
      dataIndex: 'class_code',
      key: 'class_code',
      align: 'center',
      width: '30%',
      render: (title, record) => {
        return <div onClick={() => handleExpand(record)}>{title}</div>;
      },
    },
    {
      title: 'class name',
      dataIndex: 'class_name',
      key: 'class_name',
      align: 'center',
      width: '30%',
      render: (title, record) => {
        return <div onClick={() => handleExpand(record)}>{title}</div>;
      },
    },
  ];

  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record._id);
    }
    setExpandedRowKeys(keys);
  };

  let records = responseData?.failedRecords || [];
  let updatedRecords = records.map((record, i) => {
    return {
      ...record,
      _id: i,
    };
  });

  return (
    <Row>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[0, 0]}>
          <Col>
            <div>
              <span className='heading-span'>Failed records details</span>
            </div>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row justify='center' className=''>
          <Col span={24} style={{ marginBottom: '30px' }}>
            <Table
              className='special-table activity-type-table project-statuses-table'
              style={{
                marginTop: '0px',
              }}
              columns={columns}
              pagination={false}
              scroll={{ x: 'max-content' }}
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <List
                      size='small'
                      header={null}
                      footer={null}
                      bordered
                      dataSource={record.errors}
                      renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                  );
                },
                expandRowByClick: true,
                // expandIcon: null,
                showExpandColumn: true,
                expandedRowKeys: expandedRowKeys,
                onExpand: onTableRowExpand,
                // rowExpandable: ({ _id }) => expandedRowKeys.includes(_id),
              }}
              dataSource={updatedRecords}
              onRow={({ _id }) => {
                return (
                  expandedRowKeys.includes(_id) && {
                    className:
                      'expand-parent expand-doc-parent fourth-level-expanded-row',
                  }
                );
              }}
              rowKey={(record) => record._id}
              rowClassName='editable-row'
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default ResponseDetails;
