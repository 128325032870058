import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, InputNumber, Row } from 'antd';
import React from 'react';
import { ReactComponent as CrossIcon } from '../../../assets/crossIcon.svg';

const Plans = ({ plans, setPlans, canUpdate }) => {
  const addPlan = () => {
    setPlans([
      ...plans,
      {
        planName: '',
        speed: '',
        dataLimit: '',
        priceNow: null,
        priceWas: null,
        hasDiscount: false,
      },
    ]);
  };

  const removePlan = (i) => {
    const plansCopy = [...plans];
    plansCopy.splice(i, 1);
    setPlans(plansCopy);
  };

  const onChange = (value, name, i) => {
    const newData = [...plans];
    newData[i][name] = value;
    setPlans(newData);
  };

  return (
    <>
      <Col md={24} lg={23} xl={23} xxl={22}>
        <Row justify='start' gutter={[15, 30]}>
          {plans.map((el, i) => {
            let planLength = plans.length;
            let isLast = planLength === i + 1;
            const {
              planName,
              speed,
              dataLimit,
              priceNow,
              priceWas,
              hasDiscount,
            } = el;
            return (
              <Col md={8} lg={8} xl={6} xxl={6} className='plan-parent-col'>
                <div className='plan-parent-div'>
                  <div className='header'>
                    <div className='name'>{`PLAN ${i + 1}`}</div>
                    <div>
                      {canUpdate && (
                        <CrossIcon
                          className='custom-icon'
                          onClick={() => removePlan(i)}
                        />
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className='label-div'>
                    <label>NAME</label>
                    <Input
                      value={planName}
                      placeholder='Insert Plan Name'
                      onChange={(e) => onChange(e.target.value, 'planName', i)}
                      readOnly={!canUpdate}
                    />
                  </div>
                  <div className='label-div'>
                    <label>SPEED</label>
                    <Input
                      value={speed}
                      placeholder='Insert Plan Speed'
                      onChange={(e) => onChange(e.target.value, 'speed', i)}
                      readOnly={!canUpdate}
                    />
                  </div>
                  <div className='label-div'>
                    <label>DATA LIMIT</label>
                    <Input
                      value={dataLimit}
                      placeholder='Insert Data Limit'
                      onChange={(e) => onChange(e.target.value, 'dataLimit', i)}
                      readOnly={!canUpdate}
                    />
                  </div>
                  <div className='label-div'>
                    <label>PRICE</label>
                    <div className='price-div'>
                      <div className='text r'>R</div>
                      <InputNumber
                        placeholder='Insert Price'
                        value={priceNow}
                        onChange={(e) => onChange(e, 'priceNow', i)}
                        readOnly={!canUpdate}
                      />
                      {hasDiscount && <div className='text'>WAS</div>}
                    </div>
                    {hasDiscount && (
                      <div className='price-div'>
                        <div className='text r'>R</div>
                        <InputNumber
                          placeholder='Insert Price'
                          value={priceWas}
                          onChange={(e) => onChange(e, 'priceWas', i)}
                          readOnly={!canUpdate}
                        />
                        <div className='text'>NOW</div>
                      </div>
                    )}
                    <div className='price-div'>
                      <span className='text r' style={{ opacity: 0 }}>
                        R
                      </span>
                      <Checkbox
                        checked={hasDiscount}
                        disabled={!canUpdate}
                        onChange={(e) =>
                          onChange(e.target.checked, 'hasDiscount', i)
                        }
                      >
                        ADD DISCOUNTED PRICE
                      </Checkbox>
                    </div>
                  </div>
                </div>

                {isLast &&
                  plans.length > 0 &&
                  plans.length < 8 &&
                  canUpdate && (
                    //   <Col span={22} offset={1}>
                    <div className='add-btn-div'>
                      <Button
                        onClick={addPlan}
                        icon={<PlusOutlined />}
                        className='add-plan-btn'
                      >
                        CONNECTIVITY PLAN
                      </Button>
                    </div>
                    //   </Col>
                  )}
              </Col>
            );
          })}
          {plans.length === 0 && (
            //    canUpdate &&
            <Col span={22} offset={1}>
              <Button
                onClick={addPlan}
                icon={<PlusOutlined />}
                className='add-plan-btn'
              >
                CONNECTIVITY PLAN
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </>
  );
};

export default Plans;
