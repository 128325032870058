import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';
import moment from 'moment';

export function* handleCreateNpo({ data }) {
  try {
    const response = yield axios.post(`${baseURL}/api/v2/admin/slots`, data, {
      headers: {
        'content-type': 'application/json',
      },
    });
    const { message } = response.data;
    if (response.data?.data?.slot) {
      AntdAlert.success(message);
      yield put(actions.createNpoSuccess(response.data?.data?.slot));
    }
  } catch (error) {
    yield put(actions.createNpoError());
    AntdAlert.error(error?.response?.data?.message || 'Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateNpo({ data }) {
  try {
    const response = yield axios.put(`${baseURL}/api/v2/admin/slots`, data, {
      headers: {
        'content-type': 'application/json',
      },
    });
    // const { success, message } = response.data;
    if (response.data?.data?.slot) {
      AntdAlert.success('Slot updated successfully!');
      const { slot } = response.data.data;
      yield put(actions.updateNpoSuccess(slot));
    }
    // else if (!success) {
    //   AntdAlert.error(response.data?.errors[0]);
    //   yield put(actions.updateNpoError());
    //   errorHandler(response.data);
    // }
  } catch (error) {
    console.log('error?.response', error?.response);
    yield put(actions.updateNpoError());
    AntdAlert.error(error?.response?.data.message || 'Something went wrong!');
  }
}

export function* handleFetchAllNpos({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
    if (key?.includes('date'))
      search[key] = moment(search[key]).format('YYYY-MM-DD');
  });

  try {
    const response = yield axios.get(`${baseURL}/api/v2/admin/slots`, {
      params: {
        ...rest,
        ...(Object.keys(search).length > 0 && {
          ...search,
        }),
      },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchAllNposSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.fetchAllNposError());
    }
  } catch ({ response }) {
    yield put(actions.fetchAllNposError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleDeleteNpo({ data }) {
  try {
    const response = yield axios.delete(`${baseURL}/api/v2/npos/${data.id}`);
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteNpoSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteNpoError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteNpoError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleFetchNpo({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/admin/slots/fetch-by-id/${data}`
    );

    if (response.data?.data?.slot) {
      // const { permissions, ...res } = response.data.data;
      yield put(actions.fetchNpoSuccess(response.data.data));
    } else {
      yield put(actions.updateNpoError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.updateNpoError());
    console.log(error?.response?.data?.message || 'Something went wrong!');
  }
}

export function* handleDeleteNpoAttach({ data }) {
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/npos/delete_npo_attachment`,
      {
        params: {
          id: data,
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteNpoAttachSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteNpoAttachError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteNpoAttachError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleUpdateNpoNotifications({ data }) {
  try {
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }
    const response = yield axios.put(`${baseURL}/api/v2/npos/${data.id}`, fd, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateNpoNotificationsSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.updateNpoNotificationsError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateEnrollments({ data }) {
  try {
    const response = yield axios.post(
      `${baseURL}/api/v2/admin/slot-bookings`,
      data,
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    );
    // const { success, message } = response.data;
    if (response.data?.data?.slot) {
      AntdAlert.success('Slot updated successfully!');
      const { slot } = response.data.data;
      yield put(actions.updateSlotEnrollmentsSuccess(slot));
    } else {
      yield put(actions.updateSlotEnrollmentsError());
    }
  } catch (error) {
    console.log(error);
    yield put(actions.updateSlotEnrollmentsError());
    AntdAlert.error('Something went wrong!');
  }
}

export function* watcherSlotsSaga() {
  yield takeEvery(constants.CREATE_NPO, handleCreateNpo);
  yield takeEvery(constants.UPDATE_NPO, handleUpdateNpo);
  yield takeEvery(constants.FETCH_NPO, handleFetchNpo);
  yield takeEvery(constants.FETCH_ALL_NPOS, handleFetchAllNpos);
  yield takeEvery(constants.UPDATE_ENROLLMENTS, handleUpdateEnrollments);
  // yield takeEvery(constants.DELETE_NPO, handleDeleteNpo);
}
