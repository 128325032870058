import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleFetchSiteStatuses({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/statuses?type=site`,
      {}
    );
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchSiteStatusesSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchSiteStatusesError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateSiteStatuses({ data }) {
  const { color, id, show_by_default, name } = data;

  try {
    const response = yield axios.put(
      `${baseURL}/api/v2/statuses/${id}?type=site`,
      {
        show_by_default,
        name,
        color,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateSiteStatusesSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateSiteStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateSiteStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleDeleteSiteStatuses({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/statuses/${id}?type=site`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteSiteStatusesSuccess(id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteSiteStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteSiteStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleCreateSiteStatuses({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData.id;
    const response = yield axios.post(`${baseURL}/api/v2/statuses`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createSiteStatusesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createSiteStatusesError(data.id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateSiteOrder({ data }) {
  const fd = new FormData();
  data.map((el, i) => fd.append(`statuses[${el.id}]`, i + 1));

  try {
    const response = yield axios.patch(`${baseURL}/api/v2/statuses/sort`, fd);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateSiteOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateSiteOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateSiteOrderError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}
