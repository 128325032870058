export const contractTermOptions = [
  {
    key: '1',
    label: '24 Months',
    value: 'twentyFourMonths',
  },
  {
    key: '2',
    label: '12 Months',
    value: 'twelveMonths',
  },
  {
    key: '3',
    label: 'Month to Month',
    value: 'monthToMonth',
  },
];

export const options = [
  {
    key: 'newCustomer',
    label: 'New Customer',
    subTitle: '(Please complete Sections A, B, D & E)',
    value: 'new',
  },
  {
    key: 'Existing Customer',
    label: 'Existing Customer',
    subTitle: '(Please complete Sections A, B, C & E)',
    value: 'existing',
  },
];

export const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};

export const initialState = {
  customerType: null,
  contractTerm: null,
  contractCover: false,
  connectivityPlan: null,
  fibreFixedVoice: null,
  otherValueAdded: null,
  interimLteAccess: null,
  accountType: null,
  setFullName: '',
  termsAndConditions: false,
  printedType: '',
  declarationEmail: '',
  signature: null,
  signatureDate: null,
};

export const provinceOptions = [
  {
    label: 'KwaZulu-Natal',
    value: 'kwaZuluNatal',
  },
  {
    label: 'Limpopo',
    value: 'limpopo',
  },
  {
    label: 'Eastern Cape',
    value: 'easternCape',
  },
  {
    label: 'Northern West',
    value: 'northernWest',
  },
  {
    label: 'Mpumalanga',
    value: 'mpumalanga',
  },
  {
    label: 'Western Cape',
    value: 'westernCape',
  },
  {
    label: 'Northern Cape',
    value: 'northernCape',
  },
  {
    label: 'Gauteng',
    value: 'gauteng',
  },
  {
    label: 'Free State',
    value: 'freeState',
  },
  {
    label: 'Cape Province',
    value: 'capeProvince',
  },
  {
    label: 'kwaNdebele',
    value: 'kwaNdebele',
  },
  {
    label: 'Bhisho',
    value: 'bhisho',
  },
  {
    label: 'Venda',
    value: 'venda',
  },
];
