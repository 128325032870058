import { Input, Form, DatePicker, Select } from 'antd';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode = null;
  const { allContactTypes } = useSelector((state) => state.contactTypesReducer);

  switch (dataIndex) {
    case 'date':
      inputNode = (
        <DatePicker
          placeholder='Select Date'
          format={'DD MMM YY'}
          size='middle'
          style={{ minWidth: '90px' }}
          suffixIcon={null}
          allowClear={false}
          // disabled={isRevisionUpdateCase}
        />
      );
      break;
    case 'contactType':
      inputNode = (
        <Select
          showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector`}
          // style={{ width: '85px' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Contact Type'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allContactTypes.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;
    case 'comment':
      inputNode = <TextArea rows={2} placeholder='Insert Comment' />;
      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Required!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
