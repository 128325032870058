import React, { useState } from 'react';
import {
  Row,
  Col,
  Select,
  Form,
  Button,
  Space,
  Popconfirm,
  message,
} from 'antd';
import { useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from '../../../../assets/edit-icon.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/crossIcon.svg';
import axios from 'axios';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import {
  updateActivityError,
  updateActivitySuccess,
} from '../../redux/actions';

const { Option } = Select;

const FormSelect = ({ formData, setFormData, record }) => {
  let prevFormId = record?.form?._id || null;

  const [isEdit, setIsEdit] = useState(false);
  const [formId, setFormId] = useState(prevFormId);
  const [loading, setLoading] = useState(false);

  const { allForms } = useSelector((state) => state.formSetupReducer);

  let className = `${!isEdit ? 'ims-doc-readonly-input' : ''}`;
  let placeholder = !isEdit ? 'Select Form' : null;

  const updateForm = async () => {
    if (formId === null) {
      return message.error('Please select a form.');
    }
    if (prevFormId === formId) {
      return;
    }
    setLoading(true);
    const fd = new FormData();
    fd.append('form', formId);

    const response = await axios.put(`${baseURL}/leads/${record?._id}`, fd, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    const { success } = response.data;
    setLoading(false);

    if (success) {
      setIsEdit(false);
      message.success('Form successfully updated.');
      updateActivitySuccess(response.data.lead);
    } else if (!success) {
      // AntdAlert.error(message);
      updateActivityError(response.data.lead);
    }
  };

  return (
    <Row justify='start' align='bottom' gutter={[20, 0]}>
      <Col span={16}>
        <Form.Item
          className={className}
          label='FORM NAME'
          // name='form_name'
          // key='form_name'
          labelCol={{ span: 24 }}
          style={{
            paddingLeft: '10px',
          }}
        >
          <Select
            placeholder={placeholder}
            // disabled={!isRevisionUpdateCase}
            size='small'
            onChange={(e, option) => {
              setFormId(e);
              setFormData(option.el);
            }}
            value={formId}
            style={{ width: '100%' }}
            disabled={!isEdit}
          >
            {allForms.map((el) => {
              const { _id, contract_name } = el;
              return (
                <Option value={_id} key={_id} el={el}>
                  {contract_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col style={{ paddingBottom: '6px' }}>
        <Space size={10} align='center'>
          {isEdit ? (
            <>
              <Button
                className='apply-btn'
                size='small'
                loading={loading}
                onClick={updateForm}
              >
                Apply
              </Button>
              <Popconfirm
                placement='topRight'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                title='Sure to cancel?'
                onConfirm={() => setIsEdit(false)}
              >
                <CrossIcon
                  className='custom-icon'
                  style={{
                    height: '20px',
                    width: '20px',
                  }}
                />
              </Popconfirm>
            </>
          ) : (
            <EditIcon
              className={`custom-icon`}
              onClick={() => setIsEdit(true)}
            />
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default FormSelect;
