import React, { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Button,
  Form,
  Col,
  Row,
  Space,
  DatePicker,
  InputNumber,
} from 'antd';
import moment from 'moment';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
// import { ReactComponent as AttachIcon } from '../../../assets/attach.svg';
import { ReactComponent as FilterIcon } from '../../../assets/filter.svg';
import { ReactComponent as CrossIcon } from '../../../assets/crossIcon.svg';
// import { ReactComponent as BatchUpdateIcon } from '../../../assets/patch-update.svg';
import {
  createLeadDoc,
  deleteLeadDoc,
  // fetchActivities,
  fetchLeadDocs,
  updateActivity,
  updateIsAddIsUpdate,
  fetchBatchUpdate,
  setStorage,
} from '../redux/actions';
// import { fetchActivities } from '../../learners/redux/actions';
import SpinnerComponent from '../../../components/spinner';
import _ from 'lodash';
import CustomPagination from '../../../components/customPagination/CustomPagination';
// import NonEditRowAttach from './nonEditRowAttachView';
// import CustomCalendar from '../../../components/calendar';
import AMultiSelect from '../../../components/AMultiSelect';
import { getOptions } from '../../Sales/utils';
import ExpandedRow from './expandedRow';
import EditableCell from './editableCell';
// import Learners from './learners';
// import Distribution from './distributionTable';
import ExportDoc from './export';
import ImportDoc from './import';
// import FilterAndBatchUpdate from './filterAndBatchUpdate';
import UsePermissions from '../usePermissions';
import Tooltip from '../../../components/tooltip-Custom';
import { useLocation, useNavigate } from 'react-router-dom';

const ClassesTable = ({
  contactTypesCheckList,
  setContactTypesCheckList,
  contactTypesOptions,
  setProductTypesCheckList,
  productTypesCheckList,
  productTypesOptions,
  quoteStatusOptions,
  setQuoteStatusesCheckList,
  quoteStatusesCheckList,
  leadStatusesCheckList,
  setLeadStatusesCheckList,
  leadStatusOptions,
}) => {
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [sortedInfo, setSortedInfo] = useState(null);
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentUpdateObj, setCurrentUpdateObj] = useState({});
  const [form] = Form.useForm();
  const [fieldsFormRef] = Form.useForm();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const [images, setImages] = useState([]);
  // images contains all attachments of item
  const [attachPopupVisible, setAttachPopupVisible] = useState(false);
  const [search, setSearch] = useState({});
  const [date, setDate] = useState(null);
  // const [dueDate, setDueDate] = useState(null);
  const [deleteDocId, setDeleteDocId] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [fields, setFields] = useState([]);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [isRevisionUpdateCase, setIsRevisionUpdateCase] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [showBatch, setShowBatch] = useState(false);
  const [canView, canEdit, canDelete, canExport, canImport] = UsePermissions();
  const location = useLocation();
  const [fetchByParams, setFetchByParams] = useState(true);
  const [docNumber, setDocNumber] = useState('');
  const [manuals, setManuals] = useState([]);
  const navigate = useNavigate();
  // const [attachSearch, setAttachSearch] = useState(null);

  // const { allTemplates } = useSelector((state) => state.docTypesReducer);
  // const { allDocumentField, fetchingLoading } = useSelector(
  //   (state) => state.documentFieldReducer
  // );

  // const { allInterventions } = useSelector((state) => ({
  //   allInterventions: state.interventionTypesReducer.allTemplates,
  // }));
  // const { allTemplates } = useSelector(
  //   (state) => state.templatesManagerReducer
  // );

  // useEffect(() => {
  //   if (location?.state?.document_number?.length > 0) {
  //     const { document_number } = location?.state;
  //     if (docNumber === document_number) {
  //       return;
  //     } else {
  //       setFetchByParams(true);
  //     }
  //     setData([]);
  //     if (fetchByParams) {
  //       setDocNumber(document_number);
  //       setTimeout(() => {
  //         setData([]);
  //         setSearch((prev) => ({ ...prev, document_number }));
  //         debouncedDefaultPageOneCallApi();
  //         setFetchByParams(false);
  //       }, 100);
  //     }
  //   }
  // }, [location, docNumber]);

  // useEffect(() => {
  //   const template = allTemplates.filter(
  //     (el) => el.name.toLowerCase() === name
  //   );
  //   if (template.length > 0) {
  //     const { template_fields } = template[0];
  //     setFields(template_fields);
  //   }
  // }, [allTemplates]);

  useEffect(() => {
    if (localStorage.getItem('curriculumsManagerState')) {
      const localStorageData = JSON.parse(
        localStorage.getItem('curriculumsManagerState')
      );

      if (localStorageData) {
        setTimeout(() => {
          setSearch(localStorageData.search);
          dispatch(setStorage(localStorageData));

          debouncedCallApi();
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    //   if (page && resultPerPage && page > 0 && resultPerPage > 0) {
    dispatch(
      fetchLeadDocs({
        page: page,
        per_page: resultPerPage,
        search,
        sort: sortedInfo?.order && sortedInfo?.columnKey,
        order: sortedInfo?.order
          ? sortedInfo?.order === 'ascend'
            ? 1
            : -1
          : null,
        ...filter,
      })
    );
    // dispatch(
    //   fetchActivities({
    //     page,
    //     resultPerPage,
    //     search,
    //     ...filter,
    //     npo_graph: false,
    //   })
    // );
    //   }
  }, []);

  // useEffect(() => {
  //   let paramsPage = 1;
  //   // dispatch(
  //   //   fetchLeadDocs({
  //   //     page: paramsPage,
  //   //     per_page: resultPerPage,
  //   //     search,
  //   //     sort: sortedInfo?.order && sortedInfo?.columnKey,
  //   //     order: sortedInfo?.order
  //   //       ? sortedInfo?.order === 'ascend'
  //   //         ? 1
  //   //         : -1
  //   //       : null,
  //   //     ...filter,
  //   //
  //   //   })
  //   // );
  //   // setFilter({});
  //   // setFields([]);
  //   setExpandedRowKeys([]);
  //   setEditingKey('');
  //   if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
  //     // dispatch(
  //     //   fetchActivities({
  //     //     page: paramsPage,
  //     //     per_page: resultPerPage,
  //     //     search,
  //     //     ...filter,
  //     //
  //     //     npo_graph: false,
  //     //   })
  //     // );
  //     setPage(1);
  //   }
  //   form.resetFields();
  //   fieldsFormRef.resetFields();
  //   // if (search?.productType?.length > 0) {
  //   //   updateManualsOptions(search?.productType);
  //   // } else {
  //   //   setManualTypeOptions([massOption]);
  //   // }
  // }, [defaultPageOneReq]);

  // useEffect(() => {
  //   debouncedCallApi();
  // }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 500)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  const isEditing = (record) =>
    record.key === editingKey || record._id === editingKey;

  const { user } = useSelector((state) => state.authReducer);
  const {
    allDocs,
    fetchDocsLoading,
    updateLoading,
    totalRecords,
    isAddImsDoc,
    isUpdateImsDoc,
    deletingLoading,
    ...rest
  } = useSelector((state) => state.leadsReducer);

  const handleUpdateStatus = (isAdd, isUpdate) => {
    dispatch(
      updateIsAddIsUpdate({
        isAddImsDoc: isAdd,
        isUpdateImsDoc: isUpdate,
      })
    );
  };

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey('');
      setExpandedRowKeys([]);
    }

    return () => {
      handleUpdateStatus(false, false);
    };
  }, [updateLoading]);

  const setUpdatedData = () => {
    let updatedData = allDocs.map((el) => {
      const { ...rest } = el; // attachments_urls
      // if (isEditing(el)) {
      //   edit({ ...el, isAdd: false, skipFilter: false });
      // }
      // let updatedImages = [];
      // if (attachments_urls?.length > 0) {
      //   updatedImages = attachments_urls.map((img) => {
      //     const { url, id } = img;
      //     let name = url.split('/')[url.split('/').length - 1].split('?')[0];

      //     return { id, key: id, url, isLocal: false, name };
      //   });
      // }

      return { ...rest }; // images: updatedImages
    });
    setData(updatedData);
  };

  useEffect(() => {
    if (allDocs.length === 0) {
      return setData(allDocs);
    } else if (allDocs.length > 0) {
      setUpdatedData();
    }
  }, [allDocs]);

  const edit = (record) => {
    const { isAdd } = record;
    setCurrentUpdateObj(record);
    setImages(record?.images || []);

    const {
      receiveDate,
      createdDate,
      dateToContact,
      agentId,
      managerId,
      contactType,
      quoteStatus,
      productType,
      leadStatus,
    } = record;

    form.setFieldsValue({
      ...record,
      receiveDate: moment(receiveDate),
      createdDate: moment(createdDate),
      dateToContact: moment(dateToContact),
      agentName: agentId ? `${agentId?.first_name} ${agentId?.last_name}` : '',
      managerName: managerId
        ? `${managerId?.first_name} ${managerId?.last_name}`
        : '',
      agentId: agentId?._id,
      managerId: managerId?._id,
      leadStatus: leadStatus?._id,
      productType: productType?._id,
      quoteStatus: quoteStatus?._id,
      contactType: contactType?._id,
      isAdd,
    });

    if (!isAdd) {
      if (!record?.skipFilter) {
        let filteredItems = data.filter((el) => el._id !== editingKey);
        let final = filteredItems.filter((el) => el.key !== editingKey);
        setData(final);
      }

      // setImages(record.images || []);
      // if (document_template_id !== null) {
      //   // const { document_field_value } = record;
      //   let formData = {};
      //   // document_field_value.map((el) => {
      //   //   const { template_field_id, value, date_value, template_field } = el;
      //   //   if (template_field) {
      //   //     const { field_type } = template_field;

      //   //     formData[template_field_id] =
      //   //       field_type === 'date_field' && date_value !== null
      //   //         ? moment(date_value)
      //   //         : value;
      //   //   }
      //   // });
      //   // const template = allTemplates.filter(
      //   //   (el) => el.id === document_template_id
      //   // );
      //   const template = [];
      //   let template_fields =
      //     template.length > 0 ? template[0].template_fields : [];
      //   let values = {};
      //   template_fields.map((el) => (values[el.id] = ''));
      //   let updatedFields = { ...values, ...formData };
      //   // console.log('updatedFields', updatedFields);
      //   setTimeout(() => {
      //     fieldsFormRef.setFieldsValue({ ...updatedFields });
      //   }, 1000);
      //   // console.log('template_fields', template_fields);
      //   // setFields(document_field_value); // template_fields
      handleUpdateStatus(false, true);
      // }
    }

    setEditingKey(record._id);
    setExpandedRowKeys([record._id]);
  };

  const cancel = () => {
    if (isUpdateImsDoc) {
      setData(data);
    } else {
      // if (editingKey) {
      let filteredItems = data.filter((el) => el._id !== editingKey);
      let final = filteredItems.filter((el) => el.key !== editingKey);
      setData(final);
      // }
    }
    // setFields([]);
    form.resetFields();
    fieldsFormRef.resetFields();
    handleUpdateStatus(false, false);
    setEditingKey('');
    setExpandedRowKeys([]);
  };

  const save = async (key) => {
    setEditingKey(key);
    try {
      const row = await form.validateFields();
      const formFields = await fieldsFormRef.validateFields();
      const newData = [...data];
      const index = newData.findIndex(
        (item) => key === item.key || key === item._id
      );
      if (index > -1) {
        form.submit();
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      // const { errorFields } = errInfo;
      // errorFields.forEach((el) => {
      //   el.errors[0] !== 'Required!' && message.error(el.errors[0]);
      // });
    }
  };

  const filterData = ({ values }) => {
    let activityFilteredData = allDocs.filter((entry) =>
      values?.learning_activity?.length > 0
        ? entry.learning_activity?.name
            .toLocaleLowerCase()
            .includes(values?.learning_activity?.toLocaleLowerCase())
        : allDocs
    );

    let streamsFilteredData = activityFilteredData.filter((entry) =>
      values?.stream?.length > 0
        ? entry.learning_activity?.stream
            .toLocaleLowerCase()
            .includes(values?.stream?.toLocaleLowerCase())
        : activityFilteredData
    );

    let siteFilteredData = streamsFilteredData.filter((entry) =>
      values?.name?.length > 0
        ? entry.name
            .toLocaleLowerCase()
            .includes(values?.name?.toLocaleLowerCase())
        : allDocs
    );

    let coachFilteredData = siteFilteredData.filter((entry) => {
      let coachInputLength = values?.bookName?.length;
      if (coachInputLength > 0) {
        return entry.bookName
          .toLocaleLowerCase()
          .includes(values?.bookName?.toLocaleLowerCase());
      } else return siteFilteredData;
    });

    let classFilteredData = coachFilteredData.filter((entry) => {
      let inputLength = values?.nominal_term?.length;
      if (inputLength > 0) {
        const { nominal_term } = entry;

        return nominal_term
          .toLocaleLowerCase()
          .includes(values?.nominal_term?.toLocaleLowerCase());
      } else return coachFilteredData;
    });

    let topicFilteredData = classFilteredData.filter((entry) => {
      let length = values?.topic?.length;
      if (length > 0) {
        const { topic } = entry;
        return topic
          .toLocaleLowerCase()
          .includes(values?.topic?.toLocaleLowerCase());
      } else return classFilteredData;
    });

    let mktPostFiltered = topicFilteredData.filter((entry) => {
      let length = values?.nominal_year_level?.length;
      if (length > 0) {
        let input = values?.nominal_year_level?.toString();
        const { nominal_year_level } = entry;
        return nominal_year_level.toString().includes(input);
      } else return topicFilteredData;
    });

    let sesPreFiltered = mktPostFiltered.filter((entry) => {
      let length = values?.duration?.length;
      if (length > 0) {
        let input = values?.duration?.toString();
        const { duration } = entry;
        return duration.toString().includes(input);
      } else return mktPostFiltered;
    });

    setData(sesPreFiltered);
  };

  useEffect(() => {
    filterData({ values: search });
  }, [req]);

  useEffect(() => {
    const isSearchChanged =
      JSON.stringify(search) !== JSON.stringify(rest.search);

    if (isSearchChanged) {
      setSearch(rest.search);
    }

    if (isSearchChanged) {
      debouncedCallApi();
    }
  }, []);

  const handleSetStorage = (key, value) => {
    dispatch(setStorage({ [key]: value }));
    debouncedCallApi();
  };

  const handleSearch = (key, value, func = null) => {
    let updatedState = {
      ...search,
      [key]: value,
    };

    setSearch(updatedState);
    // filterData({ values: updatedState });
    if (typeof func === 'function') {
      func();
    }

    dispatch(setStorage({ search: updatedState }));
    handleSetStorage('search', updatedState);
  };

  const searchInput = (key) => (
    <Input
      placeholder={''}
      onClick={(e) => e.stopPropagation()}
      value={search[key] || ''}
      onChange={(e) => {
        handleSearch(key, e.target.value, debouncedCallApi);
      }}
    />
  );

  const numberInput = (key) => (
    <InputNumber
      placeholder={''}
      size='small'
      controls={false}
      max={100}
      min={0}
      onClick={(e) => e.stopPropagation()}
      onPressEnter={(e) => e.stopPropagation()}
      value={search[key] || null}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      setSearch({
        ...search,
        start_date: '',
        end_date: '',
      });
    } else {
      setSearch({
        ...search,
        start_date: value[0],
        end_date: value[1],
      });
    }
    debouncedDefaultPageOneCallApi();
  };

  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
    // debouncedDefaultPageOneCallApi();
  };

  // const updateManualsOptions = (ids) => {
  //   let filteredInterventions = interventionTypesOptions.filter((el) =>
  //     ids.includes(el.value)
  //   );

  //   let AllOptions = [];
  //   AllOptions.push(massOption);

  //   filteredInterventions.forEach((el) => {
  //     const { label, manuals } = el;
  //     manuals.forEach((manual) => {
  //       let updatedLabel = `${manual.label} - (${label})`;
  //       AllOptions.push({ ...manual, label: updatedLabel });
  //     });
  //   });

  //   setManualTypeOptions(AllOptions);
  // };

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const onDeleteSuccess = () => {
    debouncedCallApi();
    setDeleteDocId();
    debouncedDefaultPageOneCallApi();
  };

  const deleteDocHandler = (record) => {
    setDeleteDocId(record._id);
    dispatch(deleteLeadDoc({ ...record, onDeleteSuccess }));
  };

  // const getCloumnName = (i) =>
  //   allDocumentField.length > 0 && allDocumentField[i].name;

  const DateInput = ({ name }) => (
    <DatePicker
      style={{ width: '100%', minWidth: '80px' }}
      placeholder=''
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className='activities-time-picker prod-due-date'
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      suffixIcon={null}
      format='D MMM YYYY'
      // popupClassName="activities-time-picker"
      onChange={(e) => {
        setSearch({
          ...search,
          [name]: e,
        });
        debouncedCallApi();
      }}
      onOpenChange={(e) => setDatePickerOpened(e)}
    />
  );

  const handleColOnClick = (record) => {
    if ((isAddImsDoc || isUpdateImsDoc) && !isRevisionUpdateCase) {
      return;
    }
    if (
      isAddImsDoc ||
      ((isRevisionUpdateCase || isUpdateImsDoc) &&
        expandedRowKeys[0] !== record._id)
    ) {
      setExpandedRowKeys([]);
      setIsRevisionUpdateCase(false);
      setEditingKey('');
      setIsRevisionUpdateCase(false);
      handleUpdateStatus(false, false);
      setData(allDocs); // setUpdatedData();
      form.resetFields();
      fieldsFormRef.resetFields();
      setExpandedRowKeys([record._id]);
      // setFields([]);
      setIsRevisionUpdateCase(true);
      return edit({ ...record, isAdd: false, skipFilter: true });
    }

    if (isRevisionUpdateCase) {
      if (expandedRowKeys[0] === record._id) {
        setExpandedRowKeys([]);
        setIsRevisionUpdateCase(false);
        setEditingKey('');
        setIsRevisionUpdateCase(false);
        handleUpdateStatus(false, false);
      }
    } else {
      setExpandedRowKeys([record._id]);
      setIsRevisionUpdateCase(true);
      edit({ ...record, isAdd: false, skipFilter: false });
    }
  };

  // const handleSearchOnchange = (value) => {
  //   if (value === undefined) {
  //     setAttachSearch(null);
  //     handleMultiSelect('', 'has_attach');
  //   } else {
  //     setAttachSearch(value);
  //     handleMultiSelect(value, 'has_attach');
  //   }
  //   debouncedDefaultPageOneCallApi();
  // };

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Learning Activity</div>
            <div>{searchInput('learning_activity')}</div>
          </div>
        );
      },
      dataIndex: 'learning_activity',
      key: 'learning_activity',
      sorter: (a, b) =>
        a?.learning_activity?.name
          ?.toLowerCase()
          ?.localeCompare(b?.learning_activity?.name?.toLowerCase()),
      width: '15%',
      editable: !isRevisionUpdateCase,
      render: (learning_activity, record) => {
        return <div>{learning_activity?.name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Stream</div>
            <div>{searchInput('stream')}</div>
          </div>
        );
      },
      dataIndex: 'stream',
      key: 'stream',
      sorter: (a, b) =>
        a?.learning_activity?.stream
          ?.toLowerCase()
          ?.localeCompare(b?.learning_activity?.stream?.toLowerCase()),
      width: '15%',
      editable: !isRevisionUpdateCase,
      render: (stream, record) => {
        return <div>{record?.learning_activity?.stream}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Curriculum</div>
            <div>{searchInput('name')}</div>
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) =>
        a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
      // className: 'less-padding-col',
      // sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      width: '15%',
      editable: !isRevisionUpdateCase,
      render: (name, record) => {
        return <div>{name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Book</div>
            <div>{searchInput('bookName')}</div>
          </div>
        );
      },
      dataIndex: 'bookName',
      key: 'bookName',
      sorter: (a, b) =>
        a?.bookName?.toLowerCase()?.localeCompare(b?.bookName?.toLowerCase()),
      // className: 'less-padding-col',
      // sortOrder: sortedInfo?.columnKey === 'book' && sortedInfo?.order,
      width: '15%',
      editable: !isRevisionUpdateCase,
      render: (bookName, record) => {
        if (!bookName) {
          return;
        }
        const { name } = record.book;
        return <div>{name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Duration (Weeks)</div>
            <div>{searchInput('duration')}</div>
          </div>
        );
      },
      dataIndex: 'duration',
      key: 'duration',
      sorter: (a, b) => a?.duration - b?.duration,
      // className: 'less-padding-col',
      // sortOrder: sortedInfo?.columnKey === 'duration' && sortedInfo?.order,
      width: '10%',
      editable: !isRevisionUpdateCase,
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Nominal Year Level</div>
            <div>{searchInput('nominal_year_level')}</div>
          </div>
        );
      },
      dataIndex: 'nominal_year_level',
      key: 'nominal_year_level',
      sorter: (a, b) => a?.nominal_year_level - b?.nominal_year_level,
      // className: 'less-padding-col',
      // sortOrder:
      // sortedInfo?.columnKey === 'nominal_year_level' && sortedInfo?.order,
      width: '10%',
      editable: !isRevisionUpdateCase,
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Nominal Term</div>
            <div>{searchInput('nominal_term')}</div>
          </div>
        );
      },
      dataIndex: 'nominal_term',
      key: 'nominal_term',
      sorter: (a, b) =>
        a?.nominal_term
          ?.toLowerCase()
          ?.localeCompare(b?.nominal_term?.toLowerCase()),
      // className: 'less-padding-col',
      // sortOrder: sortedInfo?.columnKey === 'nominal_term' && sortedInfo?.order,
      width: '8%',
      editable: !isRevisionUpdateCase,
      align: 'center',
    },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text'>
    //           MANAGER <br />
    //           NAME
    //         </div>
    //         <div>
    //           {searchInput('managerName')}
    //           {/* <AMultiSelect
    //             debouncedCallApi={debouncedDefaultPageOneCallApi}
    //             dropdownClassName='search-select'
    //             disabled={false}
    //             placeholder=''
    //             selectTags={npoRegionsCheckList}
    //             onSelectChange={(value) =>
    //               handleSelectOnchange(
    //                 value,
    //                 npoRegionsOptions,
    //                 'npo_region',
    //                 setNpoRegionsCheckList
    //               )
    //             }
    //             data={getOptions(npoRegionsOptions, npoRegionsCheckList) || []}
    //             filterOption={(input, option) => {
    //               return (
    //                 option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
    //                 0
    //               );
    //             }}
    //           /> */}
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'managerName',
    //   key: 'managerName',
    //   sorter: true,
    //   className: 'less-padding-col',
    //   sortOrder: sortedInfo?.columnKey === 'managerName' && sortedInfo?.order,
    //   width: 90,
    //   editable: !isRevisionUpdateCase,
    //   render: (managerName, record) => {
    //     if (!record?.managerId) {
    //       return;
    //     }
    //     const { first_name, last_name } = record.managerId;
    //     const name = `${first_name} ${last_name}`;
    //     return <div onClick={() => handleColOnClick(record)}>{name}</div>;
    //   },
    // },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text'>
    //           CONTACT <br />
    //           TYPE
    //         </div>
    //         <div>
    //           <AMultiSelect
    //             debouncedCallApi={debouncedDefaultPageOneCallApi}
    //             dropdownClassName='search-select'
    //             disabled={false}
    //             placeholder=''
    //             selectTags={contactTypesCheckList}
    //             onSelectChange={(value) =>
    //               handleSelectOnchange(
    //                 value,
    //                 contactTypesOptions,
    //                 'contactType',
    //                 setContactTypesCheckList
    //               )
    //             }
    //             data={
    //               getOptions(contactTypesOptions, contactTypesCheckList) || []
    //             }
    //             filterOption={(input, option) => {
    //               return (
    //                 option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
    //                 0
    //               );
    //             }}
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'contactType',
    //   key: 'contactType',
    //   sorter: true,
    //   className: 'less-padding-col',
    //   sortOrder: sortedInfo?.columnKey === 'contactType' && sortedInfo?.order,
    //   width: 100,
    //   editable: !isRevisionUpdateCase,
    //   render: (doc_template, record) => (
    //     <div onClick={() => handleColOnClick(record)}>
    //       {record?.contactType?.name}
    //     </div>
    //   ),
    // },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text'>
    //           Quote <br /> Status
    //         </div>
    //         <div>
    //           <AMultiSelect
    //             debouncedCallApi={debouncedDefaultPageOneCallApi}
    //             dropdownClassName='search-select'
    //             disabled={false}
    //             placeholder=''
    //             selectTags={quoteStatusesCheckList}
    //             onSelectChange={(value) =>
    //               handleSelectOnchange(
    //                 value,
    //                 quoteStatusOptions,
    //                 'quoteStatus',
    //                 setQuoteStatusesCheckList
    //               )
    //             }
    //             data={
    //               getOptions(quoteStatusOptions, quoteStatusesCheckList) || []
    //             }
    //             filterOption={(input, option) => {
    //               return (
    //                 option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
    //                 0
    //               );
    //             }}
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'quoteStatus',
    //   key: 'quoteStatus',
    //   sorter: true,
    //   className: 'less-padding-col',
    //   sortOrder: sortedInfo?.columnKey === 'quoteStatus' && sortedInfo?.order,
    //   width: 100,
    //   align: 'center',
    //   editable: !isRevisionUpdateCase,
    //   render: (id, record) => {
    //     if (!Boolean(record?.quoteStatus)) {
    //       return;
    //     }

    //     const { color, name } = record.quoteStatus;
    //     return (
    //       <Tag
    //         color={color}
    //         className='doc-status-tag'
    //         onClick={() => handleColOnClick(record)}
    //       >
    //         {name}
    //       </Tag>
    //     );
    //   },
    // },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text'>
    //           Product <br />
    //           type
    //         </div>
    //         <div>
    //           <AMultiSelect
    //             debouncedCallApi={debouncedDefaultPageOneCallApi}
    //             dropdownClassName='search-select'
    //             disabled={false}
    //             placeholder=''
    //             selectTags={productTypesCheckList}
    //             onSelectChange={(value) =>
    //               handleSelectOnchange(
    //                 value,
    //                 productTypesOptions,
    //                 'productType',
    //                 setProductTypesCheckList
    //               )
    //             }
    //             data={
    //               getOptions(productTypesOptions, productTypesCheckList) || []
    //             }
    //             filterOption={(input, option) => {
    //               return (
    //                 option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
    //                 0
    //               );
    //             }}
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'productType',
    //   key: 'productType',
    //   sorter: true,
    //   className: 'less-padding-col',
    //   sortOrder: sortedInfo?.columnKey === 'productType' && sortedInfo?.order,
    //   width: 100,
    //   editable: !isRevisionUpdateCase,
    //   render: (doc_template, record) => (
    //     <div onClick={() => handleColOnClick(record)}>
    //       {record?.productType?.name}
    //     </div>
    //   ),
    // },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text'>Comment</div>
    //         <div>{searchInput('comment')}</div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'comment',
    //   key: 'comment',
    //   sorter: true,
    //   className: 'less-padding-col',
    //   sortOrder: sortedInfo?.columnKey === 'comment' && sortedInfo?.order,
    //   width: 100,
    //   align: 'center',
    //   editable: !isRevisionUpdateCase,
    // },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text'>
    //           Date To
    //           <br />
    //           Contact
    //         </div>
    //         <DateInput name='dateToContact' />
    //       </div>
    //     );
    //   },
    //   dataIndex: 'dateToContact',
    //   key: 'dateToContact',
    //   sorter: true,
    //   className: 'less-padding-col',
    //   sortOrder: sortedInfo?.columnKey === 'dateToContact' && sortedInfo?.order,
    //   width: 100,
    //   align: 'center',
    //   editable: !isRevisionUpdateCase,
    //   render: (date, record) => {
    //     return (
    //       <div onClick={() => handleColOnClick(record)}>
    //         {moment(date).format('DD MMM YYYY')}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text'>
    //           Lead <br /> Status
    //         </div>
    //         <div>
    //           <AMultiSelect
    //             debouncedCallApi={debouncedDefaultPageOneCallApi}
    //             dropdownClassName='search-select'
    //             disabled={false}
    //             placeholder=''
    //             selectTags={leadStatusesCheckList}
    //             onSelectChange={(value) =>
    //               handleSelectOnchange(
    //                 value,
    //                 leadStatusOptions,
    //                 'leadStatus',
    //                 setLeadStatusesCheckList
    //               )
    //             }
    //             data={
    //               getOptions(leadStatusOptions, leadStatusesCheckList) || []
    //             }
    //             filterOption={(input, option) => {
    //               return (
    //                 option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
    //                 0
    //               );
    //             }}
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'leadStatus',
    //   key: 'leadStatus',
    //   sorter: true,
    //   className: 'less-padding-col',
    //   sortOrder: sortedInfo?.columnKey === 'leadStatus' && sortedInfo?.order,
    //   width: 100,
    //   align: 'center',
    //   editable: !isRevisionUpdateCase,
    //   render: (id, record) => {
    //     if (!Boolean(record?.leadStatus)) {
    //       return;
    //     }

    //     const { color, name } = record?.leadStatus;
    //     return (
    //       <Tag
    //         color={color}
    //         className='doc-status-tag'
    //         onClick={() => handleColOnClick(record)}
    //       >
    //         {name}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: '',
      dataIndex: 'operation',
      align: 'center',
      width: '8%',
      render: (_, record) => {
        return (
          <Space size={20} align='center'>
            <EditIcon
              width={18}
              height={18}
              className='custom-icon'
              onClick={() => {
                navigate(`/curriculum-management/update/${record._id}`);
                handleUpdateStatus(false, true);
              }}
            />
          </Space>
        );
      },
    },
    // {
    //   title: '',
    //   dataIndex: 'operation',
    //   align: 'center',
    //   width: 120,
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     let key = record?.key || record?._id;
    //     let updateCondition =
    //       isUpdateImsDoc && editingKey !== key && editingKey > 0;
    //     let shouldDisabled =
    //       isAddImsDoc || (isRevisionUpdateCase && !editable) || updateCondition;

    //     return editable && !isRevisionUpdateCase ? (
    //       editingLoading && editingKey === key ? (
    //         <SpinnerComponent fontSize={14} />
    //       ) : (
    //         <Space size={20} align='center'>
    //           <Popconfirm
    //             placement='topRight'
    //             getPopupContainer={() =>
    //               document.getElementsByClassName(
    //                 'ant-layout-content layout-content site-layout-background custom-textarea'
    //               )[0]
    //             }
    //             overlayClassName='activities-popconfirm'
    //             title='Are you sure you want to save?'
    //             onConfirm={() => save(record.key || record._id)}
    //             okText='Yes'
    //             cancelText='No'
    //           >
    //             <SaveIcon className='custom-icon' />
    //           </Popconfirm>
    //           {/* {!isUpdateImsDoc && ( */}
    //           <Popconfirm
    //             placement='topRight'
    //             getPopupContainer={() =>
    //               document.getElementsByClassName(
    //                 'ant-layout-content layout-content site-layout-background custom-textarea'
    //               )[0]
    //             }
    //             title='Sure to cancel?'
    //             onConfirm={cancel}
    //           >
    //             <CrossIcon
    //               className='custom-icon'
    //               style={{
    //                 height: '20px',
    //                 width: '20px',
    //               }}
    //             />
    //           </Popconfirm>
    //           {/* // )} */}
    //         </Space>
    //       )
    //     ) : (
    //       <div>
    //         <Space size={20} align='center'>
    //           {deletingLoading && record._id === deleteDocId ? (
    //             <SpinnerComponent fontSize={16} />
    //           ) : (
    //             <>
    //               {canEdit && (
    //                 <EditIcon
    //                   className={`custom-icon ${
    //                     shouldDisabled ? 'disable-icon' : ''
    //                   }`}
    //                   disabled={editingKey !== ''}
    //                   onClick={() => {
    //                     if (shouldDisabled) {
    //                       return;
    //                     }
    //                     if (isRevisionUpdateCase) {
    //                       setIsRevisionUpdateCase(false);
    //                     } else {
    //                       // setManualsOnEditClick(record.productType);
    //                       handleUpdateStatus(false, true);

    //                       edit({
    //                         ...record,
    //                         isAdd: false,
    //                         skipFilter: false,
    //                       });
    //                     }
    //                   }}
    //                 />
    //               )}
    //               {!shouldDisabled
    //                 ? canDelete && (
    //                     <Popconfirm
    //                       getPopupContainer={() =>
    //                         document.getElementsByClassName(
    //                           'ant-layout-content layout-content site-layout-background custom-textarea'
    //                         )[0]
    //                       }
    //                       placement='topRight'
    //                       title='Are you sure you want to delete?'
    //                       onConfirm={() => deleteDocHandler(record)}
    //                     >
    //                       <DeleteIcon className={`custom-icon`} />
    //                     </Popconfirm>
    //                   )
    //                 : canDelete && (
    //                     <DeleteIcon
    //                       className={`custom-icon ${
    //                         (isAddImsDoc || isUpdateImsDoc) && 'disable-icon'
    //                       }`}
    //                     />
    //                   )}
    //             </>
    //           )}
    //         </Space>
    //       </div>
    //     );
    //   },
    // },
  ];

  const handleAdd = () => {
    // setFields([]);
    // const template = allTemplates.filter(
    //   (el) => el.name.toLowerCase() === name
    // );
    // if (template.length > 0) {
    //   const { template_fields } = template[0];
    //   setFields(template_fields);
    // }
    return navigate('/curriculum-management/create');
    console.log('user', user);
    const { user_type } = user;
    let obj = {};
    if (user_type === 'sales_agent') {
      obj.agentName = `${user.first_name} ${user.last_name}`;
      obj.agentId = user._id;
      const { _id, first_name, last_name } = user.sale_manager;
      obj.managerName = `${first_name} ${last_name}`;
      obj.managerId = _id;
    } else {
      const { _id, first_name, last_name } = user;
      obj.managerName = `${first_name} ${last_name}`;
      obj.managerId = _id;
    }
    form.resetFields();
    fieldsFormRef.resetFields();
    setSortedInfo(null);
    handleUpdateStatus(true, false);
    setCurrentUpdateObj({});
    let key = Math.ceil(Math.random() * 2345678);
    const newData = {
      key,
      _id: key,
      receiveDate: null,
      createdDate: null,
      dateToContact: null,
      accountId: null,
      customer: null,
      agentName: '',
      agentId: null,
      managerName: '',
      managerId: null,
      contactType: null,
      quoteStatus: null,
      productType: null,
      comment: null,
      leadStatus: null,
      contactNumber: null,
      email: '',
      address: '',
      isAdd: true,
      ...obj,
    };
    let final = { ...newData };
    setData((data) => [final, ...data]);
    edit(final);
    setImages([]);
    form.setFieldsValue({
      ...newData,
    });

    setEditingKey(key);
    setExpandedRowKeys([key]);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          // inputType:,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  const handleFinish = (item) => {
    let values = fieldsFormRef.getFieldsValue();

    const { receiveDate, createdDate, dateToContact } = item;

    const { user_type } = user;
    let obj = {};
    if (user_type === 'sales_agent') {
      obj.agentName = `${user.first_name} ${user.last_name}`;
      obj.agentId = user._id;
      const { _id, first_name, last_name } = user.sale_manager;
      obj.managerName = `${first_name} ${last_name}`;
      obj.managerId = _id;
    } else {
      const { _id, first_name, last_name } = user;
      obj.managerName = `${first_name} ${last_name}`;
      obj.managerId = _id;
    }

    if (isUpdateImsDoc) {
      dispatch(
        updateActivity({
          ...obj,
          ...item,
          receiveDate: moment(receiveDate),
          createdDate: moment(createdDate),
          dateToContact: moment(dateToContact),
          id: currentUpdateObj._id,
          ...(images.length > 0 && { images }),
          field_values: values,
          debouncedDefaultPageOneCallApi,
        })
      );
    } else {
      dispatch(
        createLeadDoc({
          ...obj,
          ...item,
          receiveDate: moment(receiveDate),
          createdDate: moment(createdDate),
          // user_id: user.id,
          ...(images.length > 0 && { images }),
          field_values: values,
          debouncedDefaultPageOneCallApi,
        })
      );
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    if (datePickerOpened) {
      return;
    }
    setSortedInfo(sorter);
  };

  const expandedRowRender = (record) => {
    if (expandedRowKeys.includes(record._id)) {
      return (
        <ExpandedRow
          record={record}
          fields={fields}
          formRef={fieldsFormRef}
          onFinish={onFinishFields}
          isRevisionUpdateCase={isRevisionUpdateCase}
          images={images}
          setImages={setImages}
          attachPopupVisible={attachPopupVisible}
          setAttachPopupVisible={setAttachPopupVisible}
          currentUpdateObj={currentUpdateObj}
          shouldDisabled={false} // {shouldDisabled}
        />
      );
      // }
    }
  };

  const handleChangeDocType = (e, option) => {
    // const { name } = allNpos.filter((el) => el.id === id)[0].npo_region;
    const { manuals } = option;
    setManuals(manuals);
    form.setFieldValue('manual_id', null);

    // const { template_fields } = allTemplates.filter((el) => el.id === id)[0];
    // template_fields.map((el) => {
    //   fieldsFormRef.setFieldValue({ [el.id]: '' });
    //   return { [el.id]: null };
    // });
    // setFields(template_fields);
  };

  const handleUpdateSite = (e, option) => {
    const { npo_id } = option;
    form.setFieldValue('npo_id', npo_id);
  };

  const setManualsOnEditClick = (interventionId) => {
    // const filteredManuals = allInterventions.filter(
    //   (el) => el.id == interventionId
    // );
    // if (filteredManuals.length > 0) {
    //   let { user_manuals } = filteredManuals[0];
    //   setManuals(user_manuals);
    // }
  };

  const onFinishFields = (values) => {
    console.log('values', values);
  };

  const handleCloseBatchSection = () => {
    setShowBatch(false);
  };

  const handleCloseFilterSection = () => {
    setShowFilter(false);
    setFilter({});
    handleCloseBatchSection();
    debouncedDefaultPageOneCallApi();
  };

  const handleFilter = (filterObj) => {
    setFilter(filterObj);
    debouncedDefaultPageOneCallApi();
  };

  const handleBatchUpdate = (filterObj) => {
    setExpandedRowKeys([]);
    setEditingKey('');
    setIsRevisionUpdateCase(false);
    let document_control = data.map((el) => el._id);
    let revisions_ids = [];
    let distributions_ids = [];
    data.map((doc) => {
      const { doc_distributions, doc_revisions } = doc;
      doc_revisions.map((rev) => revisions_ids.push(rev._id));
      doc_distributions.map((dist) => distributions_ids.push(dist._id));
    });

    dispatch(
      fetchBatchUpdate({
        ...filterObj,
        document_control,
        revisions_ids,
        distributions_ids,
      })
    );
  };

  const shouldAddBtnDisable = () => {
    let shouldDisabled =
      isAddImsDoc || fetchDocsLoading || isRevisionUpdateCase || isUpdateImsDoc;
    return shouldDisabled;
  };

  const hanldeBatch = () => {
    setShowFilter(true);
    setShowBatch(true);
  };

  // console.count('Render ClassesTable', data);

  return (
    <Row className='doc-control-parent-row'>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[50, 0]} justify='space-between'>
          <Col>
            <Row gutter={[30, 0]} align='middle'>
              <Col>
                <div>
                  <span className='heading-span'>Curriculum Register</span>
                </div>
              </Col>
              {canEdit && (
                <Col>
                  <Button
                    icon={<PlusOutlined />}
                    className='add-doc-btn class-btn'
                    size='middle'
                    onClick={handleAdd}
                    disabled={shouldAddBtnDisable()}
                  >
                    CURRICULUM
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  onClick={() => {
                    handleSetStorage('search', {});
                    setSearch({});
                    debouncedCallApi();
                  }}
                  icon={<CloseOutlined />}
                  className='add-user-btn'
                  size='middle'
                >
                  Clear Filters
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      {/* <FilterAndBatchUpdate
        handleCloseFilter={handleCloseFilterSection}
        handleFilter={handleFilter}
        handleBatchUpdate={handleBatchUpdate}
        handleCloseBatch={handleCloseBatchSection}
        loading={fetchDocsLoading}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        showBatch={showBatch}
        setShowBatch={setShowBatch}
      /> */}

      <Col span={24}>
        <Form form={form} component={false} onFinish={handleFinish}>
          <Table
            // scroll={{ x: 1200 }}
            className={`special-table ims-doc-table ${
              shouldAddBtnDisable() ? 'doc-table-is-exapnded' : ''
            }`}
            components={{
              body: {
                cell: (props) => (
                  <EditableCell
                    cell={props}
                    expandedRowKeys={expandedRowKeys}
                    setExpandedRowKeys={setExpandedRowKeys}
                    currentUpdateObj={currentUpdateObj}
                    values={form.getFieldsValue()}
                    setFields={setFields}
                    fields={fields}
                    handleChangeDocType={handleChangeDocType}
                    handleUpdateSite={handleUpdateSite}
                    isRevisionUpdateCase={isRevisionUpdateCase}
                    manuals={manuals}
                  />
                ),
              },
            }}
            expandable={{
              expandedRowRender,
              expandIcon: null,
              showExpandColumn: false,
              expandedRowKeys: expandedRowKeys,
              rowExpandable: ({ _id }) => expandedRowKeys.includes(_id),
            }}
            onRow={({ _id }) => {
              if (expandedRowKeys.length > 0) {
                let isExpanded = expandedRowKeys.includes(_id);
                let expandCls = `expand-doc-parent ${
                  isRevisionUpdateCase ? 'ims-doc-readonly-input' : ''
                }`;
                let unExpandCls = isRevisionUpdateCase ? '' : 'disabled-row';
                return {
                  className: isExpanded ? expandCls : unExpandCls,
                };
              }

              return {
                onClick: () => navigate(`/curriculum-management/update/${_id}`),
              };
            }}
            rowKey={(record) => record._id}
            loading={fetchDocsLoading} // || fetchingLoading
            rowClassName='editable-row'
            dataSource={data}
            columns={mergedColumns}
            onChange={handleChange}
            pagination={false}
          />
        </Form>
      </Col>
      {/* <Col span={24}>
        <CustomPagination
          page={page}
          setPage={setPage}
          resultPerPage={resultPerPage}
          setResultPerPage={setResultPerPage}
          debouncedCallApi={debouncedCallApi}
          totalRecord={totalRecords}
        />
      </Col> */}
    </Row>
  );
};

export default ClassesTable;
