import * as constants from './constants';

export const setValueAddedIsEdit = (data) => {
  return {
    type: constants.VALUE_ADDED_IS_EDIT,
    data: data,
  };
};

export const createValueAdded = (data) => {
  return {
    type: constants.CREATE_VALUE_ADDED,
    data: data,
  };
};

export const createValueAddedSuccess = (data) => {
  return {
    type: constants.CREATE_VALUE_ADDED_SUCCESS,
    data: data,
  };
};

export const createValueAddedError = () => {
  return {
    type: constants.CREATE_VALUE_ADDED_ERROR,
  };
};

export const fetchAllValueAdded = (data) => {
  return {
    type: constants.FETCH_ALL_VALUE_ADDED,
    data,
  };
};

export const fetchAllValueAddedSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_VALUE_ADDED_SUCCESS,
    data,
  };
};
export const fetchAllValueAddedError = (data) => {
  return {
    type: constants.FETCH_ALL_VALUE_ADDED_ERROR,
    data,
  };
};
export const updateValueAdded = (data) => {
  return {
    type: constants.UPDATE_VALUE_ADDED,
    data: data,
  };
};

export const updateValueAddedSuccess = (data) => {
  return {
    type: constants.UPDATE_VALUE_ADDED_SUCCESS,
    data: data,
  };
};

export const updateValueAddedError = () => {
  return {
    type: constants.UPDATE_VALUE_ADDED_ERROR,
  };
};
export const templateCreationCleanupFunc = () => {
  return {
    type: constants.VALUE_ADDED_CREATION_CLEANUP_FUNC,
  };
};
export const deleteValueAdded = (data) => {
  return {
    type: constants.DELETE_VALUE_ADDED,
    data,
  };
};

export const deleteValueAddedSuccess = (data) => {
  return {
    type: constants.DELETE_VALUE_ADDED_SUCCESS,
    data,
  };
};

export const deleteValueAddedError = (data) => {
  return {
    type: constants.DELETE_VALUE_ADDED_ERROR,
    data,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};

export const fetchValueAdded = (data) => {
  return {
    type: constants.FETCH_VALUE_ADDED,
    data,
  };
};

export const fetchValueAddedSuccess = (data) => {
  return {
    type: constants.FETCH_VALUE_ADDED_SUCCESS,
    data,
  };
};

export const fetchValueAddedError = () => {
  return {
    type: constants.FETCH_VALUE_ADDED_ERROR,
  };
};
