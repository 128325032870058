import React from 'react';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { provinceOptions } from './utils';
const { Option } = Select;

const SectionB = ({
  InputComponent,
  Heading,
  AccountTypeBox,
  data,
  setData,
}) => {
  const updateOther = (e) => {
    setData({ ...data, genderOther: e.target.value });
  };

  const OtherInput = () => {
    return (
      <Input
        placeholder='Specify Other'
        value={data?.genderOther}
        onChange={updateOther}
      />
    );
  };

  const ProvinceSelect = ({ name }) => {
    return (
      <Form.Item label='Province' name={name} style={{ marginBottom: 0 }}>
        <Select
          placeholder='Please Select'
          size='small'
          style={{
            maxWidth: 300,
          }}
        >
          {provinceOptions.map((el) => {
            const { label, value } = el;
            return (
              <Option value={value} key={value} name={label}>
                {label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  };

  return (
    <>
      <Heading
        text={
          <div className='customer-details-heading'>
            SECTION B: Customer Details
          </div>
        }
      />
      <Heading
        text={
          <div className=''>
            Individual Application &nbsp;&nbsp;
            <span className='sub-heading'>
              (This section must be completed when an individual is applying)
            </span>
          </div>
        }
      />
      <Col span={23} className='individual-app-inputs'>
        <Row justify='start'>
          <Col span={24}>
            <InputComponent
              name='surname'
              label='Surname'
              placeholder='Surname'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='firstName'
              label='First Name(s)'
              placeholder='First Name(s)'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='ID Number' style={{ marginBottom: 0 }}>
              <Row>
                <Col span={12}>
                  <InputComponent
                    name='id_number'
                    label=''
                    placeholder='ID Number'
                  />
                </Col>
                <Col span={11}>
                  <span className='or-text'>OR</span>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label='Passport Number' style={{ marginBottom: 0 }}>
              <Row justify='space-between'>
                <Col span={12}>
                  <InputComponent
                    name='passport_number'
                    label=''
                    placeholder='Passport Number'
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='passportExpiryDate'
                    label='Passport Expiry Date'
                    style={{ marginBottom: 0 }}
                    wrapperCol={24}
                  >
                    <DatePicker
                      format='YYYY-MM-DD'
                      placeholder='Y Y Y Y - M M - D D'
                      suffixIcon={null}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label='Martial Status' style={{ marginBottom: 24 }}>
              <Row justify='space-between' align='middle' gutter={[0, 0]}>
                <Col span={4}>
                  <AccountTypeBox
                    value='single'
                    label='Single'
                    keyName='martialStatus'
                  />
                </Col>
                <Col span={4}>
                  <AccountTypeBox
                    value='married'
                    label='Married'
                    keyName='martialStatus'
                  />
                </Col>
                <Col span={4}>
                  <AccountTypeBox
                    value='divorced'
                    label='Divorced'
                    keyName='martialStatus'
                  />
                </Col>
                <Col span={12}>
                  <Row align='middle' justify='space-between'>
                    <Col span={8}>
                      <AccountTypeBox
                        value='other'
                        label='Other (Specify)'
                        keyName='martialStatus'
                      />
                    </Col>
                    <Col span={16}>
                      {data?.martialStatus === 'other' && (
                        <Col>
                          <OtherInput />
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label='Contact No (Home)' style={{ marginBottom: 0 }}>
              <Row justify='space-between'>
                <Col span={11}>
                  <InputComponent
                    name='homeContactNo'
                    label=''
                    placeholder='Home Contact Number'
                  />
                </Col>
                <Col span={13}>
                  <InputComponent
                    name='workContactNo'
                    label='Contact No (Work)'
                    placeholder='Work Contact Number'
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label='Contact No (Cell)' style={{ marginBottom: 0 }}>
              <Row>
                <Col span={11}>
                  <InputComponent
                    name='cellContactNo'
                    label=''
                    placeholder='Cell Contact Number'
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <InputComponent name='email' label='Email' placeholder='Email' />
          </Col>
          <Col span={23}>
            <div className='individual-note'>
              NOTE: Supporting documents may be requested upon credit vetting of
              your new/existing Vodacom account.
            </div>
          </Col>
        </Row>
      </Col>
      <Heading
        text={
          <div className=''>
            Business Application &nbsp;&nbsp;
            <span className='sub-heading'>
              (This section must be completed when applying on behalf of a
              registered business)
            </span>
          </div>
        }
      />
      <Col span={23} className='individual-app-inputs'>
        <Row justify='start'>
          <Col span={24}>
            <InputComponent
              name='companyName'
              label='Company Name'
              placeholder='Company Name'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='companyRegNo'
              label='Company Reg No'
              placeholder='Company Registration Number'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='companyContactPersonSurname'
              label='Company Contact Person: Surname'
              placeholder='Company Contact Person: Surname'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='companyContactPersonName'
              label='Company Contact Person: Name'
              placeholder='Company Contact Person: Name'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='companyContactPersonDesignation'
              label='Company Contact Person: Designation'
              placeholder='Company Contact Person: Designation'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='companyContactPersonPhoneNo'
              label='Company Contact Person: Phone Number'
              placeholder='Company Contact Person: Phone Number'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='companyContactPersonEmail'
              label='Company Contact Person: Email'
              placeholder='Company Contact Person: Email'
            />
          </Col>
        </Row>
      </Col>
      <Heading text='Alternative Contact Information' />
      <Col span={23} className='individual-app-inputs alternative-contact'>
        <Row justify='start'>
          <Col span={24}>
            <InputComponent
              name='alternativeContactName'
              label='Alternative Contact Name'
              placeholder='Alternative Contact Name'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='alternativeContactNo'
              label='Alternative Contact No'
              placeholder='Alternative Contact Number'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='secondaryEmail'
              label='Secondary Email Address'
              placeholder='Secondary Email Address'
            />
          </Col>
        </Row>
      </Col>
      <Heading
        text={
          <div className=''>
            Address where Vodacom Fibre Broadband Service will be Installed
            &nbsp;&nbsp;
            <span className='sub-heading'>
              (This section must be completed in full)
            </span>
          </div>
        }
      />
      <Col span={23} className='individual-app-inputs'>
        <Row justify='start'>
          <Col span={24}>
            <InputComponent
              name='streetNo'
              label='Street / Unit No'
              placeholder='Street Number / Unit Number'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='streetName'
              label='Street Name'
              placeholder='Street Name'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='estateName'
              label='Estate Name'
              placeholder='Estate Name'
            />
          </Col>
          <Col span={24}>
            <InputComponent name='suburb' label='Suburb' placeholder='Suburb' />
          </Col>
          <Col span={24}>
            <Form.Item label='City' style={{ marginBottom: 0 }}>
              <Row justify='space-between'>
                <Col span={14}>
                  <InputComponent name='city' label='' placeholder='City' />
                </Col>
                <Col span={9}>
                  <InputComponent
                    name='postalCode'
                    label='Postal Code'
                    placeholder='Postal Code'
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <ProvinceSelect name='province' />
          </Col>
        </Row>
      </Col>
      <Heading text='Delivery Address for Devices' />
      <Col span={23} className='individual-app-inputs'>
        <Row justify='start'>
          <Col span={24}>
            <Row
              gutter={[30, 0]}
              className='same-as-above terms-col bold'
              align='middle'
            >
              <Col style={{ paddingLeft: '4%' }}>
                <div className=''>Same as the above address</div>
              </Col>
              <Col>
                <AccountTypeBox
                  value='yes'
                  label='Yes'
                  keyName='deliverAddressSame'
                />
                <AccountTypeBox
                  value='no'
                  label='No'
                  keyName='deliverAddressSame'
                />
              </Col>
              <Col>
                <d style={{ fontWeight: 400 }}>
                  If No, please complete the section in full.
                </d>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <InputComponent
              name='deliveryStreetNo'
              label='Street / Unit No'
              placeholder='Street / Unit Number'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='deliveryStreetName'
              label='Street Name'
              placeholder='Street Name'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='deliveryEstateName'
              label='Estate Name'
              placeholder='Estate Name'
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='deliverySuburb'
              label='Suburb'
              placeholder='Suburb'
            />
          </Col>
          <Col span={24}>
            <Form.Item label='City' style={{ marginBottom: 0 }}>
              <Row justify='space-between'>
                <Col span={14}>
                  <InputComponent
                    name='deliveryCity'
                    label=''
                    placeholder='City'
                  />
                </Col>
                <Col span={9}>
                  <InputComponent
                    name='deliveryPostalCode'
                    label='Postal Code'
                    placeholder='Postal Code'
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <ProvinceSelect name='deliveryProvince' />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default SectionB;
