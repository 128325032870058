import * as constants from './constants';

const initialState = {
  subObservations: [],
  subUpdateLoading: false,
  subIsAdding: false,
};

const subObservationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_SUB_OBSERVATION:
      return {
        ...state,
        subUpdateLoading: true,
      };
    case constants.CREATE_SUB_OBSERVATION_SUCCESS:
      let subObservations =
        action.data?.data?.learning_activity_sub_observations || [];
      let updatedObservations = subObservations
        .map((sub) => {
          return {
            _id: sub._id,
            position: sub.position,
            learning_activity_id: action.data?.data?.learning_activity,
            observation_id: action.data?.data?.observation,
            sub_observation_id: sub.sub_observation._id,
            name: sub.sub_observation.name,
            icon: sub.sub_observation.icon,
            deleted_status: sub.sub_observation.deleted_status,
            detail: sub.sub_observation?.detail,
          };
        })
        .sort((a, b) => a.position - b.position);

      return {
        ...state,
        subUpdateLoading: false,
        subObservations: updatedObservations,
        subIsAdding: false,
      };
    case constants.CREATE_SUB_OBSERVATION_ERROR:
      return {
        ...state,
        subUpdateLoading: false,
        // subIsAdding: false,
        // subObservations: [],
      };

    case constants.UPDATE_SUB_OBSERVATION_ORDER:
      return {
        ...state,
        subUpdateLoading: true,
      };
    case constants.UPDATE_SUB_OBSERVATION_ORDER_SUCCESS:
      let subObservationsData =
        action.data?.data?.learning_activity_sub_observations || [];
      let updatedSubObsOrder = subObservationsData
        .map((sub) => {
          return {
            _id: sub._id,
            position: sub.position,
            learning_activity_id: action.data?.data?.learning_activity,
            observation_id: action.data?.data?.observation,
            sub_observation_id: sub.sub_observation._id,
            name: sub.sub_observation.name,
            icon: sub.sub_observation.icon,
            deleted_status: sub.sub_observation.deleted_status,
            detail: sub.sub_observation?.detail,
          };
        })
        .sort((a, b) => a.position - b.position);

      return {
        ...state,
        subUpdateLoading: false,
        subObservations: updatedSubObsOrder,
      };
    case constants.UPDATE_SUB_OBSERVATION_ORDER_ERROR:
      return {
        ...state,
        subUpdateLoading: false,
      };

    case constants.FETCH_SUB_OBSERVATION:
      return {
        ...state,
        subUpdateLoading: true,
      };
    case constants.FETCH_SUB_OBSERVATION_SUCCESS:
      let subObsData = action.data?.learning_activity_sub_observations || [];
      let updatedSubObservation = subObsData
        .map((sub) => {
          return {
            _id: sub._id,
            position: sub.position,
            learning_activity_id: action.data?.learning_activity,
            observation_id: action.data?.observation,
            sub_observation_id: sub.sub_observation._id,
            name: sub.sub_observation.name,
            icon: sub.sub_observation.icon,
            deleted_status: sub.sub_observation.deleted_status,
            detail: sub.sub_observation?.detail,
          };
        })
        .sort((a, b) => a.position - b.position);

      return {
        ...state,
        subUpdateLoading: false,
        subObservations: updatedSubObservation,
        subIsAdding: false,
      };
    case constants.FETCH_SUB_OBSERVATION_ERROR:
      return {
        ...state,
        subUpdateLoading: false,
        subIsAdding: false,
      };

    case constants.UPDATE_SUB_OBSERVATION:
      return {
        ...state,
        subUpdateLoading: true,
      };
    case constants.UPDATE_SUB_OBSERVATION_SUCCESS:
      let subObs = action.data?.data?.learning_activity_sub_observations || [];
      let updatedSubObs = subObs
        .map((sub) => {
          return {
            _id: sub._id,
            position: sub.position,
            learning_activity_id: action.data?.data?.learning_activity,
            observation_id: action.data?.data?.observation,
            sub_observation_id: sub.sub_observation._id,
            name: sub.sub_observation.name,
            icon: sub.sub_observation.icon,
            deleted_status: sub.sub_observation.deleted_status,
            detail: sub.sub_observation?.detail,
          };
        })
        .sort((a, b) => a.position - b.position);

      return {
        ...state,
        subObservations: updatedSubObs,
        subUpdateLoading: false,
        subIsAdding: false,
      };
    case constants.UPDATE_SUB_OBSERVATION_ERROR:
      return {
        ...state,
        subUpdateLoading: false,
        // subIsAdding: false,
      };
    case constants.IS_SUB_OBSERVATION_ADD_UPDATE:
      return {
        ...state,
        subIsAdding: action.data,
      };

    default:
      return state;
  }
};

export default subObservationsReducer;
