import React, { useEffect, useState } from 'react';
import { Col, Input, Row, Form, Select } from 'antd';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  createClass,
  setClassDetailsIsEdit,
  updateClass,
} from '../redux/actions';
import SpinnerComponent from '../../../components/spinner';
import { useLocation } from 'react-router-dom';

const { Option } = Select;

const SlotDetailsSection = ({
  isClassUpdate,
  canUpdate,
  prevSelected,
  allUsers,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [currentClass, setCurrentClass] = useState({
    id: '',
    code: '',
    name: '',
    deleted_status: 'active',
  });

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [form] = Form.useForm();

  const { createClassLoading, currentClassObj, classIsEdit } = useSelector(
    (state) => state.classesReducer
  );

  useEffect(() => {
    if (currentClassObj?._id) {
      setCurrentClass(currentClassObj);
      const { class_enrolments, ...rest } = currentClassObj;
      form.setFieldsValue({
        ...rest,
      });
    }
  }, [currentClassObj]);

  useEffect(() => {
    if (pathname.includes('create') && currentClassObj?._id === undefined) {
      setIsEdit(true);
    } else {
      setIsEdit(classIsEdit);
    }
  }, [classIsEdit, currentClassObj]);

  useEffect(() => {
    setOptionsSelected(prevSelected);
  }, [prevSelected]);

  const onFinish = (values) => {
    const { id, code, name, deleted_status } = values;
    let obj = {};
    obj['id'] = id;
    obj['code'] = code;
    obj['name'] = name;
    obj['deleted_status'] = deleted_status;

    // Find deleted users
    const deleted_users = prevSelected.filter(
      (userId) => !optionsSelected.includes(userId)
    );

    // Find new added users
    const added_users = optionsSelected.filter(
      (userId) => !prevSelected.includes(userId)
    );
    obj['added_users'] = added_users;

    isClassUpdate
      ? dispatch(
          updateClass({
            ...obj,
            deleted_users,
            class_id: currentClassObj._id,
          })
        )
      : dispatch(createClass({ ...obj }));
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleSave = () => {
    form.submit();
  };

  const handleChange = (value) => {
    setOptionsSelected(value);
  };

  const ReadOnlyInputs = ({ name, label, placeholder }) => (
    <Col md={12} lg={12} xl={8} xxl={8}>
      <Form.Item name={name} label={label}>
        <Input readOnly={true} disabled={true} placeholder={placeholder} />
      </Form.Item>
    </Col>
  );

  const SelectFormItem = (props) => {
    const { label, name, required, options, placeholder } = props;
    return (
      <Form.Item
        label={label}
        name={name}
        rules={[
          {
            required: required,
            message: 'Required!',
          },
        ]}
      >
        <Select
          size='middle'
          style={{
            width: '100%',
          }}
          disabled={!isEdit}
          placeholder={placeholder}
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          optionFilterProp='children'
          optionLabelProp='label'
        >
          {options.map(({ label, value }) => {
            return (
              <Option key={value} value={value} label={label}>
                {label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  };

  return (
    <>
      <Row gutter={[0, 20]}>
        <Col span={24} className='heading-col'>
          <Row align='middle' justify='space-between'>
            <Col>
              <div>
                <span className='heading-span'>Class Details</span>
              </div>
            </Col>
            <Col>
              {isEdit ? (
                createClassLoading ? (
                  <SpinnerComponent fontSize={14} />
                ) : (
                  <SaveIcon width={16} height={16} onClick={handleSave} />
                )
              ) : (
                canUpdate && (
                  <EditIcon
                    width={16}
                    fill='#e21c21'
                    height={16}
                    onClick={() => dispatch(setClassDetailsIsEdit(true))}
                  />
                )
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className={`user-details-section project-details-section ${
            !isEdit && 'is-edit'
          }`}
          style={{ minHeight: '30vh' }}
        >
          <Row justify='center'>
            <Col md={24} lg={23} xl={22} xxl={22}>
              <Form
                {...formItemLayout}
                form={form}
                name='class-details'
                onFinish={onFinish}
                initialValues={currentClass}
                scrollToFirstError
                labelWrap={true}
                layout='horizontal'
                className='user-detail-form'
                requiredMark={false}
              >
                <Row gutter={[20, 10]}>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='name'
                      label='Name *'
                    >
                      <Input
                        readOnly={createClassLoading}
                        disabled={!isEdit || !canUpdate}
                        placeholder='Class name'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='id'
                      label='Class ID *'
                    >
                      <Input
                        readOnly={createClassLoading}
                        disabled={!isEdit || !canUpdate}
                        placeholder='Class ID'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='code'
                      label='Class Code *'
                    >
                      <Input
                        readOnly={createClassLoading}
                        disabled={!isEdit || !canUpdate}
                        placeholder='Class code'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <SelectFormItem
                      label='Status *'
                      name='deleted_status'
                      required={true}
                      options={[
                        { label: 'Active', value: 'active' },
                        { label: 'Inactive', value: 'inactive' },
                      ]}
                      placeholder='Select status'
                    />
                  </Col>

                  {currentClassObj?._id && !isEdit && (
                    <>
                      <ReadOnlyInputs
                        name='class_risk'
                        label='Class Risk'
                        placeholder='Class risk'
                      />
                      <ReadOnlyInputs
                        name='class_competency_rate'
                        label='Competency Rate'
                        placeholder='Class competency rate'
                      />
                      <ReadOnlyInputs
                        name='class_homework_rate'
                        label='homework Rate'
                        placeholder='Class homework Rate'
                      />
                      <ReadOnlyInputs
                        name='class_parents_engagement_rate'
                        label='Parents engagement rate'
                        placeholder='Class Parents engagement rate'
                      />
                      <ReadOnlyInputs
                        name='student_count'
                        label='Student Count'
                        placeholder='Student count'
                      />
                    </>
                  )}

                  <Col md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item label='Enrollments'>
                      <Select
                        mode='multiple'
                        size='small'
                        style={{
                          width: '100%',
                        }}
                        disabled={!isEdit || !canUpdate}
                        placeholder={`Select Enrollments`}
                        value={optionsSelected}
                        onChange={handleChange}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={allUsers}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='empty-div'></div>
    </>
  );
};

export default SlotDetailsSection;
