import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';

export function* handleFetchComments(data) {
  try {
    const response = yield axios.get(`${baseURL}/api/v2/admin/comments`, {
      params: data?.data || {},
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchCommentsSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchCommentsError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateComments({ data }) {
  try {
    const response = yield axios.put(`${baseURL}/api/v2/admin/comments`, data);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateCommentsSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateCommentsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateCommentsError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleDeleteComments({ data }) {
  const { _id } = data;
  try {
    const response = yield axios.delete(`${baseURL}/api/v2/admin/comments/${_id}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteCommentsSuccess(_id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteCommentsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteCommentsError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleCreateComments({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData._id;
    const response = yield axios.post(`${baseURL}/api/v2/admin/comments`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createCommentsSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createCommentsError(data._id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateContactOrder({ data }) {
  try {
    const response = yield axios.patch(`${baseURL}/sort_api/v2/admin/comments`, data);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateCommentsOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateCommentsOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateCommentsOrderError());
    AntdAlert.error('Something went wrong!');
  }
}

export function* watcherCommentsSaga() {
  yield takeLatest(constants.FETCH_COMMENTS, handleFetchComments);
  yield takeLatest(constants.CREATE_COMMENTS, handleCreateComments);
  yield takeLatest(constants.UPDATE_COMMENTS, handleUpdateComments);
  yield takeLatest(constants.UPDATE_ORDER_COMMENTS, handleUpdateContactOrder);
  yield takeLatest(constants.DELETE_COMMENTS, handleDeleteComments);
}
