import React from "react";
import { Modal, Button, Row, Col, Form, Input, message } from "antd";

const CreatePasswordModal = ({
  isVisible,
  setVisible,
  setPassword,
  isUpdate,
}) => {
  const [form] = Form.useForm();
  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setPassword(values.password);
    setVisible(false);
    if (!isUpdate) {
      return message.success("Password has been saved!");
    }
  };

  // const handleSubmit = () => {
  // if (password === "") {
  //   message.error("Please! enter your password.");
  // } else if (cnfrmPassword === "") {
  //   message.error("Please! enter confirmation passowrd");
  // } else if (password !== cnfrmPassword) {
  //   message.error("Passwords should be same");
  // } else {
  //   dispatch(
  //     resetPasswordAction(
  //       { password, password_confirmation: cnfrmPassword, id: data.id },
  //       setLoading,
  //       redirectToLogin
  //     )
  //   );
  // }
  // };

  return (
    <>
      <Modal
        title={`Create Password`}
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="create-password-modal"
        maskClosable={false}
        footer={null}
        width={409}
      >
        <Row justify="center" gutter={[0, 20]}>
          {/* <Col>
            <div className="text-div">
              Password must be at least six characters in length containing both
              uppercase and lowercase characters (a-z and A-Z) and must contain
              at least one number (0-9) and one special character (* ! #).
            </div>
          </Col> */}
          <Col span={24}>
            <Form form={form} name="password-form" onFinish={onFinish}>
              <label>Create Password</label>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                  // {
                  //   pattern:
                  //     /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                  //   message: "Invalid!",
                  // },
                ]}
              >
                <Input.Password
                  type="password"
                  width="100%"
                  placeholder="Enter password"
                />
              </Form.Item>
              <label>Confirm password</label>
              <Form.Item
                name="ConfirmPassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("Confirm password not matched!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  width="100%"
                  placeholder="Confirm password"
                />
              </Form.Item>
              <Col>
                <Button
                  htmlType="submit"
                  className="custom-blue-btn confirm-btn"
                >
                  CONFIRM
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CreatePasswordModal;
