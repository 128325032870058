import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Row, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../../../assets/save-icon.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/cross-icon.svg';
import { ReactComponent as EmailIcon } from '../../../../../assets/email-icon.svg';
import './index.less';
import AttachComponent from './attachComponent';

const Attachments = () => {
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState({
    applications: [],
    idPassports: [],
    bankStatements: [],
    others: [],
  });

  const sendEmail = () => {
    // dispatch(isAddingAction(true));
  };

  return (
    <Row justify='center' className='attachments-parent-row'>
      <Col xxl={24} xl={24} lg={24} md={24} className='heading-col'>
        <Row align='middle' gutter={[30, 0]}>
          <Col>
            <div>
              <span className='heading-span'>ATTACHMENTS</span>
            </div>
          </Col>
          <Col>
            {/* {canUpdate && ( */}
            <Button
              icon={<EmailIcon />}
              className='send-email-btn'
              size='small'
              disabled={Boolean(editingKey)}
              onClick={sendEmail}
            >
              Email Upload Link to Client
            </Button>
            {/* )} */}
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row justify='end' style={{ margin: '5px 0px' }}>
          <Col className='required-field-text'>* Required Fields</Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <AttachComponent
              images={data.applications}
              setData={setData}
              name='applications'
              label='Applications *'
            />
          </Col>
          <Col span={24}>
            <AttachComponent
              images={data.idPassports}
              setData={setData}
              name='idPassports'
              label='ID / PASSPORT *'
            />
          </Col>
          <Col span={24}>
            <AttachComponent
              images={data.bankStatements}
              setData={setData}
              name='bankStatements'
              label='BANK STATEMENTS *'
            />
          </Col>
          <Col span={24}>
            <AttachComponent
              images={data.others}
              setData={setData}
              name='others'
              label='Others'
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Attachments;
