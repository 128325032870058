import { Row, Col, Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './index.less';
import { ReactComponent as BackIcon } from '../../../assets/back-icon.svg';
import UserDetailsSection from './userDetailsSection';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllCurriculums,
  // fetchAllUsers,
  fetchUser,
  setUserDetailsIsEdit,
  userDetailCleanupFunc,
} from '../redux/actions';
import axios from 'axios';
import { baseURL } from '../../../globalUtils/axiosConfig';
// import UseSupAdminOrAdminWithUserManagerPermission from '../../../components/useSupAdminOrAdminWithUserManagerPermission';
// import GeneralPermissions from './generalPermissions';
import StudentsListing from './StudentsListing';
import CurriculumsTable from './curriculums';

const UserDetails = () => {
  const [isUserUpdate, setIsUserUpdate] = useState(false);
  const [isProfileCase, setIsProfileCase] = useState();
  const [canUpdate, setCanUpdate] = useState(true); // false
  const [userType, setUserType] = useState('');
  const [classes, setClasses] = useState([]);
  const [{ allStudents, allGuardians, allUsers }, setAllUsers] = useState({
    allStudents: [],
    allGuardians: [],
    allUsers: [],
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  // let canEditEveryThing = UseSupAdminOrAdminWithUserManagerPermission();

  const { currentUserObj, fetchUserDetailLoading, userDetailsIsEdit } =
    useSelector((state) => state.userManagerReducer);
  const { user } = useSelector((state) => state.authReducer);

  const [allCurriculums, setCurriculums] = useState([]);

  const getCurriculums = async () => {
    const response = await axios.get(`${baseURL}/api/v2/admin/curriculums`);
    const allCurriculums = response.data?.data?.curriculums || [];
    let options = allCurriculums.map((el) => ({
      label: el.name,
      value: el._id,
    }));
    setCurriculums(options);
  };

  const getUsers = async (userType) => {
    const { tenant } = JSON.parse(localStorage.getItem('user'));

    const response = await axios.get(
      `${baseURL}/api/v2/admin/users?tenant=${tenant}`
    );

    const users = response.data?.data?.users || [];
    const filteredUsers = users
      // .filter((el) => el.user_type === userType)
      .map((item) => ({
        label: `${item.first_name} ${item.last_name}`,
        value: item._id,
        roles: item?.roles || [],
      }));

    const { allStudents, allGuardians } = users.reduce(
      (acc, el) => {
        const { first_name, last_name, _id, roles } = el;
        let label = `${first_name} ${last_name}`;

        // if (userType === 'student') {
        //   acc.allGuardians.push({ label, value: _id });
        // } else if (userType === 'guardian') {
        //   acc.allStudents.push({ label, value: _id });
        // }

        if (userType === 'student') {
          acc.allGuardians.push({ label, value: _id, roles });
        } else {
          acc.allStudents.push({ label, value: _id, roles });
        }
        return acc;
      },
      { allStudents: [], allGuardians: [] }
    );

    setAllUsers({ allStudents, allGuardians, allUsers: filteredUsers });
  };

  const getClasses = async () => {
    const response = await axios.get(
      `${baseURL}/api/v2/admin/classes/classes-list`
    );

    const classesList = response.data?.data?.classes || [];
    const filteredClasses = classesList.map((item) => ({
      label: `${item.name} - ${item.id}`,
      value: item._id,
    }));

    setClasses(filteredClasses);
  };

  useEffect(() => {
    getCurriculums();
    getClasses();
  }, []);

  useEffect(() => {
    setIsProfileCase(pathname === '/profile');
    // if (user?.role === 'Admin') {
    //   setCanUpdate(true);
    // }
  }, [pathname, user]);

  useEffect(() => {
    if (currentUserObj?._id) {
      setIsUserUpdate(true);
      setIsProfileCase(currentUserObj._id === user._id);
      setUserType(currentUserObj?.role);
      getUsers(currentUserObj?.roles);

      dispatch(
        fetchAllCurriculums({
          user_id: currentUserObj._id,
        })
      );
    } else {
      if (pathname.includes('create')) {
        dispatch(setUserDetailsIsEdit(true));
      }
    }
  }, [currentUserObj]);

  useEffect(() => {
    if (params?.id) {
      setIsUserUpdate(true);
      dispatch(fetchUser({ id: params?.id })); // user_type
    } else if (pathname === '/profile') {
      setIsUserUpdate(true);
      dispatch(fetchUser({ id: user._id })); // user_type
    }

    return () => {
      dispatch(userDetailCleanupFunc());
    };
  }, [params.id]);

  // useEffect(() => {
  //   dispatch(
  //     fetchAllUsers({
  //       page: 1,
  //       limit: 10000,
  //       search: {
  //         user_type: 'manager',
  //       },
  //       sort: 'first_name',
  //       order: 1,
  //     })
  //   );
  // }, []);

  let coachLearnerTypes = ['coach', 'learner'];
  let cannotUpdate = isProfileCase && coachLearnerTypes.includes(userType);

  let isCoach = user?.user_type === 'coach';
  let showHideFields =
    !userDetailsIsEdit && isCoach && pathname.includes('learner-users-manager');

  let currentUserType = currentUserObj?.roles || [];

  let isGuardianOrStudent = user?.roles?.some((role) =>
    ['guardian', 'student'].includes(role)
  );

  let tableData = currentUserType.includes('student')
    ? currentUserObj?.student_guardians || []
    : currentUserObj?.children || [];

  const options = tableData.map((item) => item._id);

  return (
    <div className='page-top-div heading-parent-div'>
      <Row justify='space-between'>
        <Col>
          <span className='text-span'>
            {isProfileCase
              ? `User - ${user?.first_name + ' ' + user?.last_name}`
              : isUserUpdate
              ? `Update User`
              : `Add User`}
          </span>
        </Col>
        {!isProfileCase && (
          <Col>
            <Button
              onClick={() => navigate(`/settings/users-manager`)}
              icon={<BackIcon width={24} height={14} />}
              className='back-btn'
              size='middle'
            >
              BACK
            </Button>
          </Col>
        )}
      </Row>
      <Spin spinning={fetchUserDetailLoading}>
        <div className='user-manager-parent-div'>
          <UserDetailsSection
            isUserUpdate={isUserUpdate}
            isProfileCase={isProfileCase}
            canUpdate={canUpdate}
            userType={userType}
            cannotUpdate={false} // cannotUpdate
            showHideFields={showHideFields}
            yearsLevelOption={[]} // yearsLevelOption
            prevSelected={options}
            options={currentUserType === 'student' ? allGuardians : allStudents}
            allGuardians={allGuardians}
            allStudents={allStudents}
            allUsers={allUsers}
            curriculumsOptions={allCurriculums}
            classesOptions={classes}
          />
          {/* {isUserUpdate && isGuardianOrStudent && ( */}
          {isUserUpdate && (
            <>
              {/* <GeneralPermissions
                isProfileCase={isProfileCase}
                canUpdate={canUpdate}
                userType={userType}
                cannotUpdate={cannotUpdate}
              /> */}
              <StudentsListing
                isProfileCase={isProfileCase}
                canUpdate={canUpdate}
                userType={userType}
                cannotUpdate={cannotUpdate}
                currentUserObj={currentUserObj}
                tableData={tableData}
              />
              {currentUserObj?.roles?.includes('student') && (
                <CurriculumsTable
                  currentUserObj={currentUserObj}
                  allCurriculums={allCurriculums}
                />
              )}
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default UserDetails;
