import React from 'react';
import { DatePicker, Form, Input, Select } from 'antd';
import { useSelector } from 'react-redux';
// import ImageComponent from './useAttachments';
const { Option } = Select;
const { TextArea } = Input;

let nonRequired = ['agentName', 'email', 'contactNumber', 'address'];

const EditableCell = ({
  cell,
  expandedRowKeys,
  setExpandedRowKeys,
  // images,
  // setImages,
  // attachPopupVisible,
  // setAttachPopupVisible,
  // currentUpdateObj,
  values,
  handleChangeDocType,
  handleUpdateSite,
  // getCloumnName,
  isRevisionUpdateCase,
  manuals,
}) => {
  const {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  } = cell;

  const {
    allContactTypes,
    allProductTypes,
    allQuoteStatuses,
    allLeadStatuses,
  } = useSelector((state) => ({
    allContactTypes: state.contactTypesReducer.allContactTypes,
    allProductTypes: state.productTypesReducer.allProductTypes,
    allQuoteStatuses: state.quoteStatusReducer.allQuoteStatuses,
    allLeadStatuses: state.leadStatusReducer.allLeadStatuses,
  }));

  let inputNode;
  let classname = `${isRevisionUpdateCase ? 'ims-doc-readonly-input' : ''}`;

  switch (dataIndex) {
    case 'receiveDate':
    case 'createdDate':
    case 'dateToContact':
      inputNode = (
        <DatePicker
          format={'DD MMM YY'}
          size='large'
          style={{ minWidth: '90px' }}
          suffixIcon={null}
          allowClear={false}
          className={classname}
          disabled={isRevisionUpdateCase}
        />
      );
      break;
    case 'contactType':
      inputNode = (
        <Select
          showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ width: '85px' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Contact Type'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleUpdateSite}
        >
          {allContactTypes.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;
    case 'productType':
      inputNode = (
        <Select
          showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ width: '85px' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Product Type'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allProductTypes.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;
    case 'agentName':
    case 'managerName':
      inputNode = <Input readOnly className='border-less-input' />;
      break;
    case 'accountId':
      inputNode = (
        <Input
          // disabled={shouldDisabled || isRevisionUpdateCase}
          placeholder='Account ID'
          size='large'
          classname={classname}
          readOnly={isRevisionUpdateCase}
        />
      );
      break;
    case 'customer':
      inputNode = (
        <Input
          // disabled={shouldDisabled || isRevisionUpdateCase}
          placeholder='Customer'
          size='large'
          classname={classname}
          readOnly={isRevisionUpdateCase}
        />
      );
      break;
    case 'quoteStatus':
      inputNode = (
        <Select
          showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ width: '85px' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Quote Status'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // onChange={(e) => {
          //   handleChangeDocType(e);
          // }}
        >
          {allQuoteStatuses.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;
    case 'comment':
      inputNode = <TextArea rows={2} placeholder='Insert Comment' />;
      break;
    case 'leadStatus':
      inputNode = (
        <Select
          showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ width: '85px' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Lead Status'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allLeadStatuses.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;

    default:
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          className={`ims-doc-first-level ${classname}`}
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: !nonRequired.includes(dataIndex),
              message: '', // getErrorText(dataIndex)
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
