export const statusOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

export const userTypeOptions = [
  { label: 'Admin', value: 'admin' },
  { label: 'Administrative', value: 'administrative' },
  { label: 'Assessor', value: 'assessor' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Student', value: 'student' },
  { label: 'Staff', value: 'staff' },
  { label: 'Teacher', value: 'teacher' },
];
