import React, { useEffect, useState } from 'react';
import { Table, Input, Col, Row } from 'antd';
import moment from 'moment';
import _ from 'lodash';

function AttendanceTable({
  attendances,
  presentCount,
  lateCount,
  absentCount,
}) {
  const [data, setData] = useState(attendances);
  const [sortedInfo, setSortedInfo] = useState(null);
  // images contains all attachments of item
  const [search, setSearch] = useState({});
  const [showTable, setShowTable] = useState(true);
  const [req, setReq] = useState(false);

  const filterData = () => {
    let filterData = attendances;

    if (search?.class?.length > 0) {
      filterData = attendances.filter((entry) =>
        entry.class?.id
          .toLocaleLowerCase()
          .includes(search?.class?.toLocaleLowerCase())
      );
    }

    if (search?.student?.length > 0) {
      filterData = filterData.filter((entry) => {
        const { student } = entry;
        let name = '';
        if (student) {
          name = `${student.user_id} -${student?.first_name} ${student?.last_name}`;
        }
        return name
          .toLocaleLowerCase()
          .includes(search?.student?.toLocaleLowerCase());
      });
    }

    if (search?.attendance?.length > 0) {
      filterData = filterData.filter((entry) => {
        let attendance = entry.attendance;
        return attendance
          .toLocaleLowerCase()
          .includes(search?.attendance.toLocaleLowerCase());
      });
    }

    if (search?.total_attendances?.length > 0) {
      filterData = filterData.filter(({ total_attendances }) =>
        total_attendances
          .toString()
          .includes(search?.total_attendances.toString())
      );
    }

    if (search?.present_count?.length > 0) {
      filterData = filterData.filter(({ present_count }) =>
        present_count.toString().includes(search?.present_count.toString())
      );
    }

    if (search?.late_count?.length > 0) {
      filterData = filterData.filter(({ late_count }) =>
        late_count.toString().includes(search?.late_count.toString())
      );
    }

    if (search?.absent_count?.length > 0) {
      filterData = filterData.filter(({ absent_count }) =>
        absent_count.toString().includes(search?.absent_count.toString())
      );
    }

    if (search?.present_percentage?.length > 0) {
      filterData = filterData.filter(({ present_percentage }) =>
        present_percentage
          .toString()
          .includes(search?.present_percentage.toString())
      );
    }

    if (search?.late_percentage?.length > 0) {
      filterData = filterData.filter(({ late_percentage }) =>
        late_percentage.toString().includes(search?.late_percentage.toString())
      );
    }

    if (search?.absent_percentage?.length > 0) {
      filterData = filterData.filter(({ absent_percentage }) =>
        absent_percentage
          .toString()
          .includes(search?.absent_percentage.toString())
      );
    }

    setData(filterData);
  };

  useEffect(() => {
    filterData();
  }, [req]);

  useEffect(() => {
    setData(attendances);
  }, [attendances]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const searchInput = (key, disabled = false) => (
    <Input
      placeholder={''}
      onClick={(e) => e.stopPropagation()}
      disabled={disabled}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedCallApi();
      }}
    />
  );

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Class ID</div>
            <div>{searchInput('class')}</div>
          </div>
        );
      },
      dataIndex: 'class',
      key: 'class',
      sorter: (a, b) =>
        a.class?.id?.toLowerCase()?.localeCompare(b.class?.id?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'class' && sortedInfo?.order,
      ellipsis: true,
      width: '11%',
      render: (text) => <div>{text?.id}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>student</div>
            <div>{searchInput('student')}</div>
          </div>
        );
      },
      dataIndex: 'student',
      key: 'student',
      sorter: (a, b) =>
        a.student?.user_id
          ?.toLowerCase()
          ?.localeCompare(b.student?.user_id?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'student' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      render: (student) => {
        let name = '';
        if (student) {
          name = `${student.user_id} - ${student?.first_name} ${student?.last_name}`;
        }
        return <div>{name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>attendance</div>
            <div>{searchInput('attendance')}</div>
          </div>
        );
      },
      dataIndex: 'attendance',
      key: 'attendance',
      sorter: (a, b) =>
        a.attendance?.toLowerCase()?.localeCompare(b.attendance?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'attendance' && sortedInfo?.order,
      ellipsis: true,
      width: '8%',
      align: 'left',
      render: (attendance) => (
        <div
          style={{
            textTransform: 'capitalize',
          }}
        >
          {attendance}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Time</div>
            <div>{searchInput('attendance_time', true)}</div>
          </div>
        );
      },
      dataIndex: 'attendance_time',
      key: 'attendance_time',
      sorter: (a, b) =>
        moment(a.attendance_time).unix() - moment(b.attendance_time).unix(),
      sortOrder:
        sortedInfo?.columnKey === 'attendance_time' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'left',
      render: (attendance_time) => {
        if (!attendance_time) {
          return <div></div>;
        }

        return (
          <div>
            {moment
              .utc(attendance_time, 'YYYY-MM-DD HH:mm:ss.SSS')
              .local()
              .format('DD MMM YY hh:mm A')}
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Total</div>
            <div>{searchInput('total_attendances')}</div>
          </div>
        );
      },
      dataIndex: 'total_attendances',
      key: 'total_attendances',
      sorter: (a, b) => a.total_attendances - b.total_attendances,
      sortOrder:
        sortedInfo?.columnKey === 'total_attendances' && sortedInfo?.order,
      ellipsis: true,
      width: '8%',
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Present</div>
            <div>{searchInput('present_count')}</div>
          </div>
        );
      },
      dataIndex: 'present_count',
      key: 'present_count',
      sorter: (a, b) => a.present_count - b.present_count,
      sortOrder: sortedInfo?.columnKey === 'present_count' && sortedInfo?.order,
      ellipsis: true,
      width: '8%',
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Late</div>
            <div>{searchInput('late_count')}</div>
          </div>
        );
      },
      dataIndex: 'late_count',
      key: 'late_count',
      sorter: (a, b) => a.late_count - b.late_count,
      sortOrder: sortedInfo?.columnKey === 'late_count' && sortedInfo?.order,
      ellipsis: true,
      width: '8%',
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Absent</div>
            <div>{searchInput('absent_count')}</div>
          </div>
        );
      },
      dataIndex: 'absent_count',
      key: 'absent_count',
      sorter: (a, b) => a.absent_count - b.absent_count,
      sortOrder: sortedInfo?.columnKey === 'absent_count' && sortedInfo?.order,
      ellipsis: true,
      width: '8%',
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Present %</div>
            <div>{searchInput('present_percentage')}</div>
          </div>
        );
      },
      dataIndex: 'present_percentage',
      key: 'present_percentage',
      sorter: (a, b) => a.present_percentage - b.present_percentage,
      sortOrder:
        sortedInfo?.columnKey === 'present_percentage' && sortedInfo?.order,
      ellipsis: true,
      width: '8%',
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Late %</div>
            <div>{searchInput('late_percentage')}</div>
          </div>
        );
      },
      dataIndex: 'late_percentage',
      key: 'late_percentage',
      sorter: (a, b) => a.late_percentage - b.late_percentage,
      sortOrder:
        sortedInfo?.columnKey === 'late_percentage' && sortedInfo?.order,
      ellipsis: true,
      width: '8%',
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Absent %</div>
            <div>{searchInput('absent_percentage')}</div>
          </div>
        );
      },
      dataIndex: 'absent_percentage',
      key: 'absent_percentage',
      sorter: (a, b) => a.absent_percentage - b.absent_percentage,
      sortOrder:
        sortedInfo?.columnKey === 'absent_percentage' && sortedInfo?.order,
      ellipsis: true,
      width: '8%',
      align: 'center',
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    // if (sorter.field === 'student_recording_path') {
    //   return;
    // }
    setSortedInfo(sorter);
  };

  return (
    <Row className='revisions-parent-row' style={{ minHeight: '20vh' }}>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[40, 20]}>
          <Col>
            <div className='revisions-heading-div'>
              {/* <div onClick={() => setShowTable(!showTable)}>
                {showTable ? (
                  <UpCircleOutlined className='revision-up-down-icon' />
                ) : (
                  <DownCircleOutlined className='revision-up-down-icon' />
                )}
              </div> */}
              <div>
                <span className='heading-span'>
                  Attendances{' '}
                  {attendances.length > 0 && ` (${attendances.length})`}
                </span>
              </div>
            </div>
          </Col>
          <Col>
            <b>Present: {presentCount}</b>
          </Col>
          <Col>
            <b>Late: {lateCount}</b>
          </Col>
          <Col>
            <b>Absent: {absentCount}</b>
          </Col>
        </Row>
      </Col>

      {showTable && (
        <Col span={24}>
          <Table
            scroll={{ x: 1100 }}
            className='special-table ims-doc-table users-manager-table'
            rowKey={(record) => record._id}
            // rowClassName='editable-row'
            dataSource={data}
            columns={columns}
            onChange={handleChange}
            pagination={false}
            expandable={{
              showExpandColumn: false,
            }}
          />
        </Col>
      )}
    </Row>
  );
}

export default AttendanceTable;
