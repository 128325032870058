import * as constants from './constants';

const initialState = {
  allQuoteStatuses: [],
  fetchingLoading: false,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
  completedStatusIsEdit: false,
};

const quoteStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_QUOTE_STATUSES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_QUOTE_STATUSES_SUCCESS:
      let observations =
        action.data?.data?.learning_activity_observations || [];
      let updatedObservations = observations
        .map((el) => {
          return {
            _id: el._id,
            position: el.position,
            learning_activity_id: el.learning_activity,
            observation_id: el.observation._id,
            name: el.observation.name,
            deleted_status: el.observation.deleted_status,
            sub_observations: el?.learning_activity_sub_observations || [],
            // observation: {
            //   ...el?.observation,
            // },
          };
        })
        .sort((a, b) => a.position - b.position);

      return {
        ...state,
        updateLoading: false,
        allQuoteStatuses: updatedObservations,
        isAdding: false,
      };
    case constants.CREATE_QUOTE_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_QUOTE_STATUS_ORDER:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_QUOTE_STATUS_ORDER_SUCCESS:
      let obs = action.data?.data?.learning_activity_observations || [];
      let updatedOrder = obs
        .map((el) => {
          return {
            _id: el._id,
            position: el.position,
            learning_activity_id: el.learning_activity,
            observation_id: el.observation._id,
            name: el.observation.name,
            deleted_status: el.observation.deleted_status,
            sub_observations: el?.learning_activity_sub_observations || [],
            // observation: {
            //   ...el?.observation,
            // },
          };
        })
        .sort((a, b) => a.position - b.position);

      return {
        ...state,
        fetchingLoading: false,
        allQuoteStatuses: updatedOrder,
      };
    case constants.UPDATE_QUOTE_STATUS_ORDER_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_QUOTE_STATUSES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_QUOTE_STATUSES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allQuoteStatuses: action.data,
        // totalRecords: total_allQuoteStatuses,
        isAdding: false,
        completedStatusIsEdit: false,
      };
    case constants.FETCH_QUOTE_STATUSES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_QUOTE_STATUSES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_QUOTE_STATUSES_SUCCESS:
      let observationsUpdated =
        action.data?.data?.learning_activity_observations || [];
      let updatedObs = observationsUpdated
        .map((el) => {
          return {
            _id: el._id,
            position: el.position,
            learning_activity_id: el.learning_activity,
            observation_id: el.observation._id,
            name: el.observation.name,
            deleted_status: el.observation.deleted_status,
            sub_observations: el?.learning_activity_sub_observations || [],
            // observation: {
            //   ...el?.observation,
            // },
          };
        })
        .sort((a, b) => a.position - b.position);
      return {
        ...state,
        allQuoteStatuses: updatedObs,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_QUOTE_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_QUOTE_STATUSES:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_QUOTE_STATUSES_SUCCESS:
      const updatedData = state.allQuoteStatuses.filter(
        (el) => el._id !== action.data
      );
      return {
        ...state,
        allQuoteStatuses: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_QUOTE_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_QUOTE_STATUSES_ADD_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    case constants.SET_QUOTE_COMPLETED_STATUS:
      return {
        ...state,
        completedStatusIsEdit: action.data,
      };

    case constants.UPDATE_QUOTE_COMPLETED_STATUS:
      return {
        ...state,
        updateLoading: true,
        // isAdding: false,
      };
    case constants.UPDATE_QUOTE_COMPLETED_STATUS_SUCCESS:
      // const updatedData = state.allQuoteStatuses.filter(
      //   (el) => el.id !== action.data
      // );
      return {
        ...state,
        // allQuoteStatuses: updatedData,
      };
    case constants.UPDATE_QUOTE_COMPLETED_STATUS_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default quoteStatusReducer;
