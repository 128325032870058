const FETCH_ROOMS = 'FETCH_ROOMS';
const FETCH_ROOMS_SUCCESS = 'FETCH_ROOMS_SUCCESS';
const FETCH_ROOMS_ERROR = 'FETCH_ROOMS_ERROR';
const UPDATE_ROOMS = 'UPDATE_ROOMS';
const UPDATE_ROOMS_SUCCESS = 'UPDATE_ROOMS_SUCCESS';
const UPDATE_ROOMS_ERROR = 'UPDATE_ROOMS_ERROR';
const DELETE_ROOMS = 'DELETE_ROOMS';
const DELETE_ROOMS_SUCCESS = 'DELETE_ROOMS_SUCCESS';
const DELETE_ROOMS_ERROR = 'DELETE_ROOMS_ERROR';
const CREATE_ROOMS = 'CREATE_ROOMS';
const CREATE_ROOMS_SUCCESS = 'CREATE_ROOMS_SUCCESS';
const CREATE_ROOMS_ERROR = 'CREATE_ROOMS_ERROR';
const UPDATE_ORDER_ROOMS = 'UPDATE_ORDER_ROOMS';
const UPDATE_ORDER_ROOMS_SUCCESS = 'UPDATE_ORDER_ROOMS_SUCCESS';
const UPDATE_ORDER_ROOMS_ERROR = 'UPDATE_ORDER_ROOMS_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';
const SET_ROOMS_MANAGER_STATE = 'SET_ROOMS_MANAGER_STATE';

export {
  FETCH_ROOMS,
  FETCH_ROOMS_SUCCESS,
  FETCH_ROOMS_ERROR,
  UPDATE_ROOMS,
  UPDATE_ROOMS_SUCCESS,
  UPDATE_ROOMS_ERROR,
  DELETE_ROOMS,
  DELETE_ROOMS_SUCCESS,
  DELETE_ROOMS_ERROR,
  CREATE_ROOMS,
  CREATE_ROOMS_SUCCESS,
  CREATE_ROOMS_ERROR,
  UPDATE_ORDER_ROOMS,
  UPDATE_ORDER_ROOMS_SUCCESS,
  UPDATE_ORDER_ROOMS_ERROR,
  IS_ADDING_UPDATE,
  SET_ROOMS_MANAGER_STATE,
};
