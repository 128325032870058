import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { convertStrToMinutes } from "../../../../globalUtils/convertStrToMinutes";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleFetchDocument() {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/document_control/document_control_graph`
    );
    const { success } = response.data;
console.log("response", response);
    if (success) {
      yield put(actions.fetchDocumentSuccess(response.data.data));
    } else if (!success) {
      yield put(actions.fetchDocumentError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchDocumentError());
    console.log(error);
  }
}

export function* handleFetchDocumentUtilsData({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/document_control/search_lists`
    );
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchDocumentSearchUtilsSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateDocument({ data }) {
  const { id, debouncedDefaultPageOneCallApi, ...res } = data;

  const fd = new FormData();
  for (const [key, value] of Object.entries(res)) {
    if (key === "images" && value !== undefined) {
      Array.from(value).forEach((image) => {
        const { isLocal } = image;
        if (isLocal) {
          fd.append(`images[]`, image);
        }
        // else fd.append("image_ids[]", key);
      });
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v2/document_control/${id}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateDocumentSuccess(response.data.data));
      debouncedDefaultPageOneCallApi();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateDocumentError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateDocumentError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleDelete({ data }) {
  const { id, onDeleteSuccess } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/document_control/${id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteDocumentSuccess(response.data.data));
      onDeleteSuccess();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteDocumentError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteDocumentError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleCreateDocument({ data }) {
  const fd = new FormData();

  const { debouncedDefaultPageOneCallApi, ...res } = data;

  for (const [key, value] of Object.entries(res)) {
    if (key === "images" && value !== undefined) {
      Array.from(value).forEach((image) => {
        const { key } = image;
        if (image?.isCopy) {
          fd.append("image_ids[]", key);
        } else fd.append(`images[]`, image);
      });
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const response = yield axios.post(
      `${baseURL}/api/v2/document_control`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createDocumentSuccess(response.data.data));
      debouncedDefaultPageOneCallApi();
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createDocumentError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.createDocumentError());
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleDeleteDocumentAttach({ data }) {
  const { key, recordId } = data;
  try {
    const response = yield axios.delete(`${baseURL}/api/v2/images/${key}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.deleteDocumentAttachSuccess({
          recordId,
          imgItem: response.data.data,
        })
      );
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteDocumentAttachError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteDocumentAttachError(response.data.data));
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleAddDocumentAttach({ data }) {
  const { setCurrentUpdateObj } = data;

  const { id, images } = data.data;

  const fd = new FormData();
  Array.from(images).forEach((image) => {
    const { isLocal } = image;
    if (isLocal) {
      fd.append(`images[]`, image);
    }
  });

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v2/document_control/${id}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateDocumentSuccess(response.data.data));
      setCurrentUpdateObj(response.data.data);
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateDocumentError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateDocumentError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}
