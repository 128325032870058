import React, { useEffect, useState } from 'react';
import { Table, Input, Space, Select, Col, Row, Button } from 'antd';
import {
  DownOutlined,
  UpOutlined,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import sampleImg from '../../assets/user-icon.svg';

import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUser,
  fetchAllUsers,
  setUserDetailsIsEdit,
  setStorage,
} from './redux/actions';
import SpinnerComponent from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import CustomPagination from '../../components/customPagination/CustomPagination';
import { capitalizeFirstLetterofEachWord } from '../../globalUtils/capitalizeFirstLetterofEachWord';
import { statusOptions, userTypeOptions } from './utils';
import AMultiSelect from '../../components/AMultiSelect';
// import ExportDoc from './export';
// import ImportDoc from './import';
import UsePermissions from './hooks/usePermissions';
import { baseURL } from '../../globalUtils/axiosConfig';
import { genderOptions } from './userDetails/utils';

const { Option } = Select;

const UsersRegisterTable = ({ userType, isTeacher }) => {
  const {
    allUsers,
    fetchUsersLoading,
    deleteUserLoading,
    totalRecords,
    ...rest
  } = useSelector((state) => state.userManagerReducer);

  const [sortedInfo, setSortedInfo] = useState(rest.sortedInfo || null);
  const [dataSource, setDataSource] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [currentDelRecord, setCurrentDelRecord] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const [salesUsersCheckList, setSalesUsersCheckList] = useState([]);

  // const [dynamicTypes, setDynamicTypes] = useState([]);
  // const [dynamicStatuses, setDynamicStatuses] = useState([]);
  // const [statusCheckedList, setStatusCheckedList] = useState([]);
  // const [typeCheckedList, setTypeCheckedList] = useState([]);
  // const [npoData, setNpoData] = useState([]);
  // const [npoCheckedList, setNpoCheckedList] = useState([]);
  // const { canView, canEdit, canDelete } = UsePermissions();

  // const { user } = useSelector((state) => state.authReducer);
  // const { allNpos } = useSelector((state) => state.npoSetupReducer);
  // const { allCoachTypes } = useSelector((state) => state.coachTypesReducer);
  // const { allLearnerTypes } = useSelector((state) => state.learnerTypesReducer);
  // const { allCoachStatuses } = useSelector((state) => state.coachStatusReducer);
  // const { allLeadStatuses } = useSelector((state) => state.leadStatusReducer);

  useEffect(() => {
    if (localStorage.getItem('usersManagerState')) {
      const localStorageData = JSON.parse(
        localStorage.getItem('usersManagerState')
      );

      if (localStorageData) {
        setTimeout(() => {
          setSearch(localStorageData.search);
          setPage(localStorageData.page);

          setResultPerPage(localStorageData.resultPerPage);
          dispatch({
            type: 'SET_USERS_MANAGER_STATE',
            data: localStorageData,
          });

          if (localStorageData.sortedInfo) {
            setSortedInfo(localStorageData.sortedInfo);
          }
          debouncedCallApi();
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllUsers({
          page: page,
          page_size: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === 'ascend'
              ? 1
              : -1
            : null,
          userType,
        })
      );
    }
  }, [req, userType]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllUsers({
          page: paramsPage,
          page_size: resultPerPage,
          search,
          userType,
        })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  useEffect(() => {
    const isSearchChanged =
      JSON.stringify(search) !== JSON.stringify(rest.search);

    if (isSearchChanged) {
      setSearch(rest.search);
    }

    const isPageChanged = rest.page && rest.page !== page;
    const isResultPerPageChanged =
      rest.resultPerPage && rest.resultPerPage !== resultPerPage;

    if (isPageChanged) {
      setPage(rest.page);
    }

    if (isResultPerPageChanged) {
      setResultPerPage(rest.resultPerPage);
    }

    // const isSortChanged = rest.sortedInfo && rest.sortedInfo !== sortedInfo;

    if (isPageChanged || isResultPerPageChanged || isSearchChanged) {
      debouncedCallApi();
    }
  }, []);

  // useEffect(() => {
  //   setDynamicTypes(userType === 'coach' ? allCoachTypes : allLearnerTypes);

  //   let statuses = userType === 'coach' ? allCoachStatuses : allLeadStatuses;
  //   let types = userType === 'coach' ? allCoachTypes : allLearnerTypes;

  //   let updatedStatuses = statuses.map((el) => {
  //     const { id, name } = el;
  //     return { value: id, label: name };
  //   });
  //   setDynamicStatuses(updatedStatuses);

  //   let updatedTypes = types.map((el) => {
  //     const { id, name } = el;
  //     return { value: id, label: name };
  //   });
  //   setDynamicTypes(updatedTypes);
  // }, [
  //   allCoachStatuses,
  //   allCoachTypes,
  //   allLeadStatuses,
  //   allLearnerTypes,
  //   userType,
  // ]);

  // useEffect(() => {
  //   let updatedNpos = allNpos.map((el) => {
  //     const { id, name } = el;
  //     return { value: id, label: name };
  //   });
  //   setNpoData(updatedNpos);
  // }, [allNpos]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setDataSource(allUsers);
  }, [allUsers]);

  useEffect(() => {
    setFetchLoading(fetchUsersLoading);
  }, [fetchUsersLoading]);

  // useEffect(() => {
  //   setFetchLoading(deleteUserLoading);
  // }, [deleteUserLoading]);

  const handleSetStorage = (key, value) => {
    dispatch(setStorage({ [key]: value }));
  };

  const handleChange = (pagination, filters, sorter) => {
    if (sorter.field === 'roles') {
      return;
    }

    setSortedInfo(sorter);
    // dispatch(setStorage({ sortedInfo: sorter }));
    handleSetStorage('sortedInfo', sorter);
  };

  const handleDelete = (record) => {
    setCurrentDelRecord(record);
    dispatch(deleteUser(record));
  };

  const handleSearch = (key, value, func = null) => {
    let updatedState = {
      ...search,
      [key]: value,
    };

    setSearch(updatedState);

    if (typeof func === 'function') {
      func();
    }

    dispatch(setStorage({ search: updatedState }));
    handleSetStorage('search', updatedState);
    // debouncedDefaultPageOneCallApi();
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      value={search[key]}
      onChange={(e) => {
        // setSearch({
        //   ...search,
        //   [key]: e.target.value,
        // });
        // debouncedDefaultPageOneCallApi();
        handleSearch(key, e.target.value, debouncedDefaultPageOneCallApi);
      }}
    />
  );

  const handleNavigate = (id) =>
    // isTeacher &&
    navigate(`/settings/users-manager/update/${id}`);

  const handleMultiSelect = (list, key) => {
    // setSearch({
    //   ...search,
    //   [key]: list,
    // });
    handleSearch(key, list, debouncedDefaultPageOneCallApi);
  };

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const columns = [
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text'>Profile Image</div>
    //         <div></div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'image_path',
    //   key: 'image_path',
    //   sorter: false,
    //   sortOrder: sortedInfo?.columnKey === 'image_path' && sortedInfo?.order,
    //   ellipsis: true,
    //   width: '15%',
    //   render: (image_path, { _id }) => (
    //     <div
    //       // onClick={() => handleNavigate(_id)}
    //       style={{
    //         width: '50px',
    //         height: '50px',
    //       }}
    //     >
    //       <img
    //         width='100%'
    //         height='100%'
    //         style={{
    //           borderRadius: '50%',
    //           objectFit: 'fill',
    //         }}
    //         src={image_path ? `${baseURL}/${image_path}` : sampleImg}
    //         alt=''
    //       />
    //     </div>
    //   ),
    // },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>User ID</div>
            <div>{searchInput('user_id')}</div>
          </div>
        );
      },
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'user_id' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      render: (user_id, { _id }) => (
        <div
        // onClick={() => handleNavigate(_id)}
        >
          {user_id}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>first name</div>
            <div>{searchInput('first_name')}</div>
          </div>
        );
      },
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'first_name' && sortedInfo?.order,
      ellipsis: true,
      width: '20%',
      render: (first_name, { _id }) => (
        <div
        // onClick={() => handleNavigate(_id)}
        >
          {first_name}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>last name</div>
            <div>{searchInput('last_name')}</div>
          </div>
        );
      },
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'last_name' && sortedInfo?.order,
      ellipsis: true,
      width: '20%',
      render: (last_name, { _id }) => (
        <div
        // onClick={() => handleNavigate(_id)}
        >
          {last_name}
        </div>
      ),
    },

    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>email</div>
            <div>{searchInput('email')}</div>
          </div>
        );
      },
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      // render: (email, { _id }) => (
      //   <div onClick={() => handleNavigate(_id)}>{email}</div>
      // ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>user Roles</div>
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select Project'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                value={search?.roles || null}
                showSearch
                placeholder=''
                optionFilterProp='children'
                onChange={(e) => {
                  // setSearch({
                  //   ...search,
                  //   roles: e,
                  // });
                  // debouncedDefaultPageOneCallApi();
                  handleSearch('roles', e, debouncedDefaultPageOneCallApi);
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {userTypeOptions?.map(({ label, value }) => {
                  return (
                    <Option value={value} label={label}>
                      {label || ''}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'roles',
      key: 'roles',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'roles' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      align: 'center',
      render: (roles) => {
        let rolesString = '';

        if (roles) {
          rolesString = roles
            ?.map((el) => capitalizeFirstLetterofEachWord(el))
            ?.join(', ');
        }

        return <div>{rolesString}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>gender</div>
            {/* <div>{searchInput('gender')}</div> */}
            <Select
              style={{
                width: '100%',
                minHeight: '25px',
                fontSize: '12px',
                textTransform: 'capitalize',
              }}
              onClick={(e) => e.stopPropagation()}
              allowClear={true}
              readOnly={true}
              className='Select Gender'
              dropdownClassName='select-dropdown-custom'
              getPopupContainer={() =>
                document.getElementsByClassName(
                  'ant-layout-content layout-content site-layout-background custom-textarea'
                )[0]
              }
              value={search?.gender}
              showSearch
              placeholder=''
              optionFilterProp='children'
              onChange={(e) => {
                // setSearch({
                //   ...search,
                //   gender: e,
                // });
                // debouncedDefaultPageOneCallApi();
                handleSearch('gender', e, debouncedDefaultPageOneCallApi);
              }}
              optionLabelProp='label'
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {genderOptions?.map(({ label, value }) => {
                return (
                  <Option value={value} label={label}>
                    {label || ''}
                  </Option>
                );
              })}
            </Select>
          </div>
        );
      },
      dataIndex: 'gender',
      key: 'gender',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'gender' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      render: (gen, { _id }) => {
        let gender = '';
        if (gen === 'M') {
          gender = 'Male';
        } else if (gen === 'F') {
          gender = 'Female';
        }
        return <div onClick={() => handleNavigate(_id)}>{gender}</div>;
      },
    },
    // {
    // ...(isTeacher
    //   ? {
    //       title: 'Students Count',
    //       dataIndex: 'student_count',
    //       key: 'student_count',
    //       sorter: false,
    //       sortOrder:
    //         sortedInfo?.columnKey === 'student_count' && sortedInfo?.order,
    //       ellipsis: true,
    //       width: '10%',
    //       render: (student_count, { _id }) => {
    //         return (
    //           <div
    //             onClick={() => handleNavigate(_id)}
    //           >{`${student_count}`}</div>
    //         );
    //       },
    //     }
    //   : null),
    // {
    //   title: 'Year Level',
    //   dataIndex: 'year_level',
    //   key: 'year_level',
    //   sorter: false,
    //   sortOrder:
    //     sortedInfo?.columnKey === 'year_level' && sortedInfo?.order,
    //   ellipsis: true,
    //   width: '15%',
    //   render: (year_level) => <div>{`${year_level}`}</div>,
    // }
    // },
    // {
    //   ...(!isTeacher && {
    //     title: 'Teacher',
    //     dataIndex: 'teacher',
    //     key: 'teacher',
    //     sorter: false,
    //     sortOrder: sortedInfo?.columnKey === 'teacher' && sortedInfo?.order,
    //     ellipsis: true,
    //     width: '20%',
    //     render: (teacher_, { teacher }) => {
    //       const { first_name, last_name } = teacher;
    //       let name = `${first_name} ${last_name}`;
    //       return <div>{name}</div>;
    //     },
    //   }),
    // },

    // {
    // ...(isTeacher && {
    // title: '',
    // dataIndex: 'btn',
    // key: 'btn',
    // sorter: false,
    // sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
    // ellipsis: true,
    // width: '10%',
    // render: (_, { _id }) => (
    //   <Button
    //     onClick={() => handleNavigate(_id)}
    //     size='small'
    //     style={{
    //       backgroundColor: '#fff',
    //       color: '#50B6F2',
    //       border: '1px solid #50B6F2',
    //       borderRadius: '3px',
    //     }}
    //   >
    //     Details
    //   </Button>
    // ),
    // }),
    // },

    // {
    //   title: () => {
    //     return (
    //       <div align='top' style={{ width: '100%' }}>
    //         <div className='special-table-header-text'>status</div>
    //         <div>
    //           <Select
    //             style={{
    //               width: '100%',
    //               minHeight: '25px',
    //               fontSize: '12px',
    //               textTransform: 'capitalize',
    //             }}
    //             onClick={(e) => e.stopPropagation()}
    //             allowClear={true}
    //             readOnly={true}
    //             className='Select Project'
    //             dropdownClassName='select-dropdown-custom'
    //             getPopupContainer={() =>
    //               document.getElementsByClassName(
    //                 'ant-layout-content layout-content site-layout-background custom-textarea'
    //               )[0]
    //             }
    //             showSearch
    //             placeholder=''
    //             optionFilterProp='children'
    //             onChange={(e) => {
    //               setSearch({
    //                 ...search,
    //                 user_status: e,
    //               });
    //               debouncedDefaultPageOneCallApi();
    //             }}
    //             optionLabelProp='label'
    //             filterOption={(input, option) =>
    //               option?.children
    //                 ?.toLowerCase()
    //                 .indexOf(input.toLowerCase()) >= 0
    //             }
    //           >
    //             {statusOptions?.map(({ label, value }) => {
    //               return (
    //                 <Option value={value} label={label}>
    //                   {label || ''}
    //                 </Option>
    //               );
    //             })}
    //           </Select>
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'user_status',
    //   key: 'user_status',
    //   sorter: true,
    //   sortOrder: sortedInfo?.columnKey === 'user_status' && sortedInfo?.order,
    //   ellipsis: true,
    //   width: '11%',
    //   align: 'center',
    //   render: (user_status, { _id }) => {
    //     let color;
    //     let text;
    //     switch (user_status) {
    //       case 'active':
    //         color = '#7cd175';
    //         text = 'Active';
    //         break;
    //       case 'inactive':
    //         color = '#aaaaaa';
    //         text = 'Inactive';
    //         break;
    //       default:
    //         break;
    //     }
    //     return (
    //       <Tag
    //         onClick={() => handleNavigate(_id)}
    //         color={color}
    //         style={{
    //           color: '#231f20',
    //           width: '70px',
    //           height: '20px',
    //           textAlign: 'center',
    //         }}
    //       >
    //         {text}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Status</div>
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                size='small'
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select status'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                value={search?.deleted_status}
                showSearch
                placeholder=''
                optionFilterProp='children'
                onChange={(e) => {
                  // setSearch({
                  //   ...search,
                  //   deleted_status: e,
                  // });
                  // debouncedDefaultPageOneCallApi();
                  handleSearch(
                    'deleted_status',
                    e,
                    debouncedDefaultPageOneCallApi
                  );
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {[
                  { key: 'Active', value: 'active' },
                  { key: 'Inactive', value: 'inactive' },
                ].map(({ key, value }) => {
                  return (
                    <Option value={value} label={key}>
                      {key}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'deleted_status',
      key: 'deleted_status',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'deleted_status' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (deleted_status) => <div>{deleted_status?.toUpperCase()}</div>,
    },
    {
      // ...(isTeacher && {
      title: null,
      dataIndex: '',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        // if (!canEdit && !canDelete) {
        //   return;
        // }

        return deleteUserLoading && record._id === currentDelRecord._id ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align='baseline'>
            {/* {canEdit && ( */}
            <EditIcon
              width={18}
              height={18}
              className='custom-icon'
              onClick={() => {
                setTimeout(() => {
                  dispatch(setUserDetailsIsEdit(true));
                }, 1000);
                handleNavigate(record._id);
              }}
            />
            {/* )} */}
            {/* {canDelete && ( */}
            {/* <Popconfirm
              title='Are you sure you want to delete this user?'
              placement='topRight'
              onConfirm={() => handleDelete(record)}
              okText='Yes'
              cancelText='No'
            >
              <DeleteIcon width={18} height={18} className='custom-icon' />
            </Popconfirm> */}
            {/* )} */}
          </Space>
        );
      },
      // }),
    },
  ];

  return (
    <>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[50, 0]} justify='space-between'>
          <Col>
            <Row align='middle' gutter={[30, 0]}>
              <Col>
                <div>
                  <span className='heading-span'>Users Register</span>
                </div>
              </Col>
              <Col>
                {/* {canEdit && ( */}
                {/* {isTeacher && ( */}
                <Button
                  onClick={() => {
                    dispatch(setUserDetailsIsEdit(true));
                    navigate(`/settings/users-manager/create`);
                  }}
                  icon={<PlusOutlined />}
                  className='add-user-btn'
                  size='middle'
                >
                  User
                </Button>
                {/* )} */}
                {/* )} */}
              </Col>
              <Button
                onClick={() => {
                  handleSetStorage('search', {});
                  handleSetStorage('sortedInfo', null);
                  handleSetStorage('page', 1);
                  handleSetStorage('resultPerPage', 10);
                  setPage(1);
                  setResultPerPage(10);
                  setSearch({});
                  setSortedInfo(null);
                }}
                icon={<CloseOutlined />}
                className='add-user-btn'
                size='middle'
              >
                Clear Filters
              </Button>
            </Row>
          </Col>
          {/* <Col>
            <Space size={20} align='center'>
              {canExport && (
                <Tooltip text='Export'>
                  <ExportDoc userType={userType} userTypeName={userTypeName} />
                </Tooltip>
              )}
              {canImport && (
                <Tooltip text='Import'>
                  <ImportDoc
                    userType={userType}
                    userTypeName={userTypeName}
                    debounce={debouncedDefaultPageOneCallApi}
                  />
                </Tooltip>
              )}
            </Space>
          </Col> */}
        </Row>
      </Col>

      <Col span={24}>
        <Table
          className='special-table users-manager-table' // special-table
          columns={columns}
          scroll={{ x: true }}
          onChange={handleChange}
          dataSource={dataSource}
          pagination={false}
          sortUpIcon={<UpOutlined />}
          sortDownIcon={<DownOutlined />}
          loading={fetchLoading}
          onRow={(record) => {
            return {
              onClick: () => {
                handleNavigate(record._id);
              },
            };
          }}
        />
        <CustomPagination
          page={page}
          setPage={setPage}
          resultPerPage={resultPerPage}
          setResultPerPage={setResultPerPage}
          debouncedCallApi={debouncedCallApi}
          totalRecord={totalRecords}
          handleSetStorage={handleSetStorage}
        />
      </Col>
    </>
  );
};

export default UsersRegisterTable;
