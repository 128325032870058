import { Input, InputNumber } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

const CardComponent = (props) => {
  const { label, description, price, data, setData, i, keyName } = props;
  const { valueAddedIsEdit } = useSelector((state) => state.valueAddedReducer);

  const { TextArea } = Input;

  const onChange = (value, name, i) => {
    const newData = [...data];
    newData[i][name] = value;
    let updatedData = { [keyName]: newData };
    setData((prev) => ({ ...prev, ...updatedData }));
  };

  return (
    <div className='card-parent-div'>
      <div className='header'>
        <div className='name'>{label}</div>
      </div>
      <hr />
      <div className='label-div'>
        <label>Description</label>
        <TextArea
          value={description}
          placeholder='Insert Description'
          onChange={(e) => onChange(e.target.value, 'description', i)}
          style={{
            resize: 'none',
          }}
          rows={3}
          readOnly={!valueAddedIsEdit}
          disabled={!valueAddedIsEdit}
        />
      </div>
      <div className='label-div'>
        <label>PRICE</label>
        <div className='price-div'>
          <div className='text r'>R</div>
          <InputNumber
            placeholder='Insert Price'
            value={price}
            onChange={(e) => onChange(e, 'price', i)}
            readOnly={!valueAddedIsEdit}
            disabled={!valueAddedIsEdit}
          />
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
