import React, { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Space,
  DatePicker,
  TimePicker,
  Select,
  Row,
  Col,
  Button,
} from 'antd';
import {
  DownOutlined,
  UpOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNpo,
  fetchAllNpos,
  setNpoDetailsIsEdit,
  setStorage,
} from './redux/actions';
import SpinnerComponent from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
// import { baseURL } from "../../../../globalUtils/axiosConfig";
// import { baseURL } from '../../globalUtils/axiosConfig';
import _ from 'lodash';
import CustomPagination from '../../components/customPagination/CustomPagination';
import moment from 'moment';
import CustomCalendar from '../../components/calendar';
// import CustomCalendar from '../../components/calendar';
// import AMultiSelect from '../../components/AMultiSelect';

const { Option } = Select;

let slot_date_start = {
  slot_date_start: moment(new Date()).format('YYYY-MM-DD'),
};

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const BooksTable = ({ canUpdate, canDelete, assessorOptions }) => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [currentDelRecord, setCurrentDelRecord] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({ slot_date_start });
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  // const [statusCheckedList, setStatusCheckedList] = useState([]);
  // const [regionsCheckedList, setRegionsCheckedList] = useState([]);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [date, setDate] = useState(null);
  // const [npoStatuses, SetNpoStatuses] = useState([]);
  // const [npoRegions, SetNpoRegions] = useState([]);

  const { allNpos, fetchNposLoading, deleteNpoLoading, totalRecords, ...rest } =
    useSelector((state) => state.slotsReducer);
  const { allRooms } = useSelector((state) => state.roomsReducer);

  // const { allNpoStatuses } = useSelector((state) => state.npoStatusReducer);

  // const { allNpoRegions } = useSelector((state) => state.npoRegionsReducer);

  // useEffect(() => {
  //   let updatedStatuses = allNpoStatuses.map((el) => {
  //     const { id, name } = el;
  //     return { value: id, label: name };
  //   });
  //   SetNpoStatuses(updatedStatuses);
  // }, [allNpoStatuses]);

  // useEffect(() => {
  //   let updatedRegions = allNpoRegions.map((el) => {
  //     const { id, name } = el;
  //     return { value: id, label: name };
  //   });
  //   SetNpoRegions(updatedRegions);
  // }, [allNpoRegions]);

  useEffect(() => {
    if (localStorage.getItem('slotsManagerState')) {
      const localStorageData = JSON.parse(
        localStorage.getItem('slotsManagerState')
      );

      if (localStorageData) {
        setTimeout(() => {
          setSearch(localStorageData.search);
          setPage(localStorageData.page);

          setResultPerPage(localStorageData.resultPerPage);
          dispatch(setStorage(localStorageData));

          if (localStorageData.sortedInfo) {
            setSortedInfo(localStorageData.sortedInfo);
          }

          if (localStorageData?.search?.slot_date_start) {
            const { slot_date_start, slot_date_end } = localStorageData.search;
            setDate([moment(slot_date_start), moment(slot_date_end)]);
          }

          debouncedCallApi();
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllNpos({
          page: page,
          per_page: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === 'ascend'
              ? 1
              : -1
            : null,
          timezone: timeZone,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllNpos({
          page: paramsPage,
          per_page: resultPerPage,
          search,
          timezone: timeZone,
        })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setDataSource(allNpos);
  }, [allNpos]);

  useEffect(() => {
    const isSearchChanged =
      JSON.stringify(search) !== JSON.stringify(rest.search);

    if (isSearchChanged) {
      setSearch(rest.search);
    }

    const isPageChanged = rest.page && rest.page !== page;
    const isResultPerPageChanged =
      rest.resultPerPage && rest.resultPerPage !== resultPerPage;

    if (isPageChanged) {
      setPage(rest.page);
    }

    if (isResultPerPageChanged) {
      setResultPerPage(rest.resultPerPage);
    }

    if (isPageChanged || isResultPerPageChanged || isSearchChanged) {
      debouncedCallApi();
    }
  }, []);

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };
  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
  };

  const handleSetStorage = (key, value) => {
    dispatch(setStorage({ [key]: value }));
  };

  const handleChange = (pagination, filters, sorter) => {
    if (['room', 'assessor'].includes(sorter.columnKey) || datePickerOpened) {
      return;
    }
    setSortedInfo(sorter);
    handleSetStorage('sortedInfo', sorter);
  };

  const handleSearch = (key, value, func = null) => {
    let updatedState = {
      ...search,
      [key]: value,
    };

    setSearch(updatedState);

    if (typeof func === 'function') {
      func();
    }

    dispatch(setStorage({ search: updatedState }));
    handleSetStorage('search', updatedState);
  };

  const handleDelete = (record) => {
    setCurrentDelRecord(record);
    dispatch(deleteNpo(record));
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      value={search[key]}
      onChange={(e) => {
        handleSearch(key, e.target.value, debouncedDefaultPageOneCallApi);
      }}
    />
  );

  const dateInput = (key) => (
    <DatePicker
      placeholder=''
      // className='activities-time-picker'
      style={{ width: '100%' }}
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      suffixIcon={null}
      format='DD MMM YYYY'
      // use12Hours
      // showTime
      width='100%'
      // nextIcon={null}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        debouncedDefaultPageOneCallApi();
      }}
      onOpenChange={(e) => setDatePickerOpened(e)}
    />
  );

  const timeInput = (key) => (
    <TimePicker
      placeholder=''
      // className='activities-time-picker'
      style={{ width: '100%' }}
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      use12Hours
      suffixIcon={null}
      format='hh:mm A'
      width='100%'
      onClick={(e) => e.stopPropagation()}
      // onChange={(e) => {
      // if (search?.slot_date === undefined || search?.slot_date === null) {
      //   return;
      // }
      // let time = e.format('hh:mm');
      // let slotDate = moment(search?.slot_date).format('MM/DD/YY');
      // console.log('time', time);
      // console.log('slotDate', slotDate);
      // let date = new Date(`${slotDate} ${time}`);
      // console.log('date', date);
      // setSearch({
      //   ...search,
      //   [key]: time,
      //   // [key]: moment(date),
      // });
      // debouncedDefaultPageOneCallApi();
      // }}
      // disabled={!search?.slot_date}
      disabled={true}
      onOpenChange={(e) => setDatePickerOpened(e)}
    />
  );

  const handleNavigate = (id) => navigate(`/slots/update/${id}`);

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      let searchObj = { ...search };
      delete searchObj.slot_date_start;
      delete searchObj.slot_date_end;

      setSearch({ ...searchObj, slot_date_start });
      dispatch(setStorage({ search: searchObj }));
    } else {
      let updatedSearch = {
        ...search,
        slot_date_start: value[0],
        slot_date_end: value[1],
      };
      setSearch(updatedSearch);
      dispatch(setStorage({ search: updatedSearch }));
    }
    dispatch(setStorage({ page: 1 }));

    debouncedDefaultPageOneCallApi();
  };

  const handleDownload = (url) => {
    window.open(url, '_blank');
  };

  const columns = [
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Title</div>
            <div>{searchInput('title')}</div>
          </div>
        );
      },
      dataIndex: 'title',
      key: 'title',
      // sorter: (a, b) =>
      //   a.title?.toLowerCase()?.localeCompare(b.title?.toLowerCase()),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'title' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      render: (title, { _id }) => (
        <div onClick={() => handleNavigate(_id)}>{title}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>description</div>
            <div>{searchInput('description')}</div>
          </div>
        );
      },
      dataIndex: 'description',
      key: 'description',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'description' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      render: (description) => <div>{description}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>room</div>
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                size='small'
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select Project'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                value={search?.room}
                onChange={(e) => {
                  handleSearch('room', e, debouncedDefaultPageOneCallApi);
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {allRooms.map(({ code, _id }) => {
                  return (
                    <Option value={_id} label={code}>
                      {code}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'room',
      key: 'room',
      sorter: true,
      // sortOrder: sortedInfo?.columnKey === 'room' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (room) => <div>{room?.code}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Assessor</div>
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                size='small'
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select Assessor'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                value={search?.assessor}
                onChange={(e) => {
                  handleSearch('assessor', e, debouncedDefaultPageOneCallApi);
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {assessorOptions.map(({ label, value }) => {
                  return (
                    <Option value={value} key={value} label={label}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'assessor',
      key: 'assessor',
      sorter: true,
      // sortOrder: sortedInfo?.columnKey === 'assessor' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (assessor) => {
        let name = assessor
          ? `${assessor?.first_name} ${assessor?.last_name}`
          : '';

        return <div>{name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Slot Date</div>
            <div>
              <CustomCalendar
                date={date}
                setDate={setDate}
                format={'D MMM'}
                handleDateInput={handleDateInput}
                setDatePickerOpened={setDatePickerOpened}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'slot_date',
      key: 'slot_date',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'slot_date' && sortedInfo?.order,
      ellipsis: true,
      width: '14%',
      align: 'center',
      render: (slot_date) => {
        if (!slot_date) {
          return;
        }
        return (
          <div>
            {moment
              .utc(slot_date, 'YYYY-MM-DD HH:mm:ss.SSS')
              .local()
              .format('DD MMM YYYY')}
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Start Time</div>
            <div>{timeInput('start_time')}</div>
          </div>
        );
      },
      dataIndex: 'start_time',
      key: 'start_time',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'start_time' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (start_time) => (
        <div>
          {moment
            .utc(start_time, 'YYYY-MM-DD HH:mm:ss.SSS')
            .local()
            .format('hh:mm A')}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>End Time</div>
            <div>{timeInput('end_time')}</div>
          </div>
        );
      },
      dataIndex: 'end_time',
      key: 'end_time',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'end_time' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (end_time) => (
        <div>
          {moment
            .utc(end_time, 'YYYY-MM-DD HH:mm:ss.SSS')
            .local()
            .format('hh:mm A')}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Capacity</div>
            <div>{searchInput('capacity')}</div>
          </div>
        );
      },
      dataIndex: 'capacity',
      key: 'capacity',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'capacity' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (capacity, { slot_bookings }) => {
        let capacityText = capacity ? capacity : 0;
        let reserved = slot_bookings?.length || 0;
        return <div>{`${reserved} / ${capacityText}`}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Status</div>
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                size='small'
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select status'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                value={search?.deleted_status}
                onChange={(e) => {
                  handleSearch(
                    'deleted_status',
                    e,
                    debouncedDefaultPageOneCallApi
                  );
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {[
                  { key: 'Active', value: 'active' },
                  { key: 'Inactive', value: 'inactive' },
                ].map(({ key, value }) => {
                  return (
                    <Option value={value} label={key}>
                      {key}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'deleted_status',
      key: 'deleted_status',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'deleted_status' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      render: (deleted_status) => <div>{deleted_status?.toUpperCase()}</div>,
    },
    // {
    //   title: () => {
    //     return (
    //       <div
    //         align='top'
    //         style={{ width: '100%' }}
    //         className='select-parent-div'
    //       >
    //         <div className='special-table-header-text'>
    //           DIRECTOR CONTACT NUMBER
    //         </div>
    //         <div>{searchInput('contact_no')}</div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'contact_no',
    //   key: 'contact_no',
    //   sorter: true,
    //   sortOrder: sortedInfo?.columnKey === 'contact_no' && sortedInfo?.order,
    //   ellipsis: true,
    //   width: '20%',
    //   render: (contact_no) => (
    //     <div
    //       style={{
    //         whiteSpace: 'break-spaces',
    //       }}
    //
    //     >
    //       {contact_no.length > 180
    //         ? `${contact_no.substring(0, 180) + ' ...'}`
    //         : contact_no}
    //     </div>
    //   ),
    // },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' style={{ width: '100%' }}>
    //         <div className='special-table-header-text'>Status</div>
    //         <div style={{ width: '100%' }}>
    //           <AMultiSelect
    //             dropdownClassName='status-selector'
    //             debouncedCallApi={debouncedCallApi}
    //             disabled={false}
    //             placeholder=''
    //             selectTags={statusCheckedList}
    //             onSelectChange={(value) =>
    //               handleSelectOnchange(
    //                 value,
    //                 npoStatuses,
    //                 'status_id',
    //                 setStatusCheckedList
    //               )
    //             }
    //             data={npoStatuses}
    //             filterOption={(input, option) => {
    //               return (
    //                 String(option?.label)
    //                   .toLowerCase()
    //                   .indexOf(input.toLowerCase()) >= 0
    //               );
    //             }}
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'status_id',
    //   key: 'status_id',
    //   sorter: true,
    //   sortOrder: sortedInfo?.columnKey === 'status_id' && sortedInfo?.order,
    //   ellipsis: true,
    //   width: '15%',
    //   align: 'center',
    //   render: (statusname, { status, id }) => {
    //     const { name, color } = status;
    //     return (
    //       <Tag
    //
    //         color={color}
    //         style={{
    //           color: '#444',
    //           width: '84px',
    //           height: '20px',
    //           textAlign: 'center',
    //           fontSize: '12px',
    //           whiteSpace: 'nowrap',
    //           overflow: 'hidden',
    //           textOverflow: 'ellipsis',
    //         }}
    //       >
    //         {name}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: null,
      dataIndex: '',
      key: 'action',
      width: '5%',
      align: 'center',
      render: (_, record) => {
        // if (!canUpdate && !canDelete) {
        //   return;
        // }
        return deleteNpoLoading && record._id === currentDelRecord._id ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align='baseline'>
            {/* {canUpdate && ( */}
            <EditIcon
              width={18}
              height={18}
              className='custom-icon'
              onClick={() => {
                handleNavigate(record._id);
                dispatch(setNpoDetailsIsEdit(true));
              }}
            />
            {/* // )} */}
            {/* {canDelete && (
              <Popconfirm
                title='Are you sure you want to delete this NPO?'
                onConfirm={() => handleDelete(record)}
                okText='Yes'
                cancelText='No'
              >
                <DeleteIcon width={18} height={18} className='custom-icon' />
              </Popconfirm>
            )} */}
          </Space>
        );
      },
    },
  ];

  return (
    <div className='page-top-div'>
      <span className='text-span'>Slots</span>
      <Row className='production-manager-parent-row'>
        <Col span={24} className='heading-col'>
          <Row align='middle' gutter={[30, 0]} justify='space-between'>
            <Col>
              <Row align='middle' gutter={[30, 0]}>
                <Col>
                  <div>
                    <span className='heading-span'>Slots Register</span>
                  </div>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      navigate('/slots/create');
                      dispatch(setNpoDetailsIsEdit(true));
                    }}
                    icon={<PlusOutlined />}
                    className='add-user-btn'
                    size='small'
                    style={{
                      width: '105%',
                    }}
                  >
                    SLOT
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      handleSetStorage('search', {});
                      handleSetStorage('sortedInfo', null);
                      handleSetStorage('page', 1);
                      handleSetStorage('resultPerPage', 10);
                      setPage(1);
                      setResultPerPage(10);
                      setSearch({});
                      setDate(null);
                      setSortedInfo(null);
                    }}
                    icon={<CloseOutlined />}
                    className='add-user-btn'
                    size='small'
                  >
                    Clear Filters
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Table
            className='special-table'
            columns={columns}
            scroll={{ x: !fetchNposLoading }}
            onChange={handleChange}
            dataSource={dataSource}
            pagination={false}
            sortUpIcon={<UpOutlined />}
            sortDownIcon={<DownOutlined />}
            loading={fetchNposLoading}
            onRow={(record) => {
              return {
                onClick: () => handleNavigate(record._id),
              };
            }}
          />
          <CustomPagination
            page={page}
            setPage={setPage}
            resultPerPage={resultPerPage}
            setResultPerPage={setResultPerPage}
            debouncedCallApi={debouncedCallApi}
            totalRecord={totalRecords}
            handleSetStorage={handleSetStorage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BooksTable;
