import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import * as constants from '../constants';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleFetchAllValueAdded() {
  try {
    const response = yield axios.get(`${baseURL}/value_added_services`);
    const { success, data } = response.data;
    if (success) {
      yield put(actions.fetchAllValueAddedSuccess(data));
    }
    errorHandler(response.data);
  } catch (error) {
    AntdAlert.error('Something went wrong!');
    yield put(actions.fetchAllValueAddedError());
    console.log(error);
  }
}

export function* handleUpdateValueAdded({ data }) {
  try {
    const response = yield axios.patch(`${baseURL}/value_added_services`, data);
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateValueAddedSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateValueAddedError(response.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateValueAddedError(response.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}
export function* handleDeleteValueAdded({ data }) {
  try {
    const response = yield axios.delete(
      `${baseURL}/value_added_services/${data._id}`
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteValueAddedSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteValueAddedError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteValueAddedError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleCreateValueAdded({ data }) {
  const { navigate, ...rest } = data;
  try {
    const response = yield axios.post(`${baseURL}/value_added_services`, {
      ...rest,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.createValueAddedSuccess(response.data));
      navigate('/settings/form-manager/form-setup');
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createValueAddedError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchValueAdded(data) {
  try {
    const response = yield axios.get(`${baseURL}/value_added_services`);
    const { success } = response.data;
    if (success && response?.data) {
      yield put(actions.fetchValueAddedSuccess(response.data.data));
      errorHandler(response.data);
    }
  } catch (error) {
    AntdAlert.error('Something went wrong!');
    yield put(actions.fetchValueAddedError());
    console.log(error);
  }
}

export function* watcherValueAddedSaga() {
  // yield takeLatest(constants.CREATE_VALUE_ADDED, handleCreateValueAdded);
  // yield takeLatest(constants.FETCH_ALL_VALUE_ADDED, handleFetchAllValueAdded);
  yield takeLatest(constants.FETCH_VALUE_ADDED, handleFetchValueAdded);
  yield takeLatest(constants.UPDATE_VALUE_ADDED, handleUpdateValueAdded);
  // yield takeLatest(constants.DELETE_VALUE_ADDED, handleDeleteValueAdded);
}
