import React, { useRef, useState } from 'react';
import { Row, Tabs, Col, DatePicker, message } from 'antd';
import moment from 'moment';
import TreeComponent from './TreeSelectComponent';
import useThirdStep from './useThirdStep';
import { ReactComponent as SaveIcon } from '../../../../assets/save-icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/edit-icon.svg';
import SpinnerComponent from '../../../../components/spinner';

const MilestonesTabs = () => {
  const [activeKey, setActiveKey] = useState('');
  const [items, setItems] = useState([]);
  const {
    contents,
    loading,
    getContentLoading,
    isEdit,
    setIsEdit,
    handleCreate,
  } = useThirdStep();

  const onSave = () => {
    console.log('items', items);
    function checkKeysOverlap(parentArray) {
      for (let i = 0; i < parentArray.length; i++) {
        for (let j = i + 1; j < parentArray.length; j++) {
          const firstKeys = parentArray[i].selectedKeys;
          const secondKeys = parentArray[j].selectedKeys;

          // Check if there's any common key between the two arrays
          const commonKeys = firstKeys.filter((key) =>
            secondKeys.includes(key)
          );

          if (commonKeys.length > 0) {
            let label1 = parentArray[i].label;
            let label2 = parentArray[j].label;
            let [, juzNumber, , chap, , page] = commonKeys[0].split('_');
            let msg = `${label1} and ${label2} have common Page ${page} of Chapter ${chap} of Juz ${juzNumber}`;
            message.warn(msg);
            return true; // There's an overlap
          }
        }
      }

      return false; // No overlap found
    }

    const overlapExists = checkKeysOverlap(items);

    if (overlapExists) {
      console.log('Overlap exists.');
    } else {
      console.log('No overlap found.');
    }

    // handleCreate();
  };
  const newTabIndex = useRef(0);

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const add = () => {
    const newActiveKey = `newTab${newTabIndex.current++}`;
    const newPanes = [...items];
    newPanes.push({
      label: `Milestone ${items.length + 1}`,
      key: newActiveKey,
      id: newActiveKey,
      selectedKeys: [],
      date: null,
      defaultSelectedKeys: [],
      contents,
      expandedKeys: [],
    });
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      if (getContentLoading) {
        return message.warning('Please wait until the content is loaded!');
      }
      add();
    } else {
      remove(targetKey);
    }
  };

  const MilestoneTab = (props) => {
    const { i } = props;
    let dateInitialValue = props?.date ? moment(props.date) : null;
    const [date, setDate] = useState(dateInitialValue);

    const handleDate = (e) => {
      let updatedDate = moment(e);
      items[i].date = updatedDate.format('DD-MM-YYYY');
      setDate(updatedDate);
      setItems(items);
    };

    return (
      <Row justify='start' gutter={[30, 0]} style={{ marginTop: '30px' }}>
        <Col span={12} offset={1}>
          <TreeComponent
            contents={contents}
            items={items}
            setItems={setItems}
            i={i}
          />
        </Col>
        <Col offset={1}>
          <DatePicker
            size='large'
            value={date || null}
            onChange={handleDate}
            format='DD-MM-YYYY'
          />
        </Col>
      </Row>
    );
  };

  const updatedItems = items.map((el, i) => ({
    ...el,
    children: <MilestoneTab {...el} i={i} />,
  }));

  return (
    <Row>
      <Col span={24} className='heading-col'>
        <Row align='middle' justify='space-between'>
          <Col>
            <div>
              <span className='heading-span'>Milestones Details</span>
            </div>
          </Col>
          <Col>
            {isEdit ? (
              loading ? (
                <SpinnerComponent fontSize={14} />
              ) : (
                <SaveIcon width={16} height={16} onClick={onSave} />
              )
            ) : (
              <EditIcon
                width={16}
                fill='#e21c21'
                height={16}
                onClick={() => setIsEdit(true)}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col
        span={24}
        className={`user-details-section `} // ${!isEdit && 'is-edit'}
        style={{ minHeight: '40vh' }}
      >
        <Row justify='center' style={{ marginTop: '10px' }}>
          <Col md={20} lg={20} xl={20} xxl={20}>
            <Tabs
              size='small'
              type='editable-card'
              centered
              onChange={onChange}
              activeKey={activeKey}
              onEdit={onEdit}
              items={updatedItems}
              tabBarExtraContent={{ left: <h4>Milestones</h4> }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default MilestonesTabs;
