import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Spin } from 'antd';
import { ReactComponent as BackIcon } from '../../../assets/back-icon.svg';
import DetailsSection from './detailsSection';
import { fetchNpo, npoDetailCleanupFunc } from '../redux/actions';
// import { fetchNpoStatuses } from '../../NpoStatuses/redux/actions';
import './index.less';
import StickersTable from '../stickers';
import { fetchSiteStatuses } from '../../stickers/redux/actions';
// import ObservationsTable from './observationsTable';
import { fetchComments } from '../../comments/redux/actions';
// import { fetchNpoRegions } from '../../NpoRegions/redux/actions';
import QuoteStatusesTable from '../../QuoteStatuses/Table';
import {
  // fetchQuoteStatuses,
  fetchQuoteStatusesSuccess,
} from '../../QuoteStatuses/redux/actions';
import { fetchStreams } from '../../streams/redux/actions';

const LearningActivitiesDetail = () => {
  const [isNpoUpdate, setIsNpoUpdate] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [canUpdate, setCanUpdate] = useState(true);
  const [learningActivities, setLearningActivities] = useState([]);
  const [allStickers, setAllStickers] = useState([]);
  const [allCommentsList, setAllCommentsList] = useState([]);
  const [streamsOptions, setStreamsOptions] = useState([]);

  const { currentNpoObj, fetchNpoDetailLoading } = useSelector(
    (state) => state.learningActivitiesReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const { allSiteStatuses } = useSelector((state) => state.stickersReducer);
  const { allComments } = useSelector((state) => state.commentsReducer);
  const { allStreams } = useSelector((state) => state.streamsReducer);

  useEffect(() => {
    dispatch(fetchSiteStatuses());
    dispatch(fetchComments({ is_deleted: false }));
    dispatch(fetchStreams());
  }, []);

  useEffect(() => {
    if (currentNpoObj?._id) {
      setIsNpoUpdate(true);

      let observations = currentNpoObj?.learning_activity_observations || [];
      let updatedObservations = observations
        .map((el) => {
          return {
            _id: el._id,
            position: el.position,
            learning_activity_id: el.learning_activity,
            observation_id: el.observation._id,
            name: el.observation.name,
            deleted_status: el.observation.deleted_status,
            sub_observations:
              el?.learning_activity_sub_observations?.map((sub) => ({
                _id: sub._id,
                position: sub.position,
                learning_activity_id: el.learning_activity,
                observation_id: el.observation,
                sub_observation_id: sub.sub_observation._id,
                name: sub.sub_observation.name,
                icon: sub.sub_observation.icon,
                deleted_status: sub.sub_observation.deleted_status,
                detail: sub.sub_observation?.detail,
              })) || [],
            // observation: {
            //   ...el?.observation,
            // },
          };
        })
        .sort((a, b) => a.position - b.position);

      dispatch(fetchQuoteStatusesSuccess(updatedObservations || []));
    }
  }, [currentNpoObj]);

  useEffect(() => {
    if (params?.id) {
      setIsNpoUpdate(true);
      dispatch(fetchNpo(params?.id));
    } else if (pathname === '/profile') {
      setIsNpoUpdate(true);
      dispatch(fetchNpo(user.id));
    }

    return () => {
      dispatch(npoDetailCleanupFunc());
      // dispatch(fetchNpoRegions());
    };
  }, [params.id]);

  useEffect(() => {
    let options = allSiteStatuses.map((el) => ({
      label: el.name,
      value: el._id,
      icon: el.icon,
    }));
    setAllStickers(options);
  }, [allSiteStatuses]);

  useEffect(() => {
    let options = allComments.map((el) => ({
      label: el.title,
      value: el._id,
    }));
    setAllCommentsList(options);
  }, [allComments]);

  useEffect(() => {
    let options = allStreams.map((el) => ({
      label: el.name,
      value: el.name,
    }));
    setStreamsOptions(options);
  }, [allStreams]);

  let selectedStickers = currentNpoObj?.stickers || [];
  const options = selectedStickers.map((item) => item._id);

  let selectedComments = currentNpoObj?.comments || [];
  const commentsOptions = selectedComments.map((item) => item._id);

  return (
    <div className='page-top-div heading-parent-div'>
      <Row justify='space-between'>
        <Col>
          <span className='text-span'>
            {isNpoUpdate ? 'Update Learning Activity' : 'Add Learning Activity'}
          </span>
        </Col>
        <Col>
          <Button
            onClick={() => navigate('/learning-activities')}
            icon={<BackIcon width={24} height={14} />}
            className='back-btn'
            size='middle'
          >
            BACK
          </Button>
        </Col>
      </Row>
      <Spin spinning={fetchNpoDetailLoading}>
        <div
          className='user-manager-parent-div'
          style={{
            minHeight: '60vh',
          }}
        >
          <DetailsSection
            isNpoUpdate={isNpoUpdate}
            canUpdate={canUpdate}
            learningActivities={learningActivities}
            allStickersOptions={allStickers}
            prevSelected={options}
            allCommentsOptions={allCommentsList}
            prevCommentsSelected={commentsOptions}
            streamsOptions={streamsOptions}
          />
          {currentNpoObj?._id && (
            <>
              <StickersTable id={currentNpoObj._id} />
              {/* <ObservationsTable currentNpoObj={currentNpoObj} /> */}
              <QuoteStatusesTable
                canDelete={true}
                canUpdate={true}
                currentNpoObj={currentNpoObj}
              />
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default LearningActivitiesDetail;
