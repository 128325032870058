import React, { useRef, useState } from 'react';
import { Button, message, Row, Col, Checkbox } from 'antd';
import axios from 'axios';
import { ReactComponent as ExportIcon } from '../../assets/export.svg';
import { baseURL } from '../../globalUtils/axiosConfig';
import { DownloadOutlined } from '@ant-design/icons';
import ResponseDetails from './responseDetails';
import StatsComponent from '../../components/statsComponent';

const ImportStickers = () => {
  const inputFile = useRef(null);
  const [loading, setLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [isForceUpdate, setIsForceUpdate] = useState(false);

  const handleUploadSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    try {
      formData.append('file', fileUploaded);
      const res = await axios.post(
        `${baseURL}/api/v2/admin/imports/stickers?forceUpdate=${isForceUpdate}`,
        formData,
        {
          headers: { Accept: '*/*' },
        }
      );
      if (res.status === 200) {
        message.success(res?.data?.message || 'Data successfully imported!');
        setFileUploaded(null); // Clear the file list after upload
        setResponseData(res.data.data);
        setIsForceUpdate(false);
      } else {
        message.error('Error uploading stickers file');
      }
      setLoading(false);
    } catch (error) {
      message.error('Something went wrong!');
      setLoading(false);
    }
  };

  const handleImage = (imgObj, e) => {
    e.currentTarget.value = null;

    const isValid = /\.(csv)$/i.test(imgObj.name);

    if (isValid) {
      if (imgObj) {
        setFileUploaded(imgObj);
        setResponseData(null);
      }
    } else {
      message.error('This file format is not supported.');
    }
  };

  const handleSave = async () => {
    setDownloadLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/api/v2/admin/imports/stickers-sample`,
        {
          responseType: 'arraybuffer',
        }
      );

      const blob = new Blob([response.data]);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'stickers-sample.csv';
      downloadLink.click();
      setDownloadLoading(false);
    } catch (error) {
      message.error('Something went wrong!');
      setDownloadLoading(false);
    }
  };

  return (
    <div className='page-top-div heading-parent-div'>
      <Row justify='space-between'>
        <Col>
          <span className='text-span'>Import Stickers</span>
        </Col>
      </Row>
      <div className='user-manager-parent-div' style={{ minHeight: '85vh' }}>
        <Row>
          <Col span={24} className='heading-col'>
            <Row align='middle' justify='space-between'>
              <Col>
                <div>
                  <span className='heading-span'>Import Stickers</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify='center'
          align='middle'
          gutter={[0, 30]}
          style={{ marginTop: '30px' }}
        >
          <Col span={24}>
            <Row
              justify='center'
              align='bottom'
              gutter={[30, 30]}
              style={{ textAlign: 'center' }}
            >
              {!fileUploaded && (
                <Col md={6} lg={6} xl={6} xxl={4}>
                  <Button
                    icon={
                      <ExportIcon
                        style={{
                          height: '16px',
                          width: '16px',
                          marginRight: '5px',
                        }}
                      />
                    }
                    size='large'
                    onClick={() => inputFile.current.click()}
                    className='upload-btn'
                  >
                    {' '}
                    Select File
                  </Button>
                  <input
                    type='file'
                    id='file'
                    multiple
                    accept=''
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={(e) => handleImage(e.target.files[0], e)}
                  />
                </Col>
              )}
              {fileUploaded && (
                <Col>
                  <Row justify='center' align='middle' gutter={[30, 10]}>
                    <Col span={24}>
                      <div className='file-text'>{fileUploaded.name}</div>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        checked={isForceUpdate}
                        disabled={loading}
                        onChange={(e) => setIsForceUpdate(e.target.checked)}
                        children={<h3>Force Update</h3>}
                        defaultChecked={false}
                      />
                    </Col>
                    <Col>
                      <Button
                        loading={loading}
                        onClick={handleUploadSubmit}
                        disabled={loading}
                        size='large'
                      >
                        Upload File
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col>
                <Button
                  icon={
                    <DownloadOutlined
                      style={{
                        height: '16px',
                        width: '16px',
                        marginRight: '5px',
                      }}
                    />
                  }
                  size='large'
                  onClick={handleSave}
                  disabled={downloadLoading}
                  className='upload-btn'
                >
                  Download Sample
                </Button>
              </Col>
            </Row>
          </Col>
          {responseData && (
            <Col span={24}>
              <Row gutter={[10, 20]} justify='center'>
                <Col span={24}>
                  <StatsComponent
                    successRecords={responseData?.successRecords?.length}
                    failedRecords={responseData?.failedRecords?.length}
                    totalRecords={responseData?.totalRecords}
                  />
                </Col>
                <Col span={22}>
                  <div
                    style={{
                      minHeight: '50vh',
                    }}
                  >
                    <ResponseDetails
                      responseData={responseData}
                      setResponseData={setResponseData}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ImportStickers;
