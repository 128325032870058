import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from './redux/actions';
import { useNavigate } from 'react-router';

const UseAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.authReducer);

  const validateFunc = (email, password, tenant) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(mailformat)) {
      message.error('Email is invalid.');
      return false;
    } else if (password.length < 6) {
      message.error('Password should contain at least 6 characters.');
      return false;
    } else if (tenant.length < 1) {
      message.error('Tenant is missing.');
      return false;
    } else return true;
  };

  const onFinish = (values) => {
    let { email, password, tenant } = values;
    email = email.trim();
    password = password.trim();
    tenant = tenant.trim();
    let source = 'adminPanel';
    if (validateFunc(email, password, tenant)) {
      return dispatch(loginUser({ email, password, tenant, source, navigate }));
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return [onFinish, onFinishFailed, loading];
};

export default UseAuth;
