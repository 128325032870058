import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleFetchLearnerTypes({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v2/types?type=learner`,
      {}
    );
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchLearnerTypesSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchLearnerTypesError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateLearnerTypes({ data }) {
  const { position, id, name } = data;

  try {
    const response = yield axios.put(
      `${baseURL}/api/v2/types/${id}?type=learner`,
      {
        name,
        position,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateLearnerTypesSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateLearnerTypesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateLearnerTypesError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleDeleteLearnerTypes({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/types/${id}?type=learner`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteLearnerTypesSuccess(id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteLearnerTypesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteLearnerTypesError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleCreateLearnerTypes({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData.id;
    const response = yield axios.post(`${baseURL}/api/v2/types`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createLearnerTypesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createLearnerTypesError(data.id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateLearnerOrder({ data }) {
  const fd = new FormData();
  data.map((el, i) => fd.append(`types[${el.id}]`, i + 1));

  try {
    const response = yield axios.patch(`${baseURL}/api/v2/types/sort`, fd);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateLearnerTypesOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateLearnerTypesOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateLearnerTypesOrderError());
    AntdAlert.error('Something went wrong!');
  }
}
