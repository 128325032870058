const CREATE_CLASS_SESSIONS = 'CREATE_CLASS_SESSIONS';
const CREATE_CLASS_SESSIONS_SUCCESS = 'CREATE_CLASS_SESSIONS_SUCCESS';
const CREATE_CLASS_SESSIONS_ERROR = 'CREATE_CLASS_SESSIONS_ERROR';
const UPDATE_CLASS_SESSIONS = 'UPDATE_CLASS_SESSIONS';
const UPDATE_CLASS_SESSIONS_SUCCESS = 'UPDATE_CLASS_SESSIONS_SUCCESS';
const UPDATE_CLASS_SESSIONS_ERROR = 'UPDATE_CLASS_SESSIONS_ERROR';
const FETCH_THE_CLASS_SESSIONS = 'FETCH_CLASS_SESSION';
const FETCH_CLASS_SESSIONS_SUCCESS = 'FETCH_CLASS_SESSION_SUCCESS';
const FETCH_CLASS_SESSIONS_ERROR = 'FETCH_CLASS_SESSION_ERROR';
const CLASS_SESSIONS_DETAILS_IS_EDIT = 'CLASS_SESSIONS_DETAILS_IS_EDIT';
const ADD_USER_IN_MANAGER = 'ADD_USER_IN_MANAGER';
const FETCH_CLASS_SESSIONS_UTIL_DATA = 'FETCH_CLASS_SESSIONS_UTIL_DATA';
const FETCH_CLASS_SESSIONS_UTIL_DATA_SUCCESS =
  'FETCH_CLASS_SESSIONS_UTIL_DATA_SUCCESS';
const FETCH_ALL_CLASS_SESSIONS = 'FETCH_ALL_CLASS_SESSIONS';
const FETCH_ALL_CLASS_SESSIONS_SUCCESS = 'FETCH_ALL_CLASS_SESSIONS_SUCCESS';
const FETCH_ALL_CLASS_SESSIONS_ERROR = ' FETCH_ALL_CLASS_SESSIONS_ERROR';
const DELETE_CLASS_SESSIONS = 'DELETE_CLASS_SESSIONS';
const DELETE_CLASS_SESSIONS_SUCCESS = 'DELETE_CLASS_SESSIONS_SUCCESS';
const DELETE_CLASS_SESSIONS_ERROR = 'DELETE_CLASS_SESSIONS_ERROR';
const DELETE_CLASS_SESSIONS_ATTACH = 'DELETE_CLASS_SESSIONS_ATTACH';
const DELETE_CLASS_SESSIONS_ATTACH_SUCCESS =
  'DELETE_CLASS_SESSIONS_ATTACH_SUCCESS';
const DELETE_CLASS_SESSIONS_ATTACH_ERROR = 'DELETE_CLASS_SESSIONS_ATTACH_ERROR';
const CLASS_SESSIONS_DETAIL_CLEANUP_FUNC = 'CLASS_SESSIONS_DETAIL_CLEANUP_FUNC';
const CLASS_SESSIONS_NOTIF_IS_EDIT = 'CLASS_SESSIONS_NOTIF_IS_EDIT';
const UPDATE_CLASS_SESSIONS_NOTIFICATION = 'UPDATE_CLASS_SESSIONS_NOTIFICATION';
const UPDATE_CLASS_SESSIONS_NOTIFICATION_SUCCESS =
  'UPDATE_CLASS_SESSIONS_NOTIFICATION_SUCCESS';
const UPDATE_CLASS_SESSIONS_NOTIFICATION_ERROR =
  'UPDATE_CLASS_SESSIONS_NOTIFICATION_ERROR';
const UPDATE_ENROLLMENTS = 'UPDATE_CLASS_SESSIONS_ENROLLMENTS';
const UPDATE_ENROLLMENTS_SUCCESS = 'UPDATE_CLASS_SESSIONS_ENROLLMENTS_SUCCESS';
const UPDATE_ENROLLMENTS_ERROR = 'UPDATE_CLASS_SESSIONS_ENROLLMENTS_ERROR';
const ENROLLMENTS_IS_EDIT = 'CLASS_SESSIONS_ENROLLMENTS_IS_EDIT';
const SET_CLASS_SESSIONS_MANAGER_STATE = 'SET_CLASS_SESSIONS_MANAGER_STATE';

export {
  CREATE_CLASS_SESSIONS,
  CREATE_CLASS_SESSIONS_SUCCESS,
  CREATE_CLASS_SESSIONS_ERROR,
  UPDATE_CLASS_SESSIONS,
  UPDATE_CLASS_SESSIONS_SUCCESS,
  UPDATE_CLASS_SESSIONS_ERROR,
  FETCH_THE_CLASS_SESSIONS,
  FETCH_CLASS_SESSIONS_SUCCESS,
  FETCH_CLASS_SESSIONS_ERROR,
  ADD_USER_IN_MANAGER,
  CLASS_SESSIONS_DETAILS_IS_EDIT,
  FETCH_CLASS_SESSIONS_UTIL_DATA,
  FETCH_CLASS_SESSIONS_UTIL_DATA_SUCCESS,
  FETCH_ALL_CLASS_SESSIONS,
  FETCH_ALL_CLASS_SESSIONS_SUCCESS,
  FETCH_ALL_CLASS_SESSIONS_ERROR,
  DELETE_CLASS_SESSIONS,
  DELETE_CLASS_SESSIONS_SUCCESS,
  DELETE_CLASS_SESSIONS_ERROR,
  DELETE_CLASS_SESSIONS_ATTACH,
  DELETE_CLASS_SESSIONS_ATTACH_SUCCESS,
  DELETE_CLASS_SESSIONS_ATTACH_ERROR,
  CLASS_SESSIONS_DETAIL_CLEANUP_FUNC,
  CLASS_SESSIONS_NOTIF_IS_EDIT,
  UPDATE_CLASS_SESSIONS_NOTIFICATION,
  UPDATE_CLASS_SESSIONS_NOTIFICATION_SUCCESS,
  UPDATE_CLASS_SESSIONS_NOTIFICATION_ERROR,
  UPDATE_ENROLLMENTS,
  UPDATE_ENROLLMENTS_SUCCESS,
  UPDATE_ENROLLMENTS_ERROR,
  ENROLLMENTS_IS_EDIT,
  SET_CLASS_SESSIONS_MANAGER_STATE,
};
