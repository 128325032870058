import * as constants from './constants';

const initialState = {
  allLeadStatuses: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
};

const leadStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_LEAD_STATUSES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_LEAD_STATUSES_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allLeadStatuses: [...state.allLeadStatuses, action.data],
        isAdding: false,
      };
    case constants.CREATE_LEAD_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_ORDER_LEAD_STATUS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_ORDER_LEAD_STATUS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allLeadStatuses: action.data,
      };
    case constants.UPDATE_ORDER_LEAD_STATUS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_LEAD_STATUSES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_LEAD_STATUSES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allLeadStatuses: action.data,
        isAdding: false,
      };
    case constants.FETCH_LEAD_STATUSES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_LEAD_STATUSES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_LEAD_STATUSES_SUCCESS:
      const updated = state.allLeadStatuses.map((el) =>
        el._id === action.data._id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allLeadStatuses: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_LEAD_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_LEAD_STATUSES:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_LEAD_STATUSES_SUCCESS:
      const updatedData = state.allLeadStatuses.filter(
        (el) => el._id !== action.data
      );
      return {
        ...state,
        allLeadStatuses: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_LEAD_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    default:
      return state;
  }
};

export default leadStatusReducer;
