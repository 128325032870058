const FETCH_PRODUCT_TYPES = 'FETCH_PRODUCT_TYPES';
const FETCH_PRODUCT_TYPES_SUCCESS = 'FETCH_PRODUCT_TYPES_SUCCESS';
const FETCH_PRODUCT_TYPES_ERROR = 'FETCH_PRODUCT_TYPES_ERROR';
const UPDATE_PRODUCT_TYPES = 'UPDATE_PRODUCT_TYPES';
const UPDATE_PRODUCT_TYPES_SUCCESS = 'UPDATE_PRODUCT_TYPES_SUCCESS';
const UPDATE_PRODUCT_TYPES_ERROR = 'UPDATE_PRODUCT_TYPES_ERROR';
const DELETE_PRODUCT_TYPES = 'DELETE_PRODUCT_TYPES';
const DELETE_PRODUCT_TYPES_SUCCESS = 'DELETE_PRODUCT_TYPES_SUCCESS';
const DELETE_PRODUCT_TYPES_ERROR = 'DELETE_PRODUCT_TYPES_ERROR';
const CREATE_PRODUCT_TYPES = 'CREATE_PRODUCT_TYPES';
const CREATE_PRODUCT_TYPES_SUCCESS = 'CREATE_PRODUCT_TYPES_SUCCESS';
const CREATE_PRODUCT_TYPES_ERROR = 'CREATE_PRODUCT_TYPES_ERROR';
const UPDATE_ORDER_PRODUCT_TYPES = 'UPDATE_ORDER_PRODUCT_TYPES';
const UPDATE_ORDER_PRODUCT_TYPES_SUCCESS = 'UPDATE_ORDER_PRODUCT_TYPES_SUCCESS';
const UPDATE_ORDER_PRODUCT_TYPES_ERROR = 'UPDATE_ORDER_PRODUCT_TYPES_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';

export {
  FETCH_PRODUCT_TYPES,
  FETCH_PRODUCT_TYPES_SUCCESS,
  FETCH_PRODUCT_TYPES_ERROR,
  UPDATE_PRODUCT_TYPES,
  UPDATE_PRODUCT_TYPES_SUCCESS,
  UPDATE_PRODUCT_TYPES_ERROR,
  DELETE_PRODUCT_TYPES,
  DELETE_PRODUCT_TYPES_SUCCESS,
  DELETE_PRODUCT_TYPES_ERROR,
  CREATE_PRODUCT_TYPES,
  CREATE_PRODUCT_TYPES_SUCCESS,
  CREATE_PRODUCT_TYPES_ERROR,
  UPDATE_ORDER_PRODUCT_TYPES,
  UPDATE_ORDER_PRODUCT_TYPES_SUCCESS,
  UPDATE_ORDER_PRODUCT_TYPES_ERROR,
  IS_ADDING_UPDATE,
};
