import React, { useEffect, useState } from 'react';
import { Table, Input, Col, Row, Select } from 'antd';
// import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
// import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import _ from 'lodash';
// import { capitalizeFirstLetterofEachWord } from '../../../globalUtils/capitalizeFirstLetterofEachWord';
// import { genderOptions } from '../../UserManager/userDetails/utils';
// import { userTypeOptions } from '../../UserManager/utils';
import { useNavigate } from 'react-router-dom';
import Player from './audio';
import { baseURL } from '../../../globalUtils/axiosConfig';

const { Option } = Select;

function AttemptsTable({ attempts }) {
  const [data, setData] = useState(attempts);
  const [sortedInfo, setSortedInfo] = useState(null);
  // images contains all attachments of item
  const [search, setSearch] = useState({});
  const [showTable, setShowTable] = useState(true);
  const [req, setReq] = useState(false);
  const navigate = useNavigate();

  const filterData = () => {
    let siteFilteredData = attempts.filter((entry) =>
      search?.class?.length > 0
        ? entry.class?.id
            .toLocaleLowerCase()
            .includes(search?.class?.toLocaleLowerCase())
        : data
    );

    let coachFilteredData = siteFilteredData.filter((entry) => {
      let coachInputLength = search?.curriculum?.length;
      if (coachInputLength > 0) {
        const { curriculum } = entry;
        return curriculum?.name
          ?.toLocaleLowerCase()
          .includes(search?.curriculum?.toLocaleLowerCase());
      } else return siteFilteredData;
    });

    let classFilteredData = coachFilteredData.filter((entry) => {
      let inputLength = search?.student?.length;
      if (inputLength > 0) {
        const { student } = entry;

        let name = '';

        if (student) {
          name = `${student.user_id} -${student?.first_name} ${student?.last_name}`;
        }
        return name
          .toLocaleLowerCase()
          .includes(search?.student?.toLocaleLowerCase());
      } else return coachFilteredData;
    });

    let topicFilteredData = classFilteredData.filter((entry) => {
      let length = search?.attempt_type?.length;
      if (length > 0) {
        const { attempt_type } = entry;
        return attempt_type
          .toLocaleLowerCase()
          .includes(search?.attempt_type?.toLocaleLowerCase());
      } else return classFilteredData;
    });

    let npoFilteredData = topicFilteredData.filter((entry) => {
      let length = search?.is_present?.length;
      if (length > 0) {
        let attendance = getAttendance(entry.is_present, entry.is_late);
        return attendance
          .toLocaleLowerCase()
          .includes(search?.is_present.toLocaleLowerCase());
      } else return topicFilteredData;
    });

    let typeFilteredData = npoFilteredData.filter((entry) => {
      let length = search?.status?.length;
      if (length > 0) {
        const status = entry?.curriculum_student?.status;

        return status.includes(search?.status);
      } else return npoFilteredData;
    });

    setData(typeFilteredData);
  };

  useEffect(() => {
    filterData();
  }, [req]);

  useEffect(() => {
    setData(attempts);
  }, [attempts]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const searchInput = (key, disabled = false) => (
    <Input
      placeholder={''}
      onClick={(e) => e.stopPropagation()}
      disabled={disabled}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedCallApi();
      }}
    />
  );

  const getAttendance = (is_present, is_late) => {
    let text = '';

    if (is_present === true) {
      text = is_late ? 'Late' : 'Present';
    } else if (is_present === false) {
      text = 'Absent';
    }
    // else {
    //   text = 'Not Marked';
    // }

    return text;
  };

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Class ID</div>
            <div>{searchInput('class')}</div>
          </div>
        );
      },
      dataIndex: 'class',
      key: 'class',
      sorter: (a, b) =>
        a.class?.id?.toLowerCase()?.localeCompare(b.class?.id?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'class' && sortedInfo?.order,
      ellipsis: true,
      width: '12%',
      render: (text) => <div>{text?.id}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>curriculum</div>
            <div>{searchInput('curriculum')}</div>
          </div>
        );
      },
      dataIndex: 'curriculum',
      key: 'curriculum',
      sorter: (a, b) =>
        a.curriculum?.name
          ?.toLowerCase()
          ?.localeCompare(b.curriculum?.name?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'curriculum' && sortedInfo?.order,
      ellipsis: true,
      width: '18%',
      render: (curriculum) => <div>{curriculum?.name}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>student</div>
            <div>{searchInput('student')}</div>
          </div>
        );
      },
      dataIndex: 'student',
      key: 'student',
      sorter: (a, b) =>
        a.student?.user_id
          ?.toLowerCase()
          ?.localeCompare(b.student?.user_id?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'student' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      render: (student) => {
        let name = '';
        if (student) {
          name = `${student.user_id} - ${student?.first_name} ${student?.last_name}`;
        }
        return <div>{name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>teacher</div>
            <div>{searchInput('teacher')}</div>
          </div>
        );
      },
      dataIndex: 'teacher',
      key: 'teacher',
      sorter: (a, b) =>
        a.teacher?.name
          ?.toLowerCase()
          ?.localeCompare(b.teacher?.name?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'teacher' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      render: (teacher) => {
        let name = '';
        if (teacher) {
          name = `${teacher?.first_name} ${teacher?.last_name}`;
        }
        return <div>{name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Attendance</div>
            <div>{searchInput('is_present')}</div>
          </div>
        );
      },
      dataIndex: 'is_present',
      key: 'is_present',
      sorter: (a, b) => {
        let attendanceA = getAttendance(a.is_present, a.is_late);
        let attendanceB = getAttendance(b.is_present, b.is_late);

        return attendanceA?.localeCompare(attendanceB);
      },
      sortOrder: sortedInfo?.columnKey === 'is_present' && sortedInfo?.order,
      ellipsis: true,
      width: '11%',
      align: 'center',
      render: (is_present, record) => {
        const isLate = record?.is_late;
        let attendance = getAttendance(is_present, isLate);
        return <div>{attendance}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Attempt type</div>
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select Project'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                onChange={(e) => {
                  setSearch({
                    ...search,
                    attempt_type: e,
                  });
                  debouncedCallApi();
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {[
                  {
                    key: 'Reading',
                    label: 'Reading',
                  },
                  {
                    key: 'CurriculumMove',
                    label: 'Curriculum Move',
                  },
                  {
                    key: 'PreAssessment',
                    label: 'Pre Assessment',
                  },
                  {
                    key: 'FinalAssessment',
                    label: 'Final Assessment',
                  },
                ]?.map((el) => {
                  return (
                    <Option value={el.key} label={el.label}>
                      {el.label}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'attempt_type',
      key: 'attempt_type',
      sorter: (a, b) => a.attempt_type?.localeCompare(b.attempt_type),
      sortOrder: sortedInfo?.columnKey === 'attempt_type' && sortedInfo?.order,
      ellipsis: true,
      width: '11%',
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Status</div>
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select Project'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                onChange={(e) => {
                  setSearch({
                    ...search,
                    status: e,
                  });
                  debouncedCallApi();
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {['inprogress', 'passed', 'failed', 'cancelled']?.map((el) => {
                  return (
                    <Option value={el} label={el.toUpperCase()}>
                      {el.toUpperCase()}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) =>
        a.curriculum_student?.status
          ?.toLowerCase()
          ?.localeCompare(b.curriculum_student?.status?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      ellipsis: true,
      width: '12%',
      align: 'center',
      render: (status, record) => {
        return <div>{record?.curriculum_student?.status?.toUpperCase()}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Student recording</div>
            <div>{searchInput('student_recording_path', true)}</div>
          </div>
        );
      },
      dataIndex: 'student_recording_path',
      key: 'student_recording_path',
      sorter: (a, b) =>
        a.student_recording_path?.name
          ?.toLowerCase()
          ?.localeCompare(b.student_recording_path?.name?.toLowerCase()),
      sortOrder: null,
      // sortedInfo?.columnKey === 'student_recording_path' && sortedInfo?.order,
      ellipsis: true,
      width: '30%',
      render: (student_recording_path) => {
        if (student_recording_path) {
          return (
            <Player
              url={`${baseURL}/${student_recording_path}`}
              layout='horizontal'
              showJumpControls={false}
              customAdditionalControls={[]}
              showFilledVolume={false}
            />
          );
        }
      },
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    if (sorter.field === 'student_recording_path') {
      return;
    }
    setSortedInfo(sorter);
  };

  return (
    <Row className='revisions-parent-row' style={{ minHeight: '20vh' }}>
      <Col span={24} className='heading-col'>
        <Row align='middle'>
          <Col>
            <div className='revisions-heading-div'>
              {/* <div onClick={() => setShowTable(!showTable)}>
                {showTable ? (
                  <UpCircleOutlined className='revision-up-down-icon' />
                ) : (
                  <DownCircleOutlined className='revision-up-down-icon' />
                )}
              </div> */}
              <div>
                <span className='heading-span'>
                  Attempts {attempts.length > 0 && ` (${attempts.length})`}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>

      {showTable && (
        <Col span={24}>
          <Table
            scroll={{ x: 1100 }}
            className='special-table ims-doc-table users-manager-table'
            rowKey={(record) => record._id}
            // rowClassName='editable-row'
            dataSource={data}
            columns={columns}
            onChange={handleChange}
            pagination={false}
            expandable={{
              showExpandColumn: false,
            }}
          />
        </Col>
      )}
    </Row>
  );
}

export default AttemptsTable;
