import axios from 'axios';

const errorHandler = (commonResponse) => {
  if (
    commonResponse?.message?.toLowerCase() === 'invalid authorization token'
  ) {
    localStorage.clear();
    localStorage.removeItem('user');
    axios.defaults.headers.common['Authorization'] = null;
    // message.error(commonResponse.data?.message || "Something went wrong!");
    window.location.replace('/');
  }
};

export { errorHandler };
