import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spin } from 'antd';
import { ReactComponent as BackIcon } from '../../../assets/back-icon.svg';
import { fetchFormSetup, templateCreationCleanupFunc } from '../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import FormDetail from './details';
import '../index.less';

const FormSetupManagerDetails = () => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { currentTemplateObj, fetchLoading } = useSelector(
    (state) => state.formSetupReducer
  );

  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (user?.permissions?.form_manager?.add_edit) {
      setCanUpdate(true);
    }
  }, [user]);

  useEffect(() => {
    if (currentTemplateObj?._id) {
      setIsUpdate(true);
    }
  }, [currentTemplateObj]);

  useEffect(() => {
    if (params?.id) {
      setIsUpdate(true);
      dispatch(fetchFormSetup(params?.id));
    }

    return () => {
      dispatch(templateCreationCleanupFunc());
    };
  }, [params.id]);

  return (
    <div className='page-top-div heading-parent-div'>
      <Row justify='space-between'>
        <Col>
          <span className='text-span'>
            {isUpdate ? 'Update ' : 'Add '} FORM
          </span>
        </Col>
        <Col>
          <Button
            onClick={() => navigate('/settings/form-manager/form-setup')}
            icon={<BackIcon width={24} height={14} />}
            className='back-btn'
            size='middle'
          >
            BACK
          </Button>
        </Col>
      </Row>
      <Spin spinning={fetchLoading}>
        <div className='user-manager-parent-div'>
          <FormDetail isUpdate={isUpdate} canUpdate={canUpdate} />
        </div>
      </Spin>
    </div>
  );
};

export default FormSetupManagerDetails;
