import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import * as constants from '../constants';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleFetchAllFormSetups() {
  try {
    const response = yield axios.get(`${baseURL}/form_setup`);
    const { success, data } = response.data;
    if (success) {
      yield put(actions.fetchAllFormSetupsSuccess(data));
    }
    errorHandler(response.data);
  } catch (error) {
    AntdAlert.error('Something went wrong!');
    yield put(actions.fetchAllFormSetupsError());
    console.log(error);
  }
}

export function* handleUpdateFormSetups({ data }) {
  const { navigate, _id, ...rest } = data;
  try {
    const response = yield axios.patch(`${baseURL}/form_setup/${_id}`, {
      ...rest,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateFormSetupsSuccess(data));
      navigate('/settings/form-manager/form-setup');
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateFormSetupsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateFormSetupsError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}
export function* handleDeleteFormSetup({ data }) {
  try {
    const response = yield axios.delete(`${baseURL}/form_setup/${data._id}`);
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteFormSetupSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteFormSetupError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteFormSetupError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleCreateFormSetup({ data }) {
  const { navigate, ...rest } = data;
  try {
    const response = yield axios.post(`${baseURL}/form_setup`, {
      ...rest,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.createFormSetupSuccess(response.data.data));
      navigate('/settings/form-manager/form-setup');
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createFormSetupError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchFormSetup(data) {
  try {
    const response = yield axios.get(`${baseURL}/form_setup/${data.data}`);
    console.log('response', response.data);
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchFormSetupSuccess(response.data.data));
    }
    errorHandler(response.data);
  } catch (error) {
    AntdAlert.error('Something went wrong!');
    yield put(actions.fetchFormSetupError());
    console.log(error);
  }
}

export function* watcherFormSetupSaga() {
  yield takeLatest(constants.CREATE_FORM_SETUP, handleCreateFormSetup);
  yield takeLatest(constants.FETCH_ALL_FORM_SETUPS, handleFetchAllFormSetups);
  yield takeLatest(constants.FETCH_FORM_SETUP, handleFetchFormSetup);
  yield takeLatest(constants.UPDATE_FORM_SETUP, handleUpdateFormSetups);
  yield takeLatest(constants.DELETE_FORM_SETUP, handleDeleteFormSetup);
}
