import * as constants from './constants';

export const fetchComments = (data) => {
  return {
    type: constants.FETCH_COMMENTS,
    data,
  };
};

export const fetchCommentsSuccess = (data) => {
  return {
    type: constants.FETCH_COMMENTS_SUCCESS,
    data: data,
  };
};

export const fetchCommentsError = (data) => {
  return {
    type: constants.FETCH_COMMENTS_ERROR,
    data: data,
  };
};

export const updateComments = (data) => {
  return {
    type: constants.UPDATE_COMMENTS,
    data: data,
  };
};

export const updateCommentsSuccess = (data) => {
  return {
    type: constants.UPDATE_COMMENTS_SUCCESS,
    data: data,
  };
};

export const updateCommentsError = () => {
  return {
    type: constants.UPDATE_COMMENTS_ERROR,
  };
};

export const updateCommentsOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_COMMENTS,
    data: data,
  };
};

export const updateCommentsOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_COMMENTS_SUCCESS,
    data: data,
  };
};

export const updateCommentsOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_COMMENTS_ERROR,
  };
};

export const deleteComments = (data) => {
  return {
    type: constants.DELETE_COMMENTS,
    data: data,
  };
};

export const deleteCommentsSuccess = (data) => {
  return {
    type: constants.DELETE_COMMENTS_SUCCESS,
    data: data,
  };
};

export const deleteCommentsError = () => {
  return {
    type: constants.DELETE_COMMENTS_ERROR,
  };
};

export const createComments = (data, id) => {
  return {
    type: constants.CREATE_COMMENTS,
    data: data,
  };
};

export const createCommentsSuccess = (data) => {
  return {
    type: constants.CREATE_COMMENTS_SUCCESS,
    data: data,
  };
};

export const createCommentsError = (id) => {
  return {
    type: constants.CREATE_COMMENTS_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};

export const setStorage = (data) => {
  return {
    type: constants.SET_COMMENTS_MANAGER_STATE,
    data,
  };
};
