import React from 'react';
import { Checkbox, Col, DatePicker, Form, Input, Row } from 'antd';
import { ReactComponent as DateIcon } from '../../../../../assets/date-icon.svg';
import SignaturePadComponent from './signaturePad';
import moment from 'moment';

const emailRegex =
  /^[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const SectionE = ({ data, setData }) => {
  const FullNameInput = () => {
    return (
      <Form.Item
        name='declarationFullName'
        label=''
        style={{ marginBottom: 0, width: '100%' }}
      >
        <Input size='small' />
      </Form.Item>
    );
  };

  const EmailInput = () => {
    return (
      <Form.Item
        rules={[
          {
            pattern: emailRegex,
            message: 'Invalid Email!',
          },
        ]}
        name='declarationEmail'
        label=''
        style={{ marginBottom: 0, width: '100%' }}
      >
        <Input size='small' placeholder='Email Address' />
      </Form.Item>
    );
  };

  const DatePickerComponent = () => {
    return (
      <DatePicker
        size='middle'
        placeholder='Select Date'
        suffixIcon={<DateIcon />}
        format='DD MMM YYYY'
        allowClear={false}
        value={data?.signatureDate ? moment(data.signatureDate) : null}
        onChange={(e) => {
          setData((prev) => ({
            ...prev,
            signatureDate: moment(e).format('DD MMM YYYY'),
          }));
        }}
      />
    );
  };

  const handleChange = (e) => {
    const { value, checked, keyName } = e.target;
    let updatedValue = checked ? value : null;
    setData({ ...data, [keyName]: updatedValue });
  };

  const CheckBoxComponent = ({ value, label, keyName }) => {
    const isSelected = Boolean(data[keyName]);
    return (
      <Checkbox
        onChange={handleChange}
        value={value}
        keyName={keyName}
        checked={data[keyName] === value}
        style={{
          lineHeight: '32px',
        }}
        disabled={isSelected && data[keyName] !== value}
      >
        <div className='desc-parent'>
          <div className={`price`}>{label}</div>
        </div>
      </Checkbox>
    );
  };

  return (
    <Col span={22} className='section-e'>
      <div className='first-name-div'>
        <p>I (Full Name and Surname)</p>
        <FullNameInput />
      </div>
      <p>
        hereby authorise Vodacom (Pty) Ltd to conduct the necessary credit
        vetting for a Vodacom service as detailed above and, if the Vodacom
        service contract is successfully activated, to debit my bank account
        with the total amount payable on my monthly Vodacom account.
        <br />
        I have read, understand and agree to be bound by the terms and
        conditions of the Vodacom Fibre Services and declare that the
        information given above is true and correct.
        <br />
        <br />
        <br />
        I, the undersigned, declare, agree and confirm that:
        <br />
        <br />
        If acting in a representative capacity that I am duly and fully
        authorised to do so. I personally hereby indemnify Vodacom of any
        damages suffered by it, should it at any stage appear that I’m not so
        authorised.
        <br />
        <br />
        The information supplied herein with regard to me and the Applicant is
        complete, true and correct as at date of signature/electronic processing
        hereof.
        <br />
        <br />I (and/or the Applicant) am bound to the terms and conditions
        applicable to the transaction, including but not limited to: Vodacom’s
        standard terms and conditions, available at
        http://www.vodacom.co.za/vodacom/terms/fibre/vodacom-fibre
        <br />
        <br />
        Product-specific terms and conditions related to the service and/or
        product I have applied for; as indicated on any promotional material
        and/or on Vodacom’s official product website (http://www.vodacom.co.za )
        and/or communicated to me during an application process.
        <br />
        <br />I declare myself familiar with and bound to the content of said
        terms and conditions.
        <br />
        <br />
        All these terms and conditions are available online and will be made
        available to me in printed version or may be emailed to me, if I so
        request it.
      </p>
      <Row>
        <Col span={14} className='terms-col bold'>
          <Row justify='start' align='middle'>
            <Col>
              <div className=''>
                Do you require a copy of the terms and conditions?
              </div>
            </Col>
            <Col>
              <CheckBoxComponent
                value='yes'
                label='Yes'
                keyName='requireCopyOfTermsAndConditions'
              />
            </Col>
            <Col>
              <CheckBoxComponent
                value='no'
                label='No'
                keyName='requireCopyOfTermsAndConditions'
              />
            </Col>
          </Row>
        </Col>
        <Col span={10} className='terms-col bold'>
          <Row justify='start' align='middle'>
            <Col>
              <div className=''>If yes,</div>
            </Col>
            <Col>
              <CheckBoxComponent
                value='printed'
                label='Printed'
                keyName='printed'
              />
            </Col>
            <Col>
              <div style={{ paddingTop: '3px' }}>or</div>
            </Col>
            <Col>
              <CheckBoxComponent value='soft' label='Soft' keyName='printed' />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className='email-name-div'>
            <div className='bold'>Email Address</div>
            <EmailInput />
          </div>
        </Col>
        <Col span={24} className='signature-section'>
          <Row justify='center' align='bottom'>
            <Col span={14}>
              <Row justify='center'>
                <Col span={22} className='signature-col'>
                  <div className='signature-div'>
                    <SignaturePadComponent
                      signature={data?.signature}
                      setData={setData}
                    />
                  </div>
                </Col>
                <Col>
                  <p className='bottom-text'>Customer Signature</p>
                </Col>
              </Row>
            </Col>
            <Col span={6} className='date-col'>
              <div className='date-parent-div'>
                <DatePickerComponent />
                <p className='bottom-text'>Date</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default SectionE;
